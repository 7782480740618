import { Router } from '@angular/router';
import { PacienteService } from 'app/services/paciente.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PacienteGuard implements CanActivate {

  constructor(
    private authService: PacienteService,
    private router: Router
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) : Promise<boolean | UrlTree> {
    return await this.authService.isValidToken().then((value)=>{ 
      if(value) {
        return true;
      }
      else {
        this.router.navigate(['/login-paciente']);
        return false
      }
    })
  }
  
}
