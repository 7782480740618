<form [formGroup]="medicoForm" (submit)="crearMedico(medicoForm.value)" class="formCard">
    <div class="tituloModal">
        <span>
            Crear médico
        </span>
        <mat-icon class="buttonClose" [mat-dialog-close]=false>close</mat-icon>
    </div>
    <div class="textModal">
        <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
                <mat-label>Nombre apellido</mat-label>
                <input formControlName="nombreCompleto" type="text" matInput>
            </mat-form-field>
            <div class="valid">
                <span style="color:rgb(236,75,72);" *ngIf="medicoForm.get('nombreCompleto').errors?.required && medicoForm.get('nombreCompleto').touched">
                    El nombre y apellido es requerido
                </span>
            </div>
        </div>
        <div>
            <div class="formGroup documento">
                <div class="tipoDocumento">
                    <mat-form-field appearance="fill" class="input input1">
                        <mat-label>Tipo documento</mat-label>
                        <mat-select formControlName="tipoDocumento">
                            <mat-option *ngFor="let tipoDoc of tiposDocumentos" [value]="tipoDoc.id">{{tipoDoc.texto}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="valid">
                        <span style="color:rgb(236,75,72);" *ngIf="errorTipoDocumento">
                            El tipo de documento es requerido con el numero de documento
                        </span>
                    </div> 
                </div>
                <div class="numeroDocumento">
                    <mat-form-field class="example-full-width input input2" appearance="fill">
                        <mat-label>Número documento</mat-label>
                        <input formControlName="numeroDocumento" (ngModelChange)="clearNumeroDocumento()" type="text" matInput>
                    </mat-form-field>
                    <div class="valid">
                        <span style="color:rgb(236,75,72);" *ngIf="errorNumeroDocumento">
                            El número de documento es requerido con el tipo de documento
                        </span>
                    </div> 
                </div>
            </div>
            <span style="color:rgb(236,75,72);" *ngIf="errorDocumento">
                Este tipo y número de documento fue registrado para otro médico
            </span>
        </div>
        <div class="formGroup">
            <mat-form-field class="formFile"> 
                <div class="myfilebrowser">
                  <mat-toolbar>
                    <input matInput [(ngModel)]="myfilename" readonly formControlName="memberContactNo" />
                    <button mat-raised-button class="fileButton" color="primary">
                      Buscar
                    </button>
                  </mat-toolbar>
                  <input type="button" #UploadFileInput id="fileUpload" (click)="openDialogFoto()" name="fileUpload"
                      accept="image/*" />
                </div>
            </mat-form-field>
            <div class="divText">
                <span class="textAclaration">Imagen debe contar con el mismo alto y ancho, max 1200*1200</span>
            </div>
        </div>
        <div #divImagen class="divImagen">
            <img class="imagen" #mostrarImagen [src]="imagen">
        </div>
        <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
                <mat-label>Matricula</mat-label>
                <input formControlName="matricula" (ngModelChange)="clearMatricula()" type="text" matInput>
            </mat-form-field>
            <div class="valid">
                <span style="color:rgb(236,75,72);" *ngIf="medicoForm.get('matricula').errors?.required && medicoForm.get('matricula').touched">
                    La matricula es requerida
                </span>
                <span style="color:rgb(236,75,72);" *ngIf="errorMatricula && medicoForm.get('matricula').touched">
                    La matricula fue registrada por otro médico
                </span>
            </div>    
        </div>
        <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
                <mat-label>Email</mat-label>
                <input formControlName="email" type="email" (ngModelChange)="clearEmail()" matInput>
            </mat-form-field>
            <div class="valid">
                <span class="advertenciaMensaje" *ngIf="!medicoForm.get('email').value">
                    <mat-icon class="advertenciaIcono">report_problem</mat-icon>Si no se asigna un email, no le llegaran notificaciones que pueden ser importantes
                </span>
                <span style="color:rgb(236,75,72);" *ngIf="medicoForm.get('email').errors?.email && medicoForm.get('email').touched">
                    El email no tiene el formato correcto 
                </span>
                <span style="color:rgb(236,75,72);" *ngIf="errorEmail && medicoForm.get('email').touched">
                    El email fue registrado por otro medico
                </span>
            </div> 
        </div>
        <div class="formGroup telefonoForm">
            <mat-form-field appearance="fill" class="telefonoInput">
                <mat-label>Teléfono país</mat-label>
                <mat-select formControlName="telPais">
                    <mat-option>
                        <ngx-mat-select-search 
                            id="telPaisBuscador" 
                            [noEntriesFoundLabel]="'Not found'" 
                            [placeholderLabel]="'Buscador'" 
                            [formControl]="telPaisBuscador"
                            name="filterPost" 
                            (ngModelChange)="filtrarTelPais($event)">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let tel of filtroTelefonoPais" [value]="tel.codigo">
                        {{tel.nombre}} ({{tel.codigo}})
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="example-full-width" class="telefonoInput" appearance="fill">
                <mat-label>Teléfono caract.</mat-label>
                <input formControlName="telCaract" type="text" matInput>
            </mat-form-field>
            <mat-form-field class="example-full-width" class="telefonoInput" appearance="fill">
                <mat-label>Teléfono número </mat-label>
                <input formControlName="telNumero" type="text" matInput>
            </mat-form-field>
        </div>
        <div class="formGroup telefonoForm">
            <mat-form-field appearance="fill" class="telefonoInput">
                <mat-label>Celular país</mat-label>
                <mat-select formControlName="celPais">
                    <mat-option>
                        <ngx-mat-select-search 
                            id="celPaisBuscador" 
                            [noEntriesFoundLabel]="'Not found'" 
                            [placeholderLabel]="'Buscador'" 
                            [formControl]="celPaisBuscador"
                            name="filterPost" 
                            (ngModelChange)="filtrarCelPais($event)">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let tel of filtroCelularPais" [value]="tel.codigo">
                        {{tel.nombre}} ({{tel.codigo}})
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="fill" class="telefonoInput">
                <mat-label>Celular caract.</mat-label>
                <input formControlName="celCaract" type="text" matInput>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="fill" class="telefonoInput">
                <mat-label>Celular número</mat-label>
                <input formControlName="celNumero" type="text" matInput>
            </mat-form-field>
        </div>
        <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
                <mat-label>Código interno</mat-label>
                <input formControlName="codigoInterno" type="text" matInput>
            </mat-form-field>
        </div>
        <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
                <mat-label>Password</mat-label>
                <input formControlName="password" type="password" matInput>
            </mat-form-field>
        </div>
        <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
                <mat-label>Repetir password</mat-label>
                <input formControlName="repeatPassword" type="password" matInput>
            </mat-form-field>
            <div class="valid">
                <span style="color:rgb(236,75,72);" *ngIf="medicoForm.hasError('mismatch') && medicoForm.get('repeatPassword').touched">
                    Los passwords no son iguales
                </span>
            </div>
        </div>
        <div class="formGroup formAgenda">
            <div class="agenda">
                <mat-slide-toggle
                    formControlName="agenda"
                    class="example-margin"
                    color="primary"
                    [disabled]="disabled"
                    (change)="cambiarAgenda($event)"
                >
                    Agenda
                </mat-slide-toggle>
            </div>
            <div *ngIf="agenda">
                <input formControlName="color_agenda" type="color">
            </div>
        </div>
        <div class="valid">
            <span style="color:rgb(236,75,72);" *ngIf="errorAgenda">
                Se alcanzo el límite de agendas habilitadas
            </span>
        </div>
        <div class="formGroup listaDias" [ngClass]="{'invisible': !agenda}"> 
            <div class="divFechas">
                <div class="divFecha">
                    <div class="fecha">
                        <mat-form-field class="formFieldFecha">
                            <mat-label>Lunes desde</mat-label>
                            <input class="inputFecha" matInput [format]="24" [ngxTimepicker]="pickerlunesDesde" formControlName="lunesDesde" readonly>
                        </mat-form-field>
                        <ngx-material-timepicker [theme]="darkTheme" #pickerlunesDesde></ngx-material-timepicker>
                        <div class="borrar divIcon" (click)="borrarFecha('lunesDesde')">
                            <mat-icon class="icon">delete_forever</mat-icon>
                        </div>
                    </div>
                    <div class="fecha">
                        <mat-form-field class="formFieldFecha">
                            <mat-label>Lunes hasta</mat-label>
                            <input class="inputFecha" matInput [format]="24" [ngxTimepicker]="pickerlunesHasta" formControlName="lunesHasta" readonly>
                        </mat-form-field>
                        <ngx-material-timepicker [theme]="darkTheme" #pickerlunesHasta></ngx-material-timepicker>
                        <div class="borrar divIcon" (click)="borrarFecha('lunesHasta')">
                            <mat-icon class="icon">delete_forever</mat-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf="medicoForm.hasError('lunesError1')" style="color:rgb(236,75,72);">
                    la fecha hasta es requerida si se agrega la fecha desde 
                </div>
                <div *ngIf="medicoForm.hasError('lunesError2')" style="color:rgb(236,75,72);">
                    la fecha desde es requerida si se agrega la fecha hasta
                </div>
                <div *ngIf="medicoForm.hasError('lunesError3')" style="color:rgb(236,75,72);">
                    la fecha hasta tiene que ser posterior a la fecha desde
                </div>
            </div>
            <div class="divFechas">
                <div class="divFecha">
                    <div class="fecha">
                        <mat-form-field class="formFieldFecha">
                            <mat-label>Martes desde</mat-label>
                            <input class="inputFecha" matInput [format]="24" [ngxTimepicker]="pickerMartesDesde" formControlName="martesDesde" readonly>
                        </mat-form-field>
                        <ngx-material-timepicker [theme]="darkTheme" #pickerMartesDesde></ngx-material-timepicker>
                        <div class="borrar divIcon" (click)="borrarFecha('martesDesde')">
                            <mat-icon class="icon">delete_forever</mat-icon>
                        </div>
                    </div>
                    <div class="fecha">
                        <mat-form-field class="formFieldFecha">
                            <mat-label>Martes hasta</mat-label>
                            <input class="inputFecha" matInput [format]="24" [ngxTimepicker]="pickerMartesHasta" formControlName="martesHasta" readonly>
                        </mat-form-field>
                        <ngx-material-timepicker [theme]="darkTheme" #pickerMartesHasta></ngx-material-timepicker>
                        <div class="borrar divIcon" (click)="borrarFecha('martesHasta')">
                            <mat-icon class="icon">delete_forever</mat-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf="medicoForm.hasError('martesError1')" style="color:rgb(236,75,72);">
                    la fecha hasta es requerida si se agrega la fecha desde 
                </div>
                <div *ngIf="medicoForm.hasError('martesError2')" style="color:rgb(236,75,72);">
                    la fecha desde es requerida si se agrega la fecha hasta
                </div>
                <div *ngIf="medicoForm.hasError('martesError3')" style="color:rgb(236,75,72);">
                    la fecha hasta tiene que ser posterior a la fecha desde
                </div>
            </div>
            <div class="divFechas">
                <div class="divFecha">
                    <div class="fecha">
                        <mat-form-field class="formFieldFecha">
                            <mat-label>Miercoles desde</mat-label>
                            <input class="inputFecha" matInput [format]="24" [ngxTimepicker]="pickerMiercolesDesde" formControlName="miercolesDesde" readonly>
                        </mat-form-field>
                        <ngx-material-timepicker [theme]="darkTheme" #pickerMiercolesDesde></ngx-material-timepicker>
                        <div class="borrar divIcon" (click)="borrarFecha('miercolesDesde')">
                            <mat-icon class="icon">delete_forever</mat-icon>
                        </div>
                    </div>
                    <div class="fecha">
                        <mat-form-field class="formFieldFecha">
                            <mat-label>Miercoles hasta</mat-label>
                            <input class="inputFecha" matInput [format]="24" [ngxTimepicker]="pickerMiercolesHasta" formControlName="miercolesHasta" readonly>
                        </mat-form-field>
                        <ngx-material-timepicker [theme]="darkTheme" #pickerMiercolesHasta></ngx-material-timepicker>
                        <div class="borrar divIcon" (click)="borrarFecha('miercolesHasta')">
                            <mat-icon class="icon">delete_forever</mat-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf="medicoForm.hasError('miercolesError1')" style="color:rgb(236,75,72);">
                    la fecha hasta es requerida si se agrega la fecha desde 
                </div>
                <div *ngIf="medicoForm.hasError('miercolesError2')" style="color:rgb(236,75,72);">
                    la fecha desde es requerida si se agrega la fecha hasta
                </div>
                <div *ngIf="medicoForm.hasError('miercolesError3')" style="color:rgb(236,75,72);">
                    la fecha hasta tiene que ser posterior a la fecha desde
                </div>
            </div>
            <div class="divFechas">
                <div class="divFecha">
                    <div class="fecha">
                        <mat-form-field class="formFieldFecha">
                            <mat-label>Jueves desde</mat-label>
                            <input class="inputFecha" matInput [format]="24" [ngxTimepicker]="pickerJuevesDesde" formControlName="juevesDesde" readonly>
                        </mat-form-field>
                        <ngx-material-timepicker [theme]="darkTheme" #pickerJuevesDesde></ngx-material-timepicker>
                        <div class="borrar divIcon" (click)="borrarFecha('juevesDesde')">
                            <mat-icon class="icon">delete_forever</mat-icon>
                        </div>
                    </div>
                    <div class="fecha">
                        <mat-form-field class="formFieldFecha">
                            <mat-label>Jueves hasta</mat-label>
                            <input class="inputFecha" matInput [format]="24" [ngxTimepicker]="pickerJuevesHasta" formControlName="juevesHasta" readonly>
                        </mat-form-field>
                        <ngx-material-timepicker [theme]="darkTheme" #pickerJuevesHasta></ngx-material-timepicker>
                        <div class="borrar divIcon" (click)="borrarFecha('juevesHasta')">
                            <mat-icon class="icon">delete_forever</mat-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf="medicoForm.hasError('juevesError1')" style="color:rgb(236,75,72);">
                    la fecha hasta es requerida si se agrega la fecha desde 
                </div>
                <div *ngIf="medicoForm.hasError('juevesError2')" style="color:rgb(236,75,72);">
                    la fecha desde es requerida si se agrega la fecha hasta
                </div>
                <div *ngIf="medicoForm.hasError('juevesError3')" style="color:rgb(236,75,72);">
                    la fecha hasta tiene que ser posterior a la fecha desde
                </div>
            </div>
            <div class="divFechas">
                <div class="divFecha">
                    <div class="fecha">
                        <mat-form-field class="formFieldFecha">
                            <mat-label>Viernes desde</mat-label>
                            <input class="inputFecha" matInput [format]="24" [ngxTimepicker]="pickerViernesDesde" formControlName="viernesDesde" readonly>
                        </mat-form-field>
                        <ngx-material-timepicker [theme]="darkTheme" #pickerViernesDesde></ngx-material-timepicker>
                        <div class="borrar divIcon" (click)="borrarFecha('viernesDesde')">
                            <mat-icon class="icon">delete_forever</mat-icon>
                        </div>
                    </div>
                    <div class="fecha">
                        <mat-form-field class="formFieldFecha">
                            <mat-label>Viernes hasta</mat-label>
                            <input class="inputFecha" matInput [format]="24" [ngxTimepicker]="pickerViernesHasta" formControlName="viernesHasta" readonly>
                        </mat-form-field>
                        <ngx-material-timepicker [theme]="darkTheme" #pickerViernesHasta></ngx-material-timepicker>
                        <div class="borrar divIcon" (click)="borrarFecha('viernesHasta')">
                            <mat-icon class="icon">delete_forever</mat-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf="medicoForm.hasError('viernesError1')" style="color:rgb(236,75,72);">
                    la fecha hasta es requerida si se agrega la fecha desde 
                </div>
                <div *ngIf="medicoForm.hasError('viernesError2')" style="color:rgb(236,75,72);">
                    la fecha desde es requerida si se agrega la fecha hasta
                </div>
                <div *ngIf="medicoForm.hasError('viernesError3')" style="color:rgb(236,75,72);">
                    la fecha hasta tiene que ser posterior a la fecha desde
                </div>
            </div>
            <div class="divFechas">
                <div class="divFecha">
                    <div class="fecha">
                        <mat-form-field class="formFieldFecha">
                            <mat-label>Sabado desde</mat-label>
                            <input class="inputFecha" matInput [format]="24" [ngxTimepicker]="pickerSabadoDesde" formControlName="sabadoDesde" readonly>
                        </mat-form-field>
                        <ngx-material-timepicker [theme]="darkTheme" #pickerSabadoDesde></ngx-material-timepicker>
                        <div class="borrar divIcon" (click)="borrarFecha('sabadoDesde')">
                            <mat-icon class="icon">delete_forever</mat-icon>
                        </div>
                    </div>
                    <div class="fecha">
                        <mat-form-field class="formFieldFecha">
                            <mat-label>Sabado hasta</mat-label>
                            <input class="inputFecha" matInput [format]="24" [ngxTimepicker]="pickerSabadoHasta" formControlName="sabadoHasta" readonly>
                        </mat-form-field>
                        <ngx-material-timepicker [theme]="darkTheme" #pickerSabadoHasta></ngx-material-timepicker>
                        <div class="borrar divIcon" (click)="borrarFecha('sabadoHasta')">
                            <mat-icon class="icon">delete_forever</mat-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf="medicoForm.hasError('sabadoError1')" style="color:rgb(236,75,72);">
                    la fecha hasta es requerida si se agrega la fecha desde 
                </div>
                <div *ngIf="medicoForm.hasError('sabadoError2')" style="color:rgb(236,75,72);">
                    la fecha desde es requerida si se agrega la fecha hasta
                </div>
                <div *ngIf="medicoForm.hasError('sabadoError3')" style="color:rgb(236,75,72);">
                    la fecha hasta tiene que ser posterior a la fecha desde
                </div>
            </div>
            <div class="divFechas">
                <div class="divFecha">
                    <div class="fecha">
                        <mat-form-field class="formFieldFecha">
                            <mat-label>Domingo desde</mat-label>
                            <input class="inputFecha" matInput [format]="24" [ngxTimepicker]="pickerDomingoDesde" formControlName="domingoDesde" readonly>
                        </mat-form-field>
                        <ngx-material-timepicker [theme]="darkTheme" #pickerDomingoDesde></ngx-material-timepicker>
                        <div class="borrar divIcon" (click)="borrarFecha('domingoDesde')">
                            <mat-icon class="icon">delete_forever</mat-icon>
                        </div>
                    </div>
                    <div class="fecha">
                        <mat-form-field class="formFieldFecha">
                            <mat-label>Domingo hasta</mat-label>
                            <input class="inputFecha" matInput [format]="24" [ngxTimepicker]="pickerDomingoHasta" formControlName="domingoHasta" readonly>
                        </mat-form-field>
                        <ngx-material-timepicker [theme]="darkTheme" #pickerDomingoHasta></ngx-material-timepicker>
                        <div class="borrar divIcon" (click)="borrarFecha('domingoHasta')">
                            <mat-icon class="icon">delete_forever</mat-icon>
                        </div>
                    </div>
                </div>
                <div *ngIf="medicoForm.hasError('domingoError1')" style="color:rgb(236,75,72);">
                    la fecha hasta es requerida si se agrega la fecha desde 
                </div>
                <div *ngIf="medicoForm.hasError('domingoError2')" style="color:rgb(236,75,72);">
                    la fecha desde es requerida si se agrega la fecha hasta
                </div>
                <div *ngIf="medicoForm.hasError('domingoError3')" style="color:rgb(236,75,72);">
                    la fecha hasta tiene que ser posterior a la fecha desde
                </div>
                <div *ngIf="medicoForm.hasError('fechasError')" style="color:rgb(236,75,72);">
                    Si habilita la agenda se tiene que agregar minimo los horarios de un dia 
                </div>
            </div>
        </div>
        <div class="formGroup">
            <mat-slide-toggle
                formControlName="notificaciones"
                class="example-margin"
                color="primary"
                [disabled]="disabled">
                Notificaciones de estudios médicos 
            </mat-slide-toggle>
        </div>
    </div>
    <div class="accionModal">
        <div class="buttons">
            <button mat-raised-button class="button">Crear</button>
        </div>
    </div>
</form>
