import { Component, Input, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "app-exams-table",
  templateUrl: "./exams-table.component.html",
  styleUrls: ["./exams-table.component.scss"],
})
export class ExamsTableComponent implements OnInit {
  @Input("cols") cols: any[] = [];
  @ViewChild("op") op;
  panelData = {};
  examenes: any[] = [
    {
      code: 1,
      study: "Análisis médico",
      medic: "John Doe",
      laboratory: "Dexter",
      documents: [
        {
          title: "Último examen",
          type: "pdf",
        },
        {
          title: "Doc. imagen",
          type: "image",
        },
      ],
    },
    { code: 2 },
  ];
  constructor() {}

  ngOnInit(): void {}

  open(event, doctor) {
    this.panelData = doctor;
    this.op.toggle(event);
  }
}
