import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CentroMedico } from 'app/models/centroMedico.interface';
import { GlobalVariable } from 'app/global/global';

@Injectable({
  providedIn: 'root'
})
export class CentroMedicoService {

  url:string = GlobalVariable.BASE_API_URL+"api/centro-medico/";
  urlImagen:string="";
  constructor(
    private http:HttpClient
  ) { }

  listar():Observable<any>{
    return this.http.get(this.url+"listar");
  }


  listarSucursales():Observable<any>{
    return this.http.get(this.url+"listarSucursales");
  }


  crearCentroMedico(centroMedico:any,file:File, listCarousels:any){
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    formData.append("nombre", centroMedico.nombre.trim());
    formData.append("direccion", centroMedico.direccion.trim());
    formData.append("telPais", centroMedico.telPais);
    formData.append("telCaract", centroMedico.telCaract.trim());
    formData.append("telNumero", centroMedico.telNumero.trim());
    formData.append("email", centroMedico.email.trim());
    if(file){
      formData.append("image", file);
    }
    if(listCarousels.length > 0){      
      for (let i = 0; i < listCarousels.length; i++) {
        if(!listCarousels[i]?.id) {
          formData.append('imagenes['+i+']', listCarousels[i]);
        }
        else{
          formData.append('imagenesId[]', listCarousels[i]?.id);
        }
      }
    }
    return this.http.post(this.url+"crear-centro-medico",formData,{headers:headers});
  }


  editarCentroMedico(centroMedico:any, file:File, listCarousels:any){
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    formData.append("id", centroMedico.id.toString());
    formData.append("nombre", centroMedico.nombre.trim());
    formData.append("direccion", centroMedico.direccion.trim());
    formData.append("telPais", centroMedico.telPais);
    formData.append("telCaract", centroMedico.telCaract.trim());
    formData.append("telNumero", centroMedico.telNumero.trim());
    formData.append("email", centroMedico.email.trim());
    if(file){
      formData.append("image", file);
    }
    if(listCarousels.length > 0){      
      for (let i = 0; i < listCarousels.length; i++) {
        if(!listCarousels[i]?.id) {
          formData.append('imagenes['+i+']', listCarousels[i]);
        }
        else{
          formData.append('imagenesId[]', listCarousels[i]?.id);
        }
      }
    }
    return this.http.post(this.url+"editar-centro-medico",formData,{headers:headers});
  }


  //
  crearSucursal(centroMedico:any,file:File){
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    formData.append("nombre", centroMedico.nombre.trim());
    formData.append("direccion", centroMedico.direccion.trim());
    formData.append("telPais", centroMedico.telPais);
    formData.append("telCaract", centroMedico.telCaract.trim());
    formData.append("telNumero", centroMedico.telNumero.trim());
    formData.append("email", centroMedico.email.trim());
    if(file){
      formData.append("image", file);
    }
    return this.http.post(this.url+"crear-sucursal",formData,{headers:headers});
  }


  //
  editarSucursal(centroMedico:any,file:File){
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    formData.append("nombre", centroMedico.nombre.trim());
    formData.append("direccion", centroMedico.direccion.trim());
    formData.append("telPais", centroMedico.telPais);
    formData.append("telCaract", centroMedico.telCaract.trim());
    formData.append("telNumero", centroMedico.telNumero.trim());
    formData.append("email", centroMedico.email.trim());
    formData.append("id", centroMedico.id.toString());
    if(file){
      formData.append("image", file);
    }
    return this.http.post(this.url+"editar-sucursal",formData,{headers:headers});
  }


  borrarCarousel(id):Observable<any>{
    return this.http.delete(this.url+"borrar-carousel/"+id);
  }


  listarCarouseles():Observable<any>{
    return this.http.get(this.url+"listar-carouseles");
  }


  borrar(centroMedico:CentroMedico):Observable<any>{
    return this.http.delete(this.url+"borrar/"+centroMedico.id);
  }

  mostrar(id):Observable<any>{
    return this.http.get(this.url+"mostrar/"+id);
  }


  mostrarCentroMedico():Observable<any>{
    return this.http.get(this.url+"mostrar-centro-medico");
  }


  getCentroImagen():Observable<any>{
    return this.http.get(this.url+"get-imagen");
  }

  getUrlImagen(){
    return this.urlImagen;
  }

  
}
