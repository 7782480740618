import { PacienteService } from "app/services/paciente.service";
import {
  ElementRef,
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
} from "@angular/core";
import { UsuarioPanel } from "app/models/usuarioPanel.interface";
import { Router, ActivatedRoute } from "@angular/router";
import { Paciente } from "app/models/paciente.interface";
import { GlobalVariable } from "app/global/global";
import { EventEmitter } from "@angular/core";

@Component({
  selector: "app-header-paciente",
  templateUrl: "./header-paciente.component.html",
  styleUrls: ["./header-paciente.component.scss"],
})
export class HeaderPacienteComponent implements OnInit {
  //
  @ViewChild("dropdown") dropdown: ElementRef;
  imagen: string = "assets/img/ico_paciente.svg";
  menu: string;
  paciente: any;
  nombreApellido = localStorage.getItem("nombreApellido");
  @Output() messageEvent = new EventEmitter<string>();
  @Input() set response(value: any) {
    if (value) {
      this.mostrarLogin(value);
    }
  }

  //
  constructor(private pacienteService: PacienteService) {}

  //
  ngOnInit(): void {}

  //
  mostrarLogin(data: any) {
    this.paciente = data.paciente;

    const imageUrl = `${GlobalVariable.BASE_API_URL}storage/images/paciente/${this.paciente.imagen}`;

    this.verificarImagen(imageUrl).then((exists) => {
      if (exists) {
        this.imagen = imageUrl;
      }
    });
  }

  checkImageExists(url: string): Promise<boolean> {
    return new Promise((resolve) => {
      const img = new Image();

      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);

      img.src = url;
    });
  }

  async verificarImagen(imageUrl: string) {
    const exists = await this.checkImageExists(imageUrl);
    return exists;
  }
  //
  logout() {
    this.pacienteService.logout();
  }

  //
  botonDesplegableShadow() {
    this.menu = this.menu == "true" ? "false" : "true";
    this.messageEvent.emit(this.menu);
  }
}
