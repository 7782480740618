import { GlobalVariable } from 'app/global/global';
import { ElementRef,Component, OnInit,ViewChild,NgModule ,Input, Output, EventEmitter } from '@angular/core';
import { FormGroup,FormControl,Validator, Validators } from '@angular/forms';
import { CentroMedico } from 'app/models/centroMedico.interface';
import { CentroMedicoService } from 'app/services/centro-medico.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { TelefonoPaisService } from 'app/services/telefono-pais.service';
import { TelefonoPais } from 'app/models/telefonoPais.interface';
import { SpinnerService } from 'app/services/spinner.service';
import { ShowImagenComponent } from 'app/shared/show-imagen/show-imagen.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';



@Component({
  selector: 'app-form-centro-medico',
  templateUrl: './form-centro-medico.component.html',
  styleUrls: ['./form-centro-medico.component.scss']
})
export class FormCentroMedicoComponent implements OnInit {


  //
  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  @ViewChild('divImagen') divImagen: ElementRef;
  public telPais: FormControl = new FormControl();
  editar:boolean = false;
  telefonoPais:TelefonoPais[];
  filtroTelefonoPais:TelefonoPais[];
  panelOpenState = true;
  imagen = '';
  logo64 = '';
  file:File = null;
  myfilename = 'Seleccionar logo';
  sucursalArchivo = 'Imagenes carrusel';
  button:string;
  url:string = GlobalVariable.BASE_API_URL+'storage/images/centro-medico/';
  carousels:File[] = [];
  listCarousels:File[] = [];
  errorImage = false;
  errorNombre = false;
  errorEmail = false;
  centroMedicoForm = new FormGroup({
    id: new FormControl(''),
    nombre : new FormControl('',Validators.required),
    direccion : new FormControl('',Validators.required),
    telPais : new FormControl('',Validators.required),
    telCaract : new FormControl('',Validators.required),
    telNumero : new FormControl('',Validators.required),
    email : new FormControl('',[Validators.required,Validators.email]),
    memberContactNo : new FormControl('Seleccionar logo'),
    carousel: new FormControl(''),
  });
  @Input() set response(value: any) {
    if(value){
      this.mostrarCentroMedicoValue(value);
    }
  }


  //
  constructor(
    public dialog: MatDialog,
    private centroMedicoService:CentroMedicoService,
    private snackBar: MatSnackBar,
    private telefonoPaisService:TelefonoPaisService,
    private spinnerService:SpinnerService,
  ) { }


  //
  ngOnInit(): void {}


  //
  filtrarTelPais(event:any){
    this.filtroTelefonoPais.splice(0,this.filtroTelefonoPais.length)
    let valueString:string = this.telPais.value;
    for(let obj of this.telefonoPais){
      if(obj.nombre.toUpperCase().includes(valueString.toUpperCase())){
        this.filtroTelefonoPais.push(obj);
      }
    }
  }


  //
  mostrarCentroMedicoValue(value){
    if(value.centroMedico){
      this.listCarousels = value.carouseles;
      this.imagen = `${this.url}${value.centroMedico.foto}`;
      this.divImagen.nativeElement.style.display = "block";
      this.centroMedicoForm = new FormGroup({
        id: new FormControl(value.centroMedico.id),
        nombre: new FormControl(value.centroMedico.nombre,Validators.required),
        direccion: new FormControl(value.centroMedico.direccion,Validators.required),
        telPais: new FormControl(value.centroMedico.telPais,Validators.required),
        telCaract: new FormControl(value.centroMedico.telCaract,Validators.required),
        telNumero: new FormControl(value.centroMedico.telNumero,Validators.required),
        email: new FormControl(value.centroMedico.email,[Validators.required,Validators.email]),
        memberContactNo: new FormControl(`${value.centroMedico.foto},`),
        carousel: new FormControl('Imagenes carrusel'),
      })
      this.editar = true;
    }
    else{
      this.editar = false;
    }

    this.filtroTelefonoPais = [];
    this.telefonoPais = value.listaTelefonoPais;
    for(let obj of this.telefonoPais){
      this.filtroTelefonoPais.push(obj);     
    }
  }


  //
  guardarCentroMedico(centroMedico:any){
    (this.editar) ? this.editarCentroMedico(centroMedico) : this.crearCentroMedico(centroMedico);
  }


  //
  crearCentroMedico(centroMedico:any){
    if(this.centroMedicoForm.valid){
      this.spinnerService.mostrarSpinner();
      this.centroMedicoService.crearCentroMedico(centroMedico,this.file,this.listCarousels).subscribe(
        data => {
          let response:any = data;
          if(response.statusError){
            this.errorImage = response.errorImage;
            this.errorNombre = response.errorNombre;
            this.errorEmail = response.errorEmail;
            this.openSnackBar("Guardado fallido","error");
            this.spinnerService.ocultarSpinner();
          }
          else{
            this.editar = true;
            this.openSnackBar("Guardado exitoso","exito");
            this.spinnerService.ocultarSpinner();
          }
        },
        error => {
          this.openSnackBar("Guardado fallido","error");
          this.spinnerService.ocultarSpinner();
        }
      )
    }
    else {
      this.openSnackBar((!this.file) ? "Seleccione una imagen" : "Guardado fallido" ,"error");
    }
  }


  //
  editarCentroMedico(centroMedico:any){
    if(this.centroMedicoForm.valid){
      this.spinnerService.mostrarSpinner();
      this.centroMedicoService.editarCentroMedico(centroMedico,this.file,this.listCarousels).subscribe(
        data => {
          let response:any = data;
          if(response.statusError){
            this.errorNombre = response.errorNombre;
            this.errorEmail = response.errorEmail;
            this.openSnackBar("Editado fallido","error");
            this.spinnerService.ocultarSpinner();
          }
          else{
            this.openSnackBar("Editado exitoso","exito");
            this.spinnerService.ocultarSpinner();
          }
        },
        error => {
          console.log('error',error);
          this.openSnackBar("Editado fallido","error");
          this.spinnerService.ocultarSpinner();
        }
      )
    }
    else {
      this.openSnackBar((!this.file) ? "Seleccione una imagen" : "Editado fallido" ,"error");
    }
  }



  //
  resetearFormulario(){
    this.file = null;
    this.centroMedicoForm.reset();
    this.imagen = "";
    this.myfilename = 'Seleccionar logo';
    this.divImagen.nativeElement.style.display = "none";
  }


  //
  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      let fileList: FileList = fileInput.target.files;
      if(fileList.length > 0) {
        this.file = fileList[0];
      }

      this.myfilename = '';
      Array.from(fileInput.target.files).forEach((file: File) => {
        this.myfilename += file.name + ',';
      });

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (event:any) => {
          this.divImagen.nativeElement.style.display = "block";
          this.imagen = e.target.result;
          const imgBase64Path = e.target.result;
          this.logo64 = e.target.result;
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
      this.uploadFileInput.nativeElement.value = "";
      this.errorImage = false;
    } 
    else {
      this.myfilename = 'Seleccionar logo';
    }
  }


  //
  imagenesCarousel(fileInput:any) {
    if(fileInput.target.files && fileInput.target.files[0]) {
      Array.from(fileInput.target.files).forEach((file: File) => {
        this.listCarousels.push(file);
      });
    }
    else {
      this.myfilename = 'Seleccionar logo';
    }
  }
  

  //
  borrarImagen(i){
    this.listCarousels.splice(i,1);
  }


  //
  async mostrar(imagen){
    const dialogRef = this.dialog.open(ShowImagenComponent,{
      width: '95%',
      height: '95%',
      data: imagen
    });
    
    dialogRef.afterClosed().subscribe(res=>{});
  }


  //
  borrarCarousel(id){
    this.spinnerService.mostrarSpinner();
    this.centroMedicoService.borrarCarousel(id).pipe(
      map(data => {}),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        return error;
      }),
      switchMap(data => this.centroMedicoService.listarCarouseles().pipe(
        map(data => { 
          this.listCarousels = data;
          this.spinnerService.ocultarSpinner();
        }),
      )),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        return of({ results: null });
      }),
    )
    .subscribe(
      (data) => {
        console.log('data',data);
      },
      (error) => {
        console.error('Error al llamar a la API:', error);
      }
    );
  }


  //
  ocultarMostrar(){
    this.panelOpenState = (this.panelOpenState == true) ? false : true; 
  }


  //
  openSnackBar(message: string, action: string) {
    this.snackBar.openFromComponent(NotificationComponent,{
      data: {
        message: message,
        action: action
      },
      duration: 2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [action]
    });
  }
}
