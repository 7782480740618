import { Component , OnInit , Input } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Informes } from 'app/models/informes.interface';
import { ResponsiveService } from '../../../services/responsive.service';

@Component({
  selector: 'app-informe-grafico-torta',
  templateUrl: './informe-grafico-torta.component.html',
  styleUrls: ['./informe-grafico-torta.component.scss']
})
export class InformeGraficoTortaComponent implements OnInit {


  //
  @Input() titulo:any;
  @Input() descripcion:any;
  view: any[] = [];
  habilitar:boolean = false;
  informesPorcentaje: Array<any> = []
  informesPorcentajeArray: Array<any> = []
  gradient: boolean = false;
  showLegend: boolean = false;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';
  @Input() set informeGrafico(value: any) {
    if(value){
      this.crearArray(value.cantidad,value.datos);
    }
  }
  colorScheme = {
    domain: [
      '#FE8989', '#FF9753', '#FFCD53', 
      '#FFFC53','#C8FF53','#8FFF53',
      '#00DF11','#00DF6F','#00DFBD',
      '#00ACDF','#0073DF','#0033DF',
      '#3900DF','#6900DF']
  };


  //
  constructor(
    private responsiveService: ResponsiveService,
  ){}

  
  //
  ngOnInit(): void {
    this.responsiveService.isMobile$.subscribe((mobile) => {
      this.view = [mobile ? 320 : 700, mobile ? 200 : 400];
    });
  }


  //
  crearArray(cantidad:any,datos:any){ 
    datos.forEach((element:any,index:any) => {
        let por = (element.cantidad/cantidad.cantidad)*100;
        let info:Informes = {
          name: element.texto,
          value: (por)
        };
        this.informesPorcentajeArray.push(info);
    })
      
    this.habilitar = true;
    Object.assign(this, this.informesPorcentajeArray);
  }


  //
  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }


  //
  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }


  //
  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }


}
