import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NgxMaterialTimepickerTheme } from "ngx-material-timepicker";

@Component({
  selector: "app-agenda-form",
  templateUrl: "./agenda-form.component.html",
  styleUrls: ["./agenda-form.component.scss"],
})
export class AgendaFormComponent implements OnInit {
  @Input() agendaForm: FormGroup;
  @Input() diasConConflico: string[] = [];
  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: "#ffffff",
      buttonColor: "#000000",
    },
    dial: {
      dialBackgroundColor: "#3434EC",
    },
    clockFace: {
      clockFaceBackgroundColor: "#EEEEEE",
      clockHandColor: "#05CE7C",
      clockFaceTimeInactiveColor: "#3434EC",
    },
  };

  constructor() {}

  ngOnInit() {}

  borrarFecha(fecha: any) {
    this.agendaForm.patchValue({
      [fecha]: "",
    });
  }

  public getDiasConConflicto(dia:string) {
    return this.diasConConflico?.includes(dia);
  }
}
