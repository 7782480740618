<p-table [columns]="cols" [value]="examenes" dataKey="code">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th pSortableColumn="code" *ngFor="let col of columns">
        <p-sortIcon field="code"></p-sortIcon>
        {{ col.header }}
      </th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-examen
    let-columns="columns"
    let-expanded="expanded"
  >
    <tr>
      <ng-container *ngFor="let col of columns">
        <td [pRowToggler]="col.type != 'entity' ? examen : false">
          <div
            class="row-doctor"
            *ngIf="col.type == 'entity'"
            (click)="open($event, examen)"
          >
            <p-avatar image="assets/img/avatar.png" shape="circle"></p-avatar>
            <span class="image-text">{{ examen[col.field] }}</span>
          </div>
          <div *ngIf="col.type != 'entity'">
            <p>{{ examen[col.field] }}</p>
          </div>
        </td>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template class="rowExpansion" pTemplate="rowexpansion" let-examen>
    <div class="grid grid-expansion">
      <div class="col-6">
        <h2>Documentos</h2>
        <div class="grid">
          <div class="col-4 col-md-4" *ngFor="let document of examen.documents">
            <i
              [pTooltip]="document.title"
              class="icon-file"
              [ngClass]="[
                document.type == 'pdf' ? 'fa fa-file-pdf-o' : '',
                document.type == 'image' ? 'fa fa-picture-o' : ''
              ]"
              aria-hidden="true"
            >
            </i>
          </div>
        </div>
        <a href="#">Ver más...</a>
      </div>
      <div class="col-6">
        <h2>Observaciones</h2>
        <p class="observations"></p>
        <a href="#">Ver más...</a>
      </div>
    </div>
  </ng-template>
</p-table>

<p-overlayPanel #op [showCloseIcon]="true">
  <ng-template pTemplate>
    <img src="assets/img/avatar.png" />
    John Doe
    <p>Nombre:</p>
    <p>Matrícula:</p>
    <p>Teléfono:</p>
  </ng-template>
</p-overlayPanel>
