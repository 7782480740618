import { Router } from '@angular/router';
import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { resolve } from 'dns';
import { SpinnerService } from 'app/services/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioPanelGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
  
  
  //
  constructor(
    private authService:UsuarioPanelService,
    private router:Router,
    private spinnerService:SpinnerService,
  ) { }
  

  //
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) : Promise<boolean | UrlTree> {   
    this.spinnerService.mostrarSpinner(); 
    return await this.authService.isValidToken().then((value) => {  
      if (value) {
        return true;
      }
      else{
        this.router.navigate(['/login']);
        return false;
      }
    })
  }

  
  //
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }


  //
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }


  //
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }


}
