import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalVariable } from 'app/global/global';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class TurnosService {


  //
  url:string = GlobalVariable.BASE_API_URL+"api/turnos/";


  //
  constructor(private http:HttpClient) { }


  //
  getTurnosPanel():Observable<any>{
    return this.http.get(this.url+"panel");
  }


  //
  getTurnosMedico(id:any):Observable<any>{
    return this.http.get(this.url+"medicos/"+id);
  }


  //
  getTurnosPaciente(id:any):Observable<any>{
    return this.http.get(this.url+"paciente/"+id);
  }


  //
  turnoDeUnMedico(id:any):Observable<any>{
    return this.http.get(this.url+"medico/"+id);
  }

  //
  borrarTurno(id:any){
    return this.http.delete(this.url+"turno/"+id);
  }

  //
  crearTurno(turno:any,fechaDesde:any,fechaHasta:any){
    fechaDesde = moment(fechaDesde).format('YYYY-MM-DD HH:mm:ss');
    fechaHasta = moment(fechaHasta).format('YYYY-MM-DD HH:mm:ss');

    const formData = new FormData(); 
    formData.append("id_medico", turno.id_medico);
    formData.append("id_paciente", turno.id_paciente);
    formData.append("id_centromedico", turno.centroMedico);
    formData.append("fecha_desde", fechaDesde);
    formData.append("fecha_hasta", fechaHasta);
    formData.append("titulo", turno.titulo.trim());
    formData.append("detalle", turno.detalle.trim());

    return this.http.post(this.url+"medico",formData);
  }

  //
  editarTurno(turno:any,fechaDesde:any,fechaHasta:any){
    fechaDesde = moment(fechaDesde).format('YYYY-MM-DD HH:mm:ss');
    fechaHasta = moment(fechaHasta).format('YYYY-MM-DD HH:mm:ss');

    let turnoEdit = { 
      id: turno.id,
      id_medico: turno.id_medico,
      id_paciente: turno.id_paciente,
      id_centromedico: turno.centroMedico,
      fecha_desde: fechaDesde,
      fecha_hasta: fechaHasta,
      titulo: turno.titulo.trim(),
      detalle: turno.detalle.trim()
    };

    return this.http.put(this.url+"turno",turnoEdit);
  }


  //
  validarTurno(turno:any,fechaDesde:any,fechaHasta:any){
    const formData = new FormData(); 
    formData.append("fecha_desde", fechaDesde);
    formData.append("fecha_hasta", fechaHasta);
    formData.append("id_medico", turno.id_medico);
    formData.append("id_paciente", turno.id_paciente);
    formData.append("id_sucursal", turno.centroMedico);
    return this.http.post(this.url+"validar-fechas",formData);
  }


  //
  validarTurnoEditar(turno:any,fechaDesde:any,fechaHasta:any){
    const formData = new FormData(); 
    formData.append("fecha_desde", fechaDesde);
    formData.append("fecha_hasta", fechaHasta);
    formData.append("id_paciente", turno.id_paciente);
    if(turno?.id){
      formData.append("id", turno.id);
    }
    formData.append("id_medico", turno.id_medico);
    formData.append("id_sucursal", turno.centroMedico);

    return this.http.post(this.url+"validar-fechas-editar",formData);
  }


  //
  editarFechaDrop(usuario:any,turno:any,fechaDesde:any,fechaHasta:any){
    fechaDesde = moment(fechaDesde).format('YYYY-MM-DD HH:mm:ss');
    fechaHasta = moment(fechaHasta).format('YYYY-MM-DD HH:mm:ss');
    
    let turnoEdit = { 
      usuario: usuario,
      id: turno.id,
      fecha_desde: fechaDesde,
      fecha_hasta: fechaHasta,
    };

    return this.http.put(this.url+"turno-editar-fecha",turnoEdit);
  }


  //
  borrarTurnoToken(token:any):Observable<any>{
    return this.http.delete(this.url+"borrar-turno-token/"+token);
  }


  //
  confirmarTurnoToken(token:any):Observable<any>{
    return this.http.get(this.url+"confirmar-turno-token/"+token);
  }


}
