<div class="panel">
    <div class="dashboard">
        <app-dashboard></app-dashboard>
    </div>
    <div class="panelContent">
        <app-header-panel></app-header-panel>
        <app-form-centro-medico></app-form-centro-medico>
    </div>
</div>

