import { ElementRef,AfterViewInit,Component, OnInit,ViewChild,NgModule ,Input,Output,EventEmitter } from '@angular/core';
import { FormGroup,FormControl,Validator, Validators } from '@angular/forms';
import { SucursalService } from 'app/services/sucursal.service';
import { CentroMedicoService } from 'app/services/centro-medico.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sucursal } from 'app/models/sucursal.interface';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { Router,ActivatedRoute } from '@angular/router';
import { TelefonoPaisService } from 'app/services/telefono-pais.service';
import { TelefonoPais } from 'app/models/telefonoPais.interface';
import { GlobalVariable } from 'app/global/global';
import { CentroMedico } from 'app/models/centroMedico.interface';
import { SpinnerService } from 'app/services/spinner.service';


@Component({
  selector: 'app-form-sucursal',
  templateUrl: './form-sucursal.component.html',
  styleUrls: ['./form-sucursal.component.scss']
})
export class FormSucursalComponent implements OnInit {


  //
  editarValor:boolean;
  funcionCorrecta:any;
  url:string = GlobalVariable.BASE_API_URL+'storage/images/centro-medico/';
  @Output() listar:EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  @ViewChild('divImagen') divImagen: ElementRef;
  public telPais: FormControl = new FormControl();
  panelOpenState = false;
  @Input() set editar(value:boolean) {
    this.editarValor = value;
    this.panelOpenState = (value) ? true : false;
  }
  telefonoPais:TelefonoPais[];
  filtroTelefonoPais:TelefonoPais[];
  errorImage = false;
  errorNombre = false;
  errorEmail = false;
  imagen = '';
  centroMedico:CentroMedico;
  nameImage:string;
  myfilename = 'Seleccionar archivo';
  button:string;
  file:File = null;
  logo64 = '';
  obraSocialValue:any;
  @Input() set response(value: any) {
    if(value){
      this.listarTelefonoPais(value.listaTelefonoPais);
      if(value.centroMedico){
        this.iniciarEditarFormulario(value.centroMedico);
      }
    }
  }
  sucursalForm = new FormGroup({
    id: new FormControl(''),
    nombre: new FormControl('',Validators.required),
    direccion: new FormControl('',Validators.required),
    telPais: new FormControl('',Validators.required),
    telCaract: new FormControl('',Validators.required),
    telNumero: new FormControl('',Validators.required),
    email: new FormControl('',[Validators.required,Validators.email]),
    memberContactNo: new FormControl(),
  })


  //
  constructor(
    private snackBar: MatSnackBar,
    private actRoute: ActivatedRoute,
    private router:Router,
    private apiDos:TelefonoPaisService,
    private centroMedicoService:CentroMedicoService,
    private spinnerService:SpinnerService,
  ) { }


  //
  ngOnInit(): void {}


  //
  clearNombre(){
    this.errorNombre = false;
  }


  //
  clearEmail(){
    this.errorEmail = false;
  }


  //
  iniciarEditarFormulario(data){
    this.centroMedico = data;
    let foto = null;
    if(this.centroMedico.foto!="") foto=""+this.url+this.centroMedico.foto+"";
    this.imagen = foto;
    this.divImagen.nativeElement.style.display = "block";
    this.sucursalForm.setValue({
      id: this.centroMedico.id,
      nombre : this.centroMedico.nombre,
      direccion: this.centroMedico.direccion,
      telPais: this.centroMedico.telPais,
      telCaract: this.centroMedico.telCaract,
      telNumero: this.centroMedico.telNumero,
      email: this.centroMedico.email,
      memberContactNo: this.centroMedico.foto+","
    });
    this.nameImage = this.centroMedico.foto;
  }


  listarTelefonoPais(data){
    this.filtroTelefonoPais = [];
    this.telefonoPais = data;
    for(let obj of this.telefonoPais){
      this.filtroTelefonoPais.push(obj);     
    }
  }


  filtrarTelPais(event:any){
    this.filtroTelefonoPais.splice(0,this.filtroTelefonoPais.length)
    let valueString:string = this.telPais.value;
    for(let obj of this.telefonoPais){
      if(obj.nombre.toUpperCase().includes(valueString.toUpperCase())){
        this.filtroTelefonoPais.push(obj);
      }
    }
  }


  guardarSucursal(sucursal:any){
    this.funcionCorrecta = (this.editarValor) ? this.editarSucursal(sucursal) : this.crearSucursal(sucursal); 
  }
  

  //
  crearSucursal(sucursal:any){
    if(this.sucursalForm.valid){
      this.spinnerService.mostrarSpinner();
      this.centroMedicoService.crearSucursal(sucursal,this.file).subscribe(
        data => {
          let response:any = data;
          if(response.statusError){
            this.errorImage = response.errorImage;
            this.errorNombre = response.errorNombre;
            this.errorEmail = response.errorEmail;
            this.openSnackBar("Guardado fallido","error");
            this.spinnerService.ocultarSpinner();
          }
          else{
            this.openSnackBar("Guardado exitoso","exito");
            this.resetearFormulario();
            this.listar.emit();
          }
        },
        error => {
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Guardado fallido","error");
        }
      )
    }
    else{
      if(!this.file) this.errorImage = true;
      this.openSnackBar("Guardado fallido","error");
    }
  }


  //
  editarSucursal(sucursal:any){
    if(this.sucursalForm.valid){
      this.spinnerService.mostrarSpinner(); 
      this.centroMedicoService.editarSucursal(sucursal,this.file).subscribe(
        data => {
          let response:any = data;
          if(response.statusError){
            this.errorNombre = response.errorNombre;
            this.errorEmail = response.errorEmail;
            this.openSnackBar("Editado fallido","error");
            this.spinnerService.ocultarSpinner();
          }
          else{
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Editado exitoso","exito");
            this.router.navigate(['/seccion-panel/sucursal/']);
          }
        },
        error => {
          this.openSnackBar("Editado fallido","error");
          this.spinnerService.ocultarSpinner();
        }
      )
    }
    else{
      this.openSnackBar("Editado fallido","error");
    }
  }


  //
  resetearFormulario(){
    this.file = null;
    this.sucursalForm.reset();
    this.imagen = "";
    this.myfilename = 'Seleccionar archivo';
    this.divImagen.nativeElement.style.display = "none";
  }


  //
  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      let fileList: FileList = fileInput.target.files;
      if(fileList.length > 0) {
        this.file = fileList[0];
      }

      this.myfilename = '';
      Array.from(fileInput.target.files).forEach((file: File) => {
        this.myfilename += file.name + ',';
      });

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (event:any) => {
          this.divImagen.nativeElement.style.display = "block";
          this.imagen = e.target.result;
          const imgBase64Path = e.target.result;

          this.logo64 = e.target.result;
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
      this.uploadFileInput.nativeElement.value = "";
      this.errorImage = false;
    } 
    else {
      this.myfilename = 'Seleccionar archivo';
    }
  }


  //
  ocultarMostrar(){
    this.panelOpenState = (this.panelOpenState == true) ? false : true;
  }


  //
  openSnackBar(message:string, action:string) {
    this.snackBar.openFromComponent(NotificationComponent,{
      data: {
        message: message,
        action: action
      },
      duration: 2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [action]
    });
  }


}
