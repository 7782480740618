import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedMedicoRoutingModule } from './shared-medico-routing.module';
import { HeaderMedicoComponent } from './header-medico/header-medico.component';
import { DashboardMedicoComponent } from './dashboard-medico/dashboard-medico.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from 'app/material/material.module';
import { UserModule } from 'app/user/user.module';
import { RouterModule } from '@angular/router'; 
import { CornerstoneService } from 'app/services/cornerstone.service';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    HeaderMedicoComponent,
    DashboardMedicoComponent
  ],
  imports: [
    CommonModule,
    SharedMedicoRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    UserModule,
    RouterModule,
  ],
  exports: [
    HeaderMedicoComponent,
    DashboardMedicoComponent,
  ],
  providers:[
    //CornerstoneService,
    DatePipe
  ]
  
})
export class SharedMedicoModule { }
