import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TipoDocumentoIndexComponent } from "./tipo-documento-index/tipo-documento-index.component";
import { TipoDocumentoEditarComponent } from "./tipo-documento-editar/tipo-documento-editar.component"; 
 

const routes: Routes = [
  // {
  //   path: "seccion-panel",
  //     children: [
        {
          path: "tipo-documento",
          pathMatch: "full",
          component: TipoDocumentoIndexComponent,
        },
        {
          path: "tipo-documento/editar/:id",
          pathMatch: "full",
          component: TipoDocumentoEditarComponent,
        },
  //     ],
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TipoDocumentoRoutingModule {}