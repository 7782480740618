<ngx-spinner 
    size="large"
    color="#fff"
    type="ball-spin-clockwise">
</ngx-spinner>
<router-outlet></router-outlet>







