<div style="text-align: center">
  <h1 mat-dialog-title>Contacto</h1>
  <div mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-label>Nombre</mat-label>
      <input matInput />
    </mat-form-field>
    <br />
    <mat-form-field appearance="fill">
      <mat-label>Correo</mat-label>
      <input matInput />
    </mat-form-field>
    <br />
    <mat-form-field appearance="fill">
      <mat-label>¿Cuál es tu consulta?</mat-label>
      <textarea matInput></textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions style="display: contents">
    <button mat-button (click)="onNoClick()">Cancelar</button>
    <button mat-button cdkFocusInitial>Enviar</button>
  </div>
</div>
