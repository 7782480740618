import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { EstudioMedicoIndexComponent } from "./estudio-medico-index/estudio-medico-index.component";
import { EstudioMedicoEditarComponent } from "./estudio-medico-editar/estudio-medico-editar.component";

const routes: Routes = [
  {
    path: "estudio-medico",
    pathMatch: "full",
    component: EstudioMedicoIndexComponent,
  },
  {
    path: "estudio-medico/editar/:id",
    pathMatch: "full",
    component: EstudioMedicoEditarComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EstudioMedicoRoutingModule {}
