<app-form-usuario-panel
  [response]="response"
  [editar]="editar"
  (listar)="listaActualizada($event)"
  *ngIf="!llegoLimiteUsuarios"
></app-form-usuario-panel>
<div class="divContent" *ngIf="llegoLimiteUsuarios">
  <div>
    <mat-card class="errorSeccion"
      >Ha llegado al limite de usuario disponible para su plan. Por favor,
      contacte a CentralMed</mat-card
    >
  </div>
</div>
<div class="seccionTable">
  <mat-card class="cardTable mat-elevation-z4">
    <div class="topTitle">
      <mat-card class="titleSeccion">Usuarios panel</mat-card>
    </div>

    <mat-form-field class="example-full-width buscador" appearance="fill">
      <mat-label>Buscador</mat-label>
      <input
        placeholder=""
        #buscador
        (keyup)="filter($event)"
        [(ngModel)]="searchKey"
        type="text"
        matInput
        autocomplete="off"
      />
    </mat-form-field>
    <table class="" mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="nombreYApellido">
        <th mat-header-cell *matHeaderCellDef class="th">
          <span class="span">Nombre y apellido</span>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="td"
          data-label="Nombre y apellido"
        >
          {{ element.nombreCompleto }}
        </td>
      </ng-container>
      <ng-container matColumnDef="perfil">
        <th mat-header-cell *matHeaderCellDef class="th">Perfil</th>
        <td mat-cell *matCellDef="let element" class="td" data-label="Perfil">
          {{ element.perfil }}
        </td>
      </ng-container>
      <ng-container matColumnDef="foto">
        <th mat-header-cell *matHeaderCellDef class="th">Foto</th>
        <td mat-cell *matCellDef="let element" class="td" data-label="Foto">
          <div class="divFoto">
            <img
              style="display: none"
              (load)="cargarImagen($event)"
              src="{{ url }}{{ element.imagen }}"
              class="foto"
            />
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef class="th">Acciones</th>
        <td mat-cell *matCellDef="let element" class="td" data-label="Acciones">
          <div class="divIcons">
            <div
              [routerLink]="['/seccion-panel/usuario-panel/editar', element.id]"
              class="editar divIcon"
            >
              <mat-icon class="icon"> edit</mat-icon>
            </div>
            <div (click)="cambiarPassword(element.id)" class="key divIcon">
              <mat-icon class="icon">vpn_key</mat-icon>
            </div>
            <div (click)="openDialog(element)" class="borrar divIcon">
              <mat-icon class="icon">delete_forever</mat-icon>
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        class="example-element-row"
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </mat-card>
</div>
