import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { EditarUsuarioPanelComponent } from "./usuario-panel-editar/usuario-panel-editar.component";
import { EditarPerfilUsuarioPanelComponent } from "../usuario-panel-perfil/editar-perfil-usuario-panel/editar-perfil-usuario-panel.component";
import { ListarUsuarioPanelComponent } from "./usuario-panel-listar/usuario-panel-listar.component";

const routes: Routes = [
  {
    path: "usuario-panel",
    pathMatch: "full",
    component: ListarUsuarioPanelComponent,
  },
  {
    path: "usuario-panel/editar/:id",
    pathMatch: "full",
    component: EditarUsuarioPanelComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsuarioPanelRoutingModule {}
