<div class="seccionTable">
    <mat-card class="cardTable mat-elevation-z4">  
        <div class="topTitle">
            <mat-card class="titleSeccion">Notificaciones</mat-card>
        </div>

        <mat-form-field class="example-full-width buscador" appearance="fill">
            <mat-label>Buscador</mat-label>
            <input placeholder="" #buscador (keyup)="filter($event)" [(ngModel)]="searchKey" type="text" matInput autocomplete="off">
        </mat-form-field>

        <table class="" mat-table [dataSource]="dataSource">
        
            <ng-container matColumnDef="texto">
                <th mat-header-cell *matHeaderCellDef> Texto </th>
                <td mat-cell *matCellDef="let element"> {{element.texto}} </td>
            </ng-container>
                        
            <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef class="th"> Acciones </th>
                <td mat-cell *matCellDef="let element" class="td">
                    <div *ngIf="!element.leido" class="divIcons">
                        <div class="ver divIcon" (click)="leerNotificacion(element)">
                            <mat-icon class="icon">visibility</mat-icon>
                        </div>
                    </div>
                    <div *ngIf="element.leido" class="divIcons">
                        <div class="check divIcon">
                            <mat-icon class="icon">done</mat-icon>
                        </div>
                    </div>
                </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
            
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons 
            aria-label="Select page of periodic elements">
        </mat-paginator>      
    </mat-card>        
</div>       