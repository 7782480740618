import { Component, OnInit ,Input, Output, EventEmitter} from '@angular/core';
import { FormGroup,FormControl,Validator, Validators } from '@angular/forms';
import { TipoDocumentoService } from 'app/services/tipo-documento.service';
import { TipoDocumento } from 'app/models/tipoDocumento.interface';
import { Router,ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { SpinnerService } from 'app/services/spinner.service';


@Component({
  selector: 'app-tipo-documento-form',
  templateUrl: './tipo-documento-form.component.html',
  styleUrls: ['./tipo-documento-form.component.scss']
})
export class TipoDocumentoFormComponent implements OnInit {


  //
  funcionCorrecta:any;
  editarValor:boolean;
  @Output() listar:EventEmitter<boolean> = new EventEmitter<boolean>();
  button:string;
  tipoDoc:TipoDocumento;
  panelOpenState = false;
  errorTexto:any;
  @Input() set editar(value:boolean) {
    this.editarValor = value;
    this.panelOpenState = (value) ? true : false;
  }
  tipoDocumentoForm = new FormGroup({
    id: new FormControl(''),
    texto: new FormControl('',Validators.required)
  });
  @Input() set tipoDocumento(value:any) {
    if(value){
      this.mostrarTipoDocumento(value);
    }
  }
  

  //
  constructor(
    private tipoDocumentoService:TipoDocumentoService,
    private spinnerService:SpinnerService,
    private actRoute: ActivatedRoute,
    private router:Router,
    private snackBar: MatSnackBar
  ) { }


  //
  ngOnInit(): void {}


  //
  clearTexto(){
    this.errorTexto = false;
  }


  //
  mostrarTipoDocumento = (data:any) => {
    this.tipoDoc = data;
    this.tipoDocumentoForm.setValue({
      id: this.tipoDoc.id,
      texto: this.tipoDoc.texto
    });
  }


  //
  guardarTipoDocumento = (form:TipoDocumento) => {
    this.funcionCorrecta = (this.editarValor) ? this.editarTipoDocumento(form) : this.crearTipoDocumento(form); 
  }


  //
  crearTipoDocumento = (form:TipoDocumento) => {
    if(this.tipoDocumentoForm.valid){
      this.spinnerService.mostrarSpinner();
      this.tipoDocumentoService.crear(form).subscribe(
        data => {
          let response:any = data;
          if(response.errorTexto){
            this.errorTexto = response.errorTexto;
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Guardado fallido","error");
          }
          else{
            this.resetearFormulario();
            this.openSnackBar("Guardado exitoso","exito");
            this.listar.emit();
          }
        },
        error => {
          this.openSnackBar("Guardado fallido","error");
          this.spinnerService.ocultarSpinner();
        }
      )
    }
    else{
      this.openSnackBar("Guardado fallido","error");
    }
  }


  //
  editarTipoDocumento = (form:TipoDocumento) => {
    if(this.tipoDocumentoForm.valid){
      this.spinnerService.mostrarSpinner();
      this.tipoDoc.texto = form.texto;
      this.tipoDocumentoService.editar(this.tipoDoc).subscribe(
        data => {
          let response:any = data;
          if(response.errorTexto){
            this.errorTexto = response.errorTexto;
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Editado fallido","error");
          }
          else{
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Editado exitoso","exito");
            this.router.navigate(['/seccion-panel/tipo-documento/']);
          }
        },
        error => {
          this.openSnackBar("Editado fallido","error");
          this.spinnerService.ocultarSpinner();
        }
      );
    }
    else{
      this.openSnackBar("Editado fallido","error");
    }
  }


  //
  resetearFormulario = () => {
    this.tipoDocumentoForm.reset();
  }


  //
  ocultarMostrar = () => {
    this.panelOpenState = (this.panelOpenState == true) ? false : true;
  }


  //
  openSnackBar = (message:string, action:string) => {
    this.snackBar.openFromComponent(NotificationComponent,{
      data: {
        message: message,
        action: action
      },
      duration: 2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [action]
    });
  }


}
