import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-delete-error-dialog',
  templateUrl: './delete-error-dialog.component.html',
  styleUrls: ['./delete-error-dialog.component.scss']
})
export class DeleteErrorDialogComponent implements OnInit {


  //
  titulo:string;
  mensaje:string;


  //
  constructor(
    public dialogRef:MatDialogRef<DeleteErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) { }


  //
  ngOnInit(): void {
    this.titulo = this.data.titulo;
    this.mensaje = this.data.mensaje;
  }


  //
  onClickNo(){
    this.dialogRef.close();
  }

}
