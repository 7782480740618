import { ElementRef,AfterViewInit,Component, OnInit,ViewChild,NgModule ,Inject } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FotoDialogComponent } from 'app/shared/foto-dialog/foto-dialog.component';
import { Medico } from 'app/models/medico.interface';
import { TelefonoPais } from 'app/models/telefonoPais.interface';
import { FormGroup,FormControl,Validator, Validators } from '@angular/forms';
import { TelefonoPaisService } from 'app/services/telefono-pais.service';
import { AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from '../notification/notification.component';
import { MedicoService } from 'app/services/medico.service';
import { EstudioMedicoService } from 'app/services/estudio-medico.service';
import { CentroMedicoService } from 'app/services/centro-medico.service';
import { TipoDocumentoService } from 'app/services/tipo-documento.service';
import { TipoDocumento } from 'app/models/tipoDocumento.interface';
import { SpinnerService } from 'app/services/spinner.service';
import * as moment from 'moment';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';


@Component({
  selector: 'app-medico-form-dialog',
  templateUrl: './medico-form-dialog.component.html',
  styleUrls: ['./medico-form-dialog.component.scss']
})
export class MedicoFormDialogComponent implements OnInit {

  
  @ViewChild('divImagen') divImagen: ElementRef;
  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  public telPais: FormControl = new FormControl();
  public celPais: FormControl = new FormControl();
  myfilename = 'Seleccionar Archivo';
  imagen = "";
  nameImage:string = ""; 
  medico:Medico;
  file:File = null;
  telefonoPais:TelefonoPais[];
  filtroTelefonoPais:TelefonoPais[] = [];
  filtroCelularPais:TelefonoPais[] = [];
  tiposDocumentos:TipoDocumento[];
  response:any;
  agenda:any;
  responseCreate:any;
  errorNumeroDocumento:any;
  errorDocumento:any
  errorMatricula:any;
  errorTipoDocumento:any;
  errorEmail:any;
  errorAgenda:any;
  checked = false;
  disabled = false;
  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
        bodyBackgroundColor: '#ffffff',
        buttonColor: '#000000'
    },
    dial: {
        dialBackgroundColor: '#3434EC',
    },
    clockFace: {
        clockFaceBackgroundColor: '#EEEEEE',
        clockHandColor: '#05CE7C',
        clockFaceTimeInactiveColor: '#3434EC'
    }
  };
  public telPaisBuscador:FormControl = new FormControl();
  public celPaisBuscador:FormControl = new FormControl();
  medicoForm = new FormGroup({
    nombreCompleto : new FormControl('',[Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
    tipoDocumento: new FormControl('',),
    numeroDocumento : new FormControl(''),
    matricula : new FormControl('',[Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
    email : new FormControl('',Validators.email),
    telPais : new FormControl(''),
    telPaisBuscador: new FormControl(''),
    telCaract : new FormControl(''),
    telNumero : new FormControl(''),
    celPais : new FormControl(''),
    celPaisBuscador: new FormControl(''),
    celCaract : new FormControl(''),
    celNumero : new FormControl(''),
    codigoInterno : new FormControl(''),
    memberContactNo : new FormControl(''),
    notificaciones : new FormControl(true),
    password : new FormControl(''),
    repeatPassword : new FormControl(''),
    agenda: new FormControl(false),
    color_agenda: new FormControl('#000ec4'),
    lunesDesde: new FormControl(''),
    lunesHasta: new FormControl(''),
    martesDesde: new FormControl(''),
    martesHasta: new FormControl(''),
    miercolesDesde: new FormControl(''),
    miercolesHasta: new FormControl(''),
    juevesDesde:  new FormControl(''),
    juevesHasta:  new FormControl(''),
    viernesDesde: new FormControl(''),
    viernesHasta: new FormControl(''),
    sabadoDesde: new FormControl(''),
    sabadoHasta: new FormControl(''),
    domingoDesde: new FormControl(''),
    domingoHasta: new FormControl(''),
  },[
    this.passwordMatchValidator,
    this.validarFechaLunes,
    this.validarFechaMartes,
    this.validarFechaMiercoles,
    this.validarFechaJueves,
    this.validarFechaViernes,
    this.validarFechaSabado,
    this.validarFechaDomingo,
    this.validarTodasLasFechas
  ]);


  constructor(
    public fotoDialog: MatDialog,
    public dialogRef: MatDialogRef<MedicoFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private apiTel:TelefonoPaisService,
    private medicoService:MedicoService,
    private spinnerService:SpinnerService,
    private snackBar:MatSnackBar,
    private apiTipoDocumento:TipoDocumentoService,
  ) { }


  //
  ngOnInit(): void {
    this.formularioMedico();
  }


  //
  passwordMatchValidator(g: FormGroup) {
    return (g.get('password').value === g.get('repeatPassword').value) ? null : {'mismatch': true};
  }


  //
  validarFechaLunes(g: FormGroup) {
    if(g.get('lunesDesde')?.value && !g.get('lunesHasta')?.value){
      return {'lunesError1': true};
    }
    else if(!g.get('lunesDesde')?.value && g.get('lunesHasta')?.value){
      return {'lunesError2': true};
    }
    else if(g.get('lunesDesde')?.value && g.get('lunesHasta')?.value){
      let lunesDesde = moment(g.get('lunesDesde')?.value, 'HH:mm');
      let lunesHasta = moment(g.get('lunesHasta')?.value, 'HH:mm');
      if(!lunesDesde.isBefore(lunesHasta)){
        return {'lunesError3': true};
      }
    }
    return null;
  }


  //
  validarFechaMartes(g: FormGroup) {
    if(g.get('martesDesde')?.value && !g.get('martesHasta')?.value){
      return {'martesError1': true};
    }
    else if(!g.get('martesDesde')?.value && g.get('martesHasta')?.value){
      return {'martesError2': true};
    }
    else if(g.get('martesDesde')?.value && g.get('martesHasta')?.value){
      let martesDesde = moment(g.get('martesDesde')?.value, 'HH:mm');
      let martesHasta = moment(g.get('martesHasta')?.value, 'HH:mm');
      if(!martesDesde.isBefore(martesHasta)){
        return {'martesError3': true};
      }
    }
    return null;
  }


  //
  validarFechaMiercoles(g: FormGroup) {
    if(g.get('miercolesDesde')?.value && !g.get('miercolesHasta')?.value){
      return {'miercolesError1': true};
    }
    else if(!g.get('miercolesDesde')?.value && g.get('miercolesHasta')?.value){
      return {'miercolesError2': true};
    }
    else if(g.get('miercolesDesde')?.value && g.get('miercolesHasta')?.value){
      let miercolesDesde = moment(g.get('miercolesDesde')?.value, 'HH:mm');
      let miercolesHasta = moment(g.get('miercolesHasta')?.value, 'HH:mm');
      if(!miercolesDesde.isBefore(miercolesHasta)){
        return {'miercolesError3': true};
      }
    }
    return null;
  }


  //
  validarFechaJueves(g: FormGroup) {
    if(g.get('juevesDesde')?.value && !g.get('juevesHasta')?.value){
      return {'juevesError1': true};
    }
    else if(!g.get('juevesDesde')?.value && g.get('juevesHasta')?.value){
      return {'juevesError2': true};
    }
    else if(g.get('juevesDesde')?.value && g.get('juevesHasta')?.value){
      let juevesDesde = moment(g.get('juevesDesde')?.value, 'HH:mm');
      let juevesHasta = moment(g.get('juevesHasta')?.value, 'HH:mm');
      if(!juevesDesde.isBefore(juevesHasta)){
        return {'juevesError3': true};
      }
    }
    return null;
  }


  //
  validarFechaViernes(g: FormGroup) {
    if(g.get('viernesDesde')?.value && !g.get('viernesHasta')?.value){
      return {'viernesError1': true};
    }
    else if(!g.get('viernesDesde')?.value && g.get('viernesHasta')?.value){
      return {'viernesError2': true};
    }
    else if(g.get('viernesDesde')?.value && g.get('viernesHasta')?.value){
      let viernesDesde = moment(g.get('viernesDesde')?.value, 'HH:mm');
      let viernesHasta = moment(g.get('viernesHasta')?.value, 'HH:mm');
      if(!viernesDesde.isBefore(viernesHasta)){
        return {'viernesError3': true};
      }
    }
    return null;
  }


  //
  validarFechaSabado(g: FormGroup) {
    if(g.get('sabadoDesde')?.value && !g.get('sabadoHasta')?.value){
      return {'sabadoError1': true};
    }
    else if(!g.get('sabadoDesde')?.value && g.get('sabadoHasta')?.value){
      return {'sabadoError2': true};
    }
    else if(g.get('sabadoDesde')?.value && g.get('sabadoHasta')?.value){
      let sabadoDesde = moment(g.get('sabadoDesde')?.value, 'HH:mm');
      let sabadoHasta = moment(g.get('sabadoHasta')?.value, 'HH:mm');
      if(!sabadoDesde.isBefore(sabadoHasta)){
        return {'sabadoError3': true};
      }
    }
    return null;
  }



  //
  validarFechaDomingo(g: FormGroup) {
    if(g.get('domingoDesde')?.value && !g.get('domingoHasta')?.value){
      return {'domingoError1': true};
    }
    else if(!g.get('domingoDesde')?.value && g.get('domingoHasta')?.value){
      return {'domingoError2': true};
    }
    else if(g.get('domingoDesde')?.value && g.get('domingoHasta')?.value){
      let domingoDesde = moment(g.get('domingoDesde')?.value, 'HH:mm');
      let domingoHasta = moment(g.get('domingoHasta')?.value, 'HH:mm');
      if(!domingoDesde.isBefore(domingoHasta)){
        return {'domingoError3': true};
      }
    }
    return null;
  }


  //
  validarTodasLasFechas(g: FormGroup){
    let agenda = g.get('agenda')?.value;
    let lunesDesde = g.get('lunesDesde')?.value;
    let lunesHasta = g.get('lunesHasta')?.value;
    let martesDesde = g.get('martesDesde')?.value;
    let martesHasta = g.get('martesHasta')?.value;
    let miercolesDesde = g.get('miercolesDesde')?.value;
    let miercolesHasta = g.get('miercolesHasta')?.value;
    let juevesDesde = g.get('juevesDesde')?.value;
    let juevesHasta = g.get('juevesHasta')?.value;
    let viernesDesde = g.get('viernesDesde')?.value;
    let viernesHasta = g.get('viernesHasta')?.value;
    let sabadoDesde = g.get('sabadoDesde')?.value;
    let sabadoHasta = g.get('sabadoHasta')?.value;
    let domingoDesde = g.get('domingoDesde')?.value;
    let domingoHasta = g.get('domingoHasta')?.value;
    if(
      agenda == true &&
      !lunesDesde && !lunesHasta &&
      !martesDesde && !martesHasta &&
      !miercolesDesde && !miercolesHasta &&
      !juevesDesde && !juevesHasta &&
      !viernesDesde && !viernesHasta &&
      !sabadoDesde && !sabadoHasta &&
      !domingoDesde && !domingoHasta
    ){
      return {'fechasError': true};
    }
    return null;
  }


  //
  borrarFecha(fecha:any){
    this.medicoForm.patchValue({
      [fecha]: '',
    });
  }


  //
  formularioMedico(){
    this.telefonoPais = this.data.listaTelefonoPais;
    this.filtroTelefonoPais = this.data.listaTelefonoPais;
    this.filtroCelularPais = this.data.listaTelefonoPais;
    this.tiposDocumentos = this.data.listaTipoDocumento;  
  }


  //
  filtrarTelPais(event:any){
    let textBuscador:string = this.telPaisBuscador.value;
    this.filtroTelefonoPais = this.telefonoPais.filter(function(element){
      if(element.nombre.toUpperCase().includes(textBuscador.toUpperCase())){
        return element;
      }
    });
  }


  //
  filtrarCelPais(event:any){
    let textBuscador:string = this.celPaisBuscador.value;
    this.filtroCelularPais = this.telefonoPais.filter(function(element){
      if(element.nombre.toUpperCase().includes(textBuscador.toUpperCase())){
        return element;
      }
    });
  }


  //
  crearMedico(form:Medico){
    if(this.medicoForm.valid){
      this.spinnerService.mostrarSpinner();
      this.medicoService.crear(this.nameImage,form,this.file).subscribe(
        data => {
          let response:any = data;
          if(response.statusError){
            this.errorNumeroDocumento = response.errorNumeroDocumento;
            this.errorTipoDocumento = response.errorTipoDocumento;
            this.errorDocumento = response.errorDocumento;
            this.errorMatricula = response.errorMatricula;
            this.errorEmail = response.errorEmail;
            this.errorAgenda = response.errorAgenda;
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Guardado fallido","error");
          }
          else{
            this.openSnackBar("Guardado exitoso","exito");
            this.resetearFormulario();
            this.onClickNo();
          }
        },
        error => {
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Guardado fallido","error");
        }
      );
    }
    else{
      this.openSnackBar("Guardado fallido","error");
    }
  }

  //
  resetearFormulario(){
    this.medicoForm.reset();
  }

  //
  clearNumeroDocumento(){
    this.errorNumeroDocumento = false;
    this.errorDocumento = false;
  }


  //
  clearTipoDocumento(){
    this.errorTipoDocumento = false;
    this.errorDocumento = false;
  }


  //
  clearMatricula(){
    this.errorMatricula = false;
  }


  //
  clearEmail(){
    this.errorEmail = false;
  }


  cambiarAgenda = (event) => {
    this.agenda = event.checked;
    this.errorAgenda = false;
  }


  //
  fileChangeEvent(event: any) {
    var target = event.target || event.srcElement;
  }


  //
  openDialogFoto(){
    const dialogRef = this.fotoDialog.open(FotoDialogComponent,{
      width: '50%',
    })
    dialogRef.afterClosed().subscribe(res=>{
      if(res.data == "" && res.img == ""){}
      else{
        if(res.data.id == "ico_paciente.png" || res.data.id == "ico_pacienteM.png"){
          this.myfilename = res.data.id;
          this.imagen = 'assets/img/'+res.data.id+'';
          this.nameImage = res.data.id;
          this.divImagen.nativeElement.style.display = "block";
        }
        else{
          this.myfilename = res.data.name
          this.imagen = res.img;
          this.divImagen.nativeElement.style.display = "block";     
          this.file = res.data;
          this.nameImage = "";     
        }
      }
    })
  }
  

  //
  onClickNo(){
    this.dialogRef.close(true);
  }


  //
  openSnackBar(message: string, action: string) {
    this.snackBar.openFromComponent(NotificationComponent,{
      data: {
        message : message,
        action : action
      },
      duration:2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass : [action]
    });
  }


}