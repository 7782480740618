import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, OnInit, Inject } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { TurnosService } from "app/services/turnos.service";
import { NgxMaterialTimepickerTheme } from "ngx-material-timepicker";
import * as moment from "moment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SpinnerService } from "app/services/spinner.service";
import { NotificationComponent } from "app/shared/notification/notification.component";
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  Subscription,
} from "rxjs";
import { PacienteService } from "../../services/paciente.service";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  take,
} from "rxjs/operators";
import { TipoDocumentoService } from "../../services/tipo-documento.service";
import { TipoDocumento } from "../../models/tipoDocumento.interface";
import { Paciente, PacienteRequest } from "../../models/paciente.interface";
import { diasSemanaOrdenAnglo, generarTurnos } from "../../utils/turnos.utils";
import { MatSelectChange } from "@angular/material/select";
import { Medico, MedicoConDiasTurno } from "../../models/medico.interface";
import { CentroMedico } from "../../models/centroMedico.interface";
import { Agenda } from "../../models/agenda.interface";

@Component({
  selector: "app-turno-add-dialog",
  templateUrl: "./turno-add-dialog.component.html",
  styleUrls: ["./turno-add-dialog.component.scss"],
})
export class TurnoAddDialogComponent implements OnInit {
  //
  formularioTurno = new FormGroup({
    id: new FormControl(""),
    titulo: new FormControl("", Validators.required),
    especialidad: new FormControl(""),
    especialidadBuscador: new FormControl(""),
    detalle: new FormControl("", Validators.required),
    id_paciente: new FormControl("", Validators.required),
    pacienteBuscador: new FormControl(""),
    id_medico: new FormControl("", Validators.required),
    medicoBuscador: new FormControl(""),
    centroMedico: new FormControl("", Validators.required),
    centroMedicoBuscador: new FormControl(""),
    fechaDesde: new FormControl("", Validators.required),
    fechaHasta: new FormControl("", Validators.required),
    tiempoDesde: new FormControl("", Validators.required),
    tiempoHasta: new FormControl("", Validators.required),
  });
  listaEspecialidades: any;
  filtroEspecialidades: any;
  listaCompletaMedicos: any;
  general: any;
  tipoUsuario: any;
  fechaError: any;
  listaPacientes: any;
  listaMedicos: any;
  listaCentrosMedicos: any;
  filtroPacientes: any;
  filtroMedicos: any;
  filtroCentroMedicos: any;
  tiposDocumentos: TipoDocumento[];
  turno: any;
  usuario: any;
  editar: any;
  medicoSelecionado: Medico = null;
  pacienteSeleccionado: Paciente = null;
  centroMedicoSeleccionado: CentroMedico = null;
  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: "#ffffff",
      buttonColor: "#000000",
    },
    dial: {
      dialBackgroundColor: "#3434EC",
    },
    clockFace: {
      clockFaceBackgroundColor: "#EEEEEE",
      clockHandColor: "#05CE7C",
      clockFaceTimeInactiveColor: "#3434EC",
    },
  };
  public turnoDesdeLanding = false;
  public turnoDesdePaciente = false;
  private diasSemana = diasSemanaOrdenAnglo;
  private subscripciones = new Subscription();
  private diasActivosMédicoObs = new BehaviorSubject<number[]>([
    0, 1, 2, 3, 4, 5, 6, 7,
  ]);
  private diasActivosMedico: number[] = [];
  private listaDeTurnos: any[] = [];
  public diaActual = new Date();
  public loadingPacientePorDni = false;
  public agregarNuevoPaciente = false;

  //
  constructor(
    public dialogRef: MatDialogRef<TurnoAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public response: any,
    public turnosService: TurnosService,
    private pacienteService: PacienteService,
    private tipoDocumentoService: TipoDocumentoService,
    private spinnerService: SpinnerService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {}

  //
  ngOnInit(): void {
    this.editar = this.response.editar;
    this.turno = this.response.turno;
    this.usuario = this.response.usuario;
    this.listaPacientes = this.response.pacientes;
    this.filtroPacientes = this.response.pacientes;
    this.listaMedicos = this.response.medicos;
    this.filtroMedicos = this.response.medicos;
    this.listaCompletaMedicos = this.response.medicos;
    this.listaCentrosMedicos = this.response.centrosMedicos;
    this.filtroCentroMedicos = this.response.centrosMedicos;
    this.tipoUsuario = this.response.tipoUsuario;
    this.general = this.response.general;
    this.listaEspecialidades = this.response.especialidades;
    this.filtroEspecialidades = this.response.especialidades;
    this.turnoDesdeLanding = this.response?.turnoDesdeLanding;
    this.turnoDesdePaciente = this.response?.turnoDesdePaciente;

    this.response.turno
      ? this.iniciarFormularioEdicion()
      : this.iniciarFormulario();

    this.setearFormatoFechaSegúnFecha();

    if (this.response.turnoDesdeLanding || this.response.turnoDesdePaciente) {
      this.subscripciones.add(
        combineLatest([
          this.turnosService.getTurnosPanel(),
          this.tipoDocumentoService.listar(),
          this.diasActivosMédicoObs,
        ]).subscribe(
          ([
            { especialidades, medicos, turnos, centrosMedicos },
            { listaTipoDocumento },
            diasActivosMedico,
          ]) => {
            this.listaCompletaMedicos = medicos;
            this.filtroMedicos = medicos;
            this.listaEspecialidades = especialidades;
            this.filtroEspecialidades = especialidades;
            this.tiposDocumentos = listaTipoDocumento;
            this.diasActivosMedico = diasActivosMedico;
            this.listaDeTurnos = turnos;
            this.listaCentrosMedicos = centrosMedicos;
            this.filtroCentroMedicos = centrosMedicos;
          }
        )
      );

      this.subscripciones.add(
        this.handleDniInputEvent().subscribe({
          next: (dni) => {
            if (dni?.length >= 7 && !this.agregarNuevoPaciente) {
              this.loadingPacientePorDni = true;

              this.pacienteService.buscarPorDni(dni).subscribe({
                next: ({ pacienteExiste, paciente }) => {
                  this.loadingPacientePorDni = false;
                  if (pacienteExiste) {
                    this.pacienteSeleccionado = paciente;
                    this.formularioTurno.patchValue({
                      id_paciente: paciente.id,
                      pacienteBuscador: paciente.nombreApellido,
                    });
                  }

                  if (!pacienteExiste) {
                    this.agregarNuevoPaciente = true;
                  }
                },
                error: () => {
                  this.loadingPacientePorDni = false;
                  this.agregarNuevoPaciente = true;
                },
              });
            }
          },
        })
      );
    }
  }

  ngOnDestroy() {
    if (this.subscripciones) {
      this.subscripciones.unsubscribe();
    }
  }

  private iniciarFormulario() {
    const baseForm = this.fb.group({
      especialidad:
        this.tipoUsuario === "medico" && this.usuario.especialidad
          ? this.usuario.especialidad
          : 0,
      especialidadBuscador: "",
      id_paciente: "",
      pacienteBuscador: "",
      id_medico: this.tipoUsuario === "medico" ? this.usuario?.id : "",
      medicoBuscador: "",
      centroMedico: 0,
      centroMedicoBuscador: "",
      fechaDesde: "",
      fechaHasta: "",
      tiempoDesde: "",
      tiempoHasta: "",
      titulo: "",
      detalle: "",
    });

    if (this.response?.turnoDesdeLanding) {
      baseForm.addControl(
        "nombrePaciente",
        this.fb.control("", Validators.required)
      );
      baseForm.addControl(
        "apellidoPaciente",
        this.fb.control("", Validators.required)
      );
      baseForm.addControl(
        "tipoDniPaciente",
        this.fb.control("", Validators.required)
      );
      baseForm.addControl(
        "dniPaciente",
        this.fb.control("", Validators.required)
      );
      baseForm.addControl(
        "emailPaciente",
        this.fb.control("", [Validators.required, Validators.email])
      );
      baseForm.addControl("id_paciente", this.fb.control(""));
    } else {
      baseForm.patchValue({
        id_paciente: this.tipoUsuario === "paciente" ? this.usuario?.id : "",
        pacienteBuscador: "",
        centroMedicoBuscador: "",
      });
    }

    this.formularioTurno = baseForm;

    this.setearFormatoFechaSegúnFecha();

    if (this.tipoUsuario === "medico") {
      this.medicoSelecionado = this.filtroMedicos.find(
        (elemento) => elemento.id === this.usuario?.id
      );

      const agendasMedico = this.medicoSelecionado?.agendaMedico.map(
        (agenda) => agenda.id_sucursal
      );

      this.listaCentrosMedicos = this.listaCentrosMedicos.filter((centro) =>
        agendasMedico.includes(centro.id)
      );
      this.filtroCentroMedicos = this.filtroCentroMedicos.filter((centro) =>
        agendasMedico.includes(centro.id)
      );

      if (this.listaCentrosMedicos.length === 1) {
        this.centroMedicoSeleccionado = this.listaCentrosMedicos[0];
      }

      this.formularioTurno.patchValue({
        id_medico: this.medicoSelecionado.id,
        especialidad: this.medicoSelecionado.especialidad,
        centroMedico:
          this.listaCentrosMedicos.length === 1
            ? this.listaCentrosMedicos[0].id
            : this.response.centroMedicoSeleccionado !== null ? this.response.centroMedicoSeleccionado.id : "",
      });
    }
  }

  private setFormDates(start: Date, end: Date): void {
    this.formularioTurno.patchValue({
      fechaDesde: moment(start).format(),
      fechaHasta: moment(end).format(),
    });
  }

  private setFormTimes(start: Date, end: Date): void {
    this.formularioTurno.patchValue({
      tiempoDesde: moment(start).format("HH:mm"),
      tiempoHasta: moment(end).format("HH:mm"),
    });
  }

  private setearFormatoFechaSegúnFecha() {
    const viewTypeHandlers = {
      dayGridMonth: () => {
        this.setFormDates(
          this.response.selectInfo.start,
          this.response.selectInfo.start
        );
      },
      timeGridWeek: () => {
        this.setFormTimes(
          this.response.selectInfo.start,
          this.response.selectInfo.end
        );
        this.setFormDates(
          this.response.selectInfo.start,
          this.response.selectInfo.start
        );
      },
      timeGridDay: () => {
        this.setFormTimes(
          this.response.selectInfo.start,
          this.response.selectInfo.end
        );
        this.setFormDates(
          this.response.selectInfo.start,
          this.response.selectInfo.start
        );
      },
    };

    const viewType = this.response.selectInfo?.view.type;
    if (viewType in viewTypeHandlers) {
      viewTypeHandlers[viewType]();
    }
  }

  //
  iniciarFormularioEdicion() {
    this.medicoSelecionado = this.filtroMedicos.find(
      (elemento: any) => elemento.id == this.turno.id_medico
    );
    this.pacienteSeleccionado = this.filtroPacientes.find(
      (elemento: any) => elemento.id == this.turno.id_paciente
    );

    this.centroMedicoSeleccionado = this.filtroCentroMedicos.find(
      (elemento: any) => elemento.id == this.turno.id_centromedico
    );

    this.formularioTurno.patchValue({
      id: this.turno.id,
      titulo: this.turno.titulo,
      detalle: this.turno.detalle,
      id_paciente: this.turno.id_paciente,
      especialidad: this.medicoSelecionado.especialidad
        ? this.medicoSelecionado.especialidad
        : 0,
      pacienteBuscador: "",
      centroMedico: this.turno.id_centromedico,
      centroMedicoBuscador: "",
      id_medico: this.turno.id_medico,
      medicoBuscador: "",
      fechaDesde: new Date(this.turno.fecha_desde),
      fechaHasta: new Date(this.turno.fecha_hasta),
      tiempoDesde: moment(this.turno.fecha_desde).format("HH:mm"),
      tiempoHasta: moment(this.turno.fecha_hasta).format("HH:mm"),
    });
  }

  //
  filtrarEspecialidad(event: any) {
    let valorString: string = this.formularioTurno.get(
      "especialidadBuscador"
    ).value;
    let array = [];
    for (let ops of this.listaEspecialidades) {
      if (ops.texto.toUpperCase().includes(valorString.toUpperCase())) {
        let nuevoE: any = ops;
        array.push(nuevoE);
      }
    }
    this.filtroEspecialidades = array;
  }

  //
  filtrarMedico(event: any) {
    let valorString: string = this.formularioTurno.get("medicoBuscador").value;
    this.filtroMedicos = [];

    for (let ops of this.listaMedicos) {
      if (
        ops.nombreCompleto.toUpperCase().includes(valorString.toUpperCase())
      ) {
        let nuevoM: any = ops;
        this.filtroMedicos.push(nuevoM);
      }
    }
  }

  //
  filtrarCentroMedico(event: any) {
    let valorString: string = this.formularioTurno.get(
      "centroMedicoBuscador"
    ).value;
    this.filtroCentroMedicos = [];

    for (let ops of this.listaCentrosMedicos) {
      if (ops.nombre.toUpperCase().includes(valorString.toUpperCase())) {
        let nuevoCM: any = ops;
        this.filtroCentroMedicos.push(nuevoCM);
      }
    }
  }

  //
  filtrarEspecialidadMedico(event: any) {
    let especialidad: any = this.formularioTurno.get("especialidad").value;
    if (especialidad == 0) {
      this.listaMedicos = this.listaCompletaMedicos;
      this.filtroMedicos = this.listaCompletaMedicos;
      this.formularioTurno.patchValue({
        id_medico: "",
        fechaDesde: "",
      });
    } else {
      let array = [];
      for (let ops of this.listaCompletaMedicos) {
        if (ops.especialidad == especialidad) {
          let nuevoM: any = ops;
          array.push(nuevoM);
        }
      }
      this.listaMedicos = array;
      this.filtroMedicos = array;
      this.formularioTurno.patchValue({
        id_medico: "",
        fechaDesde: "",
      });
    }
  }

  //
  filtrarPaciente(event: any) {
    let valorString: string =
      this.formularioTurno.get("pacienteBuscador").value;
    this.filtroPacientes = [];

    for (let ops of this.listaPacientes) {
      if (
        ops.nombreApellido.toUpperCase().includes(valorString.toUpperCase())
      ) {
        let nuevoP: any = ops;
        this.filtroPacientes.push(nuevoP);
      }
    }
  }

  //
  validarMedicoEmail(medicoId: number) {
    this.medicoSelecionado = this.filtroMedicos.find(
      (m: MedicoConDiasTurno) => m.id == medicoId
    );

    if (this.medicoSelecionado) {
      this.diasActivosMédicoObs.next(this.generarDiasSemanaActivo());
    }
  }

  //
  validarPacienteEmail(paacienteId: number) {
    this.pacienteSeleccionado = this.filtroPacientes.find(
      (p: Paciente) => p.id == paacienteId
    );
  }

  validarCentroMedico(centroMedicoId: number) {
    this.centroMedicoSeleccionado = this.filtroCentroMedicos.find(
      (cm: CentroMedico) => cm.id == centroMedicoId
    );
    if (this.tipoUsuario !== "medico") {
      let array = [];
      for (let ops of this.listaCompletaMedicos) {
        if (
          ops?.agendaMedico
            .map((a) => a.id_sucursal)
            .includes(this.centroMedicoSeleccionado?.id)
        ) {
          let nuevoM: any = ops;
          array.push(nuevoM);
        }
      }
      this.listaMedicos = array;
      this.filtroMedicos = array;

      this.formularioTurno.patchValue({
        id_medico: "",
        fechaDesde: "",
      });
    }
  }

  //
  guardarTurno() {
    if (this.response.turnoDesdeLanding) {
      if (this.agregarNuevoPaciente) {
        const newPaciente: PacienteRequest = {
          nombreApellido:
            this.formularioTurno.value.nombrePaciente +
            " " +
            this.formularioTurno.value.apellidoPaciente,
          tipoDocumento: this.formularioTurno.value.tipoDniPaciente,
          numeroDocumento: this.formularioTurno.value.dniPaciente,
          email: this.formularioTurno.value.emailPaciente,
          password: this.formularioTurno.value.dniPaciente,
          numeroAfiliado: "",
          direccion: "",
          imagen: "",
          obraSocial: "",
          telPais: "",
          telCaract: "",
          telNumero: "",
          celPais: "",
          celCaract: "",
          celNumero: "",
          codigoInterno: "",
          datoingreso: "",
          modificar: true,
        };

        this.pacienteService
          .crear("", newPaciente, null)
          .pipe(take(1))
          .subscribe({
            next: (data: { mensaje: string; nuevoPaciente?: Paciente }) => {
              this.spinnerService.mostrarSpinner();

              this.formularioTurno.patchValue({
                id_paciente: data?.nuevoPaciente?.id,
              });

              this.formatearYEnviarTurno();
            },
            error: () => {
              this.openSnackBar("Error al crear el paciente", "error");
            },
          });
      } else {
        this.formularioTurno.patchValue({
          id_paciente: this.pacienteSeleccionado.id,
          dniPaciente: this.pacienteSeleccionado.numeroDocumento,
        });

        this.formatearYEnviarTurno();
      }
    } else {
      this.formatearYEnviarTurno();
      return;
    }
  }

  private formatearYEnviarTurno() {
    if (this.response.turnoDesdePaciente || this.response.turnoDesdeLanding) {
      this.formularioTurno.patchValue({
        fechaHasta: this.formularioTurno.value.fechaDesde,
        tiempoHasta: this.formularioTurno.value.tiempoDesde,
      });

      if (this.response.turnoDesdePaciente) {
        this.formularioTurno.patchValue({
          id_paciente: this.response.pacienteId,
        });
      }
    }

    const fechaDesde = this.formatearFecha(
      this.formularioTurno.get("fechaDesde").value,
      this.formularioTurno.get("tiempoDesde").value
    );

    const fechaHasta = this.formatearFecha(
      this.formularioTurno.get("fechaHasta").value,
      this.formularioTurno.get("tiempoHasta").value
    );

    this.editar
      ? this.validarFechaEditar(
          this.formularioTurno.value,
          fechaDesde,
          fechaHasta
        )
      : this.validarFecha(this.formularioTurno.value, fechaDesde, fechaHasta);
  }

  private formatearFecha(fechaAFormatear: Date, tiempoAFormatear): string {
    return `${moment(fechaAFormatear).format(
      "YYYY-MM-DD"
    )} ${tiempoAFormatear}`;
  }

  //
  validarFecha(formularioTurno: any, fechaDesde: any, fechaHasta: any) {
    if (
      this.formularioTurno.valid ||
      (this.turnoDesdeLanding && this.pacienteSeleccionado !== null)
    ) {
      this.spinnerService.mostrarSpinner();
      this.turnosService
        .validarTurno(formularioTurno, fechaDesde, fechaHasta)
        .subscribe(
          (data) => {
            let response: any = data;
            this.fechaError = response.fechaError;
            if (this.fechaError == "") {
              this.crearTurno(formularioTurno, fechaDesde, fechaHasta);
            } else {
              this.spinnerService.ocultarSpinner();
              this.openSnackBar("Guardado fallido", "error");
            }
          },
          (error) => {
            console.log(error);
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Guardado fallido", "error");
          }
        );
    } else {
      this.openSnackBar("Guardado fallido", "error");
    }
  }

  //
  crearTurno(formularioTurno: any, fechaDesde: any, fechaHasta: any) {
    this.turnosService
      .crearTurno(formularioTurno, fechaDesde, fechaHasta)
      .subscribe(
        (data) => {
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Guardado exitoso", "exito");
          this.dialogRef.close({ estado: 1 });
        },
        (error) => {
          console.log(error);
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Guardado fallido", "error");
        }
      );
  }

  //
  validarFechaEditar(formularioTurno: any, fechaDesde: any, fechaHasta: any) {
    if (this.formularioTurno.valid) {
      this.spinnerService.mostrarSpinner();
      this.turnosService
        .validarTurnoEditar(formularioTurno, fechaDesde, fechaHasta)
        .subscribe(
          (data) => {
            let response: any = data;
            this.fechaError = response.fechaError;
            if (this.fechaError == "") {
              this.editarTurno(formularioTurno, fechaDesde, fechaHasta);
            } else {
              this.spinnerService.ocultarSpinner();
              this.openSnackBar("Editado fallido", "error");
            }
          },
          (error) => {
            console.log(error);
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Editado fallido", "error");
          }
        );
    } else {
      this.openSnackBar("Editado fallido", "error");
    }
  }

  //
  editarTurno(formularioTurno: any, fechaDesde: any, fechaHasta: any) {
    this.turnosService
      .editarTurno(formularioTurno, fechaDesde, fechaHasta)
      .subscribe(
        (data) => {
          this.openSnackBar("Editado exitoso", "exito");
          this.dialogRef.close({ estado: 1 });
        },
        (error) => {
          console.log(error);
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Editado fallido", "error");
        }
      );
  }

  //
  cambiarTurno(event: Event, tipoValor: any) {
    this.fechaError = "";
    if (tipoValor == "tiempoDesde") {
      let rangoHasta = moment(this.general?.rangoHasta, "HH:mm");
      let fechaDesde = this.formularioTurno.get("fechaDesde");
      let fechaHasta = this.formularioTurno.get("fechaHasta");
      let tiempoDesde = this.formularioTurno.get("tiempoDesde");
      let tiempoHasta = this.formularioTurno.get("tiempoHasta");
      if (!tiempoHasta.value && rangoHasta.isValid()) {
        let fechaMomentDesde = moment(fechaDesde.value); //.format('YYYY-MM-DD');
        let fechaMomentHasta = moment(fechaHasta.value); //.format('YYYY-MM-DD');
        if (fechaMomentDesde.isSame(fechaMomentHasta)) {
          let fechaTotalDesde = moment(
            `${fechaMomentDesde.format("YYYY-MM-DD")} ${tiempoDesde.value}`,
            "YYYY-MM-DD HH:mm"
          );
          let sumaMoment = fechaTotalDesde
            .add(rangoHasta.hours(), "hours")
            .add(rangoHasta.minutes(), "minutes");
          tiempoHasta.setValue(sumaMoment.format("HH:mm"));
          fechaHasta.setValue(
            new Date(sumaMoment.year(), sumaMoment.month(), sumaMoment.date())
          );
        } else {
          let tiempoMomentDesde = moment(tiempoDesde.value, "HH:mm");
          let sumaMoment = tiempoMomentDesde
            .add(rangoHasta.hours(), "hours")
            .add(rangoHasta.minutes(), "minutes");
          tiempoHasta.setValue(sumaMoment.format("HH:mm"));
        }
      }
    }
  }

  //
  cancelar() {
    this.dialogRef.close();
  }

  //
  borrarTurno() {
    this.dialogRef.close({
      id: this.turno.id,
      estado: 2,
    });
  }

  //
  cerrar() {
    this.dialogRef.close();
  }

  //
  openSnackBar(message: string, action: string) {
    this.snackBar.openFromComponent(NotificationComponent, {
      data: {
        message: message[0].toUpperCase() + message.slice(1),
        action: action,
      },
      duration: 2500,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: [action],
    });
  }

  private generarListaDeTodosLosTurnos(day: Date): string[] {
    const desde =
      this.medicoSelecionado && this.getAgendaActual() !== null
        ? this.getAgendaActual()[`${this.diasSemana[day.getDay()]}_desde`]
        : "00:00";

    let desdeConControlDeHora = desde;

    if (day.getDate() === this.diaActual.getDate()) {
      const horaActual = this.diaActual.getHours();
      const horaDesde =
        this.getAgendaActual() !== null
          ? Number(
              this.getAgendaActual()[
                `${this.diasSemana[day.getDay()]}_desde`
              ]?.split(":")[0]
            )
          : horaActual;
      if (horaActual > horaDesde || this.getAgendaActual() === null) {
        desdeConControlDeHora = (horaActual + 1).toString() + ":00";
      }
    }

    const hasta =
      this.medicoSelecionado && this.getAgendaActual() !== null
        ? this.getAgendaActual()[`${this.diasSemana[day.getDay()]}_hasta`]
        : "24:00";

    const listaDeTurnos = generarTurnos(
      desdeConControlDeHora,
      hasta,
      this.general.rangoHasta
    );

    return listaDeTurnos;
  }

  public listaDeTurnosSinLosYaOtorgados(): string[] {
    const diaSeleccionado = new Date(this.formularioTurno.value.fechaDesde);

    const turnosDadosPorMedico = this.turnosPorMedico()
      .filter(
        (turno) =>
          moment(turno.fecha_desde).format("YYYY-MM-DD") ===
          moment(diaSeleccionado).format("YYYY-MM-DD")
      )
      .map((turno) => {
        return turno.fecha_desde.split(" ")[1].split(":")[0];
      });

    const listaDeTurnosSinLosTurnosOtorgados =
      this.generarListaDeTodosLosTurnos(diaSeleccionado).filter(
        (turno) =>
          !turnosDadosPorMedico.includes(turno.split("-")[0].split(":")[0])
      );

    return listaDeTurnosSinLosTurnosOtorgados.length === 0
      ? ["No quedan turnos disponibles"]
      : listaDeTurnosSinLosTurnosOtorgados;
  }

  generarDiasSemanaActivo(): number[] {
    const diasSemana: number[] = [];

    if (this.getAgendaActual()?.lunes_desde !== null) {
      diasSemana.push(1);
    }
    if (this.getAgendaActual()?.martes_desde !== null) {
      diasSemana.push(2);
    }
    if (this.getAgendaActual()?.miercoles_desde !== null) {
      diasSemana.push(3);
    }
    if (this.getAgendaActual()?.jueves_desde !== null) {
      diasSemana.push(4);
    }
    if (this.getAgendaActual()?.viernes_desde !== null) {
      diasSemana.push(5);
    }
    if (this.getAgendaActual()?.sabado_desde !== null) {
      diasSemana.push(6);
    }
    if (this.getAgendaActual()?.domingo_desde !== null) {
      diasSemana.push(0);
    }

    return diasSemana;
  }

  private turnosPorMedico() {
    const medico = this.formularioTurno.value.id_medico;
    if (!medico) return;

    return this.listaDeTurnos.filter((turno) => turno.id_medico === medico);
  }

  private diaCompleto(day: Date): boolean {
    const turnosDadosPorMedico = this.turnosPorMedico()?.filter(
      (turno) =>
        moment(turno?.fecha_desde).format("YYYY-MM-DD") ===
        moment(day).format("YYYY-MM-DD")
    );

    const listaDeTurnosSinLosTurnosOtorgados =
      this.generarListaDeTodosLosTurnos(day);

    return (
      listaDeTurnosSinLosTurnosOtorgados?.length ===
      turnosDadosPorMedico?.length
    );
  }

  private filtrarFechasDatePicker(d: Date | null): boolean {
    const day = d || new Date();

    if (this.response.turnoDesdeLanding || this.response.turnoDesdePaciente) {
      return (
        this.diasActivosMedico?.includes(day.getDay()) && !this.diaCompleto(day)
      );
    } else {
      return [0, 1, 2, 3, 4, 5, 6].includes(day.getDay());
    }
  }

  public boundedFiltrarFechasDatePicker =
    this.filtrarFechasDatePicker.bind(this);

  public seleccionarHorario(event: MatSelectChange) {
    this.formularioTurno.patchValue({
      tiempoDesde: event.value.split("-")[0].trim(),
    });
  }

  public handleDniInputEvent() {
    return this.formularioTurno.controls.dniPaciente.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      catchError(() => {
        this.openSnackBar("Error al buscar paciente", "Cerrar");
        return of(null);
      })
    );
  }

  public handleAgregarNuevoPaciente() {
    if (this.pacienteSeleccionado) {
      this.agregarNuevoPaciente = false;
      this.pacienteSeleccionado = null;
    }
  }

  public getAgendaActual(): Agenda {
    const agenda = this.medicoSelecionado?.agendaMedico?.find(
      (agenda) => agenda.id_sucursal === this.centroMedicoSeleccionado?.id
    );
    return agenda ?? null;
  }
}
