import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListarSucursalComponent } from './listar-sucursal/listar-sucursal.component';
import { SucursalRoutingModule } from './sucursal-routing.module';
import { SharedModule } from 'primeng/api';
import { MaterialModule } from 'app/material/material.module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { SharedPanelModule } from '../shared-panel/shared-panel.module'; 
import { EditarSucursalComponent } from './editar-sucursal/editar-sucursal.component';
import { FormSucursalComponent } from './form-sucursal/form-sucursal.component';


@NgModule({
  declarations: [
    ListarSucursalComponent,
    EditarSucursalComponent,
    FormSucursalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    SucursalRoutingModule,
    FormsModule,
    SharedPanelModule,
    ReactiveFormsModule
  ]
})
export class SucursalModule { }
