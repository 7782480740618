<form [formGroup]="estudioMedicoForm" (submit)="crearEstudioMedico(estudioMedicoForm.value)" class="formCard">
    <div class="tituloModal">
        <span>
            Crear estudio médico
        </span>
        <mat-icon class="buttonClose" [mat-dialog-close]=false>close</mat-icon>
    </div>
    <div class="textModal">
        <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
                <mat-label>Nombre</mat-label>
                <input formControlName="texto" type="text" matInput (ngModelChange)="clearTexto()">
            </mat-form-field>
            <div class="valid">
                <span style="color:rgb(236,75,72);" *ngIf="estudioMedicoForm.get('texto').errors?.required && estudioMedicoForm.get('texto').touched">
                    El nombre es requerido
                </span>
                <span style="color:rgb(236,75,72);" *ngIf="errorTexto && estudioMedicoForm.get('texto').touched">
                    El texto fue registrado por otro estudio médico
                </span>
            </div>
        </div>
    </div>
    <div class="accionModal">
        <div class="buttons">
            <button mat-raised-button class="button">Crear</button>
        </div>
    </div>
</form>