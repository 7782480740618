import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, merge, of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[appDelayHover]'
})
export class DelayHoverDirective implements OnInit {
  @Input()
  delay = 500;

  @Output('delayed-hover') hoverEvent = new EventEmitter();

  constructor(private readonly element: ElementRef) { }

  ngOnInit() {
    const hide$ = fromEvent(this.element.nativeElement, 'mouseleave').pipe(map(_ => false));
    const show$ = fromEvent(this.element.nativeElement, 'mouseenter').pipe(map(_ => true));

    merge(hide$, show$)
      .pipe(
        switchMap(show => {
          if (!show) {
            return of(false);
          }
          return of(true).pipe(delay(this.delay));
        })
      )
      .subscribe(show => {
        if (show) {
          this.hoverEvent.emit();
        }
      });
  }

  ngOnDestroy() { }
}