import {
  ElementRef,
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  NgModule,
  Inject,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FotoDialogComponent } from "app/shared/foto-dialog/foto-dialog.component";
import { FormGroup, FormControl, Validator, Validators } from "@angular/forms";
import { UsuarioPanel } from "app/models/usuarioPanel.interface";
import { UsuarioPanelService } from "app/services/usuario-panel.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NotificationComponent } from "app/shared/notification/notification.component";
import { Router, ActivatedRoute } from "@angular/router";
import { AbstractControl } from "@angular/forms";
import { TelefonoPais } from "app/models/telefonoPais.interface";
import { TelefonoPaisService } from "app/services/telefono-pais.service";
import { GlobalVariable } from "app/global/global";
import { SpinnerService } from "app/services/spinner.service";

@Component({
  selector: "app-form-usuario-panel",
  templateUrl: "./form-usuario-panel.component.html",
  styleUrls: ["./form-usuario-panel.component.scss"],
})
export class FormUsuarioPanelComponent implements OnInit {
  //
  funcionCorrecta: any;
  url: string = GlobalVariable.BASE_API_URL + "storage/images/usuario-panel/";
  editarValor: boolean;
  panelOpenState = false;
  @Input() set editar(value: boolean) {
    this.editarValor = value;
    this.panelOpenState = value ? true : false;
  }
  @Output() listar: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild("UploadFileInput") uploadFileInput: ElementRef;
  @ViewChild("divImagen") divImagen: ElementRef;
  public telPais: FormControl = new FormControl();
  file: File;
  usuarioPanel: UsuarioPanel;
  imagen = "";
  myfilename = "Seleccionar archivo";
  nameImage: string = "";
  telefonoPais: TelefonoPais[];
  filtroTelefonoPais: TelefonoPais[];
  errorNombreUsuario: any;
  errorEmail: any;
  errorImage = false;
  usuarioPanelForm = new FormGroup(
    {
      id: new FormControl(""),
      nombreUsuario: new FormControl("", Validators.required),
      nombreCompleto: new FormControl("", Validators.required),
      email: new FormControl("", [Validators.required, Validators.email]),
      telPais: new FormControl("", Validators.required),
      telCaract: new FormControl("", Validators.required),
      telNumero: new FormControl("", Validators.required),
      perfil: new FormControl("", Validators.required),
      memberContactNo: new FormControl("", Validators.required),
      password: new FormControl("", Validators.required),
      repeatPassword: new FormControl("", [Validators.required]),
    },
    this.passwordMatchValidator
  );
  @Input() set response(value: any) {
    if (value) {
      this.listarTelefonoPais(value.listaTelefonoPais);
      if (value.usuarioPanel) {
        this.iniciarFormularioEditado(value.usuarioPanel);
      }
    }
  }

  //
  constructor(
    public fotoDialog: MatDialog,
    public usuarioPanelService: UsuarioPanelService,
    private snackBar: MatSnackBar,
    private actRoute: ActivatedRoute,
    private router: Router,
    private apiDos: TelefonoPaisService,
    private spinnerService: SpinnerService
  ) {}

  //
  passwordMatchValidator(g: FormGroup) {
    return g.get("password").value === g.get("repeatPassword").value
      ? null
      : { mismatch: true };
  }

  //
  ngOnInit(): void {}

  //
  iniciarFormularioEditado(data) {
    this.usuarioPanel = data;
    this.imagen = `${this.url}${this.usuarioPanel.imagen}`;
    this.divImagen.nativeElement.style.display = "block";
    this.usuarioPanelForm.setValue({
      id: this.usuarioPanel.id,
      nombreCompleto: this.usuarioPanel.nombreCompleto,
      nombreUsuario: this.usuarioPanel.nombreUsuario,
      email: this.usuarioPanel.email,
      telPais: this.usuarioPanel.telPais,
      telCaract: this.usuarioPanel.telCaract,
      telNumero: this.usuarioPanel.telNumero,
      perfil: this.usuarioPanel.perfil,
      memberContactNo: `${this.usuarioPanel.imagen},`,
      password: this.usuarioPanel.password,
      repeatPassword: this.usuarioPanel.password,
    });
    this.nameImage = this.usuarioPanel.imagen;
    this.errorImage = false;
  }

  //
  listarTelefonoPais(data) {
    this.filtroTelefonoPais = [];
    this.telefonoPais = data;
    for (let obj of this.telefonoPais) {
      this.filtroTelefonoPais.push(obj);
    }
  }

  //
  filtrarTelPais(event: any) {
    this.filtroTelefonoPais.splice(0, this.filtroTelefonoPais.length);
    let valueString: string = this.telPais.value;
    for (let obj of this.telefonoPais) {
      if (obj.nombre.toUpperCase().includes(valueString.toUpperCase())) {
        this.filtroTelefonoPais.push(obj);
      }
    }
  }

  //
  fileChangeEvent(event: any) {
    var target = event.target || event.srcElement;
  }

  //
  openDialogFoto() {
    const dialogRef = this.fotoDialog.open(FotoDialogComponent, {
      width: "50%",
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res.data == "" && res.img == "") {
      } else {
        if (
          res.data.id == "ico_paciente.png" ||
          res.data.id == "ico_pacienteM.png"
        ) {
          this.imagen = "assets/img/" + res.data.id + "";
          this.divImagen.nativeElement.style.display = "block";
          this.nameImage = res.data.id;
          this.myfilename = res.data.id;
        } else {
          this.myfilename = res.data.name;
          this.file = res.data;
          this.imagen = res.img;
          this.divImagen.nativeElement.style.display = "block";
          this.nameImage = "";
        }
        this.errorImage = false;
      }
    });
  }

  //
  guardarUsuarioPanel(form: UsuarioPanel) {
    this.funcionCorrecta = this.editarValor
      ? this.editarUsuarioPanel(form)
      : this.crearUsuarioPanel(form);
  }

  //
  crearUsuarioPanel(form: UsuarioPanel) {
    if (this.usuarioPanelForm.valid && (!this.file || !this.nameImage)) {
      this.spinnerService.mostrarSpinner();
      this.usuarioPanelService.crear(this.nameImage, form, this.file).subscribe(
        (data) => {
          let response: any = data;
          if (response.statusError) {
            this.errorNombreUsuario = response.errorNombreUsuario;
            this.errorEmail = response.errorEmail;
            this.errorImage = response.errorImage;
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Guardado fallido", "error");
          } else {
            this.openSnackBar("Guardado exitoso", "exito");
            this.resetearFormulario();
            this.listar.emit();
          }
        },
        (error) => {
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Guardado fallido", "error");
        }
      );
    } else {
      if (!this.file || !this.nameImage) this.errorImage = true;
      this.openSnackBar("Guardado fallido", "error");
    }
  }

  //
  editarUsuarioPanel(form: UsuarioPanel) {
    if (this.usuarioPanelForm.valid && (!this.file || !this.nameImage)) {
      this.spinnerService.mostrarSpinner();
      this.usuarioPanelService
        .editar(this.nameImage, form, this.file)
        .subscribe(
          (data) => {
            let response: any = data;
            if (response.statusError) {
              this.errorNombreUsuario = response.errorNombreUsuario;
              this.errorEmail = response.errorEmail;
              this.spinnerService.ocultarSpinner();
              this.openSnackBar("Editado fallido", "error");
            } else {
              this.spinnerService.ocultarSpinner();
              this.openSnackBar("Editado exitoso", "exito");
              this.router.navigate(["/seccion-panel/usuario-panel/"]);
            }
          },
          (error) => {
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Editado fallido", "error");
          }
        );
    } else {
      this.openSnackBar("Editado fallido", "error");
    }
  }

  //
  clearEmail() {
    this.errorEmail = false;
  }

  //
  clearNombreUsuario() {
    this.errorNombreUsuario = false;
  }

  //
  resetearFormulario() {
    this.file = null;
    this.nameImage = "";
    this.usuarioPanelForm.reset();
    this.imagen = "";
    this.myfilename = "Seleccionar archivo";
    this.usuarioPanelForm.patchValue({
      memberContactNo: "Seleccionar archivo",
    });
    this.divImagen.nativeElement.style.display = "none";
    this.panelOpenState = false;
  }

  //
  validarPassword(control) {
    return null;
  }

  //
  ocultarMostrar() {
    this.panelOpenState = this.panelOpenState == true ? false : true;
  }

  //
  openSnackBar(message: string, action: string) {
    this.snackBar.openFromComponent(NotificationComponent, {
      data: {
        message: message[0].toUpperCase() + message.slice(1),
        action: action,
      },
      duration: 2500,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: [action],
    });
  }
}
