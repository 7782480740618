import { Injectable } from "@angular/core";
import { Observable, fromEvent } from "rxjs";
import { map, startWith, distinctUntilChanged } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ResponsiveService {
  private mobileWidth = 768;

  isMobile$: Observable<boolean>;

  constructor() {
    this.isMobile$ = fromEvent(window, "resize").pipe(
      startWith({}),
      map(() => window.innerWidth < this.mobileWidth),
      distinctUntilChanged()
    );
  }
}
