import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { ObraSocial } from 'app/models/obraSocial.interface';
import { Header } from 'primeng/api';
import { GlobalVariable } from 'app/global/global';
import { Observable,Subject, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObraSocialService {


  //
  url:string = GlobalVariable.BASE_API_URL+"api/obra-social/";
  private obraSocialSubject = new Subject<any>();


  //
  constructor(private http:HttpClient) { }


  //
  listar():Observable<any>{
    return this.http.get(this.url+"listar");
  }


  //
  crear(event:any,file:any):Observable<any>{
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    if(file != null){
      formData.append("image", file); 
    }
    formData.append("nombre",event.nombre.trim());
    return this.http.post(this.url+"crear",formData,{headers:headers});
  }


  //
  borrar(form:ObraSocial):Observable<any>{
    return this.http.delete(this.url+"borrar/"+form.id);
  }


  //
  mostrar(id):Observable<any>{
    return this.http.get(this.url+"mostrar/"+id);
  }


  //
  editar(event:any,file:any):Observable<any>{
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    if(file != null){
      formData.append("image", file);
    }
    formData.append("id",event.id.toString());
    formData.append("nombre",event.nombre.trim());
    return this.http.post(this.url+"editar",formData,{headers:headers});
  }


  //
  getObraSocial = this.obraSocialSubject.asObservable(); 


  //
  emitChangeObraSocial(value:any) {
    this.obraSocialSubject.next(value);
  }

  
}
