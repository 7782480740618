<div class="contentModal">
    <!--
    <div class="tituloModal">
        Elige una foto
    </div>
    -->
    <div class="tituloModal">
        <span>
            Elige una foto
        </span>
        <mat-icon class="buttonClose" [mat-dialog-close]=false>close</mat-icon>
    </div>
    <div class="textModal">
        <div class="divSelectFoto">
            <div #divFotoM class="divFoto">
                <img name="ico_pacienteM" id="ico_pacienteM.png" (click)="elegirFoto($event)" src="assets/img//ico_pacienteM.png" class="foto"/>
            </div>
            <div #divFotoH class="divFoto">
                <img name="ico_paciente" id="ico_paciente.png" (click)="elegirFoto($event)" src="assets/img/ico_paciente.png" class="foto"/> 
            </div>
        </div>
        <div class="formFile">
            <mat-form-field class="formFile"> 
                <div class="myfilebrowser">
                  <mat-toolbar>
                    <input matInput [(ngModel)]="myfilename" readonly name="memberContactNo" />
                    <button mat-flat-button class="fileButton" color="primary">
                      Buscar
                    </button>
                  </mat-toolbar>
                  <input type="file" #UploadFileInput id="fileUpload" (change)="fileChangeEvent($event)" name="fileUpload"
                    accept="image/*" />
                </div>
            </mat-form-field>
        </div>
    </div>
    <div class="accionModal">
        <div class="buttons">
            <button mat-raised-button class="button" (click)="onClickNo()">Aceptar</button>
        </div>
    </div>
    <!--
    <div mat-dialog-actions class="accionModal">
        <button mat-raised-button class="aceptar" (click)="onClickNo()">Aceptar</button>
    </div>
    -->
</div>
