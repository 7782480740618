import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { GlobalVariable } from 'app/global/global';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComentarioEstudioMedicoService {

  //
  url:string = GlobalVariable.BASE_API_URL+"api/comentario-estudio-medico";
  urlImagen:string="";

  //
  constructor(
    private http:HttpClient
  ) { }

  //
  listar(id:string):Observable<any>{
    console.log('id => ', id);
    return this.http.get(this.url+"/listar/"+id);
  }

  //
  crear(tipoUsuario:string , idUsuario:string, idEstudioMedicoPaciente:string, comentario:any):Observable<any>{
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    formData.append("texto", comentario.texto.trim());
    if(tipoUsuario == 'usuario_panel') formData.append("id_usuario_panel", idUsuario);
    else formData.append("id_medico", idUsuario);
    formData.append("id_estudio_medico_paciente", idEstudioMedicoPaciente);
    /*
    console.log("id_usuario", idUsuario);
    console.log("id_estudio_medico_paciente", idEstudioMedicoPaciente);
    console.log("texto", comentario.texto.trim());
    */
    return this.http.post(this.url+"/crear", formData, {headers:headers});
  }

  //
  editar(id:string, texto:string):Observable<any>{
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    formData.append("id", id);
    formData.append("texto", texto);
    return this.http.post(this.url+"/editar", formData, {headers:headers});
  }

}
