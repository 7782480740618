import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SpinnerService } from "app/services/spinner.service";
import { UsuarioPanelService } from "app/services/usuario-panel.service";
import { ActivatedRoute } from "@angular/router";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";

@Component({
  selector: "app-usuario-panel-editar",
  templateUrl: "./usuario-panel-editar.component.html",
  styleUrls: ["./usuario-panel-editar.component.scss"],
})
export class EditarUsuarioPanelComponent implements OnInit, OnDestroy {
  //
  usuarioPanelFormulario: any;
  editar: boolean = true;
  usuarioPanel: any;
  telefonoPaisApi: any;
  response: any;
  notificacionesPush: any[];
  private intervalId: any;

  //
  constructor(
    private usuarioPanelService: UsuarioPanelService,
    private spinnerService: SpinnerService,
    public fotoDialog: MatDialog,
    private actRoute: ActivatedRoute
  ) {
    this.spinnerService.mostrarSpinner();
    let id = this.actRoute.snapshot.paramMap.get("id");
    this.usuarioPanelService
      .profileUser()
      .pipe(
        map((data) => {
          this.usuarioPanel = data;
        }),
        catchError((error) => {
          this.spinnerService.ocultarSpinner();
          this.usuarioPanelService.logout();
          return of({ results: null });
        }),
        switchMap((data) =>
          this.usuarioPanelService.mostrar(id).pipe(
            map((data) => {
              this.response = data;
              this.notificacionesPush = data.notificacionesPush;
              this.spinnerService.ocultarSpinner();
            })
          )
        ),
        catchError((error) => {
          this.spinnerService.ocultarSpinner();
          return of({ results: null });
        })
      )
      .subscribe(
        (data) => {
          console.log("data", data);
        },
        (error) => {
          console.error("Error al llamar a la API:", error);
        }
      );
  }

  //
  ngOnInit(): void {}

  //
  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
