import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
  ElementRef,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { GlobalVariable } from "app/global/global";

@Component({
  selector: "app-informe-top-medicos",
  templateUrl: "./informe-top-medicos.component.html",
  styleUrls: ["./informe-top-medicos.component.scss"],
})
export class InformeTopMedicosComponent implements OnInit, AfterViewInit {
  //
  @Input() titulo: any;
  @Input() descripcion: any;
  displayedColumns: string[] = [
    "nombreCompleto",
    "email",
    "imagen",
    "cantidad",
  ];
  dataSource = new MatTableDataSource<any>();
  url: string = GlobalVariable.BASE_API_URL + "storage/images/";
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() set topMedicos(value: any) {
    this.dataSource = new MatTableDataSource<any>(value);
  }

  //
  constructor(public elementRef: ElementRef) {}

  //
  ngOnInit(): void {}

  //
  ngAfterViewInit() {}

  //
  isValidImage(url: string): boolean {
    if (!url) {
      return false;
    }
    let image = new Image();
    image.src = url;
    let complete = image.complete;
    return complete;
  }

  //
  cargarImagen(event) {
    (event.target as HTMLImageElement).style.display = "flex";
  }
}
