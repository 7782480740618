<div class="contentModal">
    <div class="tituloModal">
        <span>
            Eliminar turno
        </span>
        <mat-icon class="buttonClose" (click)=cerrar()>close</mat-icon>
    </div>
    <div class="textModal">
        ¿Estas seguro de que quieres eliminar este turno? 
    </div>
    <div class="accionModal">
        <div class="buttons">
            <button mat-button class="button buttonForm cancelar" [mat-dialog-close]=false (click)=volverAEditar()>Cancelar</button>
            <button mat-button class="button buttonForm borrar" (click)=borrarTurno()>Borrar</button>
        </div>
    </div>
    <!--
    <div class="accionModal">
        <button mat-button class="button buttonForm cancelar" [mat-dialog-close]=false (click)=volverAEditar()>Cancelar</button>
        <button mat-button class="button buttonForm borrar" (click)=borrarTurno()>Borrar</button>
    </div>
    -->
</div>