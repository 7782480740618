<form
  class="formCard"
  [formGroup]="formularioTurno"
  (ngSubmit)="guardarTurno(formularioTurno.value)"
>
  <div class="tituloModal">
    <span *ngIf="!editar">Crear turno</span>
    <span *ngIf="editar">Editar turno</span>
    <mat-icon class="buttonClose" [mat-dialog-close]="false">close</mat-icon>
  </div>

  <div class="textModal">
    <ng-container *ngIf="turnoDesdeLanding">
      <div class="formGroup">
        <mat-form-field class="example-full-width input" appearance="fill">
          <mat-label>Mail </mat-label>
          <input type="text" formControlName="emailPaciente" matInput />
        </mat-form-field>
        <div class="valid">
          <div
            style="color: rgba(229, 57, 53)"
            *ngIf="
              formularioTurno.get('emailPaciente').errors?.required &&
              formularioTurno.get('emailPaciente').touched
            "
          >
            El mail es requerido
          </div>
          <div
            style="color: rgba(229, 57, 53)"
            *ngIf="
              formularioTurno.get('emailPaciente').errors?.email &&
              formularioTurno.get('emailPaciente').touched
            "
          >
            El formato de mail es inválido
          </div>
        </div>
      </div>
      <div class="divDoble">
        <div class="formGroup">
          <mat-form-field class="example-full-width input" appearance="fill">
            <mat-label>Nombre </mat-label>
            <input type="text" formControlName="nombrePaciente" matInput />
          </mat-form-field>
          <div class="valid">
            <div
              style="color: rgba(229, 57, 53)"
              *ngIf="
                formularioTurno.get('nombrePaciente').errors?.required &&
                formularioTurno.get('nombrePaciente').touched
              "
            >
              El nombre es requerido
            </div>
          </div>
        </div>
        <div class="formGroup">
          <mat-form-field class="example-full-width input" appearance="fill">
            <mat-label>Apellido </mat-label>
            <input type="text" formControlName="apellidoPaciente" matInput />
          </mat-form-field>
          <div class="valid">
            <div
              style="color: rgba(229, 57, 53)"
              *ngIf="
                formularioTurno.get('apellidoPaciente').errors?.required &&
                formularioTurno.get('apellidoPaciente').touched
              "
            >
              El apellido es requerido
            </div>
          </div>
        </div>
      </div>
      <div class="divDoble">
        <div class="formGroup">
          <mat-form-field appearance="fill" class="input input1">
            <mat-label>Tipo documento</mat-label>
            <mat-select formControlName="tipoDniPaciente">
              <mat-option
                *ngFor="let tipoDoc of tiposDocumentos"
                [value]="tipoDoc?.id"
                >{{ tipoDoc.texto }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <div class="valid">
            <span
              style="color: rgb(236, 75, 72)"
              *ngIf="
                formularioTurno.get('tipoDniPaciente').errors?.required &&
                formularioTurno.get('tipoDniPaciente').touched
              "
            >
              El tipo de documento es requerido
            </span>
          </div>
        </div>

        <div class="formGroup">
          <mat-form-field class="example-full-width input" appearance="fill">
            <mat-label>DNI </mat-label>
            <input type="text" formControlName="dniPaciente" matInput />
          </mat-form-field>
          <div class="valid">
            <div
              style="color: rgba(229, 57, 53)"
              *ngIf="
                formularioTurno.get('dniPaciente').errors?.required &&
                formularioTurno.get('dniPaciente').touched
              "
            >
              El DNI es requerido
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="formGroup" *ngIf="!turnoDesdeLanding">
      <mat-form-field class="example-full-width input" appearance="fill">
        <mat-label>Título </mat-label>
        <input type="text" formControlName="titulo" matInput />
      </mat-form-field>
      <div class="valid">
        <div
          style="color: rgba(229, 57, 53)"
          *ngIf="
            formularioTurno.get('titulo').errors?.required &&
            formularioTurno.get('titulo').touched
          "
        >
          El título es requerido
        </div>
      </div>
    </div>
    <div class="formGroup" *ngIf="!turnoDesdeLanding">
      <mat-form-field class="example-full-width input" appearance="fill">
        <mat-label>Detalle</mat-label>
        <textarea
          formControlName="detalle"
          class="textarea"
          type="text"
          matInput
        ></textarea>
      </mat-form-field>
      <div class="valid">
        <span
          style="color: rgba(229, 57, 53)"
          *ngIf="
            formularioTurno.get('detalle').errors?.required &&
            formularioTurno.get('detalle').touched
          "
        >
          El detalle es requerido
        </span>
      </div>
    </div>
    <div class="formGroup" *ngIf="!turnoDesdeLanding">
      <mat-form-field
        appearance="fill"
        class="example-full-width input"
        [ngClass]="tipoUsuario == 'paciente' ? 'readonlyBlock' : ''"
      >
        <mat-label>Paciente</mat-label>
        <mat-select
          formControlName="id_paciente"
          [placeholder]="'Paciente'"
          (ngModelChange)="validarPacienteEmail($event)"
        >
          <mat-option>
            <ngx-mat-select-search
              id="pacienteBuscador"
              [noEntriesFoundLabel]="'Not found'"
              [placeholderLabel]="'Buscador'"
              formControlName="pacienteBuscador"
              name="filterPost"
              (ngModelChange)="filtrarPaciente($event)"
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let element of filtroPacientes"
            [value]="element.id"
          >
            <div class="optionCM">
              <div class="nombreSelect">{{ element.nombreApellido }}</div>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="valid">
        <span
          class="advertenciaMensaje"
          *ngIf="pacienteSeleccionado && !pacienteSeleccionado?.email"
        >
          <mat-icon class="advertenciaIcono">report_problem</mat-icon>El
          paciente selecionado no tiene email, no le llegaran notificaciones que
          pueden ser importantes
        </span>
        <span
          style="color: rgba(229, 57, 53)"
          *ngIf="
            formularioTurno.get('id_paciente').errors?.required &&
            formularioTurno.get('id_paciente').touched
          "
        >
          El paciente es requerido
        </span>
      </div>
    </div>
    <div class="formGroup">
      <mat-form-field
        appearance="fill"
        class="example-full-width input"
        [ngClass]="tipoUsuario == 'medico' ? 'readonlyBlock' : ''"
      >
        <mat-label>Especialidad</mat-label>
        <mat-select
          formControlName="especialidad"
          [placeholder]="'Especialidad'"
          (ngModelChange)="filtrarEspecialidadMedico($event)"
        >
          <mat-option>
            <ngx-mat-select-search
              id="especialidadBuscador"
              [noEntriesFoundLabel]="'Not found'"
              [placeholderLabel]="'Especialidad'"
              formControlName="especialidadBuscador"
              name="filterPost"
              (ngModelChange)="filtrarEspecialidad($event)"
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-option [value]="0">
            <div class="optionCM">
              <div class="nombreSelect">Todas las especialidades</div>
            </div>
          </mat-option>
          <mat-option
            *ngFor="let element of filtroEspecialidades"
            [value]="element.id"
          >
            <div class="optionCM">
              <div class="nombreSelect">{{ element.texto }}</div>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="formGroup">
      <mat-form-field
        appearance="fill"
        class="example-full-width input"
        [ngClass]="tipoUsuario == 'medico' ? 'readonlyBlock' : ''"
      >
        <mat-label>Médico</mat-label>
        <mat-select
          formControlName="id_medico"
          [placeholder]="'Médico'"
          (ngModelChange)="validarMedicoEmail($event)"
        >
          <mat-option>
            <ngx-mat-select-search
              id="medicoBuscador"
              [noEntriesFoundLabel]="'Not found'"
              [placeholderLabel]="'Buscador'"
              formControlName="medicoBuscador"
              name="filterPost"
              (ngModelChange)="filtrarMedico($event)"
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let element of filtroMedicos"
            [value]="element.id"
          >
            <div class="optionCM">
              <div class="nombreSelect">{{ element.nombreCompleto }}</div>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="valid">
        <span
          class="advertenciaMensaje"
          *ngIf="medicoSelecionado && !medicoSelecionado?.email"
        >
          <mat-icon class="advertenciaIcono">report_problem</mat-icon>El médico
          selecionado no tiene email, no le llegaran notificaciones que pueden
          ser importantes
        </span>
        <span
          style="color: rgba(229, 57, 53)"
          *ngIf="
            formularioTurno.get('id_medico').errors?.required &&
            formularioTurno.get('id_medico').touched
          "
        >
          El médico es requerido
        </span>
      </div>
      <div class="horarioMedico" *ngIf="medicoSelecionado">
        <span class="horario">Horario de médico</span>
        <div
          *ngIf="medicoSelecionado.lunes_desde && medicoSelecionado.lunes_hasta"
        >
          Lunes {{ medicoSelecionado.lunes_desde }} -
          {{ medicoSelecionado.lunes_hasta }}
        </div>
        <div
          *ngIf="
            medicoSelecionado.martes_desde && medicoSelecionado.martes_hasta
          "
        >
          Martes {{ medicoSelecionado.martes_desde }} -
          {{ medicoSelecionado.martes_hasta }}
        </div>
        <div
          *ngIf="
            medicoSelecionado.miercoles_desde &&
            medicoSelecionado.miercoles_hasta
          "
        >
          Miercoles {{ medicoSelecionado.miercoles_desde }} -
          {{ medicoSelecionado.miercoles_hasta }}
        </div>
        <div
          *ngIf="
            medicoSelecionado.jueves_desde && medicoSelecionado.jueves_hasta
          "
        >
          Jueves {{ medicoSelecionado.jueves_desde }} -
          {{ medicoSelecionado.jueves_hasta }}
        </div>
        <div
          *ngIf="
            medicoSelecionado.viernes_desde && medicoSelecionado.viernes_hasta
          "
        >
          Viernes {{ medicoSelecionado.viernes_desde }} -
          {{ medicoSelecionado.viernes_hasta }}
        </div>
        <div
          *ngIf="
            medicoSelecionado.sabado_desde && medicoSelecionado.sabado_hasta
          "
        >
          Sabado {{ medicoSelecionado.sabado_desde }} -
          {{ medicoSelecionado.sabado_hasta }}
        </div>
        <div
          *ngIf="
            medicoSelecionado.domingo_desde && medicoSelecionado.domingo_hasta
          "
        >
          Domingo {{ medicoSelecionado.domingo_desde }} -
          {{ medicoSelecionado.domingo_hasta }}
        </div>
      </div>
    </div>
    <div class="formGroup" *ngIf="!turnoDesdeLanding">
      <mat-form-field appearance="fill" class="example-full-width input">
        <mat-label>Centro médico</mat-label>
        <mat-select
          formControlName="centroMedico"
          [placeholder]="'Centro médico'"
        >
          <mat-option>
            <ngx-mat-select-search
              id="centroMedicoBuscador"
              [noEntriesFoundLabel]="'Not found'"
              [placeholderLabel]="'Buscador'"
              formControlName="centroMedicoBuscador"
              name="filterPost"
              (ngModelChange)="filtrarCentroMedico($event)"
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-option
            *ngFor="let element of filtroCentroMedicos"
            [value]="element.id"
          >
            <div class="optionCM">
              <div class="nombreSelect">{{ element.nombre }}</div>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="valid">
        <span
          style="color: rgba(229, 57, 53)"
          *ngIf="
            formularioTurno.get('centroMedico').errors?.required &&
            formularioTurno.get('centroMedico').touched
          "
        >
          El centro médico es requerido
        </span>
      </div>
    </div>

    <div class="divDoble">
      <div class="formGroup">
        <mat-form-field appearance="fill" class="input">
          <mat-label>Fecha desde</mat-label>
          <input
            formControlName="fechaDesde"
            matInput
            [matDatepickerFilter]="boundedFiltrarFechasDatePicker"
            [matDatepicker]="fechaDesde"
            (ngModelChange)="cambiarTurno($event, 'dechaDesde')"
          />
          <mat-datepicker-toggle matSuffix [for]="fechaDesde">
          </mat-datepicker-toggle>
          <mat-datepicker
            #fechaDesde
            [disabled]="!medicoSelecionado"
          ></mat-datepicker>
        </mat-form-field>
        <div class="valid">
          <span
            style="color: rgba(229, 57, 53)"
            *ngIf="
              formularioTurno.get('fechaDesde').errors?.required &&
              formularioTurno.get('fechaDesde').touched
            "
          >
            El fecha desde es requerida
          </span>
        </div>
      </div>
      <div class="formGroup" *ngIf="response.turnoDesdeLanding">
        <mat-form-field appearance="fill" class="input">
          <mat-label>Horarios</mat-label>
          <mat-select
            [disabled]="!medicoSelecionado || !formularioTurno.value.fechaDesde"
            (selectionChange)="seleccionarHorario($event)"
          >
            <ng-container *ngIf="medicoSelecionado">
              <mat-option
                *ngFor="let turno of listaDeTurnosSinLosYaOtorgados()"
                [value]="turno"
              >
                {{ turno }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="formGroup" *ngIf="!response.turnoDesdeLanding">
        <mat-form-field appearance="fill" class="input">
          <mat-label>Tiempo desde</mat-label>
          <input
            matInput
            [format]="24"
            [ngxTimepicker]="pickerTiempoDesde"
            formControlName="tiempoDesde"
            (ngModelChange)="cambiarTurno($event, 'tiempoDesde')"
            required
            readonly
          />
          <ngx-material-timepicker
            [theme]="darkTheme"
            #pickerTiempoDesde
          ></ngx-material-timepicker>
        </mat-form-field>
        <div class="valid">
          <span
            style="color: rgba(229, 57, 53)"
            *ngIf="
              formularioTurno.get('tiempoDesde').errors?.required &&
              formularioTurno.get('tiempoDesde').touched
            "
          >
            El tiempo desde es requerido
          </span>
        </div>
      </div>
    </div>
    <div class="divDoble" *ngIf="!turnoDesdeLanding">
      <div class="formGroup">
        <mat-form-field appearance="fill" class="input">
          <mat-label>Fecha hasta</mat-label>
          <input
            formControlName="fechaHasta"
            matInput
            [matDatepicker]="pickerHasta"
            (ngModelChange)="cambiarTurno($event, 'fechaHasta')"
          />
          <mat-datepicker-toggle matSuffix [for]="pickerHasta">
          </mat-datepicker-toggle>
          <mat-datepicker #pickerHasta></mat-datepicker>
        </mat-form-field>
        <div class="valid">
          <span
            style="color: rgba(229, 57, 53)"
            *ngIf="
              formularioTurno.get('fechaHasta').errors?.required &&
              formularioTurno.get('fechaHasta').touched
            "
          >
            El fecha hasta es requerida
          </span>
        </div>
      </div>
      <div class="formGroup">
        <mat-form-field appearance="fill" class="input">
          <mat-label>Tiempo hasta</mat-label>
          <input
            matInput
            [format]="24"
            [ngxTimepicker]="pickerTiempoHasta"
            formControlName="tiempoHasta"
            (ngModelChange)="cambiarTurno($event, 'tiempoHasta')"
            required
            readonly
          />
          <ngx-material-timepicker
            [theme]="darkTheme"
            #pickerTiempoHasta
          ></ngx-material-timepicker>
        </mat-form-field>
        <div class="valid">
          <span
            style="color: rgba(229, 57, 53)"
            *ngIf="
              formularioTurno.get('tiempoHasta').errors?.required &&
              formularioTurno.get('tiempoHasta').touched
            "
          >
            El tiempo hasta es requerido
          </span>
        </div>
      </div>
    </div>
    <div class="validError">
      <span style="color: rgba(229, 57, 53)" *ngIf="fechaError">
        {{ fechaError }}
      </span>
    </div>
  </div>
  <div class="accionModal">
    <div class="buttons">
      <button
        *ngIf="!editar"
        mat-raised-button
        class="cancelar"
        [mat-dialog-close]="true"
        type="button"
      >
        Cancelar
      </button>

      <button
        *ngIf="!editar"
        mat-raised-button
        class="button"
        [disabled]="formularioTurno.invalid"
      >
        Crear
      </button>
      <button
        *ngIf="editar"
        [disabled]="turno.confirmado == 1 && tipoUsuario == 'paciente'"
        mat-raised-button
        type="submit"
      >
        Editar
      </button>
      <button
        *ngIf="editar"
        [disabled]="turno.confirmado == 1 && tipoUsuario == 'paciente'"
        mat-raised-button
        class="borrar"
        type="button"
        (click)="borrarTurno()"
      >
        Borrar
      </button>
    </div>
  </div>
</form>
