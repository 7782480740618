<ng-container *ngIf="!loading">
  <div class="filters-container" [formGroup]="filters">
    <div class="filters">
      <p>Filtros:</p>

      <mat-form-field appearance="fill">
        <mat-label>Seleccionar una surcursal</mat-label>
        <mat-select formControlName="sucursal">
          <mat-option
          value=""
          >Todas</mat-option>
          <mat-option *ngFor="let option of sucursales" [value]="option.id">
            {{ option.nombre }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Selecciona un año</mat-label>
        <mat-select formControlName="selectedYear">
          <mat-option *ngFor="let year of lastTenYears" [value]="year">
            {{ year }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <button mat-raised-button (click)="exportarTodosLosPDF()" class="btn">
      <span *ngIf="!downloadingPdf">Descargar PDF</span>
      <span *ngIf="downloadingPdf">
        <mat-spinner diameter="20"  ></mat-spinner> 
      </span>
    </button>
  </div>

  <div class="main">
    <app-informe-creaciones-por-anio
      [titulo]="tituloPacientesPorAnio"
      [descripcion]="descPacientesPorAnio"
      [informesPorAnio]="pacientesPorAnio"
    ></app-informe-creaciones-por-anio>
    <app-informe-creaciones-por-anio
      [titulo]="tituloEstudiosMedicosPacientesPorAnio"
      [descripcion]="descEstudiosMedicosPacientesPorAnio"
      [informesPorAnio]="estudiosMedicosPacientesPorAnio"
    ></app-informe-creaciones-por-anio>
    <app-informe-creaciones-por-anio
      [titulo]="tituloTurnosPorAnio"
      [descripcion]="descPacientePorAnio"
      [informesPorAnio]="turnosPorAnio"
    ></app-informe-creaciones-por-anio>
    <app-informe-grafico-torta
      [titulo]="tituloEstudiosMedicosTorta"
      [descripcion]="descEstudiosMedicosTorta"
      [informeGrafico]="estudiosMedicosPorcentaje"
    ></app-informe-grafico-torta>
    <app-informe-grafico-torta
      [titulo]="tituloTurnosEspecialidadTorta"
      [descripcion]="descTurnosEspecialidadTorta"
      [informeGrafico]="turnosPorcentaje"
    ></app-informe-grafico-torta>
    <app-informe-grafico-torta
      [titulo]="tituloPacientePorObraSocial"
      [descripcion]="descPacientePorObraSocial"
      [informeGrafico]="pacientesPorObraSocial"
    ></app-informe-grafico-torta>
  </div>
  <app-informe-top-medicos
    [titulo]="tituloTopMedicos"
    [descripcion]="descTopMedicos"
    [topMedicos]="topMedicos"
  ></app-informe-top-medicos>
</ng-container>
