<form [formGroup]="contactoForm" (submit)="enviarEmail(contactoForm.value)" class="formCard">
    <div class="card">
        <div class="tituloModal">
            <span>
                {{titulo_contacto}}
            </span>
            <mat-icon class="buttonClose" [mat-dialog-close]=false>close</mat-icon>
        </div>
        <div class="textModal">
            <div class="formGroup">
                <mat-form-field class="example-full-width input textarea" appearance="fill">
                    <mat-label>Texto</mat-label>
                    <textarea formControlName="texto" class="textarea" type="text" matInput></textarea>
                </mat-form-field>
            </div>
            <div class="formGroup">
                <!--<file-upload class="buttonUpload" formControlName="filesUpload" name="filesUpload"></file-upload>-->
                <file-upload class="buttonUpload" formControlName="filesUpload" name="filesUpload">
                    <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
                        <file-upload-drop-zone>
                            <ng-container *ngIf="isFileDragDropAvailable; else isNotDragDropAvailable">
                                <b>Arrastrar y soltar archivos</b> o haga clic aquí
                            </ng-container>
                            <ng-template #isNotDragDropAvailable>
                                <b>Haga clic aquí para</b> seleccionar un archivo
                            </ng-template>
                        </file-upload-drop-zone>
                    </ng-template>
            
                    <ng-template let-i="index" let-file="file" let-control="control" #item>
                        <div class="file">
                            <file-upload-list-item  [index]="i" [file]="file" [control]="control">Eliminar</file-upload-list-item>
                        </div>
                    </ng-template>
                </file-upload>
            </div>
            <div class="textAclaration">Maximo 5 archivos y 10MB en total</div>
        </div>
        <div class="accionModal">
            <div class="buttons">
                <button mat-raised-button class="button cancel" type="button" (click)="onClickNo()">Cancelar</button>
                <button mat-raised-button class="button enviar" type="submit">Enviar</button>
            </div>
        </div>
    </div>
</form>