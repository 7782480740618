<div id="scroll-up" class="scroll-up">
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0h24v24H0z" fill="none"></path>
        <path
        class="arrow-path"
          fill="none"
          d="M13.1716 12L8.22183 7.04999L9.63604 5.63577L16 12L9.63604 18.3642L8.22183 16.95L13.1716 12Z"
        ></path>
      </svg>
</div>
