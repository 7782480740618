import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { EditarObraSocialComponent } from "./obra-social-editar/obra-social-editar.component";
import { ObraSocialIndexComponent } from "./obra-social-index/obra-social-index.component";

const routes: Routes = [
  {
    path: "obra-social",
    pathMatch: "full",
    component: ObraSocialIndexComponent,
  },
  {
    path: "obra-social/editar/:id",
    pathMatch: "full",
    component: EditarObraSocialComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ObraSocialRoutingModule {}
