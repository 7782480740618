import { Component, OnInit, Input, QueryList, ViewChildren, ElementRef, ViewChild } from '@angular/core';
import { SpinnerService } from 'app/services/spinner.service';
import { NotificacionesPushService } from 'app/services/notificaciones-push.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';
import { element } from 'protractor';

@Component({
  selector: 'app-lista-notificaciones',
  templateUrl: './lista-notificaciones.component.html',
  styleUrls: ['./lista-notificaciones.component.scss']
})
export class ListaNotificacionesComponent implements OnInit {


  dataSource:any;
  notificacionesPush:any[];
  notificacionesNoLeidasPush:any;
  @ViewChild('buscador') buscador: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns:string[] = ['texto','acciones'];
  data:any;
  searchKey:string;
  @Input() set responseNotificacionesPush(value: any) {
    if (value) {
      console.log('value => ', value);
      this.notificacionesPush = value;
      this.listar(this.notificacionesPush);
    }
  }

  constructor(
    private spinnerService:SpinnerService,
    private notificacionesPushService:NotificacionesPushService,
  ) { }

  ngOnInit(): void {
  }

  listar = (data:any[]) => {
    this.data = data; 
    this.dataSource = new MatTableDataSource<any>(data);
    this.dataSource.paginator = this.paginator;
  }


  filter = (event:any) => {
    let dataFilter:any[] = [];
    let stringValue:string = this.buscador.nativeElement.value
    this.data.forEach(obj=>{
      if(obj.texto.toUpperCase().includes(stringValue.toUpperCase())){
        dataFilter.push(obj);
      } 
    })
    this.dataSource = new MatTableDataSource<any>(dataFilter);
  }


  leerNotificacion(object:any){
    this.spinnerService.mostrarSpinner();
    this.notificacionesPushService.leerNotificacion(object.id).subscribe(
      data => {
        this.data = this.data.map(dat => {
          if (dat.id === object.id) {
            return { ...dat, leido: true };
          }
          return dat;
        });
        this.listar(this.data);
        this.notificacionesNoLeidasPush = this.data.filter(dat => dat.leido == false);
        this.spinnerService.ocultarSpinner();
      },
      error => {
        console.log('error => ',error);
        this.spinnerService.ocultarSpinner();
      }
    )
  }

}
