<div class="headerPanel mat-elevation-z4">
  <button
    class="buttonIcon"
    mat-mini-fab
    aria-label="icon"
    (click)="botonDesplegableShadow()"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <p class="nombrePaciente" *ngIf="nombreApellido">
    Paciente: {{ nombreApellido }}
  </p>

  <div class="end">
    <img src="assets/img/ico_campana.png" class="campana" hidden />
    <img [src]="imagen" class="icon" [matMenuTriggerFor]="appMenu" />

    <mat-menu #appMenu="matMenu" class="dropdown">
      <mat-nav-list>
        <a
          mat-list-item
          class="buttonDash"
          [routerLink]="['/seccion-paciente/editar-perfil']"
          >Editar Perfil</a
        >
        <a mat-list-item class="buttonDash" (click)="logout()">Salir</a>
      </mat-nav-list>
    </mat-menu>
  </div>
</div>
