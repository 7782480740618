import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'app/services/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { EstudioMedicoService } from 'app/services/estudio-medico.service';
import { NotificacionesPushService } from 'app/services/notificaciones-push.service';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';


@Component({
  selector: 'app-estudio-medico-editar',
  templateUrl: './estudio-medico-editar.component.html',
  styleUrls: ['./estudio-medico-editar.component.scss']
})
export class EstudioMedicoEditarComponent implements OnInit {


  //
  usuarioPanel: any;
  estudioMedico: any;
  editar: boolean = true;
  notificacionesPush: any[];


  //
  constructor(
    private actRoute: ActivatedRoute,
    private router: Router,
    private spinnerService: SpinnerService,
    private usuarioPanelService: UsuarioPanelService,
    private estudioMedicoService: EstudioMedicoService,
    private notificacionesPushService: NotificacionesPushService,
  ) {
    this.spinnerService.mostrarSpinner();
    let id = this.actRoute.snapshot.paramMap.get("id");
    this.usuarioPanelService.profileUser().pipe(
      map(data => {
        console.log("data1: ", data);
        this.usuarioPanel = data;
      }),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        this.usuarioPanelService.logout();
        return error;
      }),
      switchMap(data => this.estudioMedicoService.mostrar(id).pipe(
        map(data => {
          console.log("data", data);
          this.estudioMedico = data.estudioMedico;
          this.notificacionesPush = data.notificacionesPush;
          this.spinnerService.ocultarSpinner();
        }),
      )),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        return error;
      }),
    )
      .subscribe(
        (data) => console.log('data', data),
        (error) => console.error('Error al llamar a la API:', error)
      );
  }


  //
  ngOnInit(): void {
    this.notificacionesPushService.listen('notificacion-push', 'NotificacionPushCreated', (data: any) => {
      this.notificacionesPush = [...this.notificacionesPush, ...data.notificacionesPush];
    });
  }


}
