import {
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  FormArray,
  FormGroup,
} from "@angular/forms";
import moment from "moment";

interface AgendaFormGroup {
  lunesDesde: string;
  lunesHasta: string;
  martesDesde: string;
  martesHasta: string;
  miercolesDesde: string;
  miercolesHasta: string;
  juevesDesde: string;
  juevesHasta: string;
  viernesDesde: string;
  viernesHasta: string;
  sabadoDesde: string;
  sabadoHasta: string;
  domingoDesde: string;
  domingoHasta: string;
}

// export function agendaOverlapValidator(): ValidatorFn {
//   return (control: AbstractControl): ValidationErrors | null => {
//     const formArray = control as FormArray;
//     const agendas: AgendaFormGroup[] = formArray.controls.map(
//       (ctrl) => ctrl.value
//     );

//     for (let i = 0; i < agendas.length; i++) {
//       for (let j = i + 1; j < agendas.length; j++) {
//         if (hasOverlap(agendas[i], agendas[j])) {
//           return { agendaOverlap: true };
//         }
//       }
//     }

//     return null;
//   };
// }

// export function agendaOverlapValidator(): ValidatorFn {
//   return (control: AbstractControl): ValidationErrors | null => {
//     const formArray = control as FormArray;
//     const agendas: AgendaFormGroup[] = formArray.controls.map(
//       (ctrl) => ctrl.value
//     );

//     const overlaps: { index1: number; index2: number; agenda1: AgendaFormGroup; agenda2: AgendaFormGroup }[] = [];

//     for (let i = 0; i < agendas.length; i++) {
//       for (let j = i + 1; j < agendas.length; j++) {
//         if (hasOverlap(agendas[i], agendas[j])) {
//           overlaps.push({
//             index1: i,
//             index2: j,
//             agenda1: agendas[i],
//             agenda2: agendas[j]
//           });
//         }
//       }
//     }

//     if (overlaps.length > 0) {
//       return { agendaOverlap: overlaps };
//     }

//     return null;
//   };
// }


// function hasOverlap(
//   agenda1: AgendaFormGroup,
//   agenda2: AgendaFormGroup
// ): boolean {
//   const days = [
//     "lunes",
//     "martes",
//     "miercoles",
//     "jueves",
//     "viernes",
//     "sabado",
//     "domingo",
//   ] as const;

//   for (const day of days) {
//     const desde1 = agenda1[`${day}Desde`];
//     const hasta1 = agenda1[`${day}Hasta`];
//     const desde2 = agenda2[`${day}Desde`];
//     const hasta2 = agenda2[`${day}Hasta`];

//     if (
//       desde1 &&
//       hasta1 &&
//       desde2 &&
//       hasta2 &&
//       isOverlapping(desde1, hasta1, desde2, hasta2)
//     ) {
//       return true;
//     }
//   }

//   return false;
// }

// function isOverlapping(
//   desde1: string,
//   hasta1: string,
//   desde2: string,
//   hasta2: string
// ): boolean {
//   const [hora1, minutos1] = desde1.split(":").map(Number);
//   const [hora2, minutos2] = hasta1.split(":").map(Number);
//   const [hora3, minutos3] = desde2.split(":").map(Number);
//   const [hora4, minutos4] = hasta2.split(":").map(Number);

//   const tiempo1 = hora1 * 60 + minutos1;
//   const tiempo2 = hora2 * 60 + minutos2;
//   const tiempo3 = hora3 * 60 + minutos3;
//   const tiempo4 = hora4 * 60 + minutos4;

//   return tiempo1 < tiempo4 && tiempo3 < tiempo2;
// }

export function agendaOverlapValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formArray = control as FormArray;
    const agendas: AgendaFormGroup[] = formArray.controls.map(
      (ctrl) => ctrl.value
    );

    const overlaps: {
      index1: number;
      index2: number;
      agenda1: AgendaFormGroup;
      agenda2: AgendaFormGroup;
      overlappingDays: string[];
    }[] = [];

    for (let i = 0; i < agendas.length; i++) {
      for (let j = i + 1; j < agendas.length; j++) {
        const overlappingDays = getOverlappingDays(agendas[i], agendas[j]);
        if (overlappingDays.length > 0) {
          overlaps.push({
            index1: i,
            index2: j,
            agenda1: agendas[i],
            agenda2: agendas[j],
            overlappingDays
          });
        }
      }
    }

    if (overlaps.length > 0) {
      return { agendaOverlap: overlaps };
    }

    return null;
  };
}

function getOverlappingDays(
  agenda1: AgendaFormGroup,
  agenda2: AgendaFormGroup
): string[] {
  const days = [
    "lunes",
    "martes",
    "miercoles",
    "jueves",
    "viernes",
    "sabado",
    "domingo",
  ] as const;

  const overlappingDays: string[] = [];

  for (const day of days) {
    const desde1 = agenda1[`${day}Desde`];
    const hasta1 = agenda1[`${day}Hasta`];
    const desde2 = agenda2[`${day}Desde`];
    const hasta2 = agenda2[`${day}Hasta`];

    if (
      desde1 &&
      hasta1 &&
      desde2 &&
      hasta2 &&
      isOverlapping(desde1, hasta1, desde2, hasta2)
    ) {
      overlappingDays.push(day);
    }
  }

  return overlappingDays;
}

function isOverlapping(
  desde1: string,
  hasta1: string,
  desde2: string,
  hasta2: string
): boolean {
  const [hora1, minutos1] = desde1.split(":").map(Number);
  const [hora2, minutos2] = hasta1.split(":").map(Number);
  const [hora3, minutos3] = desde2.split(":").map(Number);
  const [hora4, minutos4] = hasta2.split(":").map(Number);

  const tiempo1 = hora1 * 60 + minutos1;
  const tiempo2 = hora2 * 60 + minutos2;
  const tiempo3 = hora3 * 60 + minutos3;
  const tiempo4 = hora4 * 60 + minutos4;

  return tiempo1 < tiempo4 && tiempo3 < tiempo2;
}


export function passwordMatchValidator(g: FormGroup) {
    return g.get("password").value === g.get("repeatPassword").value
      ? null
      : { mismatch: true };
  }

  export function validateDaySchedule(day: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const formGroup = control as FormGroup;
      const desdeControl = formGroup.get(`${day}Desde`);
      const hastaControl = formGroup.get(`${day}Hasta`);

      if (desdeControl?.value && !hastaControl?.value) {
        return { [`${day}Error1`]: true };
      } else if (!desdeControl?.value && hastaControl?.value) {
        return { [`${day}Error2`]: true };
      } else if (desdeControl?.value && hastaControl?.value) {
        const desde = moment(desdeControl.value, "HH:mm");
        const hasta = moment(hastaControl.value, "HH:mm");
        if (!desde.isBefore(hasta)) {
          return { [`${day}Error3`]: true };
        }
      }
      return null;
    };

}


export function validarTodasLasFechas(g: FormGroup) {
    let agenda = g.get("agenda")?.value;
    let lunesDesde = g.get("lunesDesde")?.value;
    let lunesHasta = g.get("lunesHasta")?.value;
    let martesDesde = g.get("martesDesde")?.value;
    let martesHasta = g.get("martesHasta")?.value;
    let miercolesDesde = g.get("miercolesDesde")?.value;
    let miercolesHasta = g.get("miercolesHasta")?.value;
    let juevesDesde = g.get("juevesDesde")?.value;
    let juevesHasta = g.get("juevesHasta")?.value;
    let viernesDesde = g.get("viernesDesde")?.value;
    let viernesHasta = g.get("viernesHasta")?.value;
    let sabadoDesde = g.get("sabadoDesde")?.value;
    let sabadoHasta = g.get("sabadoHasta")?.value;
    let domingoDesde = g.get("domingoDesde")?.value;
    let domingoHasta = g.get("domingoHasta")?.value;
    if (
      agenda == true &&
      !lunesDesde &&
      !lunesHasta &&
      !martesDesde &&
      !martesHasta &&
      !miercolesDesde &&
      !miercolesHasta &&
      !juevesDesde &&
      !juevesHasta &&
      !viernesDesde &&
      !viernesHasta &&
      !sabadoDesde &&
      !sabadoHasta &&
      !domingoDesde &&
      !domingoHasta
    ) {
      return { fechasError: true };
    }
    return null;
  }