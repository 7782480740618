import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-editar-centro-medico',
  templateUrl: './editar-centro-medico.component.html',
  styleUrls: ['./editar-centro-medico.component.scss']
})
export class EditarCentroMedicoComponent implements OnInit {


  //
  editar:boolean = true;
  

  //
  constructor() { }


  //
  ngOnInit(): void {}

}
