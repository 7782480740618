import { UsuarioPanelService } from "app/services/usuario-panel.service";
import { MedicoService } from "app/services/medico.service";
import { PacienteService } from "app/services/paciente.service";
import { EstudioMedicoPacienteService } from "app/services/estudio-medico-paciente.service";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Plan } from "app/models/plan.interface";
import { GlobalVariable } from "app/global/global";
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";

@Injectable({
  providedIn: "root",
})
export class PlanService {
  url: string = GlobalVariable.BASE_API_URL + "api/plan/";
  response: any;
  plan: Plan;
  objeto: any;
  cantidadUsuarios: number;
  cantidadPacientes: number;
  cantidadEstudiosMedicos: number;
  cantidadMedicos: number;
  llegoLimiteUsuarios: boolean = false;
  llegoLimitePaciente: boolean = false;
  llegoLimiteEstudios: boolean = false;

  constructor(
    private http: HttpClient,
    private apiUsuario: UsuarioPanelService,
    private apiPaciente: PacienteService
  ) {}

  //
  afterAccionPacientes() {
    this.apiPaciente.cantidad().subscribe((val) => {
      this.cantidadPacientes = parseInt(JSON.stringify(val));
    });
  }

  //
  afterAccionUsuarios() {
    this.apiUsuario.cantidad().subscribe((val) => {
      this.cantidadUsuarios = parseInt(JSON.stringify(val));
      //return this.llegoAlLimiteUsuarios()
    });
  }

  //
  mostrarPlanCantidad(): Observable<any> {
    return this.http.get(this.url + "mostrar-cantidad");
  }

  mostrarDatosHomeAdminPanel(): Observable<any> {
    return this.http.get(this.url + "mostrar-datos-home-admin-panel");
  }

  //
  mostrarLandingPage() {
    return this.http.get(this.url + "landing-page");
  }

  //
  llegoAlLimiteDePacientes(planCantidadPaciente, cantidadPacientes) {
    if (planCantidadPaciente <= cantidadPacientes) {
      return true;
    }
    return false;
  }

  //
  getEspacioOcupado(espacioActual, espacioMaximo) {
    return Math.round((espacioActual * 100) / espacioMaximo);
  }

  //
  llegoAlLimiteDeUsuariosPanel(planCantidadUsuario, cantidadUsuario) {
    if (planCantidadUsuario <= cantidadUsuario) {
      return true;
    }
    return false;
  }
}
