import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { EstudioMedicoPaciente } from "app/models/estudioMedicoPaciente.interface";
import { GlobalVariable } from "app/global/global";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { SpinnerService } from "./spinner.service";
import { CornerstoneService } from "./cornerstone.service";
import * as cornerstone from "cornerstone-core";
import { DatePipe } from "@angular/common";

export interface requestObjectEstudioMedicoPaciente {
  idPaciente?: string;
  idMedico?: string;
  incluirDatosAdicionales?: boolean;
  incluirCentrosMedicos?: boolean;
  incluirEstudiosMedicos?: boolean;
  incluirMedicos?: boolean;
  incluirPlantillasEstudiosMedicos?: boolean;
  incluirPacientes?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class EstudioMedicoPacienteService {
  url: string = GlobalVariable.BASE_API_URL + "api/estudio-medico-paciente/";

  constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private cornerstoneService: CornerstoneService,
    private datePipe: DatePipe
  ) {}

  listar(reqParams: requestObjectEstudioMedicoPaciente): Observable<any> {
    let params = new HttpParams().set("incluirDatosAdicionales", "1");

    const listarUrl = GlobalVariable.BASE_API_URL + "api/estudio-medico-paciente";

    const paramMappings = {
      idPaciente: "idPaciente",
      idMedico: "idMedico",
      incluirDatosAdicionales: "incluirDatosAdicionales",
      incluirCentrosMedicos: "incluirCentrosMedicos",
      incluirEstudiosMedicos: "incluirEstudiosMedicos",
      incluirMedicos: "incluirMedicos",
      incluirPacientes: "incluirPacientes",
      incluirPlantillasEstudiosMedicos: "incluirPlantillasEstudiosMedicos",
    };

    Object.keys(paramMappings).forEach((key) => {
      if (reqParams[key]) {
        params = params.append(paramMappings[key], reqParams[key]);
      }
    });

    return this.http.get(listarUrl, { params });
  }

  crear(listFile: File[], estudioMedicoPaciente: any, descripcion: string[]) {
    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    formData.append("idPaciente", estudioMedicoPaciente.idPaciente);
    formData.append("medico", estudioMedicoPaciente.medico);
    formData.append("estudioMedico", estudioMedicoPaciente.estudioMedico);
    formData.append("fecha", estudioMedicoPaciente.fecha);
    for (let i = 0; i < listFile.length; i++) {
      formData.append("imagenes[" + i + "]", listFile[i]);
      formData.append("descripcion" + i, descripcion[i]);
    }
    formData.append("centroMedico", estudioMedicoPaciente.centroMedico);
    formData.append(
      "observaciones",
      estudioMedicoPaciente.observaciones.trim()
    );
    return this.http.post(this.url + "crear", formData, { headers: headers });
  }

  borrar(estudioMedicoPaciente: EstudioMedicoPaciente): Observable<any> {
    return this.http.delete(this.url + "borrar/" + estudioMedicoPaciente.id);
  }

  //
  mostrar(id): Observable<any> {
    return this.http.get(this.url + "mostrar/" + id);
  }

  //
  estudioMedicoPacienteEditar(id, estudioId): Observable<any> {
    return this.http.get(this.url + "mostrar/" + id + "/" + estudioId);
  }

  editar(
    listFile: any,
    estudioMedicoPaciente: any,
    descripcion: string[]
  ): Observable<any> {
    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    formData.append("id", estudioMedicoPaciente.id);
    formData.append("medico", estudioMedicoPaciente.medico);
    formData.append("estudioMedico", estudioMedicoPaciente.estudioMedico);
    if (listFile) {
      for (let i = 0; i < listFile.length; i++) {
        if (!listFile[i]?.id) {
          formData.append("imagenes[" + i + "]", listFile[i]);
          formData.append("descripcion" + i, descripcion[i]);
        } else {
          formData.append("imagenesId[]", listFile[i]?.id);
        }
      }
    }
    formData.append("fecha", estudioMedicoPaciente.fecha);
    formData.append("centroMedico", estudioMedicoPaciente.centroMedico);
    formData.append(
      "observaciones",
      estudioMedicoPaciente.observaciones.trim()
    );
    return this.http.post(this.url + "editar", formData, { headers: headers });
  }

  mostrarDetalle(id, idMedico = null): Observable<any> {
    let urlDetalle = idMedico
      ? "detalle/" + id + "/" + idMedico
      : "detalle/" + id;
    console.log("urlDetalle", urlDetalle);
    return this.http.get(this.url + urlDetalle);
  }

  cantidad() {
    return this.http.get(this.url + "cantidad");
  }

  listarEstudiosMedicosPaciente(): Observable<any> {
    return this.http.get(this.url + "listar-estudios-medicos");
  }

  async descargarPDFEstudioMedico(estudioMedicoPaciente, imagenesArray = []) {
    // Validación de formato de fecha
    const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    let formatedDate = estudioMedicoPaciente.fecha;

    if (!dateFormatRegex.test(estudioMedicoPaciente.fecha)) {
      formatedDate = this.datePipe.transform(
        estudioMedicoPaciente.fecha,
        "dd/MM/yyyy"
      );
    }

    // Creación de div temporal para renderizar el contenido
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = `
    <div style="display: flex; flex-direction: column; min-height: 297mm; font-family: Arial, sans-serif; padding: 10mm;">
      <header style="display:flex; justify-content: space-between; text-align: center; margin-bottom: 10mm; border-bottom: 1px solid #ccc;">
        <div id="logo-placeholder" style="height: 50px; width: 50px;"></div>
        <div>
          <h2 style="margin: 5px 0;">${estudioMedicoPaciente.nombreCM}</h2>
          <p>${estudioMedicoPaciente.direccionCM} | Tel: ${
      estudioMedicoPaciente.telefonoCMPais
    } ${estudioMedicoPaciente.telefonoCMCaract} ${
      estudioMedicoPaciente.telefonoCM
    } | Mail: ${estudioMedicoPaciente.emailCM}</p>
        </div>
        <img src="${"assets/img/logo.png"}" alt="${
      estudioMedicoPaciente.nombreCM
    }" style="height: 50px;">
      </header>
      <section style="margin-bottom: 10px;">
        <p><b>Fecha:</b> ${formatedDate}</p>
      </section>
      <hr style="border: 1px solid #ccc; margin: 10px 0;">
      <section style="display: flex; justify-content: space-between; border-bottom: 1px solid #ccc; padding-bottom: 10px;">
        <div style="width: 50%;">
          <p><b>Apellido y Nombre:</b> ${estudioMedicoPaciente.nombreP}</p>
          <p><b>Doctor:</b> ${estudioMedicoPaciente.nombreM}</p>
          <p><b>Estudio:</b> ${estudioMedicoPaciente.nombreEM}</p>
        </div>
        <div style="width: 50%; text-align: right;">
          <p><b>OS:</b> ${estudioMedicoPaciente.nombreOS}</p>
        </div>
      </section>
      <div style="padding-bottom: 5px; margin-top: 15px;"></div>
      <div>${
        estudioMedicoPaciente.observaciones !== null
          ? estudioMedicoPaciente.observaciones
          : ""
      }</div>
    </div>
  `;

    // Configuración del div temporal
    tempDiv.style.position = "absolute";
    tempDiv.style.top = "-9999px";
    tempDiv.style.left = "-9999px";
    tempDiv.style.width = "210mm";
    tempDiv.style.minHeight = "297mm";
    tempDiv.style.padding = "5mm";

    document.body.appendChild(tempDiv);
    this.spinnerService.mostrarSpinner();

    // Función para cargar imagen como blob con fallback
    async function loadImageWithFallback(
      url: string,
      fallbackUrl: string
    ): Promise<Blob> {
      try {
        // Primero intentamos cargar la imagen principal
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.blob();
      } catch (error) {
        console.warn(
          "Error cargando imagen principal, usando fallback:",
          error
        );
        // Si falla, intentamos cargar el fallback
        try {
          const fallbackResponse = await fetch(fallbackUrl);
          if (!fallbackResponse.ok) {
            throw new Error(`HTTP error! status: ${fallbackResponse.status}`);
          }
          return await fallbackResponse.blob();
        } catch (fallbackError) {
          console.error("Error cargando imagen de fallback:", fallbackError);
          throw fallbackError;
        }
      }
    }

    try {
      // Cargar el logo (con fallback a assets/img/logo.png)
      const logoBlob = await loadImageWithFallback(
        estudioMedicoPaciente.imagenCM,
        "assets/img/logo.png"
      );

      // Convertir blob a URL de datos para jsPDF
      const logoUrl = URL.createObjectURL(logoBlob);

      // Crear imagen para obtener dimensiones
      const img = new Image();
      img.src = logoUrl;

      await new Promise((resolve, reject) => {
        img.onload = () => {
          // Método para agregar imagen al PDF
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);

          const imgData = canvas.toDataURL("image/png");

          // Generar PDF con html2canvas
          html2canvas(tempDiv, {
            useCORS: true,
            scale: 2,
            logging: false,
            allowTaint: true,
            onclone: (doc) => {
              // Buscar el div de placeholder
              const logoPlaceholder = doc.getElementById("logo-placeholder");
              if (logoPlaceholder) {
                // Reemplazar imagen existente con nuestro logo
                const existingLogo = logoPlaceholder.querySelector("img");
                if (existingLogo) {
                  existingLogo.src = logoUrl;
                  existingLogo.style.height = "50px";
                  existingLogo.style.maxWidth = "50px";
                  existingLogo.style.objectFit = "cover";
                  existingLogo.style.objectPosition = "center";
                } else {
                  // Si no hay imagen, crear una nueva
                  const logoElement = doc.createElement("img");
                  logoElement.src = logoUrl;
                  logoElement.style.height = "50px";
                  logoElement.style.maxWidth = "50px";
                  logoElement.style.objectFit = "cover";
                  logoElement.style.objectPosition = "center";
                  logoPlaceholder.appendChild(logoElement);
                }
              }
            },
          })
            .then((canvas) => {
              document.body.removeChild(tempDiv);

              const pdfImgData = canvas.toDataURL("image/png");
              const pdf = new jsPDF("p", "mm", "a4", true);
              const pdfWidth = pdf.internal.pageSize.getWidth();
              const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

              // Agregar solo el fondo del documento
              pdf.addImage(pdfImgData, "PNG", 0, 0, pdfWidth, pdfHeight);

              // Procesar imágenes adicionales
              if (imagenesArray && imagenesArray.length > 0) {
                return this.descargarYProcesarImagenes(pdf, imagenesArray).then(
                  () => {
                    pdf.save(
                      `${formatedDate}-${estudioMedicoPaciente.nombreEM}-${estudioMedicoPaciente.nombreP}.pdf`
                    );
                    this.spinnerService.ocultarSpinner();

                    // Liberar recursos
                    URL.revokeObjectURL(logoUrl);
                    resolve(null);
                  }
                );
              } else {
                pdf.save(
                  `${formatedDate}-${estudioMedicoPaciente.nombreEM}-${estudioMedicoPaciente.nombreP}.pdf`
                );
                this.spinnerService.ocultarSpinner();

                // Liberar recursos
                URL.revokeObjectURL(logoUrl);
                resolve(null);
                return Promise.resolve();
              }
            })
            .catch((error) => {
              this.spinnerService.ocultarSpinner();
              console.error("Error generando PDF:", error);

              // Limpiar div temporal si aún existe
              if (document.body.contains(tempDiv)) {
                document.body.removeChild(tempDiv);
              }

              // Liberar recursos
              URL.revokeObjectURL(logoUrl);
              reject(error);
            });
        };

        img.onerror = (error) => {
          console.error("Error al cargar la imagen:", error);
          this.spinnerService.ocultarSpinner();
          reject(error);
        };
      });
    } catch (error) {
      console.error("Error en el proceso de carga de imagen:", error);
      this.spinnerService.ocultarSpinner();
    }
  }

  descargarYProcesarImagenes(pdf, imagenesArray: any[]) {
    // Crear un array de promesas para cada imagen
    const promesasImagenes = imagenesArray.map((imagen, index) => {
      return new Promise<void>((resolve, reject) => {
        // Detectar si es una imagen DICOM por la extensión
        const esDicom = imagen.nombre.toLowerCase().endsWith(".dcm");

        if (esDicom) {
          // Procesar archivo DICOM con Cornerstone
          return this.procesarImagenDicomConCornerstone(imagen, pdf)
            .then(() => resolve())
            .catch((error) => {
              console.error(`Error procesando imagen DICOM: ${error}`);
              resolve(); // Continuar con las siguientes imágenes
            });
        } else {
          // Procesar imagen normal (JPG, PNG)
          return this.descargarImagen(imagen)
            .then((url) => {
              const img = new Image();
              img.crossOrigin = "Anonymous";

              img.onload = function () {
                // Añadir nueva página para cada imagen
                pdf.addPage();

                // Calcular dimensiones manteniendo la proporción
                const imgWidth = pdf.internal.pageSize.getWidth() - 20; // 10mm de margen en cada lado
                const imgHeight = (img.height * imgWidth) / img.width;

                // Añadir encabezado con información de la imagen
                pdf.setFontSize(12);
                if (imagen.descripcion) {
                  pdf.setFontSize(10);
                  pdf.text(
                    `Descripción: ${imagen.descripcion || "Sin descripción"}`,
                    10,
                    16
                  );
                }

                // Determinar el formato de la imagen
                const formato = imagen.nombre.toLowerCase().endsWith(".png")
                  ? "PNG"
                  : "JPEG";

                // Añadir la imagen al PDF
                pdf.addImage(img, formato, 10, 22, imgWidth, imgHeight);

                resolve(undefined);
              };

              img.onerror = function () {
                console.error(`Error cargando imagen: ${imagen.nombre}`);
                resolve(); // Continuamos con las otras imágenes
              };

              img.src = url;
            })
            .catch((error) => {
              console.error(`Error descargando imagen: ${error}`);
              resolve(); // Continuamos con las otras imágenes
            });
        }
      });
    });

    // Esperar a que todas las imágenes se procesen
    return Promise.all(promesasImagenes);
  }

  descargarImagen(imagen): Promise<string> {
    const baseUrl = `${GlobalVariable.BASE_API_URL}storage/images/paciente`;
    const imageUrl = `${baseUrl}/${imagen.idPaciente}/${imagen.idEstudioMedico}/${imagen.nombre}`;

    return new Promise<string>((resolve, reject) => {
      // Use the fetch API approach that's already working in your other method
      fetch(imageUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.blob();
        })
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          resolve(url);
        })
        .catch((error) => {
          console.error(`Error al descargar imagen: ${error.message}`);
          reject(error);
        });
    });
  }

  // Método para procesar imágenes DICOM usando CornerstoneService
  procesarImagenDicomConCornerstone(imagen, pdf) {
    const baseUrl = `${GlobalVariable.BASE_API_URL}storage/images/paciente`;
    const imageUrl = `${baseUrl}/${imagen.idPaciente}/${imagen.idEstudioMedico}/${imagen.nombre}`;

    return new Promise<void>((resolve, reject) => {
      // URL de la imagen DICOM
      const dicomUrl = imageUrl;

      // Crear un elemento temporal para renderizar la imagen DICOM
      const cornerDiv = document.createElement("div");
      cornerDiv.id = `temp-cornerstone-${imagen.id}`;
      cornerDiv.style.width = "512px";
      cornerDiv.style.height = "512px";
      cornerDiv.style.position = "absolute";
      cornerDiv.style.left = "-9999px";
      cornerDiv.style.top = "-9999px";
      cornerDiv.setAttribute("cornerstone", "");
      cornerDiv.className = "dcm";
      document.body.appendChild(cornerDiv);

      // Habilitar cornerstone en el elemento
      cornerstone.enable(cornerDiv);

      // Usar el servicio existente para cargar la imagen DICOM
      this.cornerstoneService.fetchDicomImage(dicomUrl).subscribe({
        next: (image) => {
          // Renderizar la imagen en el div
          cornerstone.displayImage(cornerDiv, image);

          // Dar tiempo para que se renderice completamente
          setTimeout(() => {
            // Capturar el contenido renderizado
            html2canvas(cornerDiv, {
              useCORS: true,
              scale: 2,
              logging: false,
              allowTaint: true,
            })
              .then((canvas) => {
                // Limpiar recursos
                cornerstone.disable(cornerDiv);
                document.body.removeChild(cornerDiv);

                // Añadir nueva página al PDF
                pdf.addPage();

                // Convertir canvas a imagen para PDF
                const imgData = canvas.toDataURL("image/png");

                // Calcular dimensiones para PDF
                const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
                const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

                // Añadir información de la imagen DICOM
                pdf.setFontSize(12);
                if (imagen.descripcion) {
                  pdf.setFontSize(10);
                  pdf.text(
                    `Descripción: ${imagen.descripcion || "Sin descripción"}`,
                    10,
                    16
                  );
                }

                // Añadir la imagen procesada al PDF
                pdf.addImage(imgData, "PNG", 10, 22, pdfWidth, pdfHeight);

                resolve();
              })
              .catch((error) => {
                console.error("Error al capturar la imagen DICOM:", error);
                cornerstone.disable(cornerDiv);
                if (document.body.contains(cornerDiv)) {
                  document.body.removeChild(cornerDiv);
                }
                resolve(); // Continuamos con las siguientes imágenes
              });
          }, 1000); // Esperar 1 segundo para asegurar que la imagen se renderice completamente
        },
        error: (error) => {
          console.error(`Error al cargar la imagen DICOM: ${error}`);
          if (document.body.contains(cornerDiv)) {
            cornerstone.disable(cornerDiv);
            document.body.removeChild(cornerDiv);
          }
          resolve(); // Continuamos con las siguientes imágenes
        },
      });
    });
  }
}
