import { from as observableFrom, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import { GlobalVariable } from 'app/global/global';

declare const cornerstone;
declare const cornerstoneWADOImageLoader;
declare const cornerstoneWebImageLoader;

@Injectable()

export class CornerstoneService {
  
  constructor() {

    cornerstoneWebImageLoader.external.cornerstone = cornerstone;
    //cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
    //cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
    //cornerstoneWADOImageLoader.external.cornerstone = cornerstone;

    cornerstoneWADOImageLoader.webWorkerManager.initialize({
      webWorkerPath: GlobalVariable.BASE_FRONT_URL+'assets/cornerstone/webworkers/cornerstoneWADOImageLoaderWebWorker.js',
      taskConfiguration: {
        'decodeTask': {
          codecsPath: GlobalVariable.BASE_FRONT_URL+'assets/cornerstone/codecs/cornerstoneWADOImageLoaderCodecs.js'
        }
      },
      maxWebWorkers: navigator.hardwareConcurrency || 1,
      startWebWorkersOnDemand : true,
    });

    cornerstoneWebImageLoader.configure({
      beforeSend: function (xhr) {
        // Add custom headers here (e.g. auth tokens)
      }
    });
    
  }

  fetchDicomImage(url:string): Observable<any> {
    //
    return observableFrom(cornerstone.loadImage(`wadouri:${url}`)).pipe(first());

  }

}
