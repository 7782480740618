import { NotificationComponent } from 'app/shared/notification/notification.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ElementRef, AfterViewInit, Component, OnInit, ViewChild, NgModule, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NotificacionesPushService } from 'app/services/notificaciones-push.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/shared/confirm-dialog/confirm-dialog.component';
import { DeleteErrorDialogComponent } from 'app/shared/delete-error-dialog/delete-error-dialog.component';
import { TipoDocumentoService } from 'app/services/tipo-documento.service';
import { TipoDocumento } from 'app/models/tipoDocumento.interface';
import { SpinnerService } from 'app/services/spinner.service';
import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';


@Component({
  selector: 'app-tipo-documento-index',
  templateUrl: './tipo-documento-index.component.html',
  styleUrls: ['./tipo-documento-index.component.scss']
})
export class TipoDocumentoIndexComponent implements OnInit {


  //
  @ViewChild('dropdown2') element:ElementRef;
  @ViewChild('buscador') buscador:ElementRef;
  @ViewChild(MatPaginator) paginator:MatPaginator;
  displayedColumns:string[] = ['id', 'texto', 'acciones'];
  editar:boolean = false;
  data:TipoDocumento[];
  dataSource:any;
  searchKey:string;
  usuarioPanel:any;
  notificacionesPush:any[];
  listaTipoDocumento:any;


  //
  constructor(
    public dialog:MatDialog,
    private usuarioPanelService:UsuarioPanelService,
    private spinnerService:SpinnerService,
    private tipoDocumentoService:TipoDocumentoService,
    private snackBar: MatSnackBar,
    private notificacionesPushService:NotificacionesPushService,
  ) { 
    this.spinnerService.mostrarSpinner();
    this.usuarioPanelService.profileUser().pipe(
      map(data => {
        this.usuarioPanel = data;
      }),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        this.usuarioPanelService.logout();
        return of({ results: null });
      }),
      switchMap(data => this.tipoDocumentoService.listar().pipe(
        map(data => { 
          this.listaTipoDocumento = data.listaTipoDocumento;
          this.notificacionesPush = data.notificacionesPush;
          this.listar(this.listaTipoDocumento);
          this.spinnerService.ocultarSpinner();
        }),
      )),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        return of({ results: null });
      }),
    )
    .subscribe(
      (data) => {
        console.log('data',data);
      },
      (error) => {
        console.error('Error al llamar a la API:', error);
      }
    );
  }


  //
  ngOnInit(): void {
    this.notificacionesPushService.listen('notificacion-push', 'NotificacionPushCreated', (data:any) => {
      this.notificacionesPush = [...this.notificacionesPush, ...data.notificacionesPush];
    });
  }


  //
  openDialog = (element:TipoDocumento) => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      width: '50%',
      data: 'tipo documento'
    })
    dialogRef.afterClosed().subscribe(res=>{
      if(res){
        this.borrar(element);
      }
    })   
  }


  //
  openBorrarErrorDialog = () => { 
    let object = {
      titulo: 'tipo documento',
      mensaje: 'No se puede eliminar tipos de documentos con pacientes o médicos activos'
    }
    const dialogRef = this.dialog.open(DeleteErrorDialogComponent,{
      width: '50%',
      data: object
    });
    dialogRef.afterClosed().subscribe(res=>{});
  }


  //
  listar = (data:any) => {
    this.data = data;
    this.dataSource = new MatTableDataSource<TipoDocumento>(data);
    this.dataSource.paginator = this.paginator;
  }


  //
  borrar = (tipoDoc:TipoDocumento) => {
    this.spinnerService.mostrarSpinner();
    this.tipoDocumentoService.borrar(tipoDoc).pipe(
      map(data => {}),
      catchError(error => {
        if(error.error.errorTipoDocumento){
          this.openBorrarErrorDialog();
        }
        this.spinnerService.ocultarSpinner();
        return error;
      }),
      switchMap(data => this.tipoDocumentoService.listar().pipe(
        map(data => { 
          this.listaTipoDocumento = data.listaTipoDocumento;
          this.listar(this.listaTipoDocumento);
          this.spinnerService.ocultarSpinner();
        }),
      )),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        return error;
      }),
    )
    .subscribe(
      (data) => console.log('data',data),
      (error) => console.error('Error al llamar a la API:', error)
    );
  }


  //
  listaActualizada = (mensaje:boolean) => {
    this.tipoDocumentoService.listar().subscribe(
      data => {
        this.listaTipoDocumento = data.listaTipoDocumento;
        this.listar(this.listaTipoDocumento);
        this.spinnerService.ocultarSpinner();
      },
      error => {
        this.spinnerService.ocultarSpinner();
      }
    )
  }


  //
  filter = (event:any) => {
    let dataFilter:TipoDocumento[] = [];
    let valorString:string = this.buscador.nativeElement.value;
    this.data.forEach(obj=>{
      if(obj.id.toString().includes(valorString)){
        dataFilter.push(obj);
      }
      else if(obj.texto.toUpperCase().includes(valorString.toUpperCase())){
        dataFilter.push(obj);
      } 
    })
    this.dataSource = new MatTableDataSource<TipoDocumento>(dataFilter);
    this.dataSource.paginator = this.paginator;
  }


  //
  openSnackBar = (message: string, action: string) => {
    this.snackBar.openFromComponent(NotificationComponent,{
      data: {
        message: message,
        action: action
      },
      duration: 2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [action]
    });
  }


}
