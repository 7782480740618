import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TurnosService } from 'app/services/turnos.service';
import { SpinnerService } from 'app/services/spinner.service';


@Component({
  selector: 'app-turno-delete-dialog',
  templateUrl: './turno-delete-dialog.component.html',
  styleUrls: ['./turno-delete-dialog.component.scss']
})
export class TurnoDeleteDialogComponent implements OnInit {


  //
  constructor(
    public dialogRef: MatDialogRef<TurnoDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public id:any,
    public turnosService:TurnosService,
    private spinnerService:SpinnerService,
  ) { }


  //
  ngOnInit(): void {}


  //
  borrarTurno(){
    this.spinnerService.mostrarSpinner();
    this.turnosService.borrarTurno(this.id).subscribe(
      res => {
        this.dialogRef.close({
          estado: 1
        });
        
      },
      error => {
        this.spinnerService.ocultarSpinner();
      }
    )
  }


  //
  volverAEditar(){
    this.dialogRef.close({
      id: this.id,
      estado: 2
    });
  }


  //
  cerrar(){
    this.dialogRef.close();
  }


}
