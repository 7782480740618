<div class="contenIndicadores">
  <mat-card class="card mat-elevation-z4">
    <div class="cardUno">
      <div class="divIcon">
        <mat-card class="icon">
          <img class="tablaImg" src="assets/img/ico_medico.svg" />
        </mat-card>
      </div>
      <div class="medicos contenido">
        Médicos
        <div class="cantidad">{{ cantidadMedicos }}</div>
      </div>
    </div>
    <div class="cardDos"></div>
  </mat-card>
  <mat-card class="card mat-elevation-z4">
    <div class="cardUno">
      <div class="divIcon">
        <mat-card class="icon">
          <img src="assets/img/ico_paciente.svg" />
        </mat-card>
      </div>
      <div
        class="pacientes contenido"
        [ngClass]="{ alcanzoLimite: llegoLimitePaciente }"
      >
        Pacientes
        <div class="cantidad">{{ cantidadPacientes }}</div>
      </div>
    </div>
    <div class="cardDos"></div>
  </mat-card>
  <mat-card class="card mat-elevation-z4">
    <div class="cardUno">
      <div class="divIcon">
        <mat-card class="icon">
          <img src="assets/img/ico_informe.svg" />
        </mat-card>
      </div>
      <div class="estMedicos contenido">
        Estudios médicos
        <div class="cantidad">{{ cantidadEstudiosMedicos }}</div>
      </div>
    </div>
    <div class="cardDos"></div>
  </mat-card>
  <mat-card class="card mat-elevation-z4">
    <div class="cardUno">
      <div class="divIcon">
        <mat-card class="icon">
          <img id="png-img" src="assets/img/ico_hdd.png" />
        </mat-card>
      </div>
      <div
        class="estMedicos contenido"
        [ngClass]="{ alcanzoLimite: llegoLimiteEspacio }"
      >
        Espacio ocupado
        <div class="cantidad">{{ espacioActual }}%</div>
      </div>
    </div>
    <div class="cardDos"></div>
  </mat-card>
  <mat-card class="card mat-elevation-z4">
    <div class="cardUno">
      <div class="divIcon">
        <mat-card class="icon">
          <img id="png-img" src="assets/img/ico_calendario.svg" />
        </mat-card>
      </div>
      <div
        class="agendaMedicos contenido"
        [ngClass]="{ alcanzoLimite: llegoLimiteEspacio }"
      >
        Agendas disponibles
        <div class="cantidad">{{ agendasDisponibles }}</div>
      </div>
    </div>
    <div class="cardDos"></div>
  </mat-card>
</div>
