import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EstudioMedicoPaciente } from 'app/models/estudioMedicoPaciente.interface';
import { GlobalVariable } from 'app/global/global';

@Injectable({
  providedIn: 'root'
})
export class EstudioMedicoPacienteService {

  url: string = GlobalVariable.BASE_API_URL + 'api/estudio-medico-paciente/';


  constructor(
    private http: HttpClient
  ) { }


  listar(): Observable<any> {
    return this.http.get(this.url + 'listar');
  }


  ///estudio-medico-paciente/inicio/{id}
  estudioMedicoPacienteInicio(id): Observable<any> {
    return this.http.get(this.url + 'inicio/' + id);
  }


  listarPorPaciente(id): Observable<any> {
    return this.http.get(this.url + 'listar-por-paciente/' + id);
  }

  // listar-por-paciente
  crear(listFile:File[], estudioMedicoPaciente:any, descripcion:string[]) {
    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    formData.append('idPaciente', estudioMedicoPaciente.idPaciente);
    formData.append('medico', estudioMedicoPaciente.medico);
    formData.append('estudioMedico', estudioMedicoPaciente.estudioMedico);
    formData.append('fecha', estudioMedicoPaciente.fecha);
    for (let i = 0; i < listFile.length; i++) {
      formData.append('imagenes['+i+']', listFile[i]);
      formData.append('descripcion' + i, descripcion[i]);
    }
    formData.append('centroMedico' , estudioMedicoPaciente.centroMedico);
    formData.append('observaciones' , estudioMedicoPaciente.observaciones.trim());
    return this.http.post(this.url + 'crear' , formData, {headers: headers});
  }

  borrar(estudioMedicoPaciente: EstudioMedicoPaciente): Observable<any> {
    return this.http.delete(this.url + 'borrar/' + estudioMedicoPaciente.id);
  }


  //
  mostrar(id): Observable<any> {
    return this.http.get(this.url + 'mostrar/' + id);
  }


  //
  estudioMedicoPacienteEditar(id,estudioId): Observable<any> {
    return this.http.get(this.url + 'mostrar/' + id + "/" + estudioId);
  }


  editar(listFile:any, estudioMedicoPaciente:any, descripcion:string[]): Observable<any> {
    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    formData.append('id', estudioMedicoPaciente.id);
    formData.append('medico', estudioMedicoPaciente.medico);
    formData.append('estudioMedico', estudioMedicoPaciente.estudioMedico);
    if(listFile) {
      for(let i = 0; i < listFile.length; i++) {
        if(!listFile[i]?.id) {
          formData.append('imagenes['+i+']', listFile[i]);
          formData.append('descripcion'+i, descripcion[i]);
        }
        else {
          formData.append('imagenesId[]', listFile[i]?.id);
        }
      }
    }
    formData.append('fecha', estudioMedicoPaciente.fecha);
    formData.append('centroMedico', estudioMedicoPaciente.centroMedico);
    formData.append('observaciones', estudioMedicoPaciente.observaciones.trim());
    return this.http.post(this.url + 'editar' , formData, {headers: headers});
  }

  listarInformes(id): Observable<any> {
    return this.http.get(this.url + 'listar-informes/' + id);
  }

  mostrarDetalle(id, idMedico = null): Observable<any> {
    let urlDetalle = (idMedico) 
      ? 'detalle/' + id + '/' + idMedico
      : 'detalle/' + id 
    console.log('urlDetalle', urlDetalle);
    return this.http.get(this.url + urlDetalle);
  }

  cantidad() {
    return this.http.get(this.url + 'cantidad');
  }

  listarInformesMedico(idMedico,idPaciente): Observable<any>{
    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    formData.append('idMedico',idMedico);
    formData.append('idPaciente',idPaciente);
    return this.http.post(this.url+'listar-informes-medico',formData,{headers: headers});
  }

  listarEstudiosMedicosPaciente(): Observable<any>{
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(this.url+'listar-estudios-medicos',{headers: headers});
  }
}
