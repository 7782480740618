import { UsuarioPanelService } from "app/services/usuario-panel.service";
import {
  ElementRef,
  Component,
  OnInit,
  ViewChild,
  Input,
  ViewEncapsulation,
  EventEmitter,
  Output,
} from "@angular/core";
import { UsuarioPanel } from "app/models/usuarioPanel.interface";
import { NotificacionesPushService } from "app/services/notificaciones-push.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { GlobalVariable } from "app/global/global";
import { SpinnerService } from "app/services/spinner.service";

@Component({
  selector: "app-header-panel",
  templateUrl: "./header-panel.component.html",
  styleUrls: ["./header-panel.component.scss"],
  encapsulation: ViewEncapsulation.None, // Deshabilita la encapsulación de estilos
})
export class HeaderPanelComponent implements OnInit {
  //
  usuarioPanel: any;
  imagen: string = "assets/img/ico_paciente.svg";
  imagenAux: string;
  notificacionesPush: any[] = [];
  currentRouteName: any;
  public menu: string;
  @Input() set usuarioPanelBinding(value: any) {
    if (value) {
      this.usuarioPanel = value;

      const imageUrl =
        GlobalVariable.BASE_API_URL +
        "storage/images/usuario-panel/" +
        this.usuarioPanel.imagen;

      this.verificarImagen(imageUrl).then((exists) => {
        if (exists) {
          this.imagen = imageUrl;
        }
      });
    }
  }
  @Input() set notificacionesPushBinding(value: any) {
    if (value) {
      this.notificacionesPush = value;
    }
  }

  @Output() openMenu = new EventEmitter<string>();

  //
  constructor(
    private usuarioPanelService: UsuarioPanelService,
    private router: Router,
    private notificacionesPushService: NotificacionesPushService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute
  ) {
    this.router.events.subscribe(() => {
      this.currentRouteName = this.route.snapshot.routeConfig.path;
    });
  }

  //
  ngOnInit() {}

  //

  checkImageExists(url: string): Promise<boolean> {
    return new Promise((resolve) => {
      const img = new Image();

      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);

      img.src = url;
    });
  }

  async verificarImagen(imageUrl: string) {
    const exists = await this.checkImageExists(imageUrl);
    console.log(exists);
    return exists;
  }

  async loadImage() {
    const imageUrl =
      GlobalVariable.BASE_API_URL +
      "storage/images/usuario-panel/" +
      this.usuarioPanel.imagen;

    const exists = await this.verificarImagen(imageUrl);

    this.imagen = exists ? imageUrl : `assets/img/ico_paciente.svg`;

    console.log(this.imagen);
  }

  leerNotificacion(object: any) {
    this.spinnerService.mostrarSpinner();
    this.notificacionesPushService.leerNotificacion(object.id).subscribe(
      () => {
        this.notificacionesPush = this.notificacionesPush.filter(
          (dat) => dat.id !== object.id
        );
        this.spinnerService.ocultarSpinner();
      },
      (error) => {
        console.log("error => ", error);
        this.spinnerService.ocultarSpinner();
      }
    );
  }

  //
  logout() {
    this.usuarioPanelService.logout();
  }

  //
  onMenuItemClick(event: MouseEvent) {
    event.stopPropagation();
  }

  botonDesplegableShadow() {
    this.menu = this.menu == "true" ? "false" : "true";
    this.openMenu.emit(this.menu);
  }

  downloadPDF() {
    const link = document.createElement("a");
    link.href = "assets/pdf/ManualDeUsuario.pdf"; // Reemplaza 'tu-archivo.pdf' con el nombre de tu archivo
    link.download = "Manual de Usuario.pdf"; // Nombre que tendrá el archivo descargado
    link.click();
  }
}
