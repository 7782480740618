import { Injectable } from '@angular/core';
import { GlobalVariable } from 'app/global/global';

@Injectable({
  providedIn: 'root'
})

export class TokenService {

  private issuer = {
    login:  GlobalVariable.BASE_API_URL+ "api/" 
  }

  constructor() { }

  handleData(token){
    localStorage.setItem('auth_token', JSON.stringify(token));
  }

  getToken(){
    return JSON.parse(localStorage.getItem('auth_token')).token;
  }

  // Verify the token
  isValidToken(){
     const token = this.getToken();

     if(token){
       const payload = this.payload(token);
       if(payload){
         return true;
       }
     } 
     else {
        return false;
     }
  }

  payload(token) {
    const jwtPayload = token.split('.')[1];
    return JSON.parse(atob(jwtPayload));
  }

  // User state based on valid token
  isLoggedIn() {
    return this.isValidToken();
  }

  // Remove token
  removeToken(){
    localStorage.removeItem('auth_token');
  }

}