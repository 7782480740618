import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ListarPacienteComponent } from "./listar-paciente/listar-paciente.component";
import { EditarPacienteComponent } from "./editar-paciente/editar-paciente.component";

const routes: Routes = [
  {
    path: "paciente",
    pathMatch: "full",
    component: ListarPacienteComponent,
  },
  {
    path: "paciente/editar/:id",
    pathMatch: "full",
    component: EditarPacienteComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PacienteRoutingModule {}
