<app-navbar></app-navbar>
<div class="content-login">
    <mat-card class="example-card">
        <div class="divImg">
            <img src="assets/img/logo.png" class="img"/>
        </div>

        <form class="example-form" (submit)="onSubmit()">
            <div class="titleLogIn"><span>Ingresar</span></div>
            <div class="divInput">
                <mat-form-field class="example-full-width" appearance="fill" class="matForm">
                    <mat-label class="label">Numero Doc</mat-label>
                    <input type="email" matInput placeholder="" class="input" value="" >
                </mat-form-field>
            </div>
            <div class="divInput">
                <mat-form-field class="example-full-width" appearance="fill" class="matForm">
                    <mat-label>Contraseña</mat-label>
                    <input type="password" matInput placeholder="" class="input" value="">
                </mat-form-field>
            </div>
            <div class="divInput">
                <button type="submit" mat-raised-button color="primary" class="ingresar">Ingresar</button>
            </div>
        </form>
    </mat-card>
</div>

