<div class="formGroup listaDias" *ngIf="agendaForm" [formGroup]="agendaForm">
  <div class="divFechas">
    <div class="divFecha" [class.error]="getDiasConConflicto('lunes')">
      <span class="dia">Lunes</span>
      <div class="fecha">
        <mat-form-field class="formFieldFecha">
          <mat-label>Lunes desde</mat-label>
          <input
            class="inputFecha"
            matInput
            [format]="24"
            [ngxTimepicker]="pickerlunesDesde"
            formControlName="lunesDesde"
            readonly
          />
        </mat-form-field>
        <ngx-material-timepicker
          [theme]="darkTheme"
          #pickerlunesDesde
        ></ngx-material-timepicker>
        <div class="borrar divIcon" (click)="borrarFecha('lunesDesde')">
          <mat-icon class="icon">delete_forever</mat-icon>
        </div>
      </div>
      <div class="fecha">
        <mat-form-field class="formFieldFecha">
          <mat-label>Lunes hasta</mat-label>
          <input
            class="inputFecha"
            matInput
            [format]="24"
            [ngxTimepicker]="pickerlunesHasta"
            formControlName="lunesHasta"
            readonly
          />
        </mat-form-field>
        <ngx-material-timepicker
          [theme]="darkTheme"
          #pickerlunesHasta
        ></ngx-material-timepicker>
        <div class="borrar divIcon" (click)="borrarFecha('lunesHasta')">
          <mat-icon class="icon">delete_forever</mat-icon>
        </div>
      </div>
    </div>
    <div *ngIf="agendaForm.hasError('lunesError1')" class="errorDiv">
      la fecha hasta es requerida si se agrega la fecha desde
    </div>
    <div *ngIf="agendaForm.hasError('lunesError2')" class="errorDiv">
      la fecha desde es requerida si se agrega la fecha hasta
    </div>
    <div *ngIf="agendaForm.hasError('lunesError3')" class="errorDiv">
      la fecha hasta tiene que ser posterior a la fecha desde
    </div>
  </div>
  <div class="divFechas">
    <div class="divFecha" [class.error]="getDiasConConflicto('martes')">
      <span class="dia">Martes</span>
      <div class="fecha">
        <mat-form-field class="formFieldFecha">
          <mat-label>Martes desde</mat-label>
          <input
            class="inputFecha"
            matInput
            [format]="24"
            [ngxTimepicker]="pickerMartesDesde"
            formControlName="martesDesde"
            readonly
          />
        </mat-form-field>
        <ngx-material-timepicker
          [theme]="darkTheme"
          #pickerMartesDesde
        ></ngx-material-timepicker>
        <div class="borrar divIcon" (click)="borrarFecha('martesDesde')">
          <mat-icon class="icon">delete_forever</mat-icon>
        </div>
      </div>
      <div class="fecha">
        <mat-form-field class="formFieldFecha">
          <mat-label>Martes hasta</mat-label>
          <input
            class="inputFecha"
            matInput
            [format]="24"
            [ngxTimepicker]="pickerMartesHasta"
            formControlName="martesHasta"
            readonly
          />
        </mat-form-field>
        <ngx-material-timepicker
          [theme]="darkTheme"
          #pickerMartesHasta
        ></ngx-material-timepicker>
        <div class="borrar divIcon" (click)="borrarFecha('martesHasta')">
          <mat-icon class="icon">delete_forever</mat-icon>
        </div>
      </div>
    </div>
    <div *ngIf="agendaForm.hasError('martesError1')" class="errorDiv">
      la fecha hasta es requerida si se agrega la fecha desde
    </div>
    <div *ngIf="agendaForm.hasError('martesError2')" class="errorDiv">
      la fecha desde es requerida si se agrega la fecha hasta
    </div>
    <div *ngIf="agendaForm.hasError('martesError3')" class="errorDiv">
      la fecha hasta tiene que ser posterior a la fecha desde
    </div>
  </div>
  <div class="divFechas">
    <div class="divFecha" [class.error]="getDiasConConflicto('miercoles')">
      <span class="dia">Miercoles</span>
      <div class="fecha">
        <mat-form-field class="formFieldFecha">
          <mat-label>Miercoles desde</mat-label>
          <input
            class="inputFecha"
            matInput
            [format]="24"
            [ngxTimepicker]="pickerMiercolesDesde"
            formControlName="miercolesDesde"
            readonly
          />
        </mat-form-field>
        <ngx-material-timepicker
          [theme]="darkTheme"
          #pickerMiercolesDesde
        ></ngx-material-timepicker>
        <div class="borrar divIcon" (click)="borrarFecha('miercolesDesde')">
          <mat-icon class="icon">delete_forever</mat-icon>
        </div>
      </div>
      <div class="fecha">
        <mat-form-field class="formFieldFecha">
          <mat-label>Miercoles hasta</mat-label>
          <input
            class="inputFecha"
            matInput
            [format]="24"
            [ngxTimepicker]="pickerMiercolesHasta"
            formControlName="miercolesHasta"
            readonly
          />
        </mat-form-field>
        <ngx-material-timepicker
          [theme]="darkTheme"
          #pickerMiercolesHasta
        ></ngx-material-timepicker>
        <div class="borrar divIcon" (click)="borrarFecha('miercolesHasta')">
          <mat-icon class="icon">delete_forever</mat-icon>
        </div>
      </div>
    </div>
    <div *ngIf="agendaForm.hasError('miercolesError1')" class="errorDiv">
      la fecha hasta es requerida si se agrega la fecha desde
    </div>
    <div *ngIf="agendaForm.hasError('miercolesError2')" class="errorDiv">
      la fecha desde es requerida si se agrega la fecha hasta
    </div>
    <div *ngIf="agendaForm.hasError('miercolesError3')" class="errorDiv">
      la fecha hasta tiene que ser posterior a la fecha desde
    </div>
  </div>
  <div class="divFechas">
    <div class="divFecha" [class.error]="getDiasConConflicto('jueves')">
      <span class="dia">Jueves</span>
      <div class="fecha">
        <mat-form-field class="formFieldFecha">
          <mat-label>Jueves desde</mat-label>
          <input
            class="inputFecha"
            matInput
            [format]="24"
            [ngxTimepicker]="pickerJuevesDesde"
            formControlName="juevesDesde"
            readonly
          />
        </mat-form-field>
        <ngx-material-timepicker
          [theme]="darkTheme"
          #pickerJuevesDesde
        ></ngx-material-timepicker>
        <div class="borrar divIcon" (click)="borrarFecha('juevesDesde')">
          <mat-icon class="icon">delete_forever</mat-icon>
        </div>
      </div>
      <div class="fecha">
        <mat-form-field class="formFieldFecha">
          <mat-label>Jueves hasta</mat-label>
          <input
            class="inputFecha"
            matInput
            [format]="24"
            [ngxTimepicker]="pickerJuevesHasta"
            formControlName="juevesHasta"
            readonly
          />
        </mat-form-field>
        <ngx-material-timepicker
          [theme]="darkTheme"
          #pickerJuevesHasta
        ></ngx-material-timepicker>
        <div class="borrar divIcon" (click)="borrarFecha('juevesHasta')">
          <mat-icon class="icon">delete_forever</mat-icon>
        </div>
      </div>
    </div>
    <div *ngIf="agendaForm.hasError('juevesError1')" class="errorDiv">
      la fecha hasta es requerida si se agrega la fecha desde
    </div>
    <div *ngIf="agendaForm.hasError('juevesError2')" class="errorDiv">
      la fecha desde es requerida si se agrega la fecha hasta
    </div>
    <div *ngIf="agendaForm.hasError('juevesError3')" class="errorDiv">
      la fecha hasta tiene que ser posterior a la fecha desde
    </div>
  </div>
  <div class="divFechas">
    <div class="divFecha" [class.error]="getDiasConConflicto('viernes')">
      <span class="dia">Viernes</span>
      <div class="fecha">
        <mat-form-field class="formFieldFecha">
          <mat-label>Viernes desde</mat-label>
          <input
            class="inputFecha"
            matInput
            [format]="24"
            [ngxTimepicker]="pickerViernesDesde"
            formControlName="viernesDesde"
            readonly
          />
        </mat-form-field>
        <ngx-material-timepicker
          [theme]="darkTheme"
          #pickerViernesDesde
        ></ngx-material-timepicker>
        <div class="borrar divIcon" (click)="borrarFecha('viernesDesde')">
          <mat-icon class="icon">delete_forever</mat-icon>
        </div>
      </div>
      <div class="fecha">
        <mat-form-field class="formFieldFecha">
          <mat-label>Viernes hasta</mat-label>
          <input
            class="inputFecha"
            matInput
            [format]="24"
            [ngxTimepicker]="pickerViernesHasta"
            formControlName="viernesHasta"
            readonly
          />
        </mat-form-field>
        <ngx-material-timepicker
          [theme]="darkTheme"
          #pickerViernesHasta
        ></ngx-material-timepicker>
        <div class="borrar divIcon" (click)="borrarFecha('viernesHasta')">
          <mat-icon class="icon">delete_forever</mat-icon>
        </div>
      </div>
    </div>
    <div *ngIf="agendaForm.hasError('viernesError1')" class="errorDiv">
      la fecha hasta es requerida si se agrega la fecha desde
    </div>
    <div *ngIf="agendaForm.hasError('viernesError2')" class="errorDiv">
      la fecha desde es requerida si se agrega la fecha hasta
    </div>
    <div *ngIf="agendaForm.hasError('viernesError3')" class="errorDiv">
      la fecha hasta tiene que ser posterior a la fecha desde
    </div>
  </div>
  <div class="divFechas">
    <div class="divFecha" [class.error]="getDiasConConflicto('sabado')">
      <span class="dia">Sábado</span>
      <div class="fecha">
        <mat-form-field class="formFieldFecha">
          <mat-label>Sabado desde</mat-label>
          <input
            class="inputFecha"
            matInput
            [format]="24"
            [ngxTimepicker]="pickerSabadoDesde"
            formControlName="sabadoDesde"
            readonly
          />
        </mat-form-field>
        <ngx-material-timepicker
          [theme]="darkTheme"
          #pickerSabadoDesde
        ></ngx-material-timepicker>
        <div class="borrar divIcon" (click)="borrarFecha('sabadoDesde')">
          <mat-icon class="icon">delete_forever</mat-icon>
        </div>
      </div>
      <div class="fecha">
        <mat-form-field class="formFieldFecha">
          <mat-label>Sabado hasta</mat-label>
          <input
            class="inputFecha"
            matInput
            [format]="24"
            [ngxTimepicker]="pickerSabadoHasta"
            formControlName="sabadoHasta"
            readonly
          />
        </mat-form-field>
        <ngx-material-timepicker
          [theme]="darkTheme"
          #pickerSabadoHasta
        ></ngx-material-timepicker>
        <div class="borrar divIcon" (click)="borrarFecha('sabadoHasta')">
          <mat-icon class="icon">delete_forever</mat-icon>
        </div>
      </div>
    </div>
    <div *ngIf="agendaForm.hasError('sabadoError1')" class="errorDiv">
      la fecha hasta es requerida si se agrega la fecha desde
    </div>
    <div *ngIf="agendaForm.hasError('sabadoError2')" class="errorDiv">
      la fecha desde es requerida si se agrega la fecha hasta
    </div>
    <div *ngIf="agendaForm.hasError('sabadoError3')" class="errorDiv">
      la fecha hasta tiene que ser posterior a la fecha desde
    </div>
  </div>
  <div class="divFechas">
    <div class="divFecha" [class.error]="getDiasConConflicto('domingo')">
      <span class="dia">Domingo</span>
      <div class="fecha">
        <mat-form-field class="formFieldFecha">
          <mat-label>Domingo desde</mat-label>
          <input
            class="inputFecha"
            matInput
            [format]="24"
            [ngxTimepicker]="pickerDomingoDesde"
            formControlName="domingoDesde"
            readonly
          />
        </mat-form-field>
        <ngx-material-timepicker
          [theme]="darkTheme"
          #pickerDomingoDesde
        ></ngx-material-timepicker>
        <div class="borrar divIcon" (click)="borrarFecha('domingoDesde')">
          <mat-icon class="icon">delete_forever</mat-icon>
        </div>
      </div>
      <div class="fecha">
        <mat-form-field class="formFieldFecha">
          <mat-label>Domingo hasta</mat-label>
          <input
            class="inputFecha"
            matInput
            [format]="24"
            [ngxTimepicker]="pickerDomingoHasta"
            formControlName="domingoHasta"
            readonly
          />
        </mat-form-field>
        <ngx-material-timepicker
          [theme]="darkTheme"
          #pickerDomingoHasta
        ></ngx-material-timepicker>
        <div class="borrar divIcon" (click)="borrarFecha('domingoHasta')">
          <mat-icon class="icon">delete_forever</mat-icon>
        </div>
      </div>
    </div>
    <div *ngIf="agendaForm.hasError('domingoError1')" class="errorDiv">
      la fecha hasta es requerida si se agrega la fecha desde
    </div>
    <div *ngIf="agendaForm.hasError('domingoError2')" class="errorDiv">
      la fecha desde es requerida si se agrega la fecha hasta
    </div>
    <div *ngIf="agendaForm.hasError('domingoError3')" class="errorDiv">
      la fecha hasta tiene que ser posterior a la fecha desde
    </div>
    <div *ngIf="agendaForm.hasError('fechasError')" class="errorDiv">
      Si habilita la agenda se tiene que agregar minimo los horarios de un dia
    </div>
  </div>
</div>
