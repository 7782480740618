import { Component, OnInit, Input } from '@angular/core';
import { PlanService } from 'app/services/plan.service';


@Component({
  selector: 'app-indicadores-panel',
  templateUrl: './indicadores-panel.component.html',
  styleUrls: ['./indicadores-panel.component.scss']
})
export class IndicadoresPanelComponent implements OnInit {


  //
  cantidadMedicos:number = 0;
  cantidadPacientes:number = 0;
  cantidadEstudiosMedicos:number = 0;
  llegoLimitePaciente:boolean = false;
  llegoLimiteEspacio:boolean = false;
  agendasDisponibles:number = 0;
  espacioActual:number = 0;
  response:any;
  plan:any;
  indicadores:any;
  @Input() set setResponse(value){
    this.mostrarIndicadoresYPlan(value);
  }


  //
  constructor(
    private apiPlan:PlanService, 
  ) { }


  //
  ngOnInit(): void {}

  
  //
  mostrarIndicadoresYPlan(value){
    if(value != undefined || value != null){
      this.response = value;
      this.indicadores = this.response.indicadores;
      this.plan = this.response.plan;
      if(this.indicadores.cantidadEMedicos >= 0){
        this.espacioActual = this.apiPlan.getEspacioOcupado(this.plan.espacioActual,this.plan.espacioMaximo);
        this.cantidadPacientes = this.indicadores.cantidadPacientes;
        this.cantidadMedicos = this.indicadores.cantidadMedicos;
        this.cantidadEstudiosMedicos = this.indicadores.cantidadEMedicos;
        this.llegoLimitePaciente = this.apiPlan.llegoAlLimiteDePacientes(this.plan.cantidadPaciente,this.cantidadPacientes);
        this.agendasDisponibles = this.indicadores.agendasDisponibles;
      }
    }
  }


}
