import { Directive, ElementRef, Input, OnInit } from "@angular/core";

declare const cornerstone;
declare const cornerstoneTools;

@Directive({
  selector: "[cornerstone]",
})
export class CornerstoneDirective implements OnInit {
  element: any;

  imageList = [];

  currentIndex = 0;

  @Input("image")
  set image(imageData: any) {
    //
    if (imageData) {
      //

      if (
        !this.imageList.filter((img) => img.imageId === imageData.imageId)
          .length
      ) {
        this.imageList.push(imageData);
      }

      if (imageData.imageId) {
        this.displayImage(imageData);
      }
    }
  }

  constructor(public elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  ngOnInit() {
    // Retrieve the DOM element itself
    this.element = this.elementRef.nativeElement;

    // Enable the element with Cornerstone
    cornerstone.enable(this.element);
  }

  displayImage(image) {
    cornerstone.displayImage(this.element, image);
    cornerstoneTools.mouseInput.enable(this.element);
  }

  resetView() {
    cornerstoneTools.zoomWheel.deactivate(this.element);
    cornerstoneTools.length.deactivate(this.element);
    cornerstoneTools.magnify.deactivate(this.element);
    cornerstoneTools.clearToolState(this.element, "length");
    cornerstone.updateImage(this.element);

    cornerstone.reset(this.element);
  }

  activateTool(toolName: string, mouseButtonMask: number = 1) {
    cornerstoneTools.zoomWheel.deactivate(this.element);
    cornerstoneTools.length.deactivate(this.element);
    cornerstoneTools.magnify.deactivate(this.element);
    cornerstoneTools.pan.deactivate(this.element);

    // Activa la herramienta seleccionada
    switch (toolName) {
      case "zoom":
        cornerstoneTools.mouseWheelInput.enable(this.element);
        cornerstoneTools.zoomWheel.activate(this.element);
        break;
      case "length":
        cornerstoneTools.length.enable(this.element);
        cornerstoneTools.length.activate(this.element, mouseButtonMask);
        break;
      case "magnify":
        cornerstoneTools.magnify.activate(this.element, mouseButtonMask);

        break;
      case "pan":
        cornerstoneTools.pan.enable(this.element);
        cornerstoneTools.pan.activate(this.element, 1);
      default:
        break;
    }
  }

  zoomIn() {
    // Obtener el viewport actual
    const viewport = cornerstone.getViewport(this.element);

    // Incrementar el zoom (reducir el scale)
    viewport.scale *= 1.2; // Aumenta un 20%

    // Aplicar el nuevo viewport
    cornerstone.setViewport(this.element, viewport);
  }

  zoomOut() {
    // Obtener el viewport actual
    const viewport = cornerstone.getViewport(this.element);

    // Decrementar el zoom (aumentar el scale)
    viewport.scale /= 1.2; // Reduce un 20%

    // Aplicar el nuevo viewport
    cornerstone.setViewport(this.element, viewport);
  }
}
