import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InformesComponent } from './informes/informes.component';

const routes: Routes = [
  {
    path: "seccion-panel",
      children: [
        {
          path: "informes",
          pathMatch: "full",
          component: InformesComponent,
        },
      ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InformesRoutingModule { }
