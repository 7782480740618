import { SpinnerService } from './../spinner.service';
import { GlobalVariable } from './../../global/global';
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import { TokenService } from "../token.service";
import { finalize } from 'rxjs/operators';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {
    

    //
    url:string = GlobalVariable.BASE_API_URL;
    

    //
    restrictedURL:string[] = [
        //this.url+"api/plan/landing-page",
        this.url+"api/usuario-panel/login",
        this.url+"api/paciente/login",
        this.url+"api/medico/login",
        this.url+"api/recuperar-contrasenia/recuperar-panel",
        this.url+"api/recuperar-contrasenia/recuperar-paciente",
        this.url+"api/recuperar-contrasenia/recuperar-medico",
        this.url+"api/recuperar-contrasenia/cambiar-contrasenia-panel",
        this.url+"api/recuperar-contrasenia/cambiar-contrasenia-paciente",
        this.url+"api/recuperar-contrasenia/cambiar-contrasenia-medico",
        this.url+"api/recuperar-contrasenia/token-recuperacion-panel",
        this.url+"api/recuperar-contrasenia/token-recuperacion-paciente",
        this.url+"api/recuperar-contrasenia/token-recuperacion-medico",
        this.url+"api/centro-medico/get-imagen"
    ]


    //
    constructor(
        private tokenService: TokenService, 
        private spinService: SpinnerService
    ) { }


    //
    intercept(req: HttpRequest<any>, next: HttpHandler) {   
        if(this.restrictedURL.indexOf(req.url) == -1){
            if(req.url != this.url+"api/plan/landing-page"){
                const accessToken = this.tokenService.getToken();
                if(accessToken){
                    req = req.clone({
                        setHeaders: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    });
                }
            }
        }
        return next.handle(req).pipe(          
            finalize(()=>{})
        );
    }
}