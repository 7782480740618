import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CentroMedicoComponent } from './centro-medico/centro-medico.component';
import { FormCentroMedicoComponent } from './form-centro-medico/form-centro-medico.component';
import { EditarCentroMedicoComponent } from './editar-centro-medico/editar-centro-medico.component';
import { SharedModule } from 'primeng/api';
import { MaterialModule } from 'app/material/material.module';
import { CentroMedicoRoutingModule } from './centro-medico-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { SharedPanelModule } from '../shared-panel/shared-panel.module';

@NgModule({
  declarations: [
    CentroMedicoComponent,
    FormCentroMedicoComponent,
    EditarCentroMedicoComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    CentroMedicoRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedPanelModule,
    HttpClientModule,
  ]
})
export class CentroMedicoModule { }
