import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { GlobalVariable } from "app/global/global";

@Injectable({
  providedIn: "root",
})
export class InformesService {
  url: string = GlobalVariable.BASE_API_URL + "api/informes/";

  constructor(private http: HttpClient) {}

  mostrar(idSucursal = "", year = ""): Observable<any> {
    return this.http.get(this.url + "mostrar", {
      params: new HttpParams().set("id_sucursal", idSucursal).set("year", year),
    });
  }
}
