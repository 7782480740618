import { Component, OnInit } from '@angular/core';
import { GlobalVariable } from 'app/global/global';
import { CentroMedicoService } from 'app/services/centro-medico.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { FormGroup,FormControl,Validator, Validators } from '@angular/forms';
import { CanActivate, Router } from '@angular/router';
import { MedicoService } from 'app/services/medico.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { environment } from 'environments/environment.prod'; 
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';
import { SpinnerService } from 'app/services/spinner.service';
import { Console } from 'console';


@Component({
  selector: 'app-login-medico',
  templateUrl: './login-medico.component.html',
  styleUrls: ['./login-medico.component.scss']
})
export class LoginMedicoComponent implements OnInit {


  //
  VERSION:string = GlobalVariable.VERSION_FRONT;
  titulo_login:string = 'Mail';
  imagen:string = "";
  loginForm = new FormGroup({
    datoingreso : new FormControl('',Validators.required),
    password : new FormControl('',Validators.required)
  })


  //
  constructor(
    private router: Router,
    private medicoService:MedicoService,
    private snackBar:MatSnackBar,
    private centroService:CentroMedicoService,
    private spinnerService:SpinnerService
  ) { 
    this.titulo_login = (environment.MEDICO_LOGIN == 'MAIL') ? 'Mail' : 'Número documento';
    this.spinnerService.mostrarSpinner();
    this.centroService.getCentroImagen().subscribe(
      data => {
        this.imagen = `${GlobalVariable.BASE_API_URL}storage/images/centro-medico/${data}`;
        this.spinnerService.ocultarSpinner();
      },
      error => {
        this.spinnerService.ocultarSpinner();
      }
    );
  }


  //
  ngOnInit(): void {}


  //
  login(form:any) {
    this.spinnerService.mostrarSpinner();
    this.medicoService.login(form).pipe(
      map(data => {
        let response:any = data;
        this.medicoService.handleData(response);
      }),
      catchError(error => {
        this.medicoService.deleteDatosAutorizacion();
        this.spinnerService.ocultarSpinner();
        this.openSnackBar("Ingreso fallido: " + error.error.error,"error");
        return error;
      }),
      switchMap(data => this.medicoService.datosAutorizacion().pipe(
        map(data => { 
          let response:any = data;
          this.medicoService.setDatosAutorizacion(response);
          this.openSnackBar("Ingreso exitoso","exito");
          this.router.navigate(['/seccion-medico/home-medico/']);
        }),
      )),
      catchError(error => {
        this.medicoService.deleteDatosAutorizacion();
        this.spinnerService.ocultarSpinner();
        this.openSnackBar("Ingreso fallido: " + error.error.error,"error");
        return error;
      })
    )
    .subscribe(
      (data) => {
        console.log('data',data);
      },
      (error) => {
        console.log('error',error);
      }
    );
  }


  //
  openSnackBar(message:string, action:string) {
    this.snackBar.openFromComponent(NotificationComponent,{
      data: {
        message: message,
        action: action
      },
      duration: 2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [action]
    });
  }

}
