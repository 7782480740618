import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalVariable } from 'app/global/global';

@Injectable({
  providedIn: 'root'
})
export class ArchivoService {


  //
  url:string = GlobalVariable.BASE_API_URL + "api/archivo/";


  //
  constructor(
    private http:HttpClient
  ) { }


  //
  listar(id):Observable<any>{
    return this.http.get(this.url + "listar/" + id);
  }


  //
  primerArchivo(id):Observable<any>{
    return this.http.get(this.url + "listar/" + id);
  }

  
  //
  borrar(id):Observable<any>{
    return this.http.delete(this.url + "borrar/" + id);
  }
}
