import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, OnInit, Inject } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { TurnosService } from "app/services/turnos.service";
import { NgxMaterialTimepickerTheme } from "ngx-material-timepicker";
import * as moment from "moment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SpinnerService } from "app/services/spinner.service";
import { NotificationComponent } from "app/shared/notification/notification.component";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs";
import { PacienteService } from "../../services/paciente.service";
import { take } from "rxjs/operators";
import { TipoDocumentoService } from "../../services/tipo-documento.service";
import { TipoDocumento } from "../../models/tipoDocumento.interface";
import { Paciente, PacienteRequest } from "../../models/paciente.interface";
import { diasSemanaOrdenAnglo, generarTurnos } from "../../utils/turnos.utils";
import { Medico, MedicoConDiasTurno } from "../../models/medico.interface";
import { MatSelectChange } from "@angular/material/select";

@Component({
  selector: "app-turno-add-dialog",
  templateUrl: "./turno-add-dialog.component.html",
  styleUrls: ["./turno-add-dialog.component.scss"],
})
export class TurnoAddDialogComponent implements OnInit {
  //
  formularioTurno = new FormGroup({
    id: new FormControl(""),
    titulo: new FormControl("", Validators.required),
    especialidad: new FormControl(""),
    especialidadBuscador: new FormControl(""),
    detalle: new FormControl("", Validators.required),
    id_paciente: new FormControl("", Validators.required),
    pacienteBuscador: new FormControl(""),
    id_medico: new FormControl("", Validators.required),
    medicoBuscador: new FormControl(""),
    centroMedico: new FormControl("", Validators.required),
    centroMedicoBuscador: new FormControl(""),
    fechaDesde: new FormControl("", Validators.required),
    fechaHasta: new FormControl("", Validators.required),
    tiempoDesde: new FormControl("", Validators.required),
    tiempoHasta: new FormControl("", Validators.required),
  });
  listaEspecialidades: any;
  filtroEspecialidades: any;
  listaCompletaMedicos: any;
  general: any;
  tipoUsuario: any;
  fechaError: any;
  listaPacientes: any;
  listaMedicos: any;
  listaCentrosMedicos: any;
  filtroPacientes: any;
  filtroMedicos: any;
  filtroCentroMedicos: any;
  tiposDocumentos: TipoDocumento[];
  turno: any;
  usuario: any;
  editar: any;
  medicoSelecionado: any;
  pacienteSeleccionado: any;
  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: "#ffffff",
      buttonColor: "#000000",
    },
    dial: {
      dialBackgroundColor: "#3434EC",
    },
    clockFace: {
      clockFaceBackgroundColor: "#EEEEEE",
      clockHandColor: "#05CE7C",
      clockFaceTimeInactiveColor: "#3434EC",
    },
  };
  public turnoDesdeLanding = false;
  private diasSemana = diasSemanaOrdenAnglo;
  private subscripciones: Subscription;
  private diasActivosMédicoObs = new BehaviorSubject<number[]>([
    0, 1, 2, 3, 4, 5, 6, 7,
  ]);
  private diasActivosMedico: number[] = [];
  private listaDeTurnos: any[] = [];

  //
  constructor(
    public dialogRef: MatDialogRef<TurnoAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public response: any,
    public turnosService: TurnosService,
    private pacienteService: PacienteService,
    private tipoDocumentoService: TipoDocumentoService,
    private spinnerService: SpinnerService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder
  ) {}

  //
  ngOnInit(): void {
    this.editar = this.response.editar;
    this.turno = this.response.turno;
    this.usuario = this.response.usuario;
    this.listaPacientes = this.response.pacientes;
    this.filtroPacientes = this.response.pacientes;
    this.listaMedicos = this.response.medicos;
    this.filtroMedicos = this.response.medicos;
    this.listaCompletaMedicos = this.response.medicos;
    this.listaCentrosMedicos = this.response.centrosMedicos;
    this.filtroCentroMedicos = this.response.centrosMedicos;
    this.tipoUsuario = this.response.tipoUsuario;
    this.general = this.response.general;
    this.listaEspecialidades = this.response.especialidades;
    this.filtroEspecialidades = this.response.especialidades;
    this.turnoDesdeLanding = this.response?.turnoDesdeLanding;
    this.response.turno
      ? this.iniciarFormularioEdicion()
      : this.iniciarFormulario();
    if (this.response.turnoDesdeLanding) {
      this.subscripciones = combineLatest([
        this.turnosService.getTurnosPanel(),
        this.tipoDocumentoService.listar(),
        this.diasActivosMédicoObs,
      ]).subscribe(
        ([
          { especialidades, medicos, turnos },
          { listaTipoDocumento },
          diasActivosMedico,
        ]) => {
          this.listaCompletaMedicos = medicos;
          this.filtroMedicos = medicos;

          this.listaEspecialidades = especialidades;
          this.filtroEspecialidades = especialidades;

          this.tiposDocumentos = listaTipoDocumento;

          this.diasActivosMedico = diasActivosMedico;

          this.listaDeTurnos = turnos;
        }
      );
    }
  }

  ngOnDestroy() {
    if (this.subscripciones) {
      this.subscripciones.unsubscribe();
    }
  }

  //
  iniciarFormulario() {
    if (this.response?.turnoDesdeLanding) {
      this.formularioTurno = this.fb.group({
        especialidad:
          this.tipoUsuario == "medico" && this.usuario.especialidad
            ? this.usuario.especialidad
            : 0,
        especialidadBuscador: "",
        id_medico: this.tipoUsuario == "medico" ? this.usuario?.id : "",
        id_paciente: "",
        centroMedico: this.response.centroMedicoId,
        nombrePaciente: ["", Validators.required],
        apellidoPaciente: ["", Validators.required],
        tipoDniPaciente: ["", Validators.required],
        dniPaciente: ["", Validators.required],
        emailPaciente: ["", [Validators.required, Validators.email]],
        medicoBuscador: "",
        fechaDesde: "",
        fechaHasta: "",
        tiempoDesde: "",
        tiempoHasta: "",
        titulo: "",
        detalle: "",
      });
    } else {
      this.formularioTurno.patchValue({
        titulo: "",
        especialidad:
          this.tipoUsuario == "medico" && this.usuario.especialidad
            ? this.usuario.especialidad
            : 0,
        especialidadBuscador: "",
        detalle: "",
        id_paciente: this.tipoUsuario == "paciente" ? this.usuario?.id : "",
        pacienteBuscador: "",
        id_medico: this.tipoUsuario == "medico" ? this.usuario?.id : "",
        medicoBuscador: "",
        centroMedico: "",
        centroMedicoBuscador: "",
        fechaDesde: "",
        fechaHasta: new Date(this.response.selectInfo.start),
        tiempoDesde: "",
        tiempoHasta: "",
      });
    }

    if (this.response.selectInfo?.view.type == "dayGridMonth") {
      this.formularioTurno
        .get("fechaDesde")
        .setValue(new Date(this.response.selectInfo.start));
      this.formularioTurno
        .get("fechaHasta")
        .setValue(new Date(this.response.selectInfo.start));
    } else if (this.response.selectInfo?.view.type == "timeGridWeek") {
      this.formularioTurno
        .get("tiempoDesde")
        .setValue(moment(this.response.selectInfo.start).format("HH:mm"));
      this.formularioTurno
        .get("tiempoHasta")
        .setValue(moment(this.response.selectInfo.end).format("HH:mm"));
      this.formularioTurno
        .get("fechaDesde")
        .setValue(new Date(this.response.selectInfo.start));
      this.formularioTurno
        .get("fechaHasta")
        .setValue(new Date(this.response.selectInfo.start));
    } else if (this.response.selectInfo?.view.type == "timeGridDay") {
      this.formularioTurno
        .get("tiempoDesde")
        .setValue(moment(this.response.selectInfo.start).format("HH:mm"));
      this.formularioTurno
        .get("tiempoHasta")
        .setValue(moment(this.response.selectInfo.end).format("HH:mm"));
      this.formularioTurno
        .get("fechaDesde")
        .setValue(new Date(this.response.selectInfo.start));
      this.formularioTurno
        .get("fechaHasta")
        .setValue(new Date(this.response.selectInfo.start));
    }

    if (this.tipoUsuario == "medico") {
      this.medicoSelecionado = this.filtroMedicos.find(
        (elemento: any) => elemento.id == this.usuario?.id
      );
    }
  }

  //
  iniciarFormularioEdicion() {
    this.medicoSelecionado = this.filtroMedicos.find(
      (elemento: any) => elemento.id == this.turno.id_medico
    );
    this.pacienteSeleccionado = this.filtroPacientes.find(
      (elemento: any) => elemento.id == this.turno.id_paciente
    );

    this.formularioTurno.patchValue({
      id: this.turno.id,
      titulo: this.turno.titulo,
      detalle: this.turno.detalle,
      id_paciente: this.turno.id_paciente,
      especialidad: this.medicoSelecionado.especialidad
        ? this.medicoSelecionado.especialidad
        : 0,
      pacienteBuscador: "",
      centroMedico: this.turno.id_centromedico,
      centroMedicoBuscador: "",
      id_medico: this.turno.id_medico,
      medicoBuscador: "",
      fechaDesde: new Date(this.turno.fecha_desde),
      fechaHasta: new Date(this.turno.fecha_hasta),
      tiempoDesde: moment(this.turno.fecha_desde).format("HH:mm"),
      tiempoHasta: moment(this.turno.fecha_hasta).format("HH:mm"),
    });
  }

  //
  filtrarEspecialidad(event: any) {
    let valorString: string = this.formularioTurno.get(
      "especialidadBuscador"
    ).value;
    let array = [];
    for (let ops of this.listaEspecialidades) {
      if (ops.texto.toUpperCase().includes(valorString.toUpperCase())) {
        let nuevoE: any = ops;
        array.push(nuevoE);
      }
    }
    this.filtroEspecialidades = array;
  }

  //
  filtrarMedico(event: any) {
    let valorString: string = this.formularioTurno.get("medicoBuscador").value;
    this.filtroMedicos = [];

    for (let ops of this.listaMedicos) {
      if (
        ops.nombreCompleto.toUpperCase().includes(valorString.toUpperCase())
      ) {
        let nuevoM: any = ops;
        this.filtroMedicos.push(nuevoM);
      }
    }
  }

  //
  filtrarCentroMedico(event: any) {
    let valorString: string = this.formularioTurno.get(
      "centroMedicoBuscador"
    ).value;
    this.filtroCentroMedicos = [];

    for (let ops of this.listaCentrosMedicos) {
      if (ops.nombre.toUpperCase().includes(valorString.toUpperCase())) {
        let nuevoCM: any = ops;
        this.filtroCentroMedicos.push(nuevoCM);
      }
    }
  }

  //
  filtrarEspecialidadMedico(event: any) {
    let especialidad: any = this.formularioTurno.get("especialidad").value;
    if (especialidad == 0) {
      this.listaMedicos = this.listaCompletaMedicos;
      this.filtroMedicos = this.listaCompletaMedicos;
      this.formularioTurno.patchValue({
        id_medico: "",
        fechaDesde: "",
      });
    } else {
      let array = [];
      for (let ops of this.listaCompletaMedicos) {
        if (ops.especialidad == especialidad) {
          let nuevoM: any = ops;
          array.push(nuevoM);
        }
      }
      this.listaMedicos = array;
      this.filtroMedicos = array;
      this.formularioTurno.patchValue({
        id_medico: "",
        fechaDesde: "",
      });
    }
  }

  //
  filtrarPaciente(event: any) {
    let valorString: string =
      this.formularioTurno.get("pacienteBuscador").value;
    this.filtroPacientes = [];

    for (let ops of this.listaPacientes) {
      if (
        ops.nombreApellido.toUpperCase().includes(valorString.toUpperCase())
      ) {
        let nuevoP: any = ops;
        this.filtroPacientes.push(nuevoP);
      }
    }
  }

  //
  validarMedicoEmail(event: any) {
    this.medicoSelecionado = this.filtroMedicos.find(
      (elemento: any) => elemento.id == event
    );

    if (this.medicoSelecionado)
      this.diasActivosMédicoObs.next(this.generarDiasSemanaActivo());
  }

  //
  validarPacienteEmail(event: any) {
    this.pacienteSeleccionado = this.filtroPacientes.find(
      (elemento: any) => elemento.id == event
    );
  }

  //
  guardarTurno(formularioTurno: any) {
    if (this.response.turnoDesdeLanding) {
      this.spinnerService.mostrarSpinner();
      let pacienteId = 0;
      this.formularioTurno.patchValue({
        fechaHasta: this.formularioTurno.value.fechaDesde,
        tiempoHasta: this.formularioTurno.value.tiempoDesde,
      });

      this.pacienteService
        .buscarPorDni(formularioTurno.dniPaciente)
        .pipe(take(1))
        .subscribe({
          next: ({ pacienteExiste, paciente }) => {
            if (!pacienteExiste) {
              const newPaciente: PacienteRequest = {
                nombreApellido:
                  formularioTurno.nombrePaciente +
                  " " +
                  formularioTurno.apellidoPaciente,
                tipoDocumento: formularioTurno.tipoDniPaciente,
                numeroDocumento: formularioTurno.dniPaciente,
                email: formularioTurno.emailPaciente,
                password: formularioTurno.dniPaciente,
                numeroAfiliado: "",
                direccion: "",
                imagen: "",
                obraSocial: "",
                telPais: "",
                telCaract: "",
                telNumero: "",
                celPais: "",
                celCaract: "",
                celNumero: "",
                codigoInterno: "",
                datoingreso: "",
                modificar: true,
              };

              this.pacienteService
                .crear("", newPaciente, null)
                .pipe(take(1))
                .subscribe({
                  next: (data: {
                    mensaje: string;
                    nuevoPaciente?: Paciente;
                  }) => {
                    pacienteId = data?.nuevoPaciente?.id;

                    formularioTurno = {
                      ...formularioTurno,
                      id_paciente: pacienteId,
                      fechaHasta: this.formularioTurno.value.fechaDesde,
                      tiempoHasta: this.formularioTurno.value.tiempoDesde,
                    };

                    const fechaDesde = this.formatearFecha(
                      this.formularioTurno.get("fechaDesde").value,
                      this.formularioTurno
                        .get("tiempoDesde")
                        .value.split("-")[0]
                    );

                    const fechaHasta = this.formatearFecha(
                      this.formularioTurno.get("fechaHasta").value,
                      this.formularioTurno
                        .get("tiempoHasta")
                        .value.split("-")[0]
                    );

                    return this.validarFecha(
                      formularioTurno,
                      fechaDesde,
                      fechaHasta
                    );
                  },
                });
            } else {
              pacienteId = paciente.id;
            }

            this.formularioTurno.patchValue({
              id_paciente: pacienteId,
              fechaHasta: this.formularioTurno.value.fechaDesde,
              tiempoHasta: this.formularioTurno.value.tiempoDesde,
            });

            formularioTurno = {
              ...formularioTurno,
              id_paciente: pacienteId,
              fechaHasta: this.formularioTurno.value.fechaDesde,
              tiempoHasta: this.formularioTurno.value.tiempoDesde,
            };

            const fechaDesde = this.formatearFecha(
              this.formularioTurno.get("fechaDesde").value,
              this.formularioTurno.get("tiempoDesde").value.split("-")[0]
            );

            const fechaHasta = this.formatearFecha(
              this.formularioTurno.get("fechaHasta").value,
              this.formularioTurno.get("tiempoHasta").value.split("-")[0]
            );

            this.validarFecha(formularioTurno, fechaDesde, fechaHasta);
          },
        });

      return;
    }

    const fechaDesde = this.formatearFecha(
      this.formularioTurno.get("fechaDesde").value,
      this.formularioTurno.get("tiempoDesde").value
    );

    const fechaHasta = this.formatearFecha(
      this.formularioTurno.get("fechaHasta").value,
      this.formularioTurno.get("tiempoHasta").value
    );

    this.editar
      ? this.validarFechaEditar(formularioTurno, fechaDesde, fechaHasta)
      : this.validarFecha(formularioTurno, fechaDesde, fechaHasta);
  }

  private formatearFecha(fechaAFormatear: Date, tiempoAFormatear): string {
    return `${moment(fechaAFormatear).format(
      "YYYY-MM-DD"
    )} ${tiempoAFormatear}`;
  }

  //
  validarFecha(formularioTurno: any, fechaDesde: any, fechaHasta: any) {
    if (this.formularioTurno.valid) {
      this.spinnerService.mostrarSpinner();
      this.turnosService
        .validarTurno(formularioTurno, fechaDesde, fechaHasta)
        .subscribe(
          (data) => {
            let response: any = data;
            this.fechaError = response.fechaError;
            if (this.fechaError == "") {
              this.crearTurno(formularioTurno, fechaDesde, fechaHasta);
            } else {
              this.spinnerService.ocultarSpinner();
              this.openSnackBar("Guardado fallido", "error");
            }
          },
          (error) => {
            console.log(error);
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Guardado fallido", "error");
          }
        );
    } else {
      this.openSnackBar("Guardado fallido", "error");
    }
  }

  //
  crearTurno(formularioTurno: any, fechaDesde: any, fechaHasta: any) {
    this.turnosService
      .crearTurno(formularioTurno, fechaDesde, fechaHasta)
      .subscribe(
        (data) => {
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Guardado exitoso", "exito");
          this.dialogRef.close({ estado: 1 });
        },
        (error) => {
          console.log(error);
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Guardado fallido", "error");
        }
      );
  }

  //
  validarFechaEditar(formularioTurno: any, fechaDesde: any, fechaHasta: any) {
    if (this.formularioTurno.valid) {
      this.spinnerService.mostrarSpinner();
      this.turnosService
        .validarTurnoEditar(formularioTurno, fechaDesde, fechaHasta)
        .subscribe(
          (data) => {
            let response: any = data;
            this.fechaError = response.fechaError;
            if (this.fechaError == "") {
              this.editarTurno(formularioTurno, fechaDesde, fechaHasta);
            } else {
              this.spinnerService.ocultarSpinner();
              this.openSnackBar("Editado fallido", "error");
            }
          },
          (error) => {
            console.log(error);
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Editado fallido", "error");
          }
        );
    } else {
      this.openSnackBar("Editado fallido", "error");
    }
  }

  //
  editarTurno(formularioTurno: any, fechaDesde: any, fechaHasta: any) {
    this.turnosService
      .editarTurno(formularioTurno, fechaDesde, fechaHasta)
      .subscribe(
        (data) => {
          this.openSnackBar("Editado exitoso", "exito");
          this.dialogRef.close({ estado: 1 });
        },
        (error) => {
          console.log(error);
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Editado fallido", "error");
        }
      );
  }

  //
  cambiarTurno(event: Event, tipoValor: any) {
    this.fechaError = "";
    if (tipoValor == "tiempoDesde") {
      let rangoHasta = moment(this.general?.rangoHasta, "HH:mm");
      let fechaDesde = this.formularioTurno.get("fechaDesde");
      let fechaHasta = this.formularioTurno.get("fechaHasta");
      let tiempoDesde = this.formularioTurno.get("tiempoDesde");
      let tiempoHasta = this.formularioTurno.get("tiempoHasta");
      if (!tiempoHasta.value && rangoHasta.isValid()) {
        let fechaMomentDesde = moment(fechaDesde.value); //.format('YYYY-MM-DD');
        let fechaMomentHasta = moment(fechaHasta.value); //.format('YYYY-MM-DD');
        if (fechaMomentDesde.isSame(fechaMomentHasta)) {
          let fechaTotalDesde = moment(
            `${fechaMomentDesde.format("YYYY-MM-DD")} ${tiempoDesde.value}`,
            "YYYY-MM-DD HH:mm"
          );
          let sumaMoment = fechaTotalDesde
            .add(rangoHasta.hours(), "hours")
            .add(rangoHasta.minutes(), "minutes");
          tiempoHasta.setValue(sumaMoment.format("HH:mm"));
          fechaHasta.setValue(
            new Date(sumaMoment.year(), sumaMoment.month(), sumaMoment.date())
          );
        } else {
          let tiempoMomentDesde = moment(tiempoDesde.value, "HH:mm");
          let sumaMoment = tiempoMomentDesde
            .add(rangoHasta.hours(), "hours")
            .add(rangoHasta.minutes(), "minutes");
          tiempoHasta.setValue(sumaMoment.format("HH:mm"));
        }
      }
    }
  }

  //
  cancelar() {
    this.dialogRef.close();
  }

  //
  borrarTurno() {
    this.dialogRef.close({
      id: this.turno.id,
      estado: 2,
    });
  }

  //
  cerrar() {
    this.dialogRef.close();
  }

  //
  openSnackBar(message: string, action: string) {
    this.snackBar.openFromComponent(NotificationComponent, {
      data: {
        message: message[0].toUpperCase() + message.slice(1),
        action: action,
      },
      duration: 2500,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: [action],
    });
  }

  private generarListaDeTodosLosTurnos(day: Date): string[] {
    const desde = this.medicoSelecionado
      ? this.medicoSelecionado[`${this.diasSemana[day.getDay()]}_desde`]
      : "00:00";
    const hasta = this.medicoSelecionado
      ? this.medicoSelecionado[`${this.diasSemana[day.getDay()]}_hasta`]
      : "24:00";

    const listaDeTurnos = generarTurnos(desde, hasta, this.general.rangoHasta);

    return listaDeTurnos;
  }

  public listaDeTurnosSinLosYaOtorgados(): string[] {
    const diaSeleccionado = new Date(this.formularioTurno.value.fechaDesde);

    const turnosDadosPorMedico = this.turnosPorMedico()
      .filter(
        (turno) =>
          moment(turno.fecha_desde).format("YYYY-MM-DD") ===
          moment(diaSeleccionado).format("YYYY-MM-DD")
      )
      .map((turno) => {
        return turno.fecha_desde.split(" ")[1].split(":")[0];
      });

    const listaDeTurnosSinLosTurnosOtorgados =
      this.generarListaDeTodosLosTurnos(diaSeleccionado).filter(
        (turno) =>
          !turnosDadosPorMedico.includes(turno.split("-")[0].split(":")[0])
      );

    return listaDeTurnosSinLosTurnosOtorgados.length === 0
      ? ["No quedan turnos disponibles"]
      : listaDeTurnosSinLosTurnosOtorgados;
  }

  generarDiasSemanaActivo(): number[] {
    const diasSemana: number[] = [];

    if (this.medicoSelecionado.lunes_desde !== null) {
      diasSemana.push(1);
    }
    if (this.medicoSelecionado.martes_desde !== null) {
      diasSemana.push(2);
    }
    if (this.medicoSelecionado.miercoles_desde !== null) {
      diasSemana.push(3);
    }
    if (this.medicoSelecionado.jueves_desde !== null) {
      diasSemana.push(4);
    }
    if (this.medicoSelecionado.viernes_desde !== null) {
      diasSemana.push(5);
    }
    if (this.medicoSelecionado.sabado_desde !== null) {
      diasSemana.push(6);
    }
    if (this.medicoSelecionado.domingo_desde !== null) {
      diasSemana.push(0);
    }

    return diasSemana;
  }

  private turnosPorMedico() {
    const medico = this.formularioTurno.value.id_medico;
    if (!medico) return;

    return this.listaDeTurnos.filter((turno) => turno.id_medico === medico);
  }

  private diaCompleto(day: Date): boolean {
    const turnosDadosPorMedico = this.turnosPorMedico()?.filter(
      (turno) =>
        moment(turno?.fecha_desde).format("YYYY-MM-DD") ===
        moment(day).format("YYYY-MM-DD")
    );

    const listaDeTurnosSinLosTurnosOtorgados =
      this.generarListaDeTodosLosTurnos(day);

    return (
      listaDeTurnosSinLosTurnosOtorgados?.length ===
      turnosDadosPorMedico?.length
    );
  }

  private filtrarFechasDatePicker(d: Date | null): boolean {
    const day = d || new Date();

    if (this.response.turnoDesdeLanding) {
      return (
        this.diasActivosMedico?.includes(day.getDay()) && !this.diaCompleto(day)
      );
    } else {
      return [0, 1, 2, 3, 4, 5, 6].includes(day.getDay());
    }
  }

  public boundedFiltrarFechasDatePicker =
    this.filtrarFechasDatePicker.bind(this);

  public seleccionarHorario(event: MatSelectChange) {
    this.formularioTurno.patchValue({
      tiempoDesde: event.value.split("-")[0].trim(),
    });
  }
}
