import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlanService } from 'app/services/plan.service';
import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { SpinnerService } from 'app/services/spinner.service';
import { NotificacionesPushService } from 'app/services/notificaciones-push.service';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';


@Component({
  selector: 'app-home-panel',
  templateUrl: './home-panel.component.html',
  styleUrls: ['./home-panel.component.scss']
})
export class HomePanelComponent implements OnInit, OnDestroy {


  //
  orders = [];
  response:any;
  plan:any;
  indicadores:any;
  usuarioPanel:any;
  notificacionesPush:any[];


  //
  constructor(
    private planService:PlanService, 
    private usuarioPanelService:UsuarioPanelService,
    private spinnerService:SpinnerService,
    private notificacionesPushService:NotificacionesPushService,
  ) {
    this.spinnerService.mostrarSpinner();
    this.usuarioPanelService.profileUser().pipe(
      map(data => {
        this.usuarioPanel = data;
      }),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        this.usuarioPanelService.logout();
        return of({ results: null });
      }),
      switchMap(data => this.planService.mostrarPlanCantidad().pipe(
        map(data => { 
          console.log('data => ', data);
          this.response = data;
          this.plan = this.response.plan;
          this.indicadores = this.response.indicadores;
          this.notificacionesPush = this.response.notificacionesPush;
          this.spinnerService.ocultarSpinner();
        }),
      )),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        return of({ results: null });
      }),
    )
    .subscribe(
      (data) => {
        console.log('data',data);
      },
      (error) => {
        console.error('Error al llamar a la API:', error);
      }
    );
  }


  //
  ngOnInit(): void {
    this.notificacionesPushService.listen('notificacion-push', 'NotificacionPushCreated', (data:any) => {
      this.notificacionesPush = [...this.notificacionesPush, ...data.notificacionesPush];
    });
  }


  //
  ngOnDestroy() {
    
  }


}
