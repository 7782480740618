<div class="divContent">
  <mat-accordion class="acordeonContent">
    <mat-expansion-panel
      hideToggle
      [expanded]="panelOpenState"
      class="mat-elevation-z4"
    >
      <mat-expansion-panel-header
        class="acordeonHeader"
        (click)="ocultarMostrar()"
      >
        <div class="topTitle">
          <mat-card class="titleSeccion">
            <span *ngIf="!editarValor">Crear paciente</span>
            <span *ngIf="editarValor">Editar paciente</span>
            <mat-icon *ngIf="!panelOpenState" class="iconArrow"
              >keyboard_arrow_down</mat-icon
            >
            <mat-icon *ngIf="panelOpenState" class="iconArrow"
              >keyboard_arrow_up</mat-icon
            >
          </mat-card>
        </div>
      </mat-expansion-panel-header>

      <form
        [formGroup]="pacienteForm"
        (submit)="guardarPaciente(pacienteForm.value)"
        class="formCard"
      >
        <div class="card">
          <div class="formGroup">
            <input hidden matInput formControlName="id" />
          </div>
          <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
              <mat-label>Nombre apellido</mat-label>
              <input formControlName="nombreApellido" type="text" matInput />
            </mat-form-field>
            <div class="valid">
              <span
                style="color: rgb(236, 75, 72)"
                *ngIf="
                  pacienteForm.get('nombreApellido').errors?.required &&
                  pacienteForm.get('nombreApellido').touched
                "
              >
                El nombre completo es requerido
              </span>
            </div>
          </div>
          <div>
            <div class="formGroup documento">
              <div class="tipoDocumento">
                <mat-form-field appearance="fill" class="input input1">
                  <mat-label>Tipo documento</mat-label>
                  <mat-select
                    formControlName="tipoDocumento"
                    (ngModelChange)="clearTipoDocumento()"
                  >
                    <mat-option
                      *ngFor="let tipoDoc of tiposDocumentos"
                      [value]="tipoDoc.id"
                      >{{ tipoDoc.texto }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <div class="valid">
                  <span
                    style="color: rgb(236, 75, 72)"
                    *ngIf="
                      pacienteForm.get('tipoDocumento').errors?.required &&
                      pacienteForm.get('tipoDocumento').touched
                    "
                  >
                    El tipo de documento es requerido
                  </span>
                  <span
                    style="color: rgb(236, 75, 72)"
                    *ngIf="errorTipoDocumento"
                  >
                    El tipo de documento es requerido con el número de documento
                  </span>
                </div>
              </div>
              <div class="numeroDocumento">
                <mat-form-field
                  class="example-full-width input input2"
                  appearance="fill"
                >
                  <mat-label>Número documento</mat-label>
                  <input
                    formControlName="numeroDocumento"
                    (ngModelChange)="clearNumeroDocumento()"
                    type="text"
                    matInput
                  />
                </mat-form-field>
                <div class="valid">
                  <span
                    style="color: rgb(236, 75, 72)"
                    *ngIf="
                      pacienteForm.get('numeroDocumento').errors?.required &&
                      pacienteForm.get('numeroDocumento').touched
                    "
                  >
                    El número documento es requerido
                  </span>
                  <span
                    style="color: rgb(236, 75, 72)"
                    *ngIf="errorNumeroDocumento"
                  >
                    El número de documento es requerido con el tipo de documento
                  </span>
                </div>
              </div>
            </div>
            <div class="valid2">
              <span style="color: rgb(236, 75, 72)" *ngIf="errorDocumento">
                Este número de documento fue registrado para otro paciente
              </span>
            </div>
          </div>
          <div class="formGroup obraSocial">
            <div class="obraS">
              <mat-form-field
                appearance="fill"
                class="example-full-width input input1"
              >
                <mat-label>Obra social</mat-label>
                <mat-select formControlName="obraSocial">
                  <mat-option
                    *ngFor="let obra of obrasSociales"
                    [value]="obra.id"
                    >{{ obra.nombre }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div class="numeroAfiliado">
              <mat-form-field
                class="example-full-width input input2"
                appearance="fill"
              >
                <mat-label>Número de afiliado</mat-label>
                <input
                  formControlName="numeroAfiliado"
                  (ngModelChange)="clearNumeroAfiliado()"
                  type="text"
                  matInput
                />
              </mat-form-field>
              <div class="valid">
                <span
                  style="color: rgb(236, 75, 72)"
                  *ngIf="
                    errorNumeroAfiliado &&
                    pacienteForm.get('numeroAfiliado').touched
                  "
                >
                  El número de afiliado fue registrado por otro paciente
                </span>
              </div>
            </div>
          </div>
          <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
              <mat-label>Dirección</mat-label>
              <input formControlName="direccion" type="text" matInput />
            </mat-form-field>
          </div>
          <div class="formGroup fechaNacimiento">
            <mat-form-field appearance="fill" class="input">
              <mat-label>Fecha de nacimiento</mat-label>
              <input
                formControlName="fechaNacimiento"
                matInput
                [matDatepicker]="picker"
                [max]="currentDate"
                (dateInput)="calculoEdad($event)"
                disabled
              />
              <mat-datepicker-toggle matSuffix [for]="picker">
              </mat-datepicker-toggle>
              <mat-datepicker #picker disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="example-full-width input" appearance="fill">
              <mat-label>Edad</mat-label>
              <input formControlName="edad" type="text" matInput />
            </mat-form-field>
          </div>
          <div class="formGroup">
            <mat-form-field class="formFile">
              <div class="myfilebrowser">
                <mat-toolbar>
                  <input
                    matInput
                    [(ngModel)]="myfilename"
                    readonly
                    formControlName="memberContactNo"
                  />
                  <button mat-raised-button class="fileButton" color="primary">
                    Buscar
                  </button>
                </mat-toolbar>
                <input
                  type="button"
                  #UploadFileInput
                  id="fileUpload"
                  (click)="openDialogFoto()"
                  name="fileUpload"
                  multiple="multiple"
                  accept="image/*"
                />
              </div>
            </mat-form-field>
            <div #divImagen class="divImagen">
              <img class="imagen" #mostrarImagen [src]="imagen" />
            </div>
          </div>
          <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
              <mat-label>Email</mat-label>
              <input
                formControlName="email"
                (ngModelChange)="clearEmail()"
                type="email"
                matInput
              />
            </mat-form-field>
            <div class="valid">
              <span
                class="advertenciaMensaje"
                *ngIf="!pacienteForm.get('email').value"
              >
                <mat-icon class="advertenciaIcono">report_problem</mat-icon>Si
                no se asigna un email, no le llegaran notificaciones que pueden
                ser importantes
              </span>
              <span
                style="color: rgb(236, 75, 72)"
                *ngIf="
                  pacienteForm.get('email').errors?.email &&
                  pacienteForm.get('email').touched
                "
              >
                El email no tiene el formato correcto
              </span>
              <span
                style="color: rgb(236, 75, 72)"
                *ngIf="errorEmail && pacienteForm.get('email').touched"
              >
                El email fue registrado por otro paciente
              </span>
            </div>
          </div>
          <div class="formGroup telefonoForm">
            <div class="tefonoPais">
              <mat-form-field appearance="fill" class="telefonoInput1">
                <mat-label>Teléfono país</mat-label>
                <mat-select formControlName="telPais">
                  <mat-option>
                    <ngx-mat-select-search
                      id="telPaisBuscador"
                      [noEntriesFoundLabel]="'Not found'"
                      [placeholderLabel]="'Buscador'"
                      [formControl]="telPaisBuscador"
                      name="filterPost"
                      (ngModelChange)="filtrarTelPais($event)"
                    >
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let tel of filtroTelefonoPais"
                    [value]="tel.codigo"
                  >
                    {{ tel.nombre }} ({{ tel.codigo }})
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="tefonoNumero">
              <mat-form-field
                class="example-full-width input telefonoInput2"
                appearance="fill"
              >
                <mat-label>Teléfono caract.</mat-label>
                <input formControlName="telCaract" type="text" matInput />
              </mat-form-field>
            </div>
            <div class="tefonoNumero">
              <mat-form-field
                class="example-full-width input telefonoInput3"
                appearance="fill"
              >
                <mat-label>Teléfono número</mat-label>
                <input formControlName="telNumero" type="text" matInput />
              </mat-form-field>
            </div>
          </div>

          <div class="formGroup telefonoForm">
            <div class="tefonoPais">
              <mat-form-field appearance="fill" class="telefonoInput1">
                <mat-label>Celular país</mat-label>
                <mat-select formControlName="celPais">
                  <mat-option>
                    <ngx-mat-select-search
                      id="celPaisBuscador"
                      [noEntriesFoundLabel]="'Not found'"
                      [placeholderLabel]="'Buscador'"
                      [formControl]="celPaisBuscador"
                      name="filterPost"
                      (ngModelChange)="filtrarCelPais($event)"
                    >
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let tel of filtroCelularPais"
                    [value]="tel.codigo"
                  >
                    {{ tel.nombre }} ({{ tel.codigo }})
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="valid">
                <span
                  style="color: rgb(236, 75, 72)"
                  *ngIf="
                    pacienteForm.get('celPais').errors?.required &&
                    pacienteForm.get('celPais').touched
                  "
                >
                  El teléfono país es requerido
                </span>
              </div>
            </div>
            <div class="tefonoCaract">
              <mat-form-field
                class="example-full-width"
                class="telefonoInput2"
                appearance="fill"
              >
                <mat-label>Celular caract.</mat-label>
                <input formControlName="celCaract" type="text" matInput />
              </mat-form-field>
              <div class="valid">
                <span
                  style="color: rgb(236, 75, 72)"
                  *ngIf="
                    pacienteForm.get('celCaract').errors?.required &&
                    pacienteForm.get('celCaract').touched
                  "
                >
                  La característica es requerida
                </span>
              </div>
            </div>
            <div class="tefonoNumero">
              <mat-form-field
                class="example-full-width"
                class="telefonoInput3"
                appearance="fill"
              >
                <mat-label>Celular número</mat-label>
                <input formControlName="celNumero" type="text" matInput />
              </mat-form-field>
              <div class="valid">
                <span
                  style="color: rgb(236, 75, 72)"
                  *ngIf="
                    pacienteForm.get('celNumero').errors?.required &&
                    pacienteForm.get('celNumero').touched
                  "
                >
                  El número de teléfono es requerido
                </span>
              </div>
            </div>
          </div>

          <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
              <mat-label>Código interno</mat-label>
              <input formControlName="codigoInterno" type="text" matInput />
            </mat-form-field>
          </div>

          <div *ngIf="!editarValor">
            <div class="formGroup">
              <mat-form-field
                class="example-full-width input"
                appearance="fill"
              >
                <mat-label>Password</mat-label>
                <input formControlName="password" type="password" matInput />
              </mat-form-field>
              <div class="valid">
                <span
                  style="color: rgb(236, 75, 72)"
                  *ngIf="
                    pacienteForm.get('password').errors?.required &&
                    pacienteForm.get('password').touched
                  "
                >
                  El password es requerido
                </span>
              </div>
            </div>

            <div class="formGroup">
              <mat-form-field
                class="example-full-width input"
                appearance="fill"
              >
                <mat-label>Repetir password</mat-label>
                <input
                  formControlName="repeatPassword"
                  type="password"
                  matInput
                />
              </mat-form-field>
              <div class="valid">
                <span
                  style="color: rgb(236, 75, 72)"
                  *ngIf="
                    pacienteForm.hasError('mismatch') &&
                    pacienteForm.get('repeatPassword').touched
                  "
                >
                  Los passwords no son iguales
                </span>
              </div>
            </div>
          </div>

          <div class="formGroup">
            <mat-slide-toggle
              formControlName="modificar"
              class="example-margin"
              color="primary"
              [checked]="checked"
              [disabled]="disabled"
              (change)="cambiarModificar($event)"
            >
              Modificación perfil
            </mat-slide-toggle>
          </div>

          <div class="formButton">
            <button *ngIf="!editarValor" mat-raised-button class="buttonForm">
              Crear
            </button>
            <button
              *ngIf="editarValor"
              mat-raised-button
              class="buttonForm Cancelar"
              routerLink="../../"
            >
              Cancelar
            </button>
            <button *ngIf="editarValor" mat-raised-button class="buttonForm">
              Editar
            </button>
          </div>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>
