import { PacienteService } from "app/services/paciente.service";
import {
  AfterViewInit,
  Component,
  Input,
  ViewChild,
  HostListener,
} from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { EstudioMedicoPacienteService } from "app/services/estudio-medico-paciente.service";
import { DatePipe } from "@angular/common";
import { Paciente } from "app/models/paciente.interface";
import { GlobalVariable } from "app/global/global";
import { environment } from "environments/environment.prod";
import { CornerstoneService } from "app/services/cornerstone.service";
import { ArchivoService } from "app/services/archivo.service";
import { ActivatedRoute } from "@angular/router";
import { ShowImagenComponent } from "../show-imagen/show-imagen.component";
import { MatDialog } from "@angular/material/dialog";
import { saveAs } from "file-saver";
import { Router } from "@angular/router";
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from "@angular/cdk/layout";

@Component({
  selector: "app-examns-dashboard",
  templateUrl: "./examns-dashboard.component.html",
  styleUrls: ["./examns-dashboard.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class ExamnsDashboardComponent implements AfterViewInit {
  //
  @Input("cols") cols: any[] = [];
  paciente: any;
  @Input() set response(value: any) {
    if (value) {
      this.iniciarEstudiosMedicos(value);
    }
  }
  @Input() set pacienteRes(value: any) {
    if (value) {
      this.paciente = value;
    }
  }
  isOpen = false;
  url: string = GlobalVariable.BASE_API_URL;
  urlXls: string = GlobalVariable.BASE_API_URL + "storage/images/paciente/";
  dataSource: any;
  sortedData = [];
  @ViewChild(MatSort) sort: MatSort;
  imageData: any;
  tipoestudios = environment.BASE_TYPEOFESTUDIOS;
  columnsFiles = ["fecha", "estudio", "medico", "centromedico", "acciones"];
  columnsFile = ["fecha", "archivo", "acciones"];
  cellResponsive = false;
  innerWidth = 0;
  historialMedico = false;

  //
  constructor(
    public dialog: MatDialog,
    private apiPaciente: PacienteService,
    private datePipe: DatePipe,
    private apiEMPS: EstudioMedicoPacienteService,
    private apiArchivo: ArchivoService,
    private route: ActivatedRoute,
    public responsive: BreakpointObserver,
    private router: Router
  ) {
    if (window.innerWidth > 900) {
      this.historialMedico = false;
    } else {
      this.historialMedico = true;
    }
  }

  //
  @HostListener("window:resize", ["$event"]) onResize(event) {
    if (event.target.innerWidth > 900) {
      this.historialMedico = false;
    } else if (event.target.innerWidth <= 900) {
      this.historialMedico = true;
    }
  }

  //
  ngAfterViewInit() {}

  //
  iniciarEstudiosMedicos(data: any) {
    data.sort((a, b) => {
      return new Date(b.fecha).getTime() - new Date(a.fecha).getTime();
    });
    data.forEach((element) => {
      let date = new Date(element.fecha);
      element.fecha = this.datePipe.transform(date, "dd/MM/yyyy");
      element.imagenCM = this.url + "centro-medico/" + element.imagenCM;
    });

    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
  }

  //
  async mostrarImagen(imagen: any) {
    const dialogRef = this.dialog.open(ShowImagenComponent, {
      width: "95%",
      height: "95%",
      data: imagen,
    });
    dialogRef.afterClosed().subscribe((res) => {});
  }

  //
  descargarImage(imagen: any) {
    let id = imagen.imagen.idPaciente;
    fetch(this.urlXls + id + "/" + imagen.id + "/" + imagen.imagen.nombre).then(
      function (response) {
        response.blob().then(function (blob) {
          let file = new File([blob], imagen.imagen.nombre, {
            type: "application/jpeg",
          });
          saveAs(file);
        });
      }
    );
  }

  //
  descargarPdf(imagen: any) {
    let id = imagen.imagen.idPaciente;
    fetch(this.urlXls + id + "/" + imagen.id + "/" + imagen.imagen.nombre).then(
      function (response) {
        response.blob().then(function (blob) {
          let file = new File([blob], imagen.imagen.nombre, {
            type: "application/pdf",
          });
          saveAs(file);
        });
      }
    );
  }

  //
  descargarExcel(imagen: any) {
    let id = imagen.imagen.idPaciente;
    fetch(this.urlXls + id + "/" + imagen.id + "/" + imagen.imagen.nombre).then(
      function (response) {
        response.blob().then(function (blob) {
          let file = new File([blob], imagen.imagen.nombre, {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(file);
        });
      }
    );
  }

  //
  cargarImagen(event) {
    (event.target as HTMLImageElement).style.display = "flex";
  }

  //
  select(expandedElement, element) {
    //this.router.navigate(['/seccion-paciente/estudios-medicos/detalle',element.id]);
  }

  descargarPDFEstudioMedico(estudioMedicoPaciente) {
    const em = {
      ...estudioMedicoPaciente,
      nombreP: this.paciente.nombreApellido,
    };

    this.apiEMPS.descargarPDFEstudioMedico(em);
  }
}
