<mat-toolbar>
  <button
    mat-icon-button
    class="example-icon"
    aria-label="Example icon-button with menu icon"
    (click)="sideBar()"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <img src="assets/img/logo.png" height="85" class="p-mr-2" />
  <span class="example-spacer"></span>
  <button
    mat-button
    class="example-icon favorite-icon"
    aria-label="Example icon-button with heart icon"
    [matMenuTriggerFor]="menu"
  >
    <img src="assets/img/ico_paciente.svg" />
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item style="width: auto" [routerLink]="['/user/Test']">
      Perfil
    </button>
    <button mat-menu-item style="width: auto">Salir</button>
  </mat-menu>
</mat-toolbar>
<!--
<p-menubar >
  <ng-template pTemplate="start">
    <img src="assets/img/logo.png" height="85" class="p-mr-2" />
    <button type="button" mat-button style="vertical-align: top" (click)="sideBar()">
      <mat-icon>menu</mat-icon>
    </button>
  </ng-template>
  <button pButton label="Perfil" [routerLink]="['/user/Test']"></button>
  <button pButton label="Login" class="p-button-danger" [routerLink]="['/auth/login']"></button>

  
</p-menubar>
-->
