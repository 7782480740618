import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validator, Validators } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { RecuperarContraseñaService } from 'app/services/recuperar-contraseña.service';
import { Router,ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { SpinnerService } from 'app/services/spinner.service';

@Component({
  selector: 'app-cambiar-contrasenia',
  templateUrl: './cambiar-contrasenia.component.html',
  styleUrls: ['./cambiar-contrasenia.component.scss']
})

export class CambiarContraseniaComponent implements OnInit {


  //
  resetPasswordForm:FormGroup;
  resetForm:any;
  token:string;


  //
  constructor(
    private apiRCS:RecuperarContraseñaService,
    private actRoute: ActivatedRoute,
    private router:Router,
    private snackBar:MatSnackBar,
    private spinService:SpinnerService,
  ) {
    this.spinService.mostrarSpinner();
    this.iniciarFormulario();
    this.token = this.actRoute.snapshot.paramMap.get("token");
    this.apiRCS.tokenRecuperacionUsuarioPanel(this.token).subscribe(
      data => {
        this.iniciarFormularioActualizado(data);
        this.spinService.ocultarSpinner();
      },
      error => {
        this.spinService.ocultarSpinner();
        this.router.navigate(['/auth/login-panel']);
      }
    )
  }


  //
  ngOnInit(): void {}


  //
  iniciarFormulario(){
    this.resetPasswordForm = new FormGroup({
      email: new FormControl('',[Validators.required,Validators.email]),
      password : new FormControl('',Validators.required),
      repeatPassword : new FormControl('',Validators.required)
    },{ validators: passwordMatcher });
  }


  //
  iniciarFormularioActualizado(data){
    this.resetForm = data; 
    this.resetPasswordForm.setValue({
      email : this.resetForm.email,
      password : '',
      repeatPassword : '',
    });
  }


  //
  validarPassword(form:any){
    if(this.resetPasswordForm.valid){
      this.cambiarPassword(form)
    }
    else{
      this.openSnackBar("Hay un error, los datos no son correctos","error");
    }
  }


  //
  cambiarPassword(form:any){
    this.spinService.mostrarSpinner();
    this.apiRCS.cambiarContraseña(form,this.token).subscribe(
      data=>{
        this.spinService.ocultarSpinner();
        this.openSnackBar("Modificación exitosa","exito");
        this.router.navigate(['auth/login-panel']);
      },
      error=>{
        this.spinService.ocultarSpinner();
        this.openSnackBar("Error al intentar cambiar la constraseña","error");
        this.router.navigate(['auth/login-panel']);
      }
    )
  }


  //
  openSnackBar(message:string, action:string) {
    this.snackBar.openFromComponent(NotificationComponent,{
      data: {
        message : message,
        action : action
      },
      duration:2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass : [action]
    });
  }


  get password() {  return this.resetPasswordForm.get('password'); }
  get repeatPassword() {  return this.resetPasswordForm.get('repeatPassword'); }
}


function passwordMatcher(control: AbstractControl): { [key: string]: boolean } | null {
  const password = control.get('password');
  const repeatPassword = control.get('repeatPassword');
  return password.value != repeatPassword.value ? { 'passwordError': true } : null;
}