import { PacienteService } from 'app/services/paciente.service';
import { ElementRef , Component , OnInit , ViewChild, Input, Output  } from '@angular/core';
import { UsuarioPanel } from 'app/models/usuarioPanel.interface';
import { Router,ActivatedRoute } from '@angular/router';
import { Paciente } from 'app/models/paciente.interface';
import { GlobalVariable } from 'app/global/global';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header-paciente',
  templateUrl: './header-paciente.component.html',
  styleUrls: ['./header-paciente.component.scss']
})
export class HeaderPacienteComponent implements OnInit {


  //
  @ViewChild('dropdown') dropdown: ElementRef;
  imagen:string;
  menu:string;
  paciente:any;
  @Output() messageEvent = new EventEmitter<string>();
  @Input() set response(value: any) {
    if(value) {
      this.mostrarLogin(value);
    }
  }


  //
  constructor(
    private pacienteService:PacienteService,
    private router:Router,
  ) { }


  //
  ngOnInit(): void {}


  //
  mostrarLogin(data:any){
    this.paciente = data.paciente;
    this.imagen = `${GlobalVariable.BASE_API_URL}storage/images/paciente/${this.paciente.imagen}`;
  }


  //
  logout(){
    this.pacienteService.logout();
  }


  //
  botonDesplegableShadow(){
    this.menu = (this.menu == 'true') ? 'false' : 'true';
    this.messageEvent.emit(this.menu);
  }


}
