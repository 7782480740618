<app-indicadores-panel [setResponse]="response"></app-indicadores-panel>
<ng-container *ngIf="dataSource.length > 0; else emptyState">
  <div class="appointments-container">
    <h2 class="appointments-title">Turnos del día: {{today}}</h2>
    <div class="appointments-list">
      <div class="appointment-card" *ngFor="let element of dataSource">
        <div class="appointment-date">
          <span class="day">{{ element.fecha_desde | date : "dd" }}</span>
          <span class="month">{{
            element.fecha_desde | date : "MMM" | uppercase
          }}</span>
        </div>
        <div class="appointment-details">
          <p class="time">{{ element.fecha_desde | date : "HH:mm" }}</p>
          <p class="patient">Paciente: {{ element.nombrePaciente }}</p>
          <p class="doctor">{{ element.nombreMedico }}</p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #emptyState>
  <div class="emtpyStateContainer"><p>No hay turnos para hoy</p></div>
</ng-template>
