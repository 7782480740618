import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-right-arrow',
  templateUrl: './right-arrow.component.html',
  styleUrls: ['./right-arrow.component.scss']
})
export class RightArrowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
