import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ListarPacienteComponent } from "./listar-paciente/listar-paciente.component";
import { EditarPacienteComponent } from "./editar-paciente/editar-paciente.component";
import { EstudiosMedicosComponent } from "../estudios-medicos-paciente/estudios-medicos/estudios-medicos.component";
import { EditarEstudiosMedicosComponent } from "../estudios-medicos-paciente/editar-estudios-medicos/editar-estudios-medicos.component";
import { BusquedaEstudiosMedicosComponent } from "../busqueda-estudios-medicos/busqueda-estudios-medicos/busqueda-estudios-medicos.component";
import { DetalleEstudiosMedicosComponent } from "../detalle-estudios-medicos/detalle-estudios-medicos/detalle-estudios-medicos.component";

const routes: Routes = [
  {
    path: "seccion-panel",
      children: [
        {
          path: "paciente",
          pathMatch: "full",
          component: ListarPacienteComponent,
        },
        {
          path: "paciente/editar/:id",
          pathMatch: "full",
          component: EditarPacienteComponent,
        }
      ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PacienteRoutingModule {}