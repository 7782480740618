<div class="dashboardShadow" #shadow (click)="quitarShadow()"></div>
<app-right-arrow
  *ngIf="!mobile"
  (click)="toggleSidebar()"
  class="right-arrow"
  [ngClass]="{
    opened: opened
  }"
></app-right-arrow>

<div
  class="dashboardContent mat-elevation-z4"
  [ngClass]="{
    opened: opened,
    closed: !opened && !mobile
  }"
  #dashboardContent
>
  <div class="init">
    <div class="divImg">
      <img src="assets/img/logo.png" class="img" *ngIf="opened || mobile" />
      <img
        src="assets/img/CentralMedSinTexto.png"
        class="img logoSinTexto"
        *ngIf="!mobile && !opened"
      />
    </div>

    <mat-nav-list>
      <a
        mat-list-item
        [routerLink]="['/seccion-panel/home-panel']"
        [matTooltip]="!mobile && !opened ? 'Inicio' : ''"
      >
        <img src="assets/img/ico_home.svg" class="icon" />
        <span class="nav-caption">Inicio</span>
      </a>
      <a
        mat-list-item
        [matMenuTriggerFor]="appMenu"
        *ngIf="isAdmin"
        [matTooltip]="!mobile && !opened ? 'Configuracion' : ''"
      >
        <img src="assets/img/ico_configuracion.svg" class="icon" />
        <span class="nav-caption">Configuracion</span>
        <mat-icon class="arrow_right">arrow_right</mat-icon>
      </a>
      <a
        mat-list-item
        [matMenuTriggerFor]="subMenu2"
        [matTooltip]="!mobile && !opened ? 'Pacientes' : ''"
      >
        <img src="assets/img/ico_paciente.svg" class="icon" />
        <span class="nav-caption">Pacientes</span>
        <mat-icon class="arrow_right">arrow_right</mat-icon>
      </a>
      <a
        mat-list-item
        [routerLink]="['/seccion-panel/medico']"
        [matTooltip]="!mobile && !opened ? 'Medicos' : ''"
      >
        <img src="assets/img/ico_medico.svg" class="icon" />
        <span class="nav-caption">Medicos</span>
      </a>
      <a
        mat-list-item
        *ngIf="turnoHabilitado == 1 && isAdmin"
        [routerLink]="['/seccion-panel/turnos']"
        [matTooltip]="!mobile && !opened ? 'Turnos' : ''"
      >
        <img src="assets/img/ico_calendarioSmall.svg" class="icon" />
        <span class="nav-caption">Turnos</span>
      </a>
      <a mat-list-item *ngIf="turnoHabilitado != 1 || !isAdmin" class="opacity">
        <img src="assets/img/ico_calendarioSmall.svg" class="icon" />
        <span class="nav-caption">Turnos</span>
      </a>
      <a
        mat-list-item
        [routerLink]="['/seccion-panel/informes']"
        [matTooltip]="!mobile && !opened ? 'Informes' : ''"
      >
        <img src="assets/img/ico_configuracion.svg" class="icon" />
        <span class="nav-caption">Informes</span>
      </a>
    </mat-nav-list>

    <mat-menu class="desplegable" #appMenu="matMenu">
      <mat-nav-list>
        <a mat-list-item [routerLink]="['/seccion-panel/general']">
          <img src="assets/img/ico_configuracion.svg" class="icon" />
          <span class="nav-caption">General</span>
        </a>
        <a class="menuMat" mat-menu-item [matMenuTriggerFor]="subMenu">
          <div class="divIcon">
            <img src="assets/img/ico_hospital.svg" class="icon" />
          </div>
          <span class="nav-conf">Centro Medico</span>
        </a>
        <a mat-list-item [routerLink]="['/seccion-panel/estudio-medico']">
          <img src="assets/img/ico_informe.svg" class="icon" />
          <span class="nav-caption">Estudios Medicos</span>
        </a>
        <a mat-list-item [routerLink]="['/seccion-panel/obra-social']">
          <img src="assets/img/ico_obrasocial.svg" class="icon" />
          <span class="nav-caption">Obra Social</span>
        </a>
        <a mat-list-item [routerLink]="['/seccion-panel/tipo-documento']">
          <img src="assets/img/ico_documento.svg" class="icon" />
          <span class="nav-caption">Tipo Documento</span>
        </a>
        <a mat-list-item [routerLink]="['/seccion-panel/especialidad']">
          <img src="assets/img/ico_documento.svg" class="icon" />
          <span class="nav-caption">Especialidad</span>
        </a>
        <a mat-list-item [routerLink]="['/seccion-panel/usuario-panel']">
          <img src="assets/img/ico_paciente.svg" class="icon" />
          <span class="nav-caption">Usuario Panel</span>
        </a>
      </mat-nav-list>
    </mat-menu>

    <mat-menu class="subDesplegable" #subMenu="matMenu">
      <mat-nav-list>
        <a mat-list-item [routerLink]="['/seccion-panel/centro-medico']">
          <img src="assets/img/ico_hospital.svg" class="icon" />
          <span class="nav-caption">Centro Medico</span>
        </a>
        <a mat-list-item [routerLink]="['/seccion-panel/sucursal']">
          <img src="assets/img/ico_sucursal.svg" class="icon" />
          <span class="nav-caption">Sucursal</span>
        </a>
      </mat-nav-list>
    </mat-menu>

    <mat-menu class="subDesplegable" #subMenu2="matMenu">
      <mat-nav-list>
        <a mat-list-item [routerLink]="['/seccion-panel/paciente']">
          <img src="assets/img/ico_paciente.svg" class="icon" />
          <span class="nav-caption">Pacientes</span>
        </a>
        <a
          mat-list-item
          [routerLink]="['/seccion-panel/paciente/busqueda-estudios-medicos']"
        >
          <img src="assets/img/ico_informe.svg" class="icon" />
          <span class="nav-caption">Estudios medicos</span>
        </a>
      </mat-nav-list>
    </mat-menu>
  </div>
  <div class="version">
    <span class="versionTexto">VERSION</span>
    <span class="versionNumero">{{ VERSION }}</span>
  </div>
</div>
