import { ElementRef,Component, OnInit,Inject,ViewChild,NgModule } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-foto-dialog',
  templateUrl: './foto-dialog.component.html',
  styleUrls: ['./foto-dialog.component.scss']
})
export class FotoDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<FotoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public mensaje: string
  ) { }

  ngOnInit(): void {}

  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  @ViewChild('divImagen') divImagen: ElementRef;
  @ViewChild('divFotoM') fotoM: ElementRef;
  @ViewChild('divFotoH') fotoH: ElementRef;
  imagen = '';
  myfilename = 'Seleccionar archivo';
  foto = "";
  img = "";

  onClickNo(){
    this.dialogRef.close({data:this.foto,img:this.img});
  }

  fileChangeEvent(fileInput: any) {
    
    
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.foto = fileInput.target.files[0];
      this.myfilename = '';
      Array.from(fileInput.target.files).forEach((file: File) => {
        this.myfilename += file.name + ',';
      });

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (event:any) => {

          this.imagen = e.target.result;
          this.img = this.imagen;
          const imgBase64Path = e.target.result;
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);

      // Reset File Input to Selct Same file again
      this.uploadFileInput.nativeElement.value = "";
    } else {
      this.myfilename = 'Seleccionar archivo';
    }
  }

  elegirFoto(event:any){
    this.fotoM.nativeElement.style.border = "none";
    this.fotoH.nativeElement.style.border = "none";
    this.foto = event.target;
    event.target.parentElement.style.border = "3px solid #05CE7C"; 
    event.target.parentElement.style.borderRadius = "3px"; 
    
  }
}
