import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListarPacienteComponent } from './listar-paciente/listar-paciente.component';
import { SharedModule } from 'primeng/api';
import { MaterialModule } from 'app/material/material.module';
import { PacienteRoutingModule } from './paciente-routing.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { CrearFormPacienteComponent } from './crear-form-paciente/crear-form-paciente.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { EditarPacienteComponent } from './editar-paciente/editar-paciente.component';
import { SharedPanelModule } from '../shared-panel/shared-panel.module';
import { PacienteSharedModule } from '../../paciente-seccion/paciente-shared/home-paciente.module';


@NgModule({
  declarations: [
    ListarPacienteComponent,
    CrearFormPacienteComponent,
    EditarPacienteComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    PacienteRoutingModule,
    FormsModule,
    SharedPanelModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    PacienteSharedModule,
  ],
  providers: [
    DatePipe,
  ],
})
export class PacienteModule { }
