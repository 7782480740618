import {
  Component,
  OnInit,
  Inject,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "app/shared/confirm-dialog/confirm-dialog.component";
import { FormDialogComponent } from "app/shared/form-dialog/form-dialog.component";
import { MedicoFormDialogComponent } from "app/shared/medico-form-dialog/medico-form-dialog.component";
import { CentroMedicoService } from "app/services/centro-medico.service";
import { MedicoService } from "app/services/medico.service";
import { EstudioMedicoService } from "app/services/estudio-medico.service";
import { EstudioMedicoPaciente } from "app/models/estudioMedicoPaciente.interface";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { EstudioMedicoPacienteService } from "app/services/estudio-medico-paciente.service";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NotificationComponent } from "app/shared/notification/notification.component";
import { ShowImagenComponent } from "app/shared/show-imagen/show-imagen.component";
import { GlobalVariable } from "app/global/global";
import { Paciente } from "app/models/paciente.interface";
import { SpinnerService } from "app/services/spinner.service";
import { saveAs } from "file-saver";
import { Location } from "@angular/common";
// import { EditorComponent } from "@tinymce/tinymce-angular";

@Component({
  selector: "app-form-estudios-medicos",
  templateUrl: "./form-estudios-medicos.component.html",
  styleUrls: ["./form-estudios-medicos.component.scss"],
})
export class FormEstudiosMedicosComponent implements OnInit {
  //
  funcionCorrecta: any;
  urlM: string = GlobalVariable.BASE_API_URL + "storage/images/medico/";
  urlCM: string = GlobalVariable.BASE_API_URL + "storage/images/centro-medico/";
  urlXls: string = GlobalVariable.BASE_API_URL + "storage/images/paciente/";
  @Output() listar: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() editar: boolean;
  @Input() medicoSection: boolean;
  imagen = "";
  medicoSelecionado: any = null;
  panelOpenState = false;
  myfilename = "Seleccionar archivos";
  listaCentrosMedicos: any[];
  filtroCentrosMedicos: any[];
  listaMedicos: any[];
  filtroMedicos: any[];
  listaEstudioMedico: any[];
  filtroEstudioMedico: any[];
  listaTelefonoPais: any[];
  listaTipoDocumento: any[];
  listaPlantillasEstudioMedico: any[];
  estudioMedicoPaciente: any;
  listFile: any[] = [];
  files: File[] = [];
  descriptions: string[] = [];
  idPaciente: number;
  idEstudioMedico: number;
  date: Date;
  paciente: Paciente = null;
  ima = [];
  primEstudio: any;
  primMedico: any;
  primCentro: any;
  comentarios: any;
  cargando: string = "cargando";
  estudioMedicoPacienteForm = new FormGroup({
    id: new FormControl(""),
    idPaciente: new FormControl(""),
    estudioMedico: new FormControl("", Validators.required),
    medico: new FormControl("", Validators.required),
    fecha: new FormControl(new Date(), Validators.required),
    centroMedico: new FormControl("", Validators.required),
    plantilla: new FormControl(""),
    observaciones: new FormControl(""),
    memberContactNo: new FormControl("Seleccionar archivos"),
  });
  public centroMedico: FormControl = new FormControl();
  public estudioMedico: FormControl = new FormControl();
  public medico: FormControl = new FormControl();

  @Input() set response(value: any) {
    if (value) {
      this.iniciarDatos(value);
    }
  }
  @Input() set responseComentarios(value: any) {
    if (value) {
      this.comentarios = value;
    }
  }

  public init = {
    plugins: "lists link image table help wordcount fullscreen",
    toolbar:
      "undo redo | blocks fontfamily fontsize | alignleft aligncenter alignright alignjustify  | bold italic underline strikethrough | fullscreen",
    language: "es",
    placeholder: "Observaciones...",
    menu: {
      file: {
        title: "Archivo",
        items: "print",
      },
    },
    license_key: "gpl",
    promotion: false,
  };

  //
  constructor(
    public dialog: MatDialog,
    private apiCentroMedicoService: CentroMedicoService,
    private apiEstudioMedicoService: EstudioMedicoService,
    private apiMedicoService: MedicoService,
    private api: EstudioMedicoPacienteService,
    private router: Router,
    private snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private _location: Location
  ) {}

  //
  ngOnInit(): void {
    this.panelOpenState = this.editar ? true : false;
    this.subscripcionPlantillas();
  }

  //
  iniciarDatos(value: any) {
    this.paciente = value.paciente;
    this.listarMedico(value.medicos);
    this.listarCentroMedico(value.centrosMedicos);
    this.listarEstudioMedico(value.estudiosMedicos);
    this.listarTelefonoPais(value.listaTelefonoPais);
    this.listarTipoDocumento(value.listaTipoDocumento);
    this.listarPlantillasEstudioMedico(value.plantillasEstudiosMedicos);
    this.iniciarFormulario();
    if (this.editar) {
      this.actualizarFormulario(value.estudioMedicoPaciente);
      this.listarArchivos(value.archivos);
    }
    if (value.medicoId) {
      this.estudioMedicoPacienteForm.patchValue({
        medico: value.medicoId,
      });
    }
  }

  //
  iniciarFormulario() {
    this.estudioMedicoPacienteForm.patchValue({ idPaciente: this.paciente.id });
  }

  //
  actualizarFormulario(data) {
    this.estudioMedicoPaciente = data;
    this.estudioMedicoPacienteForm.patchValue({
      id: this.estudioMedicoPaciente.id,
      idPaciente: this.paciente.id,
      estudioMedico: this.estudioMedicoPaciente.estudioMedico,
      medico: this.estudioMedicoPaciente.medico,
      fecha: new Date(this.estudioMedicoPaciente.fecha),
      centroMedico: this.estudioMedicoPaciente.centroMedico,
      observaciones: this.estudioMedicoPaciente.observaciones
        ? this.estudioMedicoPaciente.observaciones
        : "",
      memberContactNo: "Seleccionar archivos",
    });
    this.medicoSelecionado = this.listaMedicos.find(
      (elemento) => elemento.id == this.estudioMedicoPaciente.medico
    );
  }

  //
  filtrarCentroMedico(event: any) {
    let valorString: string = this.centroMedico.value;
    this.filtroCentrosMedicos = [];

    for (let ops of this.listaCentrosMedicos) {
      if (ops.nombre.toUpperCase().includes(valorString.toUpperCase())) {
        let nuevoCM: any = ops;
        this.filtroCentrosMedicos.push(nuevoCM);
      }
    }
  }

  //
  filtrarMedico(event: any) {
    let valorString: string = this.medico.value;
    this.filtroMedicos = [];

    for (let ops of this.listaMedicos) {
      if (
        ops.nombreCompleto.toUpperCase().includes(valorString.toUpperCase())
      ) {
        let nuevoM: any = ops;
        this.filtroMedicos.push(nuevoM);
      }
    }
  }

  //
  filtrarEstudioMedico(event: any) {
    let valorString: string = this.estudioMedico.value;
    this.filtroEstudioMedico = [];

    for (let ops of this.listaEstudioMedico) {
      if (ops.texto.toUpperCase().includes(valorString.toUpperCase())) {
        let nuevoEM: any = ops;
        this.filtroEstudioMedico.push(nuevoEM);
      }
    }
  }

  //
  openDialog() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "50%",
      data: "Estudio medico",
    });
    dialogRef.afterClosed().subscribe((res) => {
      //
    });
  }

  //
  agregarEstudioMedico() {
    const dialogRef = this.dialog.open(FormDialogComponent, {
      width: "50%",
      data: "Estudio medico",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.listarEstudioMedicoApi();
      }
    });
  }

  //
  agregarMedico() {
    let data = {
      titulo: "Medico",
      listaTelefonoPais: this.listaTelefonoPais,
      listaTipoDocumento: this.listaTipoDocumento,
    };
    const dialogRef = this.dialog.open(MedicoFormDialogComponent, {
      width: "50%",
      data: data,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.listarMedicoApi();
      }
    });
  }

  validarMedicoEmail(event: any) {
    this.medicoSelecionado = this.listaMedicos.find(
      (elemento) => elemento.id == event
    );
  }

  //
  listarTelefonoPais(data) {
    this.listaTelefonoPais = data;
  }

  //
  listarTipoDocumento(data) {
    this.listaTipoDocumento = data;
  }

  //
  listarPlantillasEstudioMedico(data) {
    this.listaPlantillasEstudioMedico = data;
  }

  //
  listarMedico(data) {
    this.listaMedicos = data;
    this.filtroMedicos = data;
    this.primMedico = data;
  }

  //
  listarEstudioMedico(data) {
    this.listaEstudioMedico = data;
    this.filtroEstudioMedico = data;
    this.primEstudio = data[0];
  }

  //
  listarCentroMedico(data) {
    this.listaCentrosMedicos = data;
    this.filtroCentrosMedicos = data;
    this.primCentro = data[0];
  }

  //
  listarMedicoApi() {
    this.apiMedicoService.listar().subscribe((data) => {
      this.listarMedico(data);
      this.spinnerService.ocultarSpinner();
    });
  }

  //
  listarEstudioMedicoApi() {
    this.apiEstudioMedicoService.listar().subscribe((data) => {
      this.listarEstudioMedico(data);
      this.spinnerService.ocultarSpinner();
    });
  }

  //
  listarCentroMedicoApi() {
    this.apiCentroMedicoService.listar().subscribe((data) => {
      this.listarCentroMedico(data);
      this.spinnerService.ocultarSpinner();
    });
  }

  //
  guardarEstudioMedicoPaciente(form: EstudioMedicoPaciente) {
    this.funcionCorrecta = this.editar
      ? this.editarEstudioMedicoPaciente(form)
      : this.crearEstudioMedicoPaciente(form);
  }

  //
  crearEstudioMedicoPaciente(form: any) {
    if (this.estudioMedicoPacienteForm.valid) {
      this.spinnerService.mostrarSpinner();
      this.api.crear(this.listFile, form, this.descriptions).subscribe(
        () => {
          this.openSnackBar("Guardado exitoso", "exito");
          this.listar.emit();
          this.resetearFormulario();
        },
        () => {
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Guardado fallido", "error");
        }
      );
    } else {
      this.openSnackBar("Guardado fallido", "error");
    }
  }

  //
  editarEstudioMedicoPaciente(form: any) {
    if (this.estudioMedicoPacienteForm.valid) {
      this.spinnerService.mostrarSpinner();
      this.api.editar(this.listFile, form, this.descriptions).subscribe(
        () => {
          this._location.back();

          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Editado exitoso", "exito");
        },
        () => {
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Editado fallido", "error");
        }
      );
    } else {
      this.openSnackBar("Editado fallido", "error");
    }
  }

  //
  resetearFormulario() {
    this.listFile = [];
    this.descriptions = [];
    this.medicoSelecionado = null;
    this.estudioMedicoPacienteForm.patchValue({
      memberContactNo:
        this.estudioMedicoPacienteForm.get("memberContactNo").value,
      fecha: new Date(),
      medico: "",
      centroMedico: "",
      estudioMedico: "",
      observaciones: "",
    });
    this.panelOpenState = false;
  }

  //
  openSnackBar(message: string, action: string) {
    this.snackBar.openFromComponent(NotificationComponent, {
      data: {
        message: message[0].toUpperCase() + message.slice(1),
        action: action,
      },
      duration: 2500,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: [action],
    });
  }

  //
  ocultarMostrar() {
    this.panelOpenState = this.panelOpenState == true ? false : true;
  }

  //
  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      Array.from(fileInput.target.files).forEach((file: File) => {
        this.listFile.push(file);
        this.descriptions.push("");
      });
    } else {
      this.myfilename = "Seleccionar archivo";
    }
  }

  //
  descriptionChangeEvent(event: Event, i) {
    let input = (event.target as HTMLInputElement).value;
    this.descriptions[i] = input;
  }

  //
  borrarImagen(i) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "50%",
      data: "archivo",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.descriptions.splice(i, 1);
        this.listFile.splice(i, 1);
      }
    });
  }

  //
  listarArchivos(data) {
    this.listFile = data;
  }

  //
  backClicked(event: MouseEvent) {
    event.preventDefault();

    this._location.back();
  }

  //
  async mostrarImagen(imagen: any) {
    const dialogRef = this.dialog.open(ShowImagenComponent, {
      width: "95%",
      height: "95%",
      data: imagen,
    });

    dialogRef.afterClosed().subscribe((res) => {});
  }

  //
  descargarImage(imagen: any) {
    fetch(
      `${this.urlXls}${this.paciente.id}/${this.estudioMedicoPaciente.id}/${imagen.nombre}`
    ).then(function (response) {
      response.blob().then(function (blob) {
        let file = new File([blob], imagen.nombre, {
          type: "application/jpeg",
        });
        saveAs(file);
      });
    });
  }

  //
  descargarPdf(imagen: any) {
    fetch(
      `${this.urlXls}${this.paciente.id}/${this.estudioMedicoPaciente.id}/${imagen.nombre}`
    ).then(function (response) {
      response.blob().then(function (blob) {
        let file = new File([blob], imagen.nombre, { type: "application/pdf" });
        saveAs(file);
      });
    });
  }

  //
  descargarExcel(imagen: any) {
    fetch(
      `${this.urlXls}${this.paciente.id}/${this.estudioMedicoPaciente.id}/${imagen.nombre}`
    ).then(function (response) {
      response.blob().then(function (blob) {
        let file = new File([blob], imagen.nombre, {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(file);
      });
    });
  }

  isValidImage(url: string): boolean {
    console.log("url", url);
    /*if (!url) {
        return false;
    }
    let image = new Image();
    image.src = url;
    let complete = image.complete;
    
    return true;
    */
    return true;
  }

  subscripcionPlantillas() {
    this.estudioMedicoPacienteForm.get("plantilla").valueChanges.subscribe({
      next: (value) => {
        const content =
          this.listaPlantillasEstudioMedico.filter((p) => p.id === value)[0]
            ?.content ?? "";

        this.estudioMedicoPacienteForm.patchValue({
          observaciones: content,
        });
      },
    });
  }
}
