import { Injectable } from '@angular/core';
import { EstudioMedico } from 'app/models/estudioMedico.interface';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable,Subject, from } from 'rxjs';
import { GlobalVariable } from 'app/global/global';

@Injectable({
  providedIn: 'root'
})
export class EstudioMedicoService {

  url:string = GlobalVariable.BASE_API_URL+"api/estudio-medico/";
  private estudioMedicoSubject = new Subject<any>();

  constructor(private http:HttpClient) { }

  listar():Observable<any>{
    return this.http.get(this.url+"listar");
  }

  crear(form:EstudioMedico){
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    formData.append("texto", form.texto.trim());
    return this.http.post(this.url+"crear", formData, {headers:headers});
  }

  borrar(form:EstudioMedico):Observable<any>{
    return this.http.delete(this.url+"borrar/"+form.id);
  }

  mostrar(id):Observable<any>{
    return this.http.get(this.url+"mostrar/"+id);
  }

  editar(form:EstudioMedico):Observable<any>{
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    formData.append("id",form.id.toString());
    formData.append("texto",form.texto.trim());
    return this.http.post(this.url+"editar",formData, {headers:headers});
  }

  cantidad(){
    return this.http.get(this.url+"cantidad");
  }

  //
  getEstudioMedico = this.estudioMedicoSubject.asObservable(); 

  //
  emitChangeEstudioMedico(value:any) {
    this.estudioMedicoSubject.next(value);
  }
}
