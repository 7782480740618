<div class="profile row" *ngIf="user">
  <div class="grid">
    <div class="col-2">
      <img
        [src]="imgURL ? imgURL : 'assets/img/ico_paciente.svg'"
        class="profile-img"
      />
    </div>
    <div class="col-3">
      <div class="row profile-section-row">
        <h1 class="username">{{ user.username }}</h1>
        <h3 class="mat-headline username">DNI {{ user.username }}</h3>
        <h3 class="mat-headline username">
          Correo electrónico{{ user.username }}
        </h3>
        <h3 class="mat-headline username">Obra social{{ user.username }}</h3>
        <h3 class="mat-headline username">Teléfono {{ user.username }}</h3>
        <h3 class="mat-headline username">Celular {{ user.username }}</h3>
        <div>
          <button mat-button style="background-color: #05ce7c; color: #ffffff">
            Editar perfil
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
