import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListarUsuarioPanelComponent } from './usuario-panel-listar/usuario-panel-listar.component';
import { EditarUsuarioPanelComponent } from './usuario-panel-editar/usuario-panel-editar.component';
import { SharedModule } from 'primeng/api';
import { MaterialModule } from 'app/material/material.module';
import { UsuarioPanelRoutingModule } from './usuario-panel-routing.module';
import { SharedPanelModule } from '../shared-panel/shared-panel.module';
import { FormUsuarioPanelComponent } from './form-usuario-panel/form-usuario-panel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ListarUsuarioPanelComponent,
    EditarUsuarioPanelComponent,
    FormUsuarioPanelComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    UsuarioPanelRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedPanelModule,
  ]
})
export class UsuarioPanelModule { }
