import { ElementRef,Component, OnInit,Inject,ViewChild,NgModule } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup,FormControl,Validator, Validators } from '@angular/forms';
import { PacienteService } from 'app/services/paciente.service';
import { Paciente } from 'app/models/paciente.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from '../notification/notification.component'; 
import { environment } from 'environments/environment.prod';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { MedicoService } from 'app/services/medico.service';
import { Medico } from 'app/models/medico.interface';
import { SpinnerService } from 'app/services/spinner.service';

@Component({
  selector: 'app-contacto-dialog',
  templateUrl: './contacto-dialog.component.html',
  styleUrls: ['./contacto-dialog.component.scss']
})
export class ContactoDialogComponent implements OnInit {


  //
  titulo_contacto:string='Contacto';
  paciente:Paciente;
  medico:Medico;
  contactoForm = new FormGroup({
    texto : new FormControl('',Validators.required),
    filesUpload : new FormControl(null, FileUploadValidators.filesLimit(5))
  })
  public uploadedFiles: Array<File> = [];


  //
  constructor(
    private snackBar:MatSnackBar,
    private pacienteService:PacienteService,
    private medicoService:MedicoService,
    private spinnerService:SpinnerService,
    public dialogRef: MatDialogRef<ContactoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public mensaje: any
  ) { 
    this.titulo_contacto = environment.BOTON_CONTACTO;
  }


  //
  ngOnInit(): void {
    this.mostrarUsuario();
  }


  //
  mostrarUsuario(){
    if(this.mensaje.tipoUsuario == 'paciente') {
      this.paciente = this.mensaje.usuario;
    }
    else{
      this.medico = this.mensaje.usuario;
    }
  }


  //
  enviarEmail(form:FormGroup){
    if(this.contactoForm.valid){
      this.spinnerService.mostrarSpinner();
      let usuario:any = (this.mensaje.tipoUsuario == 'paciente') ? this.paciente : this.medico; 
      let texto = this.contactoForm.get('texto').value;
      let filesUpload = this.contactoForm.get('filesUpload').value;
      let fileSaleSize = 0;
      if(filesUpload){
        for(let i = 0; i < filesUpload.length; i++) fileSaleSize += filesUpload[i].size;
      }
      if(fileSaleSize > 10485760){
        return false;
      }
      this.enviarMensajeDeContacto(usuario.id,texto,filesUpload,fileSaleSize);
    }
    else{
      this.openSnackBar("Email fallido","error");
    }
  }


  //
  enviarMensajeDeContacto(id,texto,filesUpload,fileSaleSize){
    if(this.mensaje.tipoUsuario == 'paciente'){
      this.pacienteService.emailContacto(id,texto,filesUpload,fileSaleSize).subscribe(
        data => {
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Email exitoso","exito");
          this.onClickNo();
        },
        error => {
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Email fallido","error");
        }
      )
    }
    else{
      this.medicoService.emailContacto(id,texto,filesUpload,fileSaleSize).subscribe(
        data => {
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Email exitoso","exito");
          this.onClickNo();
        },
        error => {
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Email fallido","error");
        }
      )
    }
  }
  

  //
  onClickNo(){
    this.dialogRef.close();
  }


  //
  openSnackBar(message: string, action: string) {
    this.snackBar.openFromComponent(NotificationComponent,{
      data: {
        message: message,
        action: action
      },
      duration: 2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [action]
    });
  }


}
