import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./login/login.component";
import { AuthRoutingModule } from "./auth.routing.module";
import { SharedModule } from "app/shared/shared.module";
import { MaterialModule } from 'app/material/material.module';
import { LoginPanelComponent } from "./login-panel/login-panel.component";
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LoginPacienteComponent } from "./login-paciente/login-paciente.component";
import { CambiarContraseniaComponent } from "./cambiar-contrasenia/cambiar-contrasenia.component";
import { CambiarContraseniaPacienteComponent } from "./cambiar-contrasenia-paciente/cambiar-contrasenia-paciente.component"
import { EnviarMailComponent } from "./enviar-mail/enviar-mail.component";
import { EnviarEmailPacienteComponent } from "./enviar-email-paciente/enviar-email-paciente.component";
import { LoginMedicoComponent } from './login-medico/login-medico.component';
import { EnviarMailMedicoComponent } from "./enviar-mail-medico/enviar-mail-medico.component";
import { CambiarContraseniaMedicoComponent } from "./cambiar-contrasenia-medico/cambiar-contrasenia-medico.component";

@NgModule({
  declarations: [
    LoginComponent,
    LoginPanelComponent,
    LoginPacienteComponent,
    CambiarContraseniaComponent,
    CambiarContraseniaPacienteComponent,
    EnviarMailComponent,
    EnviarEmailPacienteComponent,
    LoginMedicoComponent,
    EnviarMailMedicoComponent,
    CambiarContraseniaMedicoComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AuthRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
})
export class AuthModule {}

