import { ElementRef, Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ObraSocialService } from 'app/services/obra-social.service'; 
import { FormGroup,FormControl,Validator, Validators } from '@angular/forms';
import { ObraSocial } from 'app/models/obraSocial.interface';
import { Router,ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { GlobalVariable } from 'app/global/global';
import { SpinnerService } from 'app/services/spinner.service';


@Component({
  selector: 'app-obra-social-form',
  templateUrl: './obra-social-form.component.html',
  styleUrls: ['./obra-social-form.component.scss']
})
export class ObraSocialFormComponent implements OnInit {


  //
  funcionCorrecta:any;
  editarValor:boolean;
  panelOpenState = false;
  @Output() listar:EventEmitter<boolean> = new EventEmitter<boolean>();
  url:string = GlobalVariable.BASE_API_URL+'storage/images/obra-social/';
  @Input() set editar(value:boolean) {
    console.log('value',value);
    this.editarValor = value;
    this.panelOpenState = (value) ? true : false;
  }
  @ViewChild('UploadFileInput') uploadFileInput:ElementRef;
  @ViewChild('divImagen') divImagen:ElementRef;
  obraSocialValue:any;
  button:string;
  imagen = '';
  logo64 = '';
  file:File;
  errorNombre:any;
  myfilename = 'Seleccionar archivo';
  obraSocialForm = new FormGroup({
    id: new FormControl(''),
    nombre: new FormControl('',Validators.required),
    memberContactNo: new FormControl('Seleccionar archivo'),
  });
  @Input() set obraSocial(value: any) {
    if(value){
      this.mostrarObraSocial(value);
    }
  }


  //
  constructor(
    private obraSocialService:ObraSocialService,
    private actRoute: ActivatedRoute,
    private router:Router,
    private snackBar: MatSnackBar,
    private spinnerService:SpinnerService,
  ) {}


  //
  ngOnInit(): void {}


  //
  mostrarObraSocial(data:any){
    this.obraSocialValue = data;
    this.imagen = (this.obraSocialValue.logo) ? `${this.url}${this.obraSocialValue.logo}` : ``;
    (this.obraSocialValue.logo) ? this.divImagen.nativeElement.style.display = `block` : null;
    this.obraSocialForm.setValue({
      id: this.obraSocialValue.id,
      nombre : this.obraSocialValue.nombre,
      memberContactNo: (this.obraSocialValue.logo) ? `${this.obraSocialValue.logo},` : `Seleccionar archivo`,
    });
  }


  //
  fileChangeEvent(fileInput:any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      let fileList: FileList = fileInput.target.files;
      if(fileList.length > 0) {
        this.file = fileList[0];
      }

      this.myfilename = '';
      Array.from(fileInput.target.files).forEach((file: File) => {
        this.myfilename += file.name + ',';
      });

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (event:any) => {
          this.divImagen.nativeElement.style.display = 'block';
          this.imagen = e.target.result;
          const imgBase64Path = e.target.result;
          this.logo64 = e.target.result;
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
      this.uploadFileInput.nativeElement.value = '';
    } 
    else {
      this.myfilename = 'Seleccionar archivo';
    }
  }


  //
  clearNombre(){
    this.errorNombre = false;
  }


  guardarObraSocial(form:ObraSocial){
    this.funcionCorrecta = (this.editarValor) ? this.editarObraSocial(form) : this.crearObraSocial(form); 
  }


  //
  crearObraSocial(form:ObraSocial){
    if(this.obraSocialForm.valid){
      this.spinnerService.mostrarSpinner();
      this.obraSocialService.crear(form,this.file).subscribe(
        data => {
          let response:any = data;
          if(response.statusError){
            this.errorNombre = response.errorNombre;
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Guardado fallido","error");
          }
          else{
            this.resetearFormulario();
            this.listar.emit();
            this.openSnackBar("Guardado exitoso","exito");
          }
        },
        error => {
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Guardado fallido","error");
        } 
      ) 
    }
    else{
      this.openSnackBar("Guardado fallido","error");
    }
  }


  //
  editarObraSocial(form:ObraSocial){
    if(this.obraSocialForm.valid){
      this.spinnerService.mostrarSpinner();
      this.obraSocialService.editar(form,this.file).subscribe(
        data => {
          let response:any = data;
          if(response.statusError){
            this.spinnerService.ocultarSpinner();
            this.errorNombre = response.errorNombre;
            this.openSnackBar("Editado fallido","error");
          }
          else{
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Editado exitoso","exito");
            this.router.navigate(['/seccion-panel/obra-social/']);
          }
        },
        error => {
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Editado fallido","error");
        }
      )
    }
    else{
      this.openSnackBar("Editado fallido","error");
    }
  }


  //
  ocultarMostrar(){
    this.panelOpenState = (this.panelOpenState == true) ? false : true;
  }


  //
  resetearFormulario(){
    this.file = null;
    this.obraSocialForm.reset();
    this.imagen = "";
    this.myfilename = 'Seleccionar archivo';
    this.obraSocialForm.patchValue({
      memberContactNo : 'Seleccionar archivo'
    });
    this.divImagen.nativeElement.style.display = "none";
  }


  //
  openSnackBar(message:string, action:string) {
      this.snackBar.openFromComponent(NotificationComponent,{
      data: {
        message: message,
        action: action
      },
      duration: 2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [action]
    });
  }


}
