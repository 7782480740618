import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  file: File;
  imgURL: any;
  username: string;
  user: any;
  sameUser = false;
  me: string;
  days = [];  
  constructor() { }

  ngOnInit(): void {
    this.user = {
      username:'Test'
    }
  }

}
