import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditarObraSocialComponent } from './obra-social-editar/obra-social-editar.component';
import { SharedModule } from 'primeng/api';
import { MaterialModule } from 'app/material/material.module';
import { ObraSocialRoutingModule } from './obra-social-routing.module';
import { SharedPanelModule } from '../shared-panel/shared-panel.module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { ObraSocialIndexComponent } from './obra-social-index/obra-social-index.component';
import { ObraSocialFormComponent } from './obra-social-form/obra-social-form.component';

@NgModule({
  declarations: [
    EditarObraSocialComponent,
    ObraSocialIndexComponent,
    ObraSocialFormComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    ObraSocialRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedPanelModule,
  ]
})
export class ObraSocialModule { }
