import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ListarMedicoComponent } from "./listar-medico/listar-medico.component";
import { EditarMedicoComponent } from "./editar-medico/editar-medico.component";

const routes: Routes = [
  {
    path: "seccion-panel",
      children: [
        {
          path: "medico",
          pathMatch: "full",
          component: ListarMedicoComponent,
        },
        {
          path: "medico/editar/:id",
          pathMatch: "full",
          component: EditarMedicoComponent,
        },
      ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MedicoRoutingModule {}