import { Component, OnInit, OnDestroy } from '@angular/core';
import { TurnosService } from 'app/services/turnos.service';
import { FormGroup,FormControl,Validator, Validators } from '@angular/forms';
import { MedicoService } from 'app/services/medico.service';
import { NotificacionesPushService } from 'app/services/notificaciones-push.service';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';
import { SpinnerService } from 'app/services/spinner.service';
import { CentroMedico } from '../../../models/centroMedico.interface';

//
@Component({
  selector: 'app-turnos-medico',
  templateUrl: './turnos-medico.component.html',
  styleUrls: ['./turnos-medico.component.scss']
})
export class TurnosMedicoComponent implements OnInit, OnDestroy {


  //
  filtroTurnos:any;
  turnos:any;
  pacientes:any;
  paciente:any;
  medicos:any;
  response:any;
  centrosMedicos:any;
  id:any;
  tipo:any = 'medico';
  menu:string;
  centroMedico :CentroMedico = null;
  medico:any;
  responseLogin:any;
  responseDatos:any;
  notificacionesPush:any[];
  private intervalId: any;


  //
  constructor(
    private turnosService:TurnosService,
    private medicoService:MedicoService,
    private spinnerService:SpinnerService,
    private notificacionesPushService:NotificacionesPushService,
  ) {
    this.spinnerService.mostrarSpinner();
    this.medicoService.medicoLogeado().pipe(
      map(data => {
        this.responseLogin = data;
        this.medico = this.responseLogin.medico;
      }),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        this.medicoService.logout();
        return of({ results: null });
      }),
      switchMap(data => this.turnosService.getTurnosMedico(this.medico.id).pipe(
        map(data => { 
          this.responseDatos = data;
          this.turnos = data.turnos;
          this.filtroTurnos = data.turnos;
          this.notificacionesPush = data.notificacionesPush;
          this.spinnerService.ocultarSpinner();
        }),
      )),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        return of({ results: null });
      }),
    )
    .subscribe(
      (data) => {
        console.log('data',data);
      },
      (error) => {
        console.error('Error al llamar a la API:', error);
      }
    );
  }
 
 
  //
  ngOnInit(): void {
    this.intervalId = setInterval(() => {
      this.notificacionesPushService.listarPorMedicoSinLeer(this.medico.id).subscribe(
        data => {
          this.notificacionesPush = data.notificacionesPush;
        },
        error => {
          console.log('error => ', error);
        }
      )
    }, 60000);
  }


  //
  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
 
 
  //
  busquedaFiltrada(event:any){
    let object = JSON.parse(event);
    this.centroMedico = object.centroMedico ?? null;
    this.paciente = object.paciente;
    this.filtroTurnos = object.filtroTurnos;
  }
 
 
  //
  refrescarListaTurnos(event){
    this.listarTurnos();
  }
 
 
  //
  listarTurnos() {
   this.spinnerService.mostrarSpinner()
    this.turnosService.getTurnosMedico(this.medico.id).subscribe(
      data => {
        this.turnos = data.turnos;
        this.spinnerService.ocultarSpinner();
      },
      error => {
        console.log(error);
        this.spinnerService.ocultarSpinner();
      }
    )
  }


  //
  receiveMessage($event) {
    this.menu = $event;
  }


}
