<div class="headerPanel mat-elevation-z2">
  <button
    class="buttonIcon"
    mat-mini-fab
    aria-label="icon"
    (click)="botonDesplegableShadow()"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <div class="end">
    <div
      *ngIf="currentRouteName != 'notificaciones'"
      class="divCampana"
      [matMenuTriggerFor]="notificacion"
    >
      <span *ngIf="notificacionesPush.length > 0" class="contador">{{
        notificacionesPush.length
      }}</span>
      <img src="assets/img/ico_campana.png" class="iconCampana" />
    </div>
    <div>
      <button
        mat-icon-button
        [color]="'primary'"
        matTooltip="Descargar manual"
        (click)="downloadPDF()"
      >
        <mat-icon>help_outline</mat-icon>
      </button>
    </div>
    <div class="divPerfil" [matMenuTriggerFor]="appMenu">
      <img [src]="imagen" class="iconPerfil" />
    </div>
    <mat-menu class="custom-menu" #notificacion="matMenu">
      <div class="divNotification" (click)="onMenuItemClick($event)">
        <div class="tituloNotificacion">Notificaciones</div>
        <mat-nav-list class="list">
          <div *ngIf="notificacionesPush.length > 0">
            <div
              class="notificationPush"
              *ngFor="let notificacionPush of notificacionesPush"
            >
              <div
                *ngIf="notificacionPush.texto.length > 120"
                class="notificationText"
              >
                {{ notificacionPush.texto.substr(0, 120).trim() }}...
              </div>
              <div
                *ngIf="notificacionPush.texto.length <= 120"
                class="notificationText"
              >
                {{ notificacionPush.texto }}
              </div>
              <div class="divIcon">
                <div
                  class="divIconLink"
                  (click)="leerNotificacion(notificacionPush)"
                >
                  <mat-icon class="iconLink">visibility</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </mat-nav-list>
        <div *ngIf="notificacionesPush.length < 1" class="mensaje">
          <span class="mensajeText"> No hay notficaciones nuevas </span>
        </div>
        <div class="viewAll" [routerLink]="['/seccion-panel/notificaciones']">
          Ver todas las notificaciones
        </div>
      </div>
    </mat-menu>
    <mat-menu class="dropdown" #appMenu="matMenu">
      <mat-nav-list>
        <a
          mat-list-item
          class="buttonDash"
          [routerLink]="['/seccion-panel/editar-perfil']"
          >Editar Perfil</a
        >
        <a mat-list-item class="buttonDash" (click)="logout()">Salir</a>
      </mat-nav-list>
    </mat-menu>
  </div>
</div>
