import { PlanService } from 'app/services/plan.service';
import { ElementRef, AfterViewInit, Component, OnInit, ViewChild, NgModule, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NotificacionesPushService } from 'app/services/notificaciones-push.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/shared/confirm-dialog/confirm-dialog.component';
import { FotoDialogComponent } from 'app/shared/foto-dialog/foto-dialog.component';
import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { UsuarioPanel } from 'app/models/usuarioPanel.interface';
import { PasswordDialogComponent } from 'app/shared/password-dialog/password-dialog.component';
import { GlobalVariable } from 'app/global/global';
import { SpinnerService } from 'app/services/spinner.service';
import { TelefonoPaisService } from 'app/services/telefono-pais.service';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';


@Component({
  selector: 'app-usuario-panel-listar',
  templateUrl: './usuario-panel-listar.component.html',
  styleUrls: ['./usuario-panel-listar.component.scss']
})
export class ListarUsuarioPanelComponent implements OnInit {


  //
  url: string = GlobalVariable.BASE_API_URL + 'storage/images/usuario-panel/';
  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  @ViewChild('buscador') buscador: ElementRef;
  @ViewChild('dropdown2') element: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('divImagen') divImagen: ElementRef;
  displayedColumns: string[] = ['nombreYApellido', 'perfil', 'foto', 'acciones'];
  myfilename = 'Seleccionar Archivo';
  imagen = "";
  listaUsuarios: any;
  editar: boolean = false;
  listaUsuarioPanelFiltro: any[];
  dataSource: any;
  searchKey: string;
  llegoLimiteUsuarios: boolean = false;
  response: any;
  plan: any;
  cantidadUsuarios: number;
  usuarioPanel: any;
  listaPaciente: any;
  pacienteFormulario: any;
  responsePlan: any;
  notificacionesPush: any[];
  listaUsuarioPanel: any;


  //
  constructor(
    public dialog: MatDialog,
    public fotoDialog: MatDialog,
    public apiUsuario: UsuarioPanelService,
    private planService: PlanService,
    private usuarioPanelService: UsuarioPanelService,
    private spinnerService: SpinnerService,
    private telefonoPaisService: TelefonoPaisService,
    private notificacionesPushService:NotificacionesPushService,
  ) {
    this.spinnerService.mostrarSpinner();
    this.usuarioPanelService.profileUser().pipe(
      map(data => {
        this.usuarioPanel = data;
      }),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        this.usuarioPanelService.logout();
        return of({ results: null });
      }),
      switchMap(data => this.usuarioPanelService.datosInicio().pipe(
        map(data => {
          this.response = data;
          this.listaUsuarioPanel = data.listaUsuarioPanel;
          this.listaUsuarioPanelFiltro = this.response.listaUsuarioPanel;
          this.notificacionesPush = data.notificacionesPush;
          this.listar(this.listaUsuarioPanel);
          this.llegoAlLimite();
          this.spinnerService.ocultarSpinner();
        }),
      )),
      catchError(error => {
        this.spinnerService.ocultarSpinner();
        return of({ results: null });
      }),
    )
      .subscribe(
        (data) => {
          console.log('data', data);
        },
        (error) => {
          console.error('Error al llamar a la API:', error);
        }
      );
  }


  //
  ngOnInit(): void { 
    this.notificacionesPushService.listen('notificacion-push', 'NotificacionPushCreated', (data:any) => {
      this.notificacionesPush = [...this.notificacionesPush, ...data.notificacionesPush];
    });
  }


  //
  private llegoAlLimite() {
    this.llegoLimiteUsuarios = ((this.response.plan.cantidadUsuario - this.listaUsuarios.length) <= 0) ? true : false;
  }


  //
  fileChangeEvent(event: any) {
    var target = event.target || event.srcElement;
  }


  //
  listar(data) {
    this.listaUsuarios = data;
    this.dataSource = new MatTableDataSource<UsuarioPanel>(this.listaUsuarios);
    this.dataSource.paginator = this.paginator;
  }


  //
  borrar(usuarioPanel: UsuarioPanel) {
    this.spinnerService.mostrarSpinner();
    this.usuarioPanelService.borrar(usuarioPanel).pipe(
      switchMap((data) => {
        return this.usuarioPanelService.listar();
      })
    )
      .subscribe((data) => {
        this.listaUsuarioPanel = data.listaUsuarioPanel;
        this.listar(this.listaUsuarioPanel);
        this.llegoAlLimite();
        this.spinnerService.ocultarSpinner();
      },
        (error) => {
          this.spinnerService.ocultarSpinner();
        }
      );
  }


  //
  openDialog(element: UsuarioPanel) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '50%',
      data: 'usuario panel'
    })
    dialogRef.afterClosed().subscribe(res => {

      if (res) {
        this.borrar(element);
      }
    })
  }


  //
  openDialogFoto() {
    const dialogRef = this.fotoDialog.open(FotoDialogComponent, {
      width: '50%',
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res.data.id == "ico_paciente.png" || res.data.id == "ico_pacienteM.png") {
        this.myfilename = res.data.id;
        this.imagen = 'assets/img/' + res.data.id + '';
        this.divImagen.nativeElement.style.display = "block";
      }
      else {
        this.imagen = res.img;
        this.divImagen.nativeElement.style.display = "block";
      }
    })
  }


  //
  cambiarPassword(id) {
    console.log('id => ', id);
    const dialogRef = this.dialog.open(PasswordDialogComponent, {
      width: '50%',
      data: { nombre: 'usuarioPanel', id: id }
    });
    dialogRef.afterClosed().subscribe(res => { });
  }


  //
  filter(event) {
    let dataFilter: UsuarioPanel[] = [];
    this.listaUsuarios.forEach(obj => {
      let valorString: string = this.buscador.nativeElement.value;
      if (obj.nombreCompleto.toUpperCase().includes(valorString.toUpperCase())) {
        dataFilter.push(obj);
      }
      else if (obj.perfil.toUpperCase().includes(valorString.toUpperCase())) {
        dataFilter.push(obj);
      }
    })

    this.dataSource = new MatTableDataSource<UsuarioPanel>(dataFilter);
    this.dataSource.paginator = this.paginator;
  }


  //
  listaActualizada(mensaje: boolean) {
    this.usuarioPanelService.listar().subscribe(
      data => {
        this.listaUsuarioPanel = data.listaUsuarioPanel;
        this.listar(this.listaUsuarioPanel);
        this.llegoAlLimite();
        this.spinnerService.ocultarSpinner();
      },
      error => {
        this.spinnerService.ocultarSpinner();
      }
    )
  }


  //
  cargarImagen(event) {
    (event.target as HTMLImageElement).style.display = 'flex';
  }

}