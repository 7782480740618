import { Component, OnInit ,Output,EventEmitter} from '@angular/core';
import { Event, Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private router: Router) { }
  @Output() login:EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.addLogin();
  }

  addLogin(){
    this.login.emit(true)
  }

  onSubmit(){
    return false;
  }

}