import { Component, OnInit, Input,Output, EventEmitter} from '@angular/core';
import { FormGroup,FormControl,Validator, Validators } from '@angular/forms';


@Component({
  selector: 'app-filtro-turnos-medico',
  templateUrl: './filtro-turnos-medico.component.html',
  styleUrls: ['./filtro-turnos-medico.component.scss']
})
export class FiltroTurnosMedicoComponent implements OnInit {


  //
  filtroPacientes:any;
  listPacientes:any;
  listTurnos:any;
  filtrosTurnos:any;
  idMedico:any;
  tipoMedico:any;
  panelOpenState = true;
  public paciente:FormControl = new FormControl();
  public pacienteBuscador:FormControl = new FormControl();
  filtroCalendario = new FormGroup({
    pacienteBuscador: new FormControl(''),
    paciente: new FormControl(''),
 });
  @Output() filtroEvent = new EventEmitter<string>();
  @Input() set response(value:any){
    if(value){
      this.mostrarDatos(value);
    }
  }
  @Input() set turnos(value:any){
    if(value){
      this.createListTurno(value);
    }
  }


  //
  constructor() { }


  //
  ngOnInit(): void {}


  //
  mostrarDatos(data:any){
    this.listPacientes = data.pacientes;
    this.filtroPacientes = data.pacientes;
  }


  //
  createListTurno(data:any){
    this.listTurnos = data;
    this.filtrosTurnos = data;
    this.busquedaFiltrada();
  }


  //
  filtrarPaciente(event:any){
    let valorString:string = this.pacienteBuscador.value;
    this.filtroPacientes = [];

    for(let ops of this.listPacientes){
      if(ops.nombreApellido.toUpperCase().includes(valorString.toUpperCase())){
        let nuevoP:any = ops;
        this.filtroPacientes.push(nuevoP);
      }
    }
  }


  //
  busquedaFiltrada(){
    let pacienteValue = this.filtroCalendario.get('paciente').value;
    this.filtrosTurnos = this.listTurnos;
    this.filtrosTurnos = this.listTurnos.filter((elemento:any) => {
      if(!pacienteValue) return elemento;
      else if(pacienteValue == elemento.id_paciente) return elemento;
    });
    let paciente = this.listPacientes.find((element:any) => element.id == pacienteValue);
    let object = {
      paciente: paciente,
      filtroTurnos: this.filtrosTurnos,
    }
    this.filtroEvent.emit(JSON.stringify(object));
  }


  //
  ocultarMostrar(){
    this.panelOpenState = (this.panelOpenState == true) ? false : true;
  }


}
