import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UsuarioPanel } from 'app/models/usuarioPanel.interface';
import { GlobalVariable } from 'app/global/global';
import { Paciente } from 'app/models/paciente.interface';
import { Medico } from 'app/models/medico.interface';

@Injectable({
  providedIn: 'root'
})
export class RecuperarContraseñaService {

  url:string = GlobalVariable.BASE_API_URL+"api/recuperar-contrasenia/";

  constructor(
    private http:HttpClient
  ) { }

  recuperar(usuario:any){
    let formData = new FormData(); 
    formData.append("email",usuario.email);
    return this.http.post(this.url+"recuperar-panel",formData);
  }

  cambiarContraseña(usuario:UsuarioPanel,token:string){
    let formData = new FormData(); 
    formData.append("email",usuario.email);
    formData.append("password",usuario.password);
    formData.append("token",token);
    return this.http.post(this.url+"cambiar-contrasenia-panel",formData);
  }

  tokenRecuperacionUsuarioPanel(token){
    let formData = new FormData(); 
    formData.append("token",token);
    return this.http.post(this.url+"token-recuperacion-panel",formData);
  }

  recuperarPaciente(paciente:Paciente){
    let formData = new FormData(); 
    formData.append("email",paciente.email);
    return this.http.post(this.url+"recuperar-paciente",formData);
  }

  cambiarContraseñaPaciente(paciente:Paciente,token:string){
    let formData = new FormData(); 
    formData.append("email",paciente.email);
    formData.append("password",paciente.password);
    formData.append("token",token);
    return this.http.post(this.url+"cambiar-contrasenia-paciente",formData);
  }

  tokenRecuperacionPaciente(token){
    let formData = new FormData(); 
    formData.append("token",token);
    return this.http.post(this.url+"token-recuperacion-paciente",formData);
  }

  recuperarMedico(medico:Medico){
    let formData = new FormData(); 
    formData.append("email",medico.email);
    return this.http.post(this.url+"recuperar-medico",formData);
  }

  cambiarContraseñaMedico(medico:Medico,token:string){
    let formData = new FormData(); 
    formData.append("email",medico.email);
    formData.append("password",medico.password);
    formData.append("token",token);
    return this.http.post(this.url+"cambiar-contrasenia-medico",formData);
  }

  tokenRecuperacionMedico(token){
    let formData = new FormData(); 
    formData.append("token",token);
    console.log(token);
    return this.http.post(this.url+"token-recuperacion-medico",formData);
  }
}
