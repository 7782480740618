import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import {Directive, ElementRef, HostListener,  Input, OnInit} from '@angular/core';
import * as cornerstoneFileImageLoader  from 'cornerstone-file-image-loader'
import { GlobalVariable } from 'app/global/global';

declare const cornerstone;

declare const cornerstoneTools;

declare const cornerstoneWebImageLoader;

@Directive({
  selector: '[cornerstone]',
})

export class CornerstoneDirective implements OnInit {

  element:  any;

  imageList = [];

  currentIndex = 0;

  @Input('image')
  set image(imageData: any) {
    // 
    if (imageData) {
      //

      if (!this.imageList.filter(img => img.imageId === imageData.imageId).length) {
        this.imageList.push(imageData);
      }

      if (imageData.imageId) {
        this.displayImage(imageData);
      }
    }
  }

  constructor(public elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    cornerstone.resize(this.element, true);
  }

  @HostListener('mousewheel', ['$event'])
  onMouseWheel(event) {
    event.preventDefault();

    const delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
    // 

    if(delta > 0){
      this.currentIndex ++;
      if( this.currentIndex > this.imageList.length) {
        this.currentIndex = this.imageList.length-1;
      }
    } else {

      this.currentIndex --;
      if(this.currentIndex < 0){
        this.currentIndex = 0;
      }
    }

    
    this.image = this.imageList
      //----------
      .filter( img => img.imageId === `${this.currentIndex}`)[0];
  }

  ngOnInit() {

      // Retrieve the DOM element itself
      this.element = this.elementRef.nativeElement;
      
      // Enable the element with Cornerstone
      cornerstone.enable(this.element);
  }

  displayImage(image) {
    cornerstone.displayImage(this.element,image); 
    cornerstoneTools.mouseInput.enable(this.element);
    cornerstoneTools.mouseWheelInput.enable(this.element);
    //cornerstoneTools.wwwc.activate(this.element, 1); 
    cornerstoneTools.pan.activate(this.element, 2); 
    cornerstoneTools.zoomWheel.activate(this.element);
    cornerstoneTools.magnify.enable(this.element);
    cornerstoneTools.magnify.activate(this.element, 4);
    cornerstoneTools.length.enable(this.element);
    cornerstoneTools.length.activate(this.element,1);
    //cornerstoneTools.magnify.enable(this.element);
    //cornerstoneTools.magnify.activate(this.element, 4);
    
    //cornerstoneTools.length.activate(this.element, 1);
    //cornerstoneTools.wwwc.activate(this.element, 1); // Left Click
    //cornerstoneTools.mouseInput.enable(this.element);
    //cornerstoneTools.mouseWheelInput.enable(this.element);
    //cornerstoneTools.magnify.enable(this.element);
    //cornerstoneTools.magnify.activate(this.element,4);
    //cornerstoneTools.zoom.activate(this.element, 4);
    //cornerstoneTools.length.enable(this.element);
    //cornerstoneTools.length.activate(this.element, LEFT_MOUSE_BUTTON);
    //cornerstoneTools.ZoomTouchPinchTool.activate(this.element,4);
    //cornerstoneTools.pan.activate(this.element, 2);
    //cornerstoneTools.imageStats.enable(this.element);
    //cornerstoneTools.zoomWheel.activate(this.element);
    //cornerstoneTools.wwwc.activate(this.element, 1); // Left Click
    //cornerstoneTools.pan.activate(this.element, 2); // Middle Click
  }

}

//.filter( img => img.imageId === `wadouri:http://localhost:4200/assets/dicom/im${this.currentIndex}`)[0];
