import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TurnosMedicoRoutingModule } from './turnos-medico-routing.module'; 
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedMedicoModule } from '../shared-medico/shared-medico.module';
import { TurnosMedicoComponent } from './turnos-medico/turnos-medico.component';
import { FiltroTurnosMedicoComponent } from './filtro-turnos-medico/filtro-turnos-medico.component';
import { CalendarioMedicoComponent } from './calendario-medico/calendario-medico.component';
import { UserModule } from 'app/user/user.module';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from 'app/material/material.module';


FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin,
  listPlugin,
]);


@NgModule({
  declarations: [
    TurnosMedicoComponent,
    CalendarioMedicoComponent,
    FiltroTurnosMedicoComponent

  ],
  imports: [
    CommonModule,
    TurnosMedicoRoutingModule,
    FullCalendarModule,
    FormsModule,
    ReactiveFormsModule,
    SharedMedicoModule,
    UserModule,
    MaterialModule,
    HttpClientModule
  ]
})

export class TurnosMedicoModule { }
