import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavbarComponent } from "./navbar/navbar.component";
import { FooterComponent } from "./footer/footer.component";
import { PrimeNgModule } from "app/prime-ng/prime-ng.module";
import { ExamsTableComponent } from "./exams-table/exams-table.component";
import { RouterModule } from "@angular/router";
import { ExamnsDashboardComponent } from "./examns-dashboard/examns-dashboard.component";
import { MaterialModule } from "app/material/material.module";
import { SideBarComponent, ContactModal } from "./side-bar/side-bar.component";
import { FotoDialogComponent } from "./foto-dialog/foto-dialog.component";
import { FormDialogComponent } from "./form-dialog/form-dialog.component";
import { MedicoFormDialogComponent } from "./medico-form-dialog/medico-form-dialog.component";
import { NotificationComponent } from "./notification/notification.component";
import { PasswordDialogComponent } from "./password-dialog/password-dialog.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ShowImagenComponent } from "./show-imagen/show-imagen.component";
import { ContactoDialogComponent } from "./contacto-dialog/contacto-dialog.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { CornerstoneDirective } from "app/directives/cornerstone.directive";
import { CornerstoneElementDirective } from "app/directives/cornerstone-element.directive";
import { DelayHoverDirective } from "app/delay-hover.directive";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { SafePipe } from "../safe.pipe";
import { EstudiosMedicosComponent } from "./medico/estudios-medicos/estudios-medicos.component";
import { FileUploadModule } from "@iplab/ngx-file-upload";
import { TurnoAddDialogComponent } from "./turno-add-dialog/turno-add-dialog.component";
import { TurnoDeleteDialogComponent } from "./turno-delete-dialog/turno-delete-dialog.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { DeleteErrorDialogComponent } from "./delete-error-dialog/delete-error-dialog.component";
import { ComentarioEstudioMedicoComponent } from "./comentario-estudio-medico/comentario-estudio-medico.component";
import { ListaNotificacionesComponent } from "./lista-notificaciones/lista-notificaciones.component";
import { FormEstudiosMedicosComponent } from "./form-estudios-medicos/form-estudios-medicos.component";
import { EditorModule } from "@tinymce/tinymce-angular";
import { AyudaBotonesComponent } from './show-imagen/ayuda-botones/ayuda-botones.component';

@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    ExamsTableComponent,
    ExamnsDashboardComponent,
    SideBarComponent,
    ContactModal,
    FotoDialogComponent,
    FormDialogComponent,
    MedicoFormDialogComponent,
    NotificationComponent,
    PasswordDialogComponent,
    ShowImagenComponent,
    ContactoDialogComponent,
    ConfirmDialogComponent,
    CornerstoneDirective,
    CornerstoneElementDirective,
    DelayHoverDirective,
    SafePipe,
    EstudiosMedicosComponent,
    TurnoAddDialogComponent,
    TurnoDeleteDialogComponent,
    DeleteErrorDialogComponent,
    ComentarioEstudioMedicoComponent,
    ListaNotificacionesComponent,
    FormEstudiosMedicosComponent,
    AyudaBotonesComponent,
  ],
  imports: [
    CommonModule,
    PrimeNgModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    FileUploadModule,
    NgxMaterialTimepickerModule,
    NgxSpinnerModule,
    EditorModule,
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    ExamsTableComponent,
    ExamnsDashboardComponent,
    SideBarComponent,
    ConfirmDialogComponent,
    EstudiosMedicosComponent,
    ComentarioEstudioMedicoComponent,
    ListaNotificacionesComponent,
    FormEstudiosMedicosComponent,
  ],
})
export class SharedModule {}
