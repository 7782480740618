import { PlanService } from "app/services/plan.service";
import { PasswordDialogComponent } from "./../../../shared/password-dialog/password-dialog.component";
import { ElementRef, Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { NotificacionesPushService } from "app/services/notificaciones-push.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "app/shared/confirm-dialog/confirm-dialog.component";
import { FotoDialogComponent } from "app/shared/foto-dialog/foto-dialog.component";
import { PacienteService } from "app/services/paciente.service";
import { Paciente } from "app/models/paciente.interface";
import { PacienteCompleto } from "app/models/pacienteCompleto.interface";
import { UsuarioPanelService } from "app/services/usuario-panel.service";
import { SpinnerService } from "app/services/spinner.service";
import { MatSort } from "@angular/material/sort";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";

@Component({
  selector: "app-listar-paciente",
  templateUrl: "./listar-paciente.component.html",
  styleUrls: ["./listar-paciente.component.scss"],
})
export class ListarPacienteComponent implements OnInit {
  //
  @ViewChild("dropdown2") element: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("UploadFileInput") uploadFileInput: ElementRef;
  @ViewChild("buscador") buscador: ElementRef;
  @ViewChild("divImagen") divImagen: ElementRef;
  displayedColumns: string[] = [
    "nombreApellido",
    "tipo",
    "numDocumento",
    "codigoInterno",
    "obraSocial",
    "numAfiliado",
    "acciones",
  ];
  dataSource: any;
  editar: boolean = false;
  myfilename = "Seleccionar Archivo";
  imagen = "";
  data: PacienteCompleto[];
  searchKey: string;
  response: any;
  llegoLimitePacientes: boolean = false;
  plan: any;
  cantidadPacientes: any;
  usuarioPanel: any;
  listaPaciente: any;
  pacienteFormulario: any;
  responsePlan: any;
  telefonos: any;
  notificacionesPush: any[];


  //
  constructor(
    public dialog: MatDialog,
    public fotoDialog: MatDialog,
    private pacienteService: PacienteService,
    private usuarioPanelService: UsuarioPanelService,
    private spinnerService: SpinnerService
  ) {
    this.spinnerService.mostrarSpinner();
    this.usuarioPanelService
      .profileUser()
      .pipe(
        map((data) => {
          this.usuarioPanel = data;
        }),
        catchError((error) => {
          this.spinnerService.ocultarSpinner();
          this.usuarioPanelService.logout();
          return of({ results: null });
        }),
        switchMap((data) =>
          this.pacienteService.mostrarformPaciente().pipe(
            map((data) => {
              this.response = data;
              this.listar(this.response.pacientes);
              this.notificacionesPush = data.notificacionesPush;
              this.spinnerService.ocultarSpinner();
            })
          )
        ),
        catchError((error) => {
          this.spinnerService.ocultarSpinner();
          return of({ results: null });
        })
      )
      .subscribe(
        (data) => {
          console.log("data", data);
        },
        (error) => {
          console.error("Error al llamar a la API:", error);
        }
      );
  }

  //
  ngOnInit(): void {}

  //
  llegoAlLimite() {
    this.llegoLimitePacientes =
      this.response.plan.cantidadPaciente - this.response.pacientes.length <= 0
        ? true
        : false;
  }

  //
  filter(event) {
    let dataFilter: PacienteCompleto[] = [];
    let valorString: string = this.buscador.nativeElement.value;
    dataFilter = this.listaPaciente.filter((element) => {
      //console.log(obj);
      if (
        element.nombreApellido.toUpperCase().includes(valorString.toUpperCase())
      ) {
        return element;
      } else if (
        element.texto
          .toString()
          .toUpperCase()
          .includes(valorString.toUpperCase())
      ) {
        return element;
      } else if (
        element.numeroDocumento
          .toUpperCase()
          .includes(valorString.toUpperCase())
      ) {
        return element;
      } else if (
        element.codigoInterno &&
        element.codigoInterno.toUpperCase().includes(valorString.toUpperCase())
      ) {
        return element;
      } else if (
        element.nombre
          .toString()
          .toUpperCase()
          .includes(valorString.toUpperCase())
      ) {
        return element;
      } else if (
        element.numeroAfiliado.toUpperCase().includes(valorString.toUpperCase())
      ) {
        return element;
      }
    });

    this.dataSource = new MatTableDataSource<PacienteCompleto>(dataFilter);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  //
  listar(data) {
    this.listaPaciente = data;
    this.listaPaciente.forEach((value) => {
      if (value.direccion == "null") value.direccion = "";
      if (value.telPais == "null") value.telPais = "";
      if (value.telCaract == "null") value.telCaract = "";
      if (value.telNumero == "null") value.telNumero = "";
      if (value.codigoInterno == "null") value.codigoInterno = "";
      if (value.imagen == "null" || value.imagen == "")
        value.imagen = "ico_paciente.png";
      if (value.nombre == null || value.nombre == "null" || value.nombre == "")
        value.nombre = "";
      if (
        value.numeroAfiliado == null ||
        value.numeroAfiliado == "null" ||
        value.numeroAfiliado == ""
      )
        value.numeroAfiliado = "";
      value.celular = value.celPais + value.celCaract + value.celNumero;
    });
    this.dataSource = new MatTableDataSource<PacienteCompleto>(
      this.listaPaciente
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  //
  fileChangeEvent(event: any) {
    var target = event.target || event.srcElement;
  }

  //
  openDialog(element: Paciente) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "50%",
      data: "paciente",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.borrar(element);
      }
    });
  }

  //
  borrar(paciente: Paciente) {
    this.spinnerService.mostrarSpinner();
    this.pacienteService
      .borrar(paciente)
      .pipe(
        map((data) => {}),
        catchError((error) => {
          this.spinnerService.ocultarSpinner();
          return of({ results: null });
        }),
        switchMap((data) =>
          this.pacienteService.listar().pipe(
            map((data) => {
              this.listar(data);
              this.spinnerService.ocultarSpinner();
            })
          )
        ),
        catchError((error) => {
          this.spinnerService.ocultarSpinner();
          return of({ results: null });
        })
      )
      .subscribe(
        (data) => {
          console.log("data", data);
        },
        (error) => {
          console.error("Error al llamar a la API:", error);
        }
      );
  }

  //
  listaActualizada(mensaje: boolean) {
    this.pacienteService.listar().subscribe(
      (data) => {
        this.listar(data);
        this.llegoAlLimite();
        this.spinnerService.ocultarSpinner();
      },
      (error) => {
        this.spinnerService.ocultarSpinner();
      }
    );
  }

  //
  openDialogFoto() {
    const dialogRef = this.fotoDialog.open(FotoDialogComponent, {
      width: "50%",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (
        res.data.id == "ico_paciente.png" ||
        res.data.id == "ico_pacienteM.png"
      ) {
        this.myfilename = res.data.id;
        this.imagen = "assets/img/" + res.data.id + "";
        this.divImagen.nativeElement.style.display = "block";
      } else {
        this.myfilename = res.data.name;
        this.imagen = res.img;
        this.divImagen.nativeElement.style.display = "block";
      }
    });
  }

  //
  cambiarPassword(id) {
    const dialogRef = this.dialog.open(PasswordDialogComponent, {
      width: "50%",
      data: { nombre: "paciente", id: id },
    });
    dialogRef.afterClosed().subscribe((res) => {});
  }
}
