import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validator, Validators } from '@angular/forms';
import { CanActivate, Router } from '@angular/router';
import { UsuarioPanel } from 'app/models/usuarioPanel.interface';
import { RecuperarContraseñaService } from 'app/services/recuperar-contraseña.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { SpinnerService } from '../../services/spinner.service';

@Component({
  selector: 'app-enviar-mail-medico',
  templateUrl: './enviar-mail-medico.component.html',
  styleUrls: ['./enviar-mail-medico.component.scss']
})
export class EnviarMailMedicoComponent implements OnInit {


  mailForm:FormGroup;

  constructor(
    private router:Router,
    private apiRCS:RecuperarContraseñaService,
    private snackBar:MatSnackBar,
    private spinService:SpinnerService,
  ) { 
    this.iniciarFormularion();
  }


  ngOnInit(): void {}

  
  iniciarFormularion(){
    this.mailForm = new FormGroup({
      email : new FormControl('',[Validators.required,Validators.email])
    });
  }


  enviarMail(form:any){
    this.spinService.mostrarSpinner();
    this.apiRCS.recuperarMedico(form).subscribe(
      data => {
        this.spinService.ocultarSpinner();
        this.openSnackBar("Email enviado exitosamente","exito");
      },
      error=>{
        this.spinService.ocultarSpinner();
        this.openSnackBar("El email ingresado no esta registrado ","error");
      }
    )
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.openFromComponent(NotificationComponent,{
      data: {
        message : message,
        action : action
      },
      duration:2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass : [action]
    });
  }

}
