import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ListarSucursalComponent } from "./listar-sucursal/listar-sucursal.component";
import { EditarSucursalComponent } from "./editar-sucursal/editar-sucursal.component";

const routes: Routes = [
  {
    path: "seccion-panel",
      children: [
        {
          path: "sucursal",
          pathMatch: "full",
          component: ListarSucursalComponent,
        },
        {
          path: "sucursal/editar/:id",
          pathMatch: "full",
          component: EditarSucursalComponent,
        }
      ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SucursalRoutingModule {}