import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {


  //
  showIndex = 0;
  hideIndex = 0;


  //
  constructor(private spinService:NgxSpinnerService) { }


  //
  public resetCount(){
    this.hideIndex=0;
    this.showIndex=0;
  }

 
  //
  public mostrarSpinner(){
    this.spinService.show();
  }


  //
  public ocultarSpinner(){
    this.spinService.hide();
  }
}
