import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PacienteService } from 'app/services/paciente.service';

@Injectable({
  providedIn: 'root'
})
export class TurnosPanelGuard implements CanActivate {
  

  //
  constructor(
    private authService: PacienteService,
    private router: Router
  ) { }


  //
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) : Promise<boolean | UrlTree> {
    if(Number(localStorage.getItem('turno_habilitado')) == 1){
      return true;
    }
    else {
      this.router.navigate(['/seccion-panel/home-panel']);
      return false; 
    }
  }
  
}
