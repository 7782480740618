<div class="divContent">
    <div *ngIf="habilitar">
        <mat-card class="cardTable mat-elevation-z4">
            <div class="topTitle"><mat-card class="titleSeccion">{{titulo}}</mat-card></div>
            <div class="topDesc">{{descripcion}}</div>
            <div class="divGrafico">
                <div class="grafico">
                    <ngx-charts-bar-vertical
                        [view]="view"
                        [scheme]="colorScheme"
                        [results]="informesEstudioMedico"
                        [gradient]="gradient"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        [legend]="showLegend"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxisLabel]="xAxisLabel"
                        [yAxisLabel]="yAxisLabel"
                        (select)="onSelect($event)"
                    >
                    </ngx-charts-bar-vertical>
                </div>
            </div>
        </mat-card>
    </div>
</div>