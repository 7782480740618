
import { PacienteGuard } from "./guards/paciente.guard";
import { TurnosMedicoGuard } from "./guards/turnos-medico.guard";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MedicoGuard } from "./guards/medico.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "inicio",
    pathMatch: "full",
  },
  {
    path: "inicio",
    loadChildren: () =>
      import("./inicio/inicio.module").then((m) => m.InicioModule),
  },
  {
    path: "",
    loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
  },
  {
    path: "doctor",
    loadChildren: () =>
      import("./doctor/doctor.module").then((m) => m.DoctorModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("./confirmar-turnos/confirmar-turnos.module").then(
        (m) => m.ConfirmarTurnosModule
      ),
  },
  {
    path: "",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  {
    path: "",
    loadChildren: () =>
      import("./panel-seccion/panel-seccion.module").then(
        (m) => m.PanelSeccionModule
      ),
  },

  {
    path: "",
    loadChildren: () =>
      import("./paciente-seccion/home-paciente/home-paciente.module").then(
        (m) => m.HomePacienteModule
      ),
    canActivate: [PacienteGuard],
  },
  {
    path: "",
    loadChildren: () =>
      import("./paciente-seccion/turnos-paciente/turnos-paciente.module").then(
        (m) => m.TurnosPacienteModule
      ),
    canActivate: [PacienteGuard],
  },
  {
    path: "",
    loadChildren: () =>
      import(
        "./paciente-seccion/detalle-estudios-medicos/detalle-estudios-medicos.module"
      ).then((m) => m.DetalleEstudiosMedicosModule),
    canActivate: [PacienteGuard],
  },
  {
    path: "",
    loadChildren: () =>
      import(
        "./paciente-seccion/editar-perfil-paciente/editar-perfil-paciente.module"
      ).then((m) => m.EditarPerfilPacienteModule),
    canActivate: [PacienteGuard],
  },
  {
    path: "",
    loadChildren: () =>
      import("./medico-seccion/shared-medico/shared-medico.module").then(
        (m) => m.SharedMedicoModule
      ),
    canActivate: [MedicoGuard],
  },
  {
    path: "",
    loadChildren: () =>
      import("./medico-seccion/turnos-medico/turnos-medico.module").then(
        (m) => m.TurnosMedicoModule
      ),
    canActivate: [MedicoGuard, TurnosMedicoGuard],
  },
  {
    path: "",
    loadChildren: () =>
      import("./medico-seccion/home-medico/home-medico.module").then(
        (m) => m.HomeMedicoModule
      ),
    canActivate: [MedicoGuard],
  },
  {
    path: "",
    loadChildren: () =>
      import(
        "./medico-seccion/editar-perfil-medico/editar-perfil-medico.module"
      ).then((m) => m.EditarPerfilMedicoModule),
    canActivate: [MedicoGuard],
  },
  {
    path: "",
    loadChildren: () =>
      import(
        "./medico-seccion/listar-estudios-medicos/listar-estudios-medicos.module"
      ).then((m) => m.ListarEstudiosMedicosModule),
    canActivate: [MedicoGuard],
  },
  {
    path: "",
    loadChildren: () =>
      import(
        "./medico-seccion/detalle-estudios-medicos/detalle-estudios-medicos.module"
      ).then((m) => m.DetalleEstudiosMedicosModule),
    canActivate: [MedicoGuard],
  },
  {
    path: "",
    loadChildren: () =>
      import("./medico-seccion/notificaciones/notificaciones.module").then(
        (m) => m.NotificacionesModule
      ),
    canActivate: [MedicoGuard],
  },
  { path: "**", redirectTo: "inicio", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
