<div class="contentModal">
    <div class="tituloModal">
        <span>
            Borrar {{titulo}}
        </span>
        <mat-icon class="buttonClose" [mat-dialog-close]=false>close</mat-icon>
    </div>
    <div class="textModal">
        {{mensaje}}
    </div>
    <div class="accionModal">
        <div class="buttons">
            <button mat-raised-button class="button aceptar" [mat-dialog-close]=true>Aceptar</button>
        </div>
    </div>
</div>
