import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from 'app/material/material.module';
import { HeaderPacienteComponent } from './header-paciente/header-paciente.component';
import { DashboardPacienteComponent } from './dashboard-paciente/dashboard-paciente.component';
import { DatePipe } from '@angular/common';
import { UserModule } from 'app/user/user.module';
import { CornerstoneService } from 'app/services/cornerstone.service';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    HeaderPacienteComponent,
    DashboardPacienteComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    UserModule,
    RouterModule,
  ],
  exports:[
    HeaderPacienteComponent,
    DashboardPacienteComponent,
  ],
})
export class PacienteSharedModule { }
