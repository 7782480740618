import { Injectable } from '@angular/core';
import { GlobalVariable } from 'app/global/global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import Echo from 'laravel-echo';
import * as Pusher from 'pusher-js';  // I

@Injectable({
  providedIn: 'root'
})
export class NotificacionesPushService {

  //
  private echo: Echo;
  url: string = GlobalVariable.BASE_API_URL + "api/notifiaciones-push/";

  //
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    window['Pusher'] = Pusher;
    this.echo = new Echo({
      broadcaster: GlobalVariable.BROADCASTER,
      key: GlobalVariable.KEY,
      wsHost: GlobalVariable.WS_HOST,
      wsPort: GlobalVariable.WS_PORT,
      disableStats: GlobalVariable.DISABLE_STATS,
      forceTLS: GlobalVariable.FORCE_TLS,
      encrypted: GlobalVariable.ENCRYPTED,
      cluster: GlobalVariable.CLUSTER,
    });
  }


  //
  listen(channel: string, event: string, callback: Function) {
    this.echo.channel(channel)
      .listen(event, (data:any) => {
        callback(data);
      });
  }
  

  //
  listar(): Observable<any> {
    return this.http.get(this.url + "listar");
  }


  //
  listarPorMedico(id:number): Observable<any> {
    return this.http.get(this.url + "listar-por-medico/" + id);
  }


  //
  leerNotificacion(id: any): Observable<any> {
    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    formData.append("id", id);
    return this.http.post(this.url + "leer", formData, { headers: headers });
  }

}
