<div class="contentModal">
    <div class="tituloModal">
        <span>
            Borrar {{mensaje}}
        </span>
        <mat-icon class="buttonClose" [mat-dialog-close]=false>close</mat-icon>
    </div>
    <div class="textModal">
        ¿Estás seguro de que quieres eliminar este registro?
    </div>
    <div class="accionModal">
        <div class="buttons">
            <button mat-raised-button class="button cancelar" [mat-dialog-close]=false>Cancelar</button>
            <button mat-raised-button class="button borrar" [mat-dialog-close]=true>Borrar</button>
        </div>
    </div>
</div>