<div class="divContent grafico-container">
  <div class="seccionTable">
    <mat-card class="cardTable mat-elevation-z4">
      <div class="topTitle">
        <mat-card class="titleSeccion">{{ titulo }}</mat-card>
      </div>
      <div class="topDesc">{{ descripcion }}</div>
      <table class="table" mat-table [dataSource]="dataSource">
        <!-- Weight Column -->
        <ng-container matColumnDef="imagen">
          <th mat-header-cell *matHeaderCellDef>Imagen</th>
          <td mat-cell *matCellDef="let element" data-label="Imagen">
            <div class="tdM">
              <img
                class="tablaImg"
                style="display: none"
                (load)="cargarImagen($event)"
                src="{{ url }}/medico/{{ element.imagen }}"
              />{{ element.nombreM }}
            </div>
          </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="nombreCompleto">
          <th mat-header-cell *matHeaderCellDef>Nombre</th>
          <td mat-cell *matCellDef="let element" data-label="Nombre">
            {{ element.nombreCompleto }}
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let element" data-label="Email">
            {{ element.email }}
          </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="cantidad">
          <th mat-header-cell *matHeaderCellDef>Pacientes</th>
          <td mat-cell *matCellDef="let element" data-label="Pacientes">
            {{ element.cantidad }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="example-element-row"
        ></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>
