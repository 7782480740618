import { Component, OnInit } from "@angular/core";
import { PlanService } from "app/services/plan.service";
import { UsuarioPanelService } from "app/services/usuario-panel.service";
import { SpinnerService } from "app/services/spinner.service";
import { Turno } from "../../../models/turno.interface";
import * as moment from "moment";

@Component({
  selector: "app-home-panel",
  templateUrl: "./home-panel.component.html",
  styleUrls: ["./home-panel.component.scss"],
})
export class HomePanelComponent implements OnInit {
  //
  orders = [];
  response: any;
  plan: any;
  indicadores: any;
  usuarioPanel: any;
  public today: string = moment().format("DD/MM/YYYY");

  public dataSource: any[] = [];

  //
  constructor(
    private planService: PlanService,
    private usuarioPanelService: UsuarioPanelService,
    private spinnerService: SpinnerService
  ) {}

  //
  ngOnInit(): void {
    this.getHomePanelData();
  }

  public getHomePanelData() {
    this.spinnerService.mostrarSpinner();
    this.planService.mostrarDatosHomeAdminPanel().subscribe({
      next: (data) => {
        this.response = data;

        this.dataSource = data.turnos?.filter((turno) => {
          const fechaTurno = new Date(turno.fecha_desde);
          const horaActual = new Date();
          return fechaTurno > horaActual;
        });

        this.spinnerService.ocultarSpinner();
      },
      error: () => {
        this.spinnerService.ocultarSpinner();
        this.usuarioPanelService.logout();
      },
    });
  }
}
