<!--<app-navbar></app-navbar>-->
<div class="panel">
    <div class="dashboard">
        <div class="dashboardContent mat-elevation-z4">
            <app-dashboard></app-dashboard>
        </div>
    </div>
    <div class="panelContent">
        <div class="headerPanel">
            <mat-icon aria-hidden="false" class="icon" aria-label="Example home icon">notifications</mat-icon>
            <mat-icon aria-hidden="false" class="icon" aria-label="Example home icon">person</mat-icon>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>