import { Component, OnInit, Input, QueryList, ViewChildren, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validator, Validators } from '@angular/forms';
import { ComentarioEstudioMedicoService } from 'app/services/comentario-estudio-medico.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-comentario-estudio-medico',
  templateUrl: './comentario-estudio-medico.component.html',
  styleUrls: ['./comentario-estudio-medico.component.scss']
})
export class ComentarioEstudioMedicoComponent implements OnInit {


  //
  @ViewChild('title') title!: ElementRef;
  isDisabledEdit: boolean = false;
  isDisabledCreate: boolean = true;
  createSpinner: boolean = false;
  tipoUsuario: string;
  idUsuario: string;
  listComentarioEstudioMedico: any[];
  idEstudioMedicoPaciente: string;
  screenHeight: any;
  @Input() set responseComentarios(value: any) {
    if (value) {
      console.log('value => ', value);
      this.tipoUsuario = value.tipoUsuario;
      this.idUsuario = value.usuario.id;
      this.listComentarioEstudioMedico = value.listComentarioEstudioMedico;
      this.idEstudioMedicoPaciente = value.idEstudioMedicoPaciente;
    }
  }
  comentarioEstudioMedicoForm = new FormGroup({
    texto: new FormControl(''),
  });


  //
  constructor(
    private spinner: NgxSpinnerService,
    private comentarioEstudioMedicoService: ComentarioEstudioMedicoService
  ) { }


  //
  ngOnInit(): void { }


  //
  editarComentario(item) {
    item.boton_editar = true;
  }


  //
  openMenu(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }


  //
  guardarComentarioEstudioMedico(value: any) {
    this.createSpinner = true;
    this.isDisabledEdit = true;
    this.isDisabledCreate = true;
    this.spinner.show('spinner_create_chat');
    this.comentarioEstudioMedicoService.crear(this.tipoUsuario, this.idUsuario, this.idEstudioMedicoPaciente, value).subscribe(
      data => {
        this.createSpinner = false;
        this.spinner.hide('spinner_create_chat');
        let element: any = data;
        element.spinner = false;
        element.boton_editar = false;
        element.disabled = false;
        this.listComentarioEstudioMedico.push(element);
        this.isDisabledEdit = false;
        console.log('this.comentarioEstudioMedicoForm.value.texto => ', this.comentarioEstudioMedicoForm);
        this.comentarioEstudioMedicoForm.patchValue({ texto: '' });
        this.isDisabledCreate = true;
      },
      error => {
        this.createSpinner = false;
        this.spinner.hide('spinner_create_chat');
        this.isDisabledEdit = false;
        if (this.comentarioEstudioMedicoForm.value.texto) this.isDisabledCreate = false;
        else this.isDisabledCreate = true;
        console.log('error => ', error);
      }
    )
  }


  //
  cancelarEdicion(item: any, index: any) {
    const textAreaComentarios = document.querySelectorAll('.textAreaComentario');
    textAreaComentarios.forEach((element: HTMLElement) => {
      if (item.id == element.getAttribute('data-id')) {
        (element as HTMLTextAreaElement).value = item.texto;
      }
    });
    item.boton_editar = false;
  }


  //
  confirmarEdicion(item: any, index: any) {
    this.isDisabledEdit = true;
    this.isDisabledCreate = true;
    item.spinner = true;
    this.spinner.show('spinner_chat_' + index);
    const textAreaComentarios = document.querySelectorAll('.textAreaComentario');
    let texto = '';
    textAreaComentarios.forEach((element: HTMLElement) => {
      if (item.id == element.getAttribute('data-id')) {
        texto = (element as HTMLTextAreaElement).value;
      }
    });
    item.texto = texto;
    item.boton_editar = false;
    this.comentarioEstudioMedicoService.editar(item.id, texto).subscribe(
      data => {
        item.spinner = false;
        this.spinner.hide('spinner_chat_' + index);
        this.isDisabledEdit = false;
        console.log('this.comentarioEstudioMedicoForm.value => ', this.comentarioEstudioMedicoForm.value);
        if (this.comentarioEstudioMedicoForm.value.texto) this.isDisabledCreate = false;
        else this.isDisabledCreate = true;
        console.log('data => ', data);
      },
      error => {
        item.spinner = false;
        this.spinner.hide('spinner_chat_' + index);
        this.isDisabledEdit = false;
        if (this.comentarioEstudioMedicoForm.value.texto) this.isDisabledCreate = false;
        else this.isDisabledCreate = true;
        console.log('error => ', error);
      }
    )
  }


  //
  valid(event: Event) {
    const text = event.target as HTMLTextAreaElement;
    if (text.value) this.isDisabledCreate = false;
    else this.isDisabledCreate = true;
  }


  //
  getClasses(index: number) {
    return (index === this.listComentarioEstudioMedico.length - 1) ? 'ultimoComentario' : 'comentario';
  }


  //
  getClassPaciente() {
    return (this.tipoUsuario == 'medico' || this.tipoUsuario == 'usuario_panel')
      ? 'listaDeComentarios'
      : 'listaDeComentariosPaciente';
  }


  //
  textAreaValid(event: Event, item: any) {
    const textarea = event.target as HTMLTextAreaElement;
    if (textarea.value) item.disabled = false;
    else item.disabled = true;
  }


}
