import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { UsuarioPanelService } from "app/services/usuario-panel.service";
import { SpinnerService } from "app/services/spinner.service";
import { ObraSocialService } from "app/services/obra-social.service";
import { NotificacionesPushService } from "app/services/notificaciones-push.service";
import { MatPaginator } from "@angular/material/paginator";
import { ObraSocial } from "app/models/obraSocial.interface";
import { ConfirmDialogComponent } from "app/shared/confirm-dialog/confirm-dialog.component";
import { DeleteErrorDialogComponent } from "app/shared/delete-error-dialog/delete-error-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { NotificationComponent } from "app/shared/notification/notification.component";
import { GlobalVariable } from "app/global/global";
import { MatSnackBar } from "@angular/material/snack-bar";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";

@Component({
  selector: "app-obra-social-index",
  templateUrl: "./obra-social-index.component.html",
  styleUrls: ["./obra-social-index.component.scss"],
})
export class ObraSocialIndexComponent implements OnInit {
  //
  editar: boolean = false;
  url: string = GlobalVariable.BASE_API_URL + "storage/images/obra-social/";
  displayedColumns: string[] = ["id", "nombre", "logo", "acciones"];
  @ViewChild("buscador") buscador: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: any;
  lista: any;
  usuarioPanel: any;
  data: any;
  searchKey: string;
  notificacionesPush: any[];
  listaObraSocial: any;
  private intervalId: any;

  //
  constructor(
    private usuarioPanelService: UsuarioPanelService,
    private spinnerService: SpinnerService,
    private obraSocialService: ObraSocialService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    this.spinnerService.mostrarSpinner();
    this.usuarioPanelService
      .profileUser()
      .pipe(
        map((data) => {
          this.usuarioPanel = data;
        }),
        catchError((error) => {
          this.spinnerService.ocultarSpinner();
          this.usuarioPanelService.logout();
          return of({ results: null });
        }),
        switchMap((data) =>
          this.obraSocialService.listar().pipe(
            map((data) => {
              console.log("data => ", data);
              this.listaObraSocial = data.listaObraSocial;
              this.listar(this.listaObraSocial);
              this.notificacionesPush = data.notificacionesPush;
              this.spinnerService.ocultarSpinner();
            })
          )
        ),
        catchError((error) => {
          this.spinnerService.ocultarSpinner();
          return of({ results: null });
        })
      )
      .subscribe(
        (data) => console.log("data", data),
        (error) => console.error("Error al llamar a la API:", error)
      );
  }

  //
  ngOnInit(): void {}

  //
  openDialog = (element: ObraSocial) => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "50%",
      data: "obra social",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.borrar(element);
      }
    });
  };

  //
  openBorrarErrorDialog = () => {
    let object = {
      titulo: "obra social",
      mensaje: "No se puede eliminar obras sociales con pacientes activos",
    };
    const dialogRef = this.dialog.open(DeleteErrorDialogComponent, {
      width: "50%",
      data: object,
    });
    dialogRef.afterClosed().subscribe((res) => {});
  };

  //
  listar = (data: any) => {
    this.data = data;
    this.data.forEach((element) => {
      element.validateImagen = 1;
    });
    this.dataSource = new MatTableDataSource<ObraSocial>(data);
    this.dataSource.paginator = this.paginator;
  };

  //
  borrar = (obraSocial: ObraSocial) => {
    this.spinnerService.mostrarSpinner();
    this.obraSocialService
      .borrar(obraSocial)
      .pipe(
        map((data) => {}),
        catchError((error) => {
          if (error.error.errorObraSocial) {
            this.openBorrarErrorDialog();
          }
          this.spinnerService.ocultarSpinner();
          return error;
        }),
        switchMap((data) =>
          this.obraSocialService.listar().pipe(
            map((data) => {
              this.listaObraSocial = data.listaObraSocial;
              this.listar(this.listaObraSocial);
              this.spinnerService.ocultarSpinner();
            })
          )
        ),
        catchError((error) => {
          this.spinnerService.ocultarSpinner();
          return error;
        })
      )
      .subscribe(
        (data) => console.log("data", data),
        (error) => console.error("Error al llamar a la API:", error)
      );
  };

  //
  filter = (event: any) => {
    let dataFilter: ObraSocial[] = [];
    let stringValue: string = this.buscador.nativeElement.value;
    this.data.forEach((obj) => {
      if (obj.id.toString().toUpperCase().includes(stringValue.toUpperCase())) {
        dataFilter.push(obj);
      } else if (obj.nombre.toUpperCase().includes(stringValue.toUpperCase())) {
        dataFilter.push(obj);
      }
    });

    this.dataSource = new MatTableDataSource<ObraSocial>(dataFilter);
    this.dataSource.paginator = this.paginator;
  };

  //
  listaActualizada = (mensaje: boolean) => {
    this.obraSocialService.listar().subscribe(
      (data) => {
        this.listaObraSocial = data.listaObraSocial;
        this.listar(this.listaObraSocial);
        this.spinnerService.ocultarSpinner();
      },
      (error) => {
        this.spinnerService.ocultarSpinner();
      }
    );
  };

  //
  openSnackBar = (message: string, action: string) => {
    this.snackBar.openFromComponent(NotificationComponent, {
      data: {
        message: message,
        action: action,
      },
      duration: 2500,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: [action],
    });
  };

  //
  cargarImagen = (event: any) => {
    (event.target as HTMLImageElement).style.display = "flex";
  };
}
