import { ElementRef, Component, OnInit, Input, ViewChild, Output } from '@angular/core';
import { GlobalVariable } from 'app/global/global';
import { CentroMedicoService } from 'app/services/centro-medico.service';
import { PacienteService } from 'app/services/paciente.service';
import { Router,ActivatedRoute } from '@angular/router';
import { NotificacionesPushService } from 'app/services/notificaciones-push.service';
import { SpinnerService } from 'app/services/spinner.service';
import { MatDialog } from '@angular/material/dialog';
import { ContactoDialogComponent } from 'app/shared/contacto-dialog/contacto-dialog.component';
import { Paciente } from 'app/models/paciente.interface';
import { environment } from 'environments/environment.prod';
import { MedicoService } from 'app/services/medico.service';
import { EventEmitter } from '@angular/core';


@Component({
  selector: 'app-header-medico',
  templateUrl: './header-medico.component.html',
  styleUrls: ['./header-medico.component.scss']
})
export class HeaderMedicoComponent implements OnInit {


  //
  @ViewChild('dropdown') dropdown: ElementRef;
  imagen:string;
  menu:string = 'true';
  medico:any;
  notificacionesPush:any[] = [];
  currentRouteName:any;
  @Output() messageEvent = new EventEmitter<string>();
  @Input() set medicoBinding(value: any) {
    if (value) {
      this.iniciarMedico(value);
    }
  }
  @Input() set notificacionesPushBinding(value: any) {
    if (value) {
      this.notificacionesPush = value;
    }
  }


  //
  constructor(
    private pacienteService:PacienteService,
    private medicoService:MedicoService,
    private router: Router,
    private notificacionesPushService: NotificacionesPushService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute
  ) {
    this.router.events.subscribe(() => {
      this.currentRouteName = this.route.snapshot.routeConfig.path;
    });
  }


  //
  mobileOpenClose() {}


  //
  ngOnInit(): void {}


  //
  leerNotificacion(object: any) {
    this.spinnerService.mostrarSpinner();
    this.notificacionesPushService.leerNotificacion(object.id).subscribe(
      data => {
        this.notificacionesPush = this.notificacionesPush.filter(dat => dat.id !== object.id);
        this.spinnerService.ocultarSpinner();
      },
      error => {
        console.log('error => ', error);
        this.spinnerService.ocultarSpinner();
      }
    )
  }


  //
  iniciarMedico(data){
    this.medico = data.medico;
    const imageUrl= `${GlobalVariable.BASE_API_URL}storage/images/medico/${this.medico.imagen}`;

    this.imagen = this.checkImageExists(imageUrl) ? imageUrl : `assets/img/${this.medico.imagen}`
  }

  checkImageExists(url: string): boolean {
    const img = new Image();
    img.src = url;
    return img.complete && img.naturalHeight !== 0;
  }


  //
  botonDesplegableShadow(){
    this.menu = (this.menu == 'true') ? 'false' : 'true';
    this.messageEvent.emit(this.menu);
  }


  //
  logout(){
    this.medicoService.logout();
  }

  //
  onMenuItemClick(event: MouseEvent) {
    event.stopPropagation();
  }

}
