<div class="divContent">
  <mat-accordion class="acordeonContent">
    <mat-expansion-panel
      hideToggle
      [expanded]="panelOpenState"
      class="mat-elevation-z4"
    >
      <mat-expansion-panel-header
        class="acordeonHeader"
        (click)="ocultarMostrar()"
      >
        <div class="topTitle">
          <mat-card class="titleSeccion">
            <span *ngIf="!editar">Crear centro médico</span>
            <span *ngIf="editar">Actualizar centro médico</span>
            <mat-icon *ngIf="!panelOpenState" class="iconArrow"
              >keyboard_arrow_down</mat-icon
            >
            <mat-icon *ngIf="panelOpenState" class="iconArrow"
              >keyboard_arrow_up</mat-icon
            >
          </mat-card>
        </div>
      </mat-expansion-panel-header>

      <form
        [formGroup]="centroMedicoForm"
        (submit)="guardarCentroMedico(centroMedicoForm.value)"
        class="formCard"
      >
        <div class="card">
          <div class="formGroup">
            <input hidden matInput formControlName="id" />
          </div>
          <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
              <mat-label>Nombre</mat-label>
              <input type="text" formControlName="nombre" matInput />
            </mat-form-field>
            <div class="valid">
              <span
                style="color: rgb(236, 75, 72)"
                *ngIf="
                  centroMedicoForm.get('nombre').errors?.required &&
                  centroMedicoForm.get('nombre').touched
                "
              >
                El nombre es requerida
              </span>
            </div>
          </div>
          <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
              <mat-label>Dirección</mat-label>
              <input type="text" formControlName="direccion" matInput />
            </mat-form-field>
            <div class="valid">
              <span
                style="color: rgb(236, 75, 72)"
                *ngIf="
                  centroMedicoForm.get('direccion').errors?.required &&
                  centroMedicoForm.get('direccion').touched
                "
              >
                La dirección es requerida
              </span>
            </div>
          </div>
          <div class="formGroup telefonoForm">
            <div class="tefonoPais">
              <mat-form-field appearance="fill" class="telefonoInput1">
                <mat-label>Teléfono país</mat-label>
                <mat-select formControlName="telPais">
                  <mat-option>
                    <ngx-mat-select-search
                      id="telPais"
                      [noEntriesFoundLabel]="'Not found'"
                      [placeholderLabel]="'Buscador'"
                      [formControl]="telPais"
                      name="filterPost"
                      (ngModelChange)="filtrarTelPais($event)"
                    >
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let tel of filtroTelefonoPais"
                    [value]="tel.codigo"
                  >
                    {{ tel.nombre }} ({{ tel.codigo }})
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="valid">
                <span
                  style="color: rgb(236, 75, 72)"
                  *ngIf="
                    centroMedicoForm.get('telPais').errors?.required &&
                    centroMedicoForm.get('telPais').touched
                  "
                >
                  El teléfono país es requerido
                </span>
              </div>
            </div>
            <div class="tefonoCaract">
              <mat-form-field
                class="example-full-width"
                class="input telefonoInput2"
                appearance="fill"
              >
                <mat-label>Teléfono caract.</mat-label>
                <input formControlName="telCaract" type="text" matInput />
              </mat-form-field>
              <div class="valid">
                <span
                  style="color: rgb(236, 75, 72)"
                  *ngIf="
                    centroMedicoForm.get('telCaract').errors?.required &&
                    centroMedicoForm.get('telCaract').touched
                  "
                >
                  La característica es requerida
                </span>
              </div>
            </div>
            <div class="tefonoNumero">
              <mat-form-field
                class="example-full-width"
                class="input telefonoInput3"
                appearance="fill"
              >
                <mat-label>Teléfono número</mat-label>
                <input formControlName="telNumero" type="text" matInput />
              </mat-form-field>
              <div class="valid">
                <span
                  style="color: rgb(236, 75, 72)"
                  *ngIf="
                    centroMedicoForm.get('telNumero').errors?.required &&
                    centroMedicoForm.get('telNumero').touched
                  "
                >
                  El número de teléfono es requerido
                </span>
              </div>
            </div>
          </div>
          <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
              <mat-label>Email</mat-label>
              <input type="email" formControlName="email" matInput />
            </mat-form-field>
            <div class="valid">
              <span
                style="color: rgb(236, 75, 72)"
                *ngIf="
                  centroMedicoForm.get('email').errors?.required &&
                  centroMedicoForm.get('email').touched
                "
              >
                El email es requerido
              </span>
              <span
                style="color: rgb(236, 75, 72)"
                *ngIf="
                  centroMedicoForm.get('email').errors?.email &&
                  centroMedicoForm.get('email').touched
                "
              >
                El email no tiene el formato correcto
              </span>
            </div>
          </div>
          <div class="formGroup">
            <mat-form-field class="formFile">
              <div class="myfilebrowser">
                <mat-toolbar>
                  <input
                    matInput
                    [(ngModel)]="myfilename"
                    readonly
                    formControlName="memberContactNo"
                  />
                  <button mat-raised-button class="fileButton" color="primary">
                    Seleccionar logo
                  </button>
                </mat-toolbar>
                <input
                  type="file"
                  #UploadFileInput
                  id="fileUpload"
                  (change)="fileChangeEvent($event)"
                  name="fileUpload"
                  multiple="multiple"
                  accept="image/*"
                />
              </div>
            </mat-form-field>
            <p class="textAclaration">
              Imagen debe contar con el mismo alto y ancho, max 1200*1200
            </p>
          </div>
          <div #divImagen class="divImagen">
            <img #mostrarImagen class="imagen" [src]="imagen" />
          </div>
          <div>
            <span style="color: rgb(236, 75, 72)" *ngIf="errorImage">
              La imagen es requerida
            </span>
          </div>
          <div class="formGroup">
            <mat-form-field class="formFile">
              <div class="myfilebrowser">
                <mat-toolbar>
                  <input
                    matInput
                    [(ngModel)]="sucursalArchivo"
                    readonly
                    formControlName="carousel"
                  />
                  <button mat-raised-button class="fileButton" color="primary">
                    Seleccionar carrusel
                  </button>
                </mat-toolbar>
                <input
                  type="file"
                  #UploadFileInput
                  id="fileUpload"
                  (change)="imagenesCarousel($event)"
                  name="fileUpload"
                  multiple="multiple"
                  accept="image/*"
                />
              </div>
            </mat-form-field>
            <p class="textAclaration">
              Imagen debe contar 400px de alto y 1500px ancho aproximadamente
            </p>
          </div>
          <div class="imagenesArray">
            <mat-card
              class="imagenesAgregadas mat-elevation-z3"
              *ngFor="let imagen of listCarousels; let i = index"
            >
              {{ imagen.name ? imagen.name : imagen.nombreImagen }}
              <div class="divIcons">
                <div class="divIcon ver" (click)="mostrar(imagen)">
                  <mat-icon class="icon">visibility</mat-icon>
                </div>
                <div class="divIcon borrar" (click)="borrarImagen(i)">
                  <mat-icon class="icon">delete_forever</mat-icon>
                </div>
              </div>
            </mat-card>
          </div>
          <div class="formButton">
            <button *ngIf="editar" mat-raised-button class="buttonForm">
              Actualizar
            </button>
            <button *ngIf="!editar" mat-raised-button class="buttonForm">
              Crear
            </button>
          </div>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>
