import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA,} from "@angular/material/dialog";

@Component({
  selector: "app-side-bar",
  templateUrl: "./side-bar.component.html",
  styleUrls: ["./side-bar.component.scss"],
})
export class SideBarComponent implements OnInit {
  @Input('status') isExpanded ;
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openDialog(): void {
    const dialogRef = this.dialog.open(ContactModal, {
      width: "450px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }
}

@Component({
  selector: "contact-modal",
  templateUrl: "./contact-modal.component.html",
})
export class ContactModal {
  constructor(
    public dialogRef: MatDialogRef<ContactModal>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
function DialogOverviewExampleDialog(
  DialogOverviewExampleDialog: any,
  arg1: { width: string; data: { name: any; animal: any } }
) {
  throw new Error("Function not implemented.");
}
