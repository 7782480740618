<div class="container" *ngIf="arrayDeErrores?.length > 0">
  <div class="header">
    <p class="title">Hay conflictos de agenda</p>
    <button mat-mini-fab (click)="close()" color="warn">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="conflictContainer" *ngFor="let error of arrayDeErrores">
    <div class="conflict">
      <p>
        Hay conflicto entre {{ error?.agenda1.nombre_sucursal }} y
        {{ error?.agenda2.nombre_sucursal }}
      </p>
      <p>
        Día/s:
        <span *ngFor="let day of error?.overlappingDays; index as i">
          {{ day | titlecase }}
          {{ i === error?.overlappingDays.length - 1 ? "" : ", " }}
        </span>
      </p>
    </div>
  </div>
</div>
