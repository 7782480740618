import { ElementRef,AfterViewInit,Component, OnInit,ViewChild,NgModule ,Inject ,Input,Output,EventEmitter} from '@angular/core';
import { FotoDialogComponent } from 'app/shared/foto-dialog/foto-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Medico } from 'app/models/medico.interface';
import { MedicoService } from 'app/services/medico.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { FormGroup,FormControl,Validator, Validators } from '@angular/forms';
import { TelefonoPaisService } from 'app/services/telefono-pais.service';
import { TelefonoPais } from 'app/models/telefonoPais.interface';
import { AbstractControl } from '@angular/forms';
import { GlobalVariable } from 'app/global/global';
import { TipoDocumentoService } from 'app/services/tipo-documento.service';
import { TipoDocumento } from 'app/models/tipoDocumento.interface';
import { SpinnerService } from 'app/services/spinner.service';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import * as moment from 'moment';


@Component({
  selector: 'app-crear-form-medico',
  templateUrl: './crear-form-medico.component.html',
  styleUrls: ['./crear-form-medico.component.scss']
})
export class CrearFormMedicoComponent implements OnInit {


  //
  funcionCorrecta:any;
  url:string = GlobalVariable.BASE_API_URL+'storage/images/medico/';
  editarValor:boolean;
  panelOpenState = false;
  @Input() set editar(value:boolean) {
    this.editarValor = value;
    this.panelOpenState = (value) ? true : false;
  }
  @Output() listar:EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('UploadFileInput') uploadFileInput:ElementRef;
  @ViewChild('divImagen') divImagen:ElementRef;
  telPais:FormControl = new FormControl();
  celPais:FormControl = new FormControl();
  imagen = "";
  nameImage:string = ""; 
  medico:any;
  file:File = null;
  myfilename = 'Seleccionar archivo';
  telefonoPais:TelefonoPais[];
  filtroTelefonoPais:TelefonoPais[] = [];
  filtroCelularPais:TelefonoPais[] = [];
  tiposDocumentos:TipoDocumento[];
  checked = false;
  disabled = false;
  especialidades:any;
  errorDocumento:any;
  errorNumeroDocumento:any;
  errorTipoDocumento:any;
  errorMatricula:any;
  errorEmail:any;
  errorAgenda:any;
  selectedColor:string = '#c32af3';
  agenda:boolean;
  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
        bodyBackgroundColor: '#ffffff',
        buttonColor: '#000000'
    },
    dial: {
        dialBackgroundColor: '#3434EC',
    },
    clockFace: {
        clockFaceBackgroundColor: '#EEEEEE',
        clockHandColor: '#05CE7C',
        clockFaceTimeInactiveColor: '#3434EC'
    }
  };
  public telPaisBuscador:FormControl = new FormControl();
  public celPaisBuscador:FormControl = new FormControl();
  medicoForm = new FormGroup({
    id: new FormControl(''),
    nombreCompleto: new FormControl('',[Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
    tipoDocumento: new FormControl(''),
    numeroDocumento: new FormControl(''),
    especialidad: new FormControl(''),
    matricula: new FormControl('',[Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
    email: new FormControl('',Validators.email),
    telPais: new FormControl(''),
    telPaisBuscador: new FormControl(''),
    telCaract: new FormControl(''),
    telNumero: new FormControl(''),
    celPais: new FormControl(''),
    celPaisBuscador: new FormControl(''),
    celCaract: new FormControl(''),
    celNumero: new FormControl(''),
    agenda: new FormControl(false),
    codigoInterno: new FormControl(''),
    memberContactNo: new FormControl('Seleccionar archivo'),
    notificaciones: new FormControl(true),
    password: new FormControl(''),
    repeatPassword: new FormControl(''),
    color_agenda: new FormControl('#000ec4'),
    lunesDesde: new FormControl(''),
    lunesHasta: new FormControl(''),
    martesDesde: new FormControl(''),
    martesHasta: new FormControl(''),
    miercolesDesde: new FormControl(''),
    miercolesHasta: new FormControl(''),
    juevesDesde: new FormControl(''),
    juevesHasta: new FormControl(''),
    viernesDesde: new FormControl(''),
    viernesHasta: new FormControl(''),
    sabadoDesde: new FormControl(''),
    sabadoHasta: new FormControl(''),
    domingoDesde: new FormControl(''),
    domingoHasta: new FormControl(''),
  },[
    this.passwordMatchValidator,
    this.validarFechaLunes,
    this.validarFechaMartes,
    this.validarFechaMiercoles,
    this.validarFechaJueves,
    this.validarFechaViernes,
    this.validarFechaSabado,
    this.validarFechaDomingo,
    this.validarTodasLasFechas,
  ]);
  @Input() set response(value: any) {
    if(value){
      this.formularioMedico(value);
      if(value.medico){
        this.iniciarFormularioEditado(value);
      }
    }
  }


  //
  constructor(
    public fotoDialog: MatDialog,
    private medicoService:MedicoService,
    private actRoute:ActivatedRoute,
    private router:Router,
    private snackBar:MatSnackBar,
    private apiTPS:TelefonoPaisService,
    private apiTDS:TipoDocumentoService,
    private spinnerService:SpinnerService,
  ) { }


  //
  ngOnInit(): void {}


  //
  clearNumeroDocumento(){
    this.errorNumeroDocumento = false;
    this.errorDocumento = false;
  }


  //
  clearTipoDocumento(){
    this.errorTipoDocumento = false;
    this.errorDocumento = false;
  }


  //
  clearMatricula = () => {
    this.errorMatricula = false;
  }


  //
  clearEmail = () => {
    this.errorEmail = false;
  }


  //
  validarFechaLunes(g: FormGroup) {
    if(g.get('lunesDesde')?.value && !g.get('lunesHasta')?.value){
      return {'lunesError1': true};
    }
    else if(!g.get('lunesDesde')?.value && g.get('lunesHasta')?.value){
      return {'lunesError2': true};
    }
    else if(g.get('lunesDesde')?.value && g.get('lunesHasta')?.value){
      let lunesDesde = moment(g.get('lunesDesde')?.value, 'HH:mm');
      let lunesHasta = moment(g.get('lunesHasta')?.value, 'HH:mm');
      if(!lunesDesde.isBefore(lunesHasta)){
        return {'lunesError3': true};
      }
    }
    return null;
  }


  //
  validarFechaMartes(g: FormGroup) {
    if(g.get('martesDesde')?.value && !g.get('martesHasta')?.value){
      return {'martesError1': true};
    }
    else if(!g.get('martesDesde')?.value && g.get('martesHasta')?.value){
      return {'martesError2': true};
    }
    else if(g.get('martesDesde')?.value && g.get('martesHasta')?.value){
      let martesDesde = moment(g.get('martesDesde')?.value, 'HH:mm');
      let martesHasta = moment(g.get('martesHasta')?.value, 'HH:mm');
      if(!martesDesde.isBefore(martesHasta)){
        return {'martesError3': true};
      }
    }
    return null;
  }


  //
  validarFechaMiercoles(g: FormGroup) {
    if(g.get('miercolesDesde')?.value && !g.get('miercolesHasta')?.value){
      return {'miercolesError1': true};
    }
    else if(!g.get('miercolesDesde')?.value && g.get('miercolesHasta')?.value){
      return {'miercolesError2': true};
    }
    else if(g.get('miercolesDesde')?.value && g.get('miercolesHasta')?.value){
      let miercolesDesde = moment(g.get('miercolesDesde')?.value, 'HH:mm');
      let miercolesHasta = moment(g.get('miercolesHasta')?.value, 'HH:mm');
      if(!miercolesDesde.isBefore(miercolesHasta)){
        return {'miercolesError3': true};
      }
    }
    return null;
  }


  //
  validarFechaJueves(g: FormGroup) {
    if(g.get('juevesDesde')?.value && !g.get('juevesHasta')?.value){
      return {'juevesError1': true};
    }
    else if(!g.get('juevesDesde')?.value && g.get('juevesHasta')?.value){
      return {'juevesError2': true};
    }
    else if(g.get('juevesDesde')?.value && g.get('juevesHasta')?.value){
      let juevesDesde = moment(g.get('juevesDesde')?.value, 'HH:mm');
      let juevesHasta = moment(g.get('juevesHasta')?.value, 'HH:mm');
      if(!juevesDesde.isBefore(juevesHasta)){
        return {'juevesError3': true};
      }
    }
    return null;
  }


  //
  validarFechaViernes(g: FormGroup) {
    if(g.get('viernesDesde')?.value && !g.get('viernesHasta')?.value){
      return {'viernesError1': true};
    }
    else if(!g.get('viernesDesde')?.value && g.get('viernesHasta')?.value){
      return {'viernesError2': true};
    }
    else if(g.get('viernesDesde')?.value && g.get('viernesHasta')?.value){
      let viernesDesde = moment(g.get('viernesDesde')?.value, 'HH:mm');
      let viernesHasta = moment(g.get('viernesHasta')?.value, 'HH:mm');
      if(!viernesDesde.isBefore(viernesHasta)){
        return {'viernesError3': true};
      }
    }
    return null;
  }


  //
  validarFechaSabado(g: FormGroup) {
    if(g.get('sabadoDesde')?.value && !g.get('sabadoHasta')?.value){
      return {'sabadoError1': true};
    }
    else if(!g.get('sabadoDesde')?.value && g.get('sabadoHasta')?.value){
      return {'sabadoError2': true};
    }
    else if(g.get('sabadoDesde')?.value && g.get('sabadoHasta')?.value){
      let sabadoDesde = moment(g.get('sabadoDesde')?.value, 'HH:mm');
      let sabadoHasta = moment(g.get('sabadoHasta')?.value, 'HH:mm');
      if(!sabadoDesde.isBefore(sabadoHasta)){
        return {'sabadoError3': true};
      }
    }
    return null;
  }



  //
  validarFechaDomingo(g: FormGroup) {
    if(g.get('domingoDesde')?.value && !g.get('domingoHasta')?.value){
      return {'domingoError1': true};
    }
    else if(!g.get('domingoDesde')?.value && g.get('domingoHasta')?.value){
      return {'domingoError2': true};
    }
    else if(g.get('domingoDesde')?.value && g.get('domingoHasta')?.value){
      let domingoDesde = moment(g.get('domingoDesde')?.value, 'HH:mm');
      let domingoHasta = moment(g.get('domingoHasta')?.value, 'HH:mm');
      if(!domingoDesde.isBefore(domingoHasta)){
        return {'domingoError3': true};
      }
    }
    return null;
  }


  //
  validarTodasLasFechas(g: FormGroup){
    let agenda = g.get('agenda')?.value;
    let lunesDesde = g.get('lunesDesde')?.value;
    let lunesHasta = g.get('lunesHasta')?.value;
    let martesDesde = g.get('martesDesde')?.value;
    let martesHasta = g.get('martesHasta')?.value;
    let miercolesDesde = g.get('miercolesDesde')?.value;
    let miercolesHasta = g.get('miercolesHasta')?.value;
    let juevesDesde = g.get('juevesDesde')?.value;
    let juevesHasta = g.get('juevesHasta')?.value;
    let viernesDesde = g.get('viernesDesde')?.value;
    let viernesHasta = g.get('viernesHasta')?.value;
    let sabadoDesde = g.get('sabadoDesde')?.value;
    let sabadoHasta = g.get('sabadoHasta')?.value;
    let domingoDesde = g.get('domingoDesde')?.value;
    let domingoHasta = g.get('domingoHasta')?.value;
    if(
      agenda == true &&
      !lunesDesde && !lunesHasta &&
      !martesDesde && !martesHasta &&
      !miercolesDesde && !miercolesHasta &&
      !juevesDesde && !juevesHasta &&
      !viernesDesde && !viernesHasta &&
      !sabadoDesde && !sabadoHasta &&
      !domingoDesde && !domingoHasta
    ){
      return {'fechasError': true};
    }
    return null;
  }


  //
  borrarFecha(fecha:any){
    this.medicoForm.patchValue({
      [fecha]: '',
    });
  }


  //
  passwordMatchValidator(g: FormGroup){
    return (g.get('password').value === g.get('repeatPassword').value) ? null : {'mismatch': true};
  }


  //
  formularioMedico = (data:any) => {
    this.telefonoPais = data.listaTelefonoPais;
    this.filtroTelefonoPais = data.listaTelefonoPais;
    this.filtroCelularPais = data.listaTelefonoPais;
    this.tiposDocumentos = data.listatipoDocumento; 
    this.especialidades = data.listaEspecialidad;    
  }


  //
  iniciarFormularioEditado = (data:any) => {
    this.medico = data.medico;
    if(!this.imagen || !this.imagen){
      this.imagen = `${this.url}${this.medico.imagen}`;
      this.divImagen.nativeElement.style.display = "block";
      this.nameImage = this.medico.imagen;
    } 
    
    this.agenda  = this.medico.agenda;
    this.medicoForm.patchValue({
      id: this.medico.id,
      nombreCompleto: (this.medico.nombreCompleto) ? this.medico.nombreCompleto : '',
      tipoDocumento: (this.medico.tipoDocumento) ? parseInt(this.medico.tipoDocumento) : '',
      numeroDocumento: (this.medico.numeroDocumento) ? this.medico.numeroDocumento : '',
      especialidad: (this.medico.especialidad) ? this.medico.especialidad : '',
      matricula: (this.medico.matricula) ? this.medico.matricula : '',
      email: (this.medico.email) ? this.medico.email : '',
      telPais: (this.medico.telPais) ? this.medico.telPais : '',
      telPaisBuscador: '',
      telCaract: (this.medico.telCaract) ? this.medico.telCaract : '',
      telNumero: (this.medico.telNumero) ? this.medico.telNumero : '',
      celPais: (this.medico.celPais) ? this.medico.celPais : '',
      celPaisBuscador: '',
      celCaract: (this.medico.celCaract) ? this.medico.celCaract : '',
      celNumero: (this.medico.celNumero) ? this.medico.celNumero : '',
      agenda: ((this.medico.agenda.toString() == '0') ? false : true),
      codigoInterno: (this.medico.codigoInterno) ? this.medico.codigoInterno : '',
      memberContactNo: (this.medico.imagen) ? this.medico.imagen : 'Seleccionar archivo',
      notificaciones: ((this.medico.notificaciones.toString() == '0') ? false : true),
      color_agenda: (this.medico.color_agenda) ? this.medico.color_agenda : '#000ec4',
    });

    if(data.agendaMedico){
      this.medicoForm.patchValue({
        lunesDesde: (data.agendaMedico.lunes_desde) ? data.agendaMedico.lunes_desde : '',
        lunesHasta: (data.agendaMedico.lunes_hasta) ? data.agendaMedico.lunes_hasta : '',
        martesDesde: (data.agendaMedico.martes_desde) ? data.agendaMedico.martes_desde : '',
        martesHasta: (data.agendaMedico.martes_hasta) ? data.agendaMedico.martes_hasta : '',
        miercolesDesde: (data.agendaMedico.miercoles_desde) ? data.agendaMedico.miercoles_desde : '',
        miercolesHasta: (data.agendaMedico.miercoles_hasta) ? data.agendaMedico.miercoles_hasta : '',
        juevesDesde: (data.agendaMedico.jueves_desde) ? data.agendaMedico.jueves_desde : '',
        juevesHasta: (data.agendaMedico.jueves_hasta) ? data.agendaMedico.jueves_hasta : '',
        viernesDesde: (data.agendaMedico.viernes_desde) ? data.agendaMedico.viernes_desde : '',
        viernesHasta: (data.agendaMedico.viernes_hasta) ? data.agendaMedico.viernes_hasta : '',
        sabadoDesde: (data.agendaMedico.sabado_desde) ? data.agendaMedico.sabado_desde : '',
        sabadoHasta: (data.agendaMedico.sabado_hasta) ? data.agendaMedico.sabado_hasta : '',
        domingoDesde: (data.agendaMedico.domingo_desde) ? data.agendaMedico.domingo_desde : '',
        domingoHasta: (data.agendaMedico.domingo_hasta) ? data.agendaMedico.domingo_hasta : '',
      });
    }
  }


  //
  filtrarTelPais = (event:any) => {
    let textBuscador:string = this.telPaisBuscador.value;
    this.filtroTelefonoPais = this.telefonoPais.filter(function(element){
      if(element.nombre.toUpperCase().includes(textBuscador.toUpperCase())){
        return element;
      }
    });
  }


  //
  filtrarCelPais = (event:any) => {
    let textBuscador:string = this.celPaisBuscador.value;
    this.filtroCelularPais = this.telefonoPais.filter(function(element){
      if(element.nombre.toUpperCase().includes(textBuscador.toUpperCase())){
        return element;
      }
    });
  }


  //
  fileChangeEvent = (event: any) => {
    var target = event.target || event.srcElement;
  }


  //
  cambiarAgenda = (event) => {
    this.agenda = event.checked;
    this.errorAgenda = false;
  }


  //
  guardarMedico(form:any){
    this.funcionCorrecta = (this.editarValor) ? this.editarMedico(form) : this.crearMedico(form);   
  }


  //
  crearMedico(form:any){
    if(this.medicoForm.valid){
      this.spinnerService.mostrarSpinner();
      this.medicoService.crear(this.nameImage,form,this.file).subscribe(
        data => {
          let response:any = data;
          if(response.statusError){
            this.errorNumeroDocumento = response.errorNumeroDocumento;
            this.errorTipoDocumento = response.errorTipoDocumento;
            this.errorDocumento = response.errorDocumento;
            this.errorMatricula = response.errorMatricula;
            this.errorEmail = response.errorEmail;
            this.errorAgenda = response.errorAgenda;
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Guardado fallido","error");
          }
          else{
            this.openSnackBar("Guardado exitoso","exito");
            this.listar.emit();
            this.resetearFormulario();
          }
        },
        error => {
          console.log('error',error);
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Guardado fallido","error");
        }
      );
    }
    else{
      this.openSnackBar("Guardado fallido","error");
    }
  }


  //
  editarMedico(form:any){
    if(this.medicoForm.valid){
      this.spinnerService.mostrarSpinner();
      this.medicoService.editar(this.nameImage,form,this.file).subscribe(
        data => {
          console.log('data',data);
          let response:any = data;
          if(response.statusError){
            this.errorNumeroDocumento = response.errorNumeroDocumento;
            this.errorTipoDocumento = response.errorTipoDocumento;
            this.errorDocumento = response.errorDocumento;
            this.errorMatricula = response.errorMatricula;
            this.errorEmail = response.errorEmail;
            this.errorAgenda = response.errorAgenda;
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Editado fallido","error");
          }
          else{
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Editado exitoso","exito");
            this.router.navigate(['/seccion-panel/medico/']);
          }
        },
        error => {
          console.log(error);
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Editado fallido","error");
        }
      );
    }
  }


  //
  ocultarMostrar = () => {
    this.panelOpenState = (this.panelOpenState == true) ? false : true;
  }


  //
  resetearFormulario = () => {
    this.file = null;
    this.medicoForm.reset();
    this.imagen = "";
    this.myfilename = 'Seleccionar archivo';
    this.medicoForm.patchValue({
      nombreCompleto: '',
      tipoDocumento: '',
      numeroDocumento: '',
      especialidad: '',
      matricula: '',
      email: '',
      telPais: '',
      telPaisBuscador: '',
      telCaract: '',
      telNumero: '',
      celPais: '',
      celPaisBuscador: '',
      celCaract: '',
      celNumero: '',
      agenda: false,
      codigoInterno: '',
      memberContactNo: 'Seleccionar archivo',
      notificaciones: true,
      password: '',
      repeatPassword: '',
      color_agenda: '#000ec4', 
      lunesDesde: '',
      lunesHasta: '',
      martesDesde: '',
      martesHasta: '',
      miercolesDesde: '',
      miercolesHasta: '',
      juevesDesde: '',
      juevesHasta: '',
      viernesDesde: '',
      viernesHasta: '',
      sabadoDesde: '',
      sabadoHasta: '',
      domingoDesde: '',
      domingoHasta: '',
    });
    this.agenda = false;
    this.errorAgenda = false;
    this.divImagen.nativeElement.style.display = "none";
  }


  //
  openDialogFoto = () => {
    const dialogRef = this.fotoDialog.open(FotoDialogComponent,{
      width: '50%',
    })
    dialogRef.afterClosed().subscribe(res=>{
      if(res.data == "" && res.img == ""){}
      else{
        if(res.data.id == "ico_paciente.png" || res.data.id == "ico_pacienteM.png"){
          this.myfilename = res.data.id;
          this.imagen = 'assets/img/'+res.data.id+'';
          this.nameImage = res.data.id;
          this.divImagen.nativeElement.style.display = "block";
        }
        else{
          this.myfilename = res.data.name
          this.imagen = res.img;
          this.divImagen.nativeElement.style.display = "block";     
          this.file = res.data;
          this.nameImage = "";     
        }
      }
    })
  }


  //
  openSnackBar = (message:string, action:string) => {
      this.snackBar.openFromComponent(NotificationComponent,{
      data: {
        message: message,
        action: action,
      },
      duration: 2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [action]
    });
  }


}
