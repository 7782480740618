import { Injectable } from '@angular/core';
import { TipoDocumento } from 'app/models/tipoDocumento.interface';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { GlobalVariable } from 'app/global/global';
import { Observable,Subject, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TipoDocumentoService {


  //
  url:string = GlobalVariable.BASE_API_URL+"api/tipo-documento/";
  private tipoDocumentoSubject = new Subject<any>();


  //
  constructor(private http:HttpClient) { }


  //
  listar():Observable<any>{
    return this.http.get(this.url+"listar");
  }


  //
  crear(form:TipoDocumento):Observable<any>{
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    formData.append("texto", form.texto.trim());
    return this.http.post(this.url+"crear",formData);
  }


  //
  borrar(form:TipoDocumento):Observable<any>{
    return this.http.delete(this.url+"borrar/"+form.id);
  }


  //
  mostrar(id):Observable<any>{
    return this.http.get(this.url+"mostrar/"+id);
  }


  //
  editar(form:TipoDocumento):Observable<any>{
    const formData = new FormData(); 
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    formData.append("id", form.id.toString());
    formData.append("texto", form.texto.trim());
    return this.http.post(this.url+"editar",formData);
  }


  //
  getTipoDocumento = this.tipoDocumentoSubject.asObservable(); 


  //
  emitChangeTipoDocumento(value:any) {
    this.tipoDocumentoSubject.next(value);
  }


}
