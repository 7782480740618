<div class="panel">
    <div class="dashboard">
        <div class="dashboardContent">
            <app-dashboard [usuarioPanel]="usuarioPanel"></app-dashboard>
        </div>
    </div>
    <div class="panelContent">
        <app-header-panel [usuarioPanelBinding]="usuarioPanel" [notificacionesPushBinding]="notificacionesPush"></app-header-panel>
        <app-form-sucursal [response]="response" [editar]="editar"></app-form-sucursal>
    </div>
</div>


