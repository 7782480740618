import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UserRoutingModule } from "./user-routing.module";
import { StudiesComponent } from "./pages/studies/studies.component";
import { StudiesDetailComponent } from "./pages/studies-detail/studies-detail.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { PrimeNgModule } from "app/prime-ng/prime-ng.module";
import { SharedModule } from "app/shared/shared.module";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "app/material/material.module";


@NgModule({
  declarations: [
    StudiesComponent, 
    StudiesDetailComponent, 
    ProfileComponent
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    RouterModule,
    PrimeNgModule,
    MaterialModule,
    SharedModule,
  ],
  exports: [
    StudiesComponent, 
    StudiesDetailComponent, 
    ProfileComponent
  ]
})
export class UserModule {}
