<div class="divContent">
  <mat-accordion class="acordeonContent">
    <mat-expansion-panel
      hideToggle
      [expanded]="panelOpenState"
      class="mat-elevation-z4"
    >
      <mat-expansion-panel-header
        class="acordeonHeader"
        (click)="ocultarMostrar()"
      >
        <div class="topTitle">
          <mat-card class="titleSeccion">
            <span *ngIf="!editarValor">Crear obra social</span>
            <span *ngIf="editarValor">Editar obra social</span>
            <mat-icon *ngIf="!panelOpenState" class="iconArrow"
              >keyboard_arrow_down</mat-icon
            >
            <mat-icon *ngIf="panelOpenState" class="iconArrow"
              >keyboard_arrow_up</mat-icon
            >
          </mat-card>
        </div>
      </mat-expansion-panel-header>

      <form
        [formGroup]="obraSocialForm"
        (submit)="guardarObraSocial(obraSocialForm.value)"
        class="formCard"
      >
        <div class="card">
          <div class="formGroup">
            <input hidden matInput formControlName="id" />
          </div>
          <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
              <mat-label>Nombre</mat-label>
              <input
                formControlName="nombre"
                type="text"
                (ngModelChange)="clearNombre()"
                matInput
              />
            </mat-form-field>
            <span
              style="color: rgb(236, 75, 72)"
              *ngIf="
                obraSocialForm.get('nombre').errors &&
                (obraSocialForm.get('nombre').dirty ||
                  obraSocialForm.get('nombre').touched)
              "
            >
              El nombre es requerido
            </span>
            <span
              style="color: rgb(236, 75, 72)"
              *ngIf="errorNombre && obraSocialForm.get('nombre').touched"
            >
              El nombre fue registrado por otra obra social
            </span>
          </div>
          <div class="formGroup">
            <mat-form-field class="formFile">
              <div class="myfilebrowser">
                <mat-toolbar>
                  <input
                    matInput
                    [(ngModel)]="myfilename"
                    readonly
                    formControlName="memberContactNo"
                  />
                  <button mat-raised-button class="fileButton" color="primary">
                    Buscar
                  </button>
                </mat-toolbar>
                <input
                  type="file"
                  #UploadFileInput
                  id="fileUpload"
                  (change)="fileChangeEvent($event)"
                  name="fileUpload"
                  accept="image/*"
                />
              </div>
            </mat-form-field>
            <p class="textAclaration">
              Imagen debe contar con el mismo alto y ancho, max 1200*1200
            </p>
          </div>
          <div #divImagen class="divImagen">
            <img #mostrarImagen class="imagen" [src]="imagen" />
          </div>
          <div class="formButton">
            <button *ngIf="!editarValor" mat-raised-button class="buttonForm">
              Crear
            </button>
            <button
              *ngIf="editarValor"
              mat-raised-button
              class="buttonForm Cancelar"
              routerLink="../../"
            >
              Cancelar
            </button>
            <button *ngIf="editarValor" mat-raised-button class="buttonForm">
              Editar
            </button>
          </div>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>
