<div class="divContent">
  <mat-accordion class="acordeonContent">
    <mat-expansion-panel
      hideToggle
      [expanded]="panelOpenState"
      class="mat-elevation-z4"
    >
      <mat-expansion-panel-header
        class="acordeonHeader"
        (click)="ocultarMostrar()"
      >
        <div class="topTitle">
          <mat-card class="titleSeccion">
            <span *ngIf="!editarValor">Crear médico</span>
            <span *ngIf="editarValor">Editar médico</span>
            <mat-icon *ngIf="!panelOpenState" class="iconArrow"
              >keyboard_arrow_down</mat-icon
            >
            <mat-icon *ngIf="panelOpenState" class="iconArrow"
              >keyboard_arrow_up</mat-icon
            >
          </mat-card>
        </div>
      </mat-expansion-panel-header>

      <form
        [formGroup]="medicoForm"
        (submit)="guardarMedico(medicoForm.value)"
        class="formCard"
      >
        <div class="card">
          <div class="formGroup">
            <input hidden matInput formControlName="id" />
          </div>
          <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
              <mat-label>Nombre apellido</mat-label>
              <input formControlName="nombreCompleto" type="text" matInput />
            </mat-form-field>
            <div class="valid">
              <span
                *ngIf="
                  medicoForm.get('nombreCompleto').errors?.required &&
                  medicoForm.get('nombreCompleto').touched
                "
              >
                El nombre y apellido es requerido
              </span>
            </div>
          </div>
          <div>
            <div class="formGroup documento">
              <div class="tipoDocumento">
                <mat-form-field appearance="fill" class="input input1">
                  <mat-label>Tipo documento</mat-label>
                  <mat-select
                    formControlName="tipoDocumento"
                    (ngModelChange)="clearTipoDocumento()"
                  >
                    <mat-option
                      *ngFor="let tipoDoc of tiposDocumentos"
                      [value]="tipoDoc.id"
                      >{{ tipoDoc.texto }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <div class="valid">
                  <span *ngIf="errorTipoDocumento">
                    El tipo de documento es requerido con el numero de documento
                  </span>
                </div>
              </div>
              <div class="numeroDocumento">
                <mat-form-field
                  class="example-full-width input input2"
                  appearance="fill"
                >
                  <mat-label>Número documento</mat-label>
                  <input
                    formControlName="numeroDocumento"
                    (ngModelChange)="clearNumeroDocumento()"
                    type="text"
                    matInput
                  />
                </mat-form-field>
                <div class="valid">
                  <span *ngIf="errorNumeroDocumento">
                    El número de documento es requerido con el tipo de documento
                  </span>
                </div>
              </div>
            </div>
            <div class="valid2">
              <span *ngIf="errorDocumento">
                Este número de documento fue registrado para otro médico
              </span>
            </div>
          </div>
          <div class="formGroup">
            <div class="tipoDocumento">
              <mat-form-field appearance="fill" class="input input1">
                <mat-label>Especialidad</mat-label>
                <mat-select formControlName="especialidad">
                  <mat-option
                    *ngFor="let espe of especialidades"
                    [value]="espe.id"
                    >{{ espe.texto }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="formGroup">
            <mat-form-field class="formFile">
              <div class="myfilebrowser">
                <mat-toolbar>
                  <input
                    matInput
                    [(ngModel)]="myfilename"
                    readonly
                    formControlName="memberContactNo"
                  />
                  <button mat-raised-button class="fileButton" color="primary">
                    Buscar
                  </button>
                </mat-toolbar>
                <input
                  type="button"
                  #UploadFileInput
                  id="fileUpload"
                  (click)="openDialogFoto()"
                  name="fileUpload"
                  accept="image/*"
                />
              </div>
            </mat-form-field>
            <div class="divText">
              <span class="textAclaration">
                Imagen debe contar con el mismo alto y ancho, max 1200*1200
              </span>
            </div>
          </div>
          <div #divImagen class="divImagen">
            <img class="imagen" #mostrarImagen [src]="imagen" />
          </div>
          <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
              <mat-label>Matrícula</mat-label>
              <input
                formControlName="matricula"
                (ngModelChange)="clearMatricula()"
                type="text"
                matInput
              />
            </mat-form-field>
            <div class="valid">
              <span
                *ngIf="
                  medicoForm.get('matricula').errors?.required &&
                  medicoForm.get('matricula').touched
                "
              >
                La matrícula es requerida
              </span>
              <span
                *ngIf="errorMatricula && medicoForm.get('matricula').touched"
              >
                La matrícula fue registrada por otro medico
              </span>
            </div>
          </div>
          <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
              <mat-label>Email</mat-label>
              <input
                formControlName="email"
                (ngModelChange)="clearEmail()"
                type="email"
                matInput
              />
            </mat-form-field>
            <div class="valid">
              <span
                class="advertenciaMensaje"
                *ngIf="!medicoForm.get('email').value"
              >
                <mat-icon class="advertenciaIcono">report_problem</mat-icon>Si
                no se asigna un email, no le llegaran notificaciones que pueden
                ser importantes
              </span>
              <span
                *ngIf="
                  medicoForm.get('email').errors?.email &&
                  medicoForm.get('email').touched
                "
              >
                El email no tiene el formato correcto
              </span>
              <span *ngIf="errorEmail && medicoForm.get('email').touched">
                El email fue registrado por otro medico
              </span>
            </div>
          </div>
          <div class="formGroup telefonoForm">
            <div class="tefonoPais">
              <mat-form-field appearance="fill" class="telefonoInput1">
                <mat-label>Teléfono país</mat-label>
                <mat-select formControlName="telPais">
                  <mat-option>
                    <ngx-mat-select-search
                      id="telPaisBuscador"
                      [noEntriesFoundLabel]="'Not found'"
                      [placeholderLabel]="'Buscador'"
                      [formControl]="telPaisBuscador"
                      name="filterPost"
                      (ngModelChange)="filtrarTelPais($event)"
                    >
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let tel of filtroTelefonoPais"
                    [value]="tel.codigo"
                  >
                    {{ tel.nombre }} ({{ tel.codigo }})
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="tefonoCaract">
              <mat-form-field
                class="example-full-width"
                class="telefonoInput2"
                appearance="fill"
              >
                <mat-label>Teléfono caract.</mat-label>
                <input formControlName="telCaract" type="text" matInput />
              </mat-form-field>
            </div>
            <div class="tefonoNumero">
              <mat-form-field
                class="example-full-width"
                class="telefonoInput3"
                appearance="fill"
              >
                <mat-label>Telefono número</mat-label>
                <input formControlName="telNumero" type="text" matInput />
              </mat-form-field>
            </div>
          </div>
          <div class="formGroup telefonoForm">
            <mat-form-field appearance="fill" class="telefonoInput1">
              <mat-label>Celular país</mat-label>
              <mat-select formControlName="celPais">
                <mat-option>
                  <ngx-mat-select-search
                    id="celPaisBuscador"
                    [noEntriesFoundLabel]="'Not found'"
                    [placeholderLabel]="'Buscador'"
                    [formControl]="celPaisBuscador"
                    name="filterPost"
                    (ngModelChange)="filtrarCelPais($event)"
                  >
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let tel of filtroCelularPais"
                  [value]="tel.codigo"
                >
                  {{ tel.nombre }} ({{ tel.codigo }})
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              class="example-full-width"
              appearance="fill"
              class="telefonoInput2"
            >
              <mat-label>Celular caract.</mat-label>
              <input formControlName="celCaract" type="text" matInput />
            </mat-form-field>
            <mat-form-field
              class="example-full-width"
              appearance="fill"
              class="telefonoInput3"
            >
              <mat-label>Celular número</mat-label>
              <input formControlName="celNumero" type="text" matInput />
            </mat-form-field>
          </div>
          <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
              <mat-label>Código interno</mat-label>
              <input formControlName="codigoInterno" type="text" matInput />
            </mat-form-field>
          </div>
          <div *ngIf="!editarValor">
            <div class="formGroup">
              <mat-form-field
                class="example-full-width input"
                appearance="fill"
              >
                <mat-label>Password</mat-label>
                <input formControlName="password" type="password" matInput />
              </mat-form-field>
            </div>
            <div class="formGroup">
              <mat-form-field
                class="example-full-width input"
                appearance="fill"
              >
                <mat-label>Repetir password</mat-label>
                <input
                  formControlName="repeatPassword"
                  type="password"
                  matInput
                />
              </mat-form-field>
              <div class="valid">
                <span
                  *ngIf="
                    medicoForm.hasError('mismatch') &&
                    medicoForm.get('repeatPassword').touched
                  "
                >
                  Los passwords no son iguales
                </span>
              </div>
            </div>
          </div>
          <div class="formGroup formAgenda">
            <div class="agenda">
              <mat-slide-toggle
                formControlName="agenda"
                class="example-margin"
                color="primary"
                (change)="cambiarAgenda($event)"
              >
                Agenda
              </mat-slide-toggle>
            </div>
            <div *ngIf="agenda">
              <input formControlName="color_agenda" type="color" />
            </div>
          </div>
          <div class="valid">
            <span *ngIf="errorAgenda">
              Se alcanzo el límite de agendas habilitadas
            </span>
          </div>
          <div class="formGroup" *ngIf="sucursales.length > 1 && agenda">
            <div class="tipoDocumento">
              <mat-form-field appearance="fill" class="input input1">
                <mat-label>Sucursales</mat-label>
                <mat-select formControlName="sucursalSeleccionada">
                  <mat-option *ngFor="let suc of sucursales" [value]="suc.id">{{
                    suc.sucursal === 0
                      ? suc.nombre + " (Casa central)"
                      : suc.nombre
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <app-agenda-form
            *ngIf="agenda"
            [agendaForm]="agendaFormGroup$ | async"
            [diasConConflico]="
              overlapErrorDays((agendaFormGroup$ | async)?.value?.id_sucursal)
            "
          ></app-agenda-form>

          <div class="formGroup">
            <mat-slide-toggle
              formControlName="notificaciones"
              class="example-margin"
              color="primary"
            >
              Notificaciones de estudios médicos
            </mat-slide-toggle>
          </div>
          <div class="formGroup">
            <mat-slide-toggle
              formControlName="crearEstudiosMedicos"
              class="example-margin"
              color="primary"
            >
              Permitir crear estudios médicos
            </mat-slide-toggle>
          </div>
          <div class="formButton">
            <button
              *ngIf="!editarValor"
              mat-raised-button
              class="buttonForm"
              [disabled]="medicoForm.invalid"
            >
              Crear
            </button>
            <button
              *ngIf="editarValor"
              mat-raised-button
              class="buttonForm Cancelar"
              routerLink="../../"
            >
              Cancelar
            </button>
            <button
              *ngIf="editarValor"
              mat-raised-button
              class="buttonForm"
              [disabled]="medicoForm.invalid"
            >
              Editar
            </button>
          </div>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>
