import { MatSnackBar } from "@angular/material/snack-bar";
import { NotificationComponent } from "app/shared/notification/notification.component";
import { ElementRef, Component, ViewChild } from "@angular/core";
import { NotificacionesPushService } from "app/services/notificaciones-push.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { DeleteErrorDialogComponent } from "app/shared/delete-error-dialog/delete-error-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "app/shared/confirm-dialog/confirm-dialog.component";
import { EstudioMedicoService } from "app/services/estudio-medico.service";
import { EstudioMedico } from "app/models/estudioMedico.interface";
import { SpinnerService } from "app/services/spinner.service";
import { UsuarioPanelService } from "app/services/usuario-panel.service";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";

@Component({
  selector: "app-estudio-medico-index",
  templateUrl: "./estudio-medico-index.component.html",
  styleUrls: ["./estudio-medico-index.component.scss"],
})
export class EstudioMedicoIndexComponent {
  //
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("buscador") buscador: ElementRef;
  displayedColumns: string[] = ["id", "texto", "acciones"];
  dataSource: any;
  data: EstudioMedico[];
  searchKey: string;
  editar: boolean = false;
  usuarioPanel: any;
  notificacionesPush: any[];
  private intervalId: any;

  //
  constructor(
    public dialog: MatDialog,
    private usuarioPanelService: UsuarioPanelService,
    private spinnerService: SpinnerService,
    private estudioMedicoService: EstudioMedicoService,
    private snackBar: MatSnackBar
  ) {
    this.spinnerService.mostrarSpinner();
    this.usuarioPanelService
      .profileUser()
      .pipe(
        map((data) => {
          this.usuarioPanel = data;
        }),
        catchError((error) => {
          this.spinnerService.ocultarSpinner();
          this.usuarioPanelService.logout();
          return of({ results: null });
        }),
        switchMap((data) =>
          this.estudioMedicoService.listar().pipe(
            map((data) => {
              this.listar(data.listaEstudiosMedicos);
              this.notificacionesPush = data.notificacionesPush;
              this.spinnerService.ocultarSpinner();
            })
          )
        ),
        catchError((error) => {
          this.spinnerService.ocultarSpinner();
          return of({ results: null });
        })
      )
      .subscribe(
        (data) => console.log("data", data),
        (error) => console.error("Error al llamar a la API:", error)
      );
  }

  //
  ngOnInit(): void {}

  //
  openDialog(element: EstudioMedico) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "50%",
      data: "Estudio médico",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.borrar(element);
      }
    });
  }

  //
  listar = (data: any) => {
    this.data = data;
    this.dataSource = new MatTableDataSource<EstudioMedico>(data);
    this.dataSource.paginator = this.paginator;
  };

  //
  openBorrarErrorDialog = () => {
    let object = {
      titulo: "estudios médicos",
      mensaje: "No se puede eliminar estudios médicos asignados a pacientes",
    };
    const dialogRef = this.dialog.open(DeleteErrorDialogComponent, {
      width: "50%",
      data: object,
    });
    dialogRef.afterClosed().subscribe((res) => {});
  };

  //
  borrar = (EstudioMec: EstudioMedico) => {
    this.spinnerService.mostrarSpinner();

    this.estudioMedicoService.borrar(EstudioMec).subscribe(
      () => {
        this.estudioMedicoService.listar().subscribe(
          (data) => {
            this.listar(data.listaEstudiosMedicos);
            this.paginator.firstPage();
            this.spinnerService.ocultarSpinner();
            this.snackBar.open("Estudio médico borrado correctamente", "X", {
              duration: 5000,
              panelClass: ["snackbar"],
            });
          },
          () => {
            this.spinnerService.ocultarSpinner();
            this.snackBar.open(
              "Error al actualizar la lista de estudios médicos",
              "X",
              {
                duration: 5000,
                panelClass: ["snackbar", "error"],
              }
            );
          }
        );
      },
      (error) => {
        if (error.error.errorEstudioMedico) {
          this.openBorrarErrorDialog();
        }
        this.spinnerService.ocultarSpinner();
        this.snackBar.open("Error al borrar el estudio médico", "X", {
          duration: 5000,
          panelClass: ["snackbar", "error"],
        });
      }
    );
  };

  //
  listaActualizada = (mensaje: boolean) => {
    this.estudioMedicoService.listar().subscribe(
      (data) => {
        this.listar(data.listaEstudiosMedicos);
        this.spinnerService.ocultarSpinner();
      },
      (error) => {
        this.spinnerService.ocultarSpinner();
      }
    );
  };

  //
  filter = (event: any) => {
    let dataFilter: EstudioMedico[] = [];
    let valorString: string = this.buscador.nativeElement.value;
    this.data.forEach((obj) => {
      if (obj.id.toString().includes(valorString)) {
        dataFilter.push(obj);
      } else if (obj.texto.toUpperCase().includes(valorString.toUpperCase())) {
        dataFilter.push(obj);
      }
    });
    this.dataSource = new MatTableDataSource<EstudioMedico>(dataFilter);
    this.dataSource.paginator = this.paginator;
  };

  //
  openSnackBar = (message: string, action: string) => {
    this.snackBar.openFromComponent(NotificationComponent, {
      data: {
        message: message,
        action: action,
      },
      duration: 2500,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: [action],
    });
  };
}
