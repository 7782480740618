<!--
<mat-drawer-container class="example-container" autosize>
  <mat-drawer
    #drawer
    class="example-sidenav"
    [opened]="sideBarStatus"
    mode="side"
  >
    <mat-list role="list">
      <mat-list-item role="listitem" [routerLink]="['/user/estudios']"
        ><img
          class="icon"
          src="assets/img/ico_home.svg"
        />Inicio</mat-list-item
      >
      <mat-list-item role="listitem" (click)="openDialog()"
        ><img
          class="icon"
          src="assets/img/ico_consulta.svg"
        />Contacto</mat-list-item
      >
    </mat-list>
  </mat-drawer>

  <div class="example-sidenav-content">
    <ng-content></ng-content>
  </div>
</mat-drawer-container>
-->
<mat-drawer-container class="example-container mat-typography" autosize>
  <mat-drawer
  class="sidenav"
    [ngClass]="{ 'expanded-sidenav': isExpanded }"
    #drawer
    mode="side"
    disableClose="true"
    opened="true"
  >
    <mat-list role="list">
      <mat-list-item role="listitem" [routerLink]="['/user/estudios']"
        ><img class="icon" src="assets/img/ico_home.svg" />
        <h4 *ngIf="isExpanded">Inicio</h4></mat-list-item
      >
      <mat-list-item role="listitem" (click)="openDialog()"
        ><img class="icon" src="assets/img/ico_consulta.svg" />
        <h4 *ngIf="isExpanded">Contacto</h4></mat-list-item
      >
    </mat-list>
  </mat-drawer>

  <div class="example-sidenav-content">
    <ng-content></ng-content>
  </div>
</mat-drawer-container>
