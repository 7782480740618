import { GlobalVariable } from 'app/global/global';
import { CentroMedicoService } from 'app/services/centro-medico.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validator, Validators } from '@angular/forms';
import { CanActivate, Router } from '@angular/router';
import { PacienteService } from 'app/services/paciente.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { environment } from 'environments/environment.prod';
import { SpinnerService } from 'app/services/spinner.service';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';


@Component({
  selector: 'app-login-paciente',
  templateUrl: './login-paciente.component.html',
  styleUrls: ['./login-paciente.component.scss']
})
export class LoginPacienteComponent implements OnInit {


  //
  VERSION:string = GlobalVariable.VERSION_FRONT;
  titulo_login:string = 'Mail';
  imagen:string = "";
  loginForm = new FormGroup({
    datoingreso : new FormControl('',Validators.required),
    password : new FormControl('',Validators.required)
  });


  //
  constructor(
    private router: Router,
    private pacienteService:PacienteService,
    private snackBar:MatSnackBar,
    private centroService:CentroMedicoService,
    private spinnerService:SpinnerService
  ) { 
    this.titulo_login = (environment.PACIENTE_LOGIN == 'MAIL') ? 'Mail' : 'Número documento';
    this.spinnerService.mostrarSpinner();
    this.centroService.getCentroImagen().subscribe(
      data => {
        this.imagen = `${GlobalVariable.BASE_API_URL}storage/images/centro-medico/${data}`;
        this.spinnerService.ocultarSpinner();
      },
      error => {
        this.spinnerService.ocultarSpinner();
      }
    );
  }


  //
  ngOnInit(): void {}


  //
  login(form) {
    this.spinnerService.mostrarSpinner();
    this.pacienteService.login(form) .subscribe({
      next: (data) => {
        // let response:any = data;
        // console.log('Datos login paciente', response)
        // let dataToken = { token: response.token };
        this.pacienteService.handleData(data);
        this.openSnackBar("Ingreso exitoso","exito");
          this.router.navigate(['/seccion-paciente/home-paciente/']);
      },
     error: (error) => {
      this.pacienteService.deleteDatosAutorizacion();
      this.spinnerService.ocultarSpinner();
      this.openSnackBar("Ingreso fallido: " + error.error.error,"error");
      
      }
  });
    
    // .pipe(
    //   map(data => {
    //     let response:any = data;
    //     console.log('Datos login paciente', response)
    //     let dataToken = { token: response.token };
    //     this.pacienteService.handleData(data);
    //   }),
    //   catchError(error => {
    //     this.pacienteService.deleteDatosAutorizacion();
    //     this.spinnerService.ocultarSpinner();
    //     this.openSnackBar("Ingreso fallido: " + error.error.error,"error");
    //     return error;
    //   }),
    //   switchMap(data => this.pacienteService.datosAutorizacion().pipe(
    //     map(data => { 
    //       let response:any = data;
    //       this.pacienteService.setDatosAutorizacion(response);
    //       this.openSnackBar("Ingreso exitoso","exito");
    //       this.router.navigate(['/seccion-paciente/home-paciente/']);
    //     }),
    //   )),
    //   catchError(error => {
    //     this.pacienteService.deleteDatosAutorizacion();
    //     this.spinnerService.ocultarSpinner();
    //     this.openSnackBar("Ingreso fallido: " + error.error.error,"error");
    //     return error;
    //   })
    // )
    // .subscribe(
    //   (data) => {
    //     console.log('data',data);
    //   },
    //   (error) => {
    //     console.log('error',error);
    //   }
    // );
  }


  //
  openSnackBar(message: string, action: string) {
    this.snackBar.openFromComponent(NotificationComponent, {
      data: {
        message: message,
        action: action
      },
      duration: 2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [action]
    });
  }


}
