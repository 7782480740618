import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { ElementRef, Component, OnInit, ViewChild, Input, ViewEncapsulation } from '@angular/core';
import { UsuarioPanel } from 'app/models/usuarioPanel.interface';
import { NotificacionesPushService } from 'app/services/notificaciones-push.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { GlobalVariable } from 'app/global/global';
import { SpinnerService } from 'app/services/spinner.service';

@Component({
  selector: 'app-header-panel',
  templateUrl: './header-panel.component.html',
  styleUrls: ['./header-panel.component.scss'],
  encapsulation: ViewEncapsulation.None // Deshabilita la encapsulación de estilos
})
export class HeaderPanelComponent implements OnInit {


  //
  usuarioPanel: any;
  imagen: string;
  imagenAux: string;
  notificacionesPush: any[] = [];
  currentRouteName: any;
  @Input() set usuarioPanelBinding(value: any) {
    if (value) {
      this.usuarioPanel = value;
      this.imagen = GlobalVariable.BASE_API_URL + 'storage/images/usuario-panel/' + this.usuarioPanel.imagen;
    }
  }
  @Input() set notificacionesPushBinding(value: any) {
    if (value) {
      this.notificacionesPush = value;
      console.log('this.notificacionesPush.length ',this.notificacionesPush.length);
    }
  }


  //
  constructor(
    private usuarioPanelService: UsuarioPanelService,
    private router: Router,
    private notificacionesPushService: NotificacionesPushService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute
  ) {
    this.router.events.subscribe(() => {
      this.currentRouteName = this.route.snapshot.routeConfig.path;
    });
  }



  //
  ngOnInit(): void {
  }


  //
  setImagen() {
    this.imagen = GlobalVariable.BASE_API_URL + 'storage/images/usuario-panel/' + this.usuarioPanel.imagen;
  }


  leerNotificacion(object: any) {
    this.spinnerService.mostrarSpinner();
    this.notificacionesPushService.leerNotificacion(object.id).subscribe(
      data => {
        this.notificacionesPush = this.notificacionesPush.filter(dat => dat.id !== object.id);
        this.spinnerService.ocultarSpinner();
      },
      error => {
        console.log('error => ', error);
        this.spinnerService.ocultarSpinner();
      }
    )
  }


  //
  logout() {
    this.usuarioPanelService.logout();
  }


  //
  onMenuItemClick(event: MouseEvent) {
    event.stopPropagation();
  }

}
