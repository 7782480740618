<div class="divContent">
    <mat-accordion class="acordeonContent">
        <mat-expansion-panel hideToggle [expanded]="panelOpenState" class="mat-elevation-z4">
            <mat-expansion-panel-header class="acordeonHeader" (click)="ocultarMostrar()">
                <div class="topTitleAcordeon">
                    <mat-card class="titleSeccion">
                        <span >Filtro calendario</span>
                        <mat-icon *ngIf="!panelOpenState" class="iconArrow">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="panelOpenState" class="iconArrow">keyboard_arrow_up</mat-icon>
                    </mat-card>
                </div>
            </mat-expansion-panel-header>

            <form class="formCard" [formGroup]="filtroCalendario">
                <div class="card">
                    <div class="formGroup">
                        <mat-form-field appearance="fill" class="example-full-width input">
                            <mat-label>Pacientes</mat-label>
                            <mat-select formControlName="paciente" [placeholder]="'Pacientes'">
                                <mat-option>
                                    <ngx-mat-select-search
                                        id="pacienteBuscador"
                                        [noEntriesFoundLabel]="'Not found'"
                                        [placeholderLabel]="'paciente'"
                                        [formControl]="pacienteBuscador"
                                        name="filterPost"
                                        (ngModelChange)="filtrarPaciente($event)">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option [value]="0">
                                    <div class="optionCM">
                                        <div class="nombreSelect">Todos los pacientes</div>
                                    </div>
                                </mat-option>
                                <mat-option *ngFor="let element of filtroPacientes" [value]="element.id">
                                    <div class="optionCM">
                                        <div class="nombreSelect">{{element.nombreApellido}}</div>
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="formButton">
                        <button mat-raised-button class="buttonForm" (click)="busquedaFiltrada()">Buscar</button>
                    </div>
                </div>
            </form>

        </mat-expansion-panel>
    </mat-accordion>
</div>

