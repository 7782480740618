import { ElementRef, Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { NotificacionesPushService } from "app/services/notificaciones-push.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "app/shared/confirm-dialog/confirm-dialog.component";
import { PasswordDialogComponent } from "app/shared/password-dialog/password-dialog.component";
import { FotoDialogComponent } from "app/shared/foto-dialog/foto-dialog.component";
import { MedicoService } from "app/services/medico.service";
import { Medico } from "app/models/medico.interface";
import { UsuarioPanelService } from "app/services/usuario-panel.service";
import { SpinnerService } from "app/services/spinner.service";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";

@Component({
  selector: "app-listar-medico",
  templateUrl: "./listar-medico.component.html",
  styleUrls: ["./listar-medico.component.scss"],
})
export class ListarMedicoComponent implements OnInit {
  //
  @ViewChild("divImagen") divImagen: ElementRef;
  @ViewChild("UploadFileInput") uploadFileInput: ElementRef;
  @ViewChild("dropdown2") element: ElementRef;
  @ViewChild("buscador") buscador: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [
    "apellido",
    "tipo",
    "numeroDoc",
    "matricula",
    "acciones",
  ];
  myfilename = "Seleccionar Archivo";
  imagen = "";
  data: any[];
  editar: boolean = false;
  dataSource: any;
  searchKey: string;
  usuarioPanel: any;
  medicoFormulario: any;
  response: any;
  notificacionesPush: any[];

  //
  constructor(
    public dialog: MatDialog,
    public fotoDialog: MatDialog,
    private medicoService: MedicoService,
    private usuarioPanelService: UsuarioPanelService,
    private spinnerService: SpinnerService
  ) {
    this.spinnerService.mostrarSpinner();
    this.usuarioPanelService
      .profileUser()
      .pipe(
        map((data) => {
          this.usuarioPanel = data;
        }),
        catchError((error) => {
          this.spinnerService.ocultarSpinner();
          this.usuarioPanelService.logout();
          return of({ results: null });
        }),
        switchMap((data) =>
          this.medicoService.formularioMedico().pipe(
            map((data: any) => {
              this.response = data;
              this.notificacionesPush = data.notificacionesPush;
              this.listar(this.response.listaMedicos);
              this.spinnerService.ocultarSpinner();
            })
          )
        ),
        catchError((error) => {
          this.spinnerService.ocultarSpinner();
          return of({ results: null });
        })
      )
      .subscribe(
        (data) => {
          console.log("data", data);
        },
        (error) => {
          console.error("Error al llamar a la API:", error);
        }
      );
  }

  //
  ngOnInit(): void {}

  //

  //
  fileChangeEvent(event: any) {
    var target = event.target || event.srcElement;
  }

  //
  listar(data) {
    this.data = data;
    this.data.forEach(function (value) {
      value.celular = value.celPais + value.celCaract + value.celNumero;
    });
    this.data.forEach(function (element) {
      if (element.texto == null || element.texto == "null") element.texto = "";
      if (element.numeroDocumento == null || element.numeroDocumento == "null")
        element.numeroDocumento = "";
    });
    this.dataSource = new MatTableDataSource<Medico>(this.data);
    this.dataSource.paginator = this.paginator;
  }

  //
  borrar(medico: Medico) {
    this.spinnerService.mostrarSpinner();
    this.medicoService
      .borrar(medico)
      .pipe(
        map((data) => {}),
        catchError((error) => {
          this.spinnerService.ocultarSpinner();
          return error;
        }),
        switchMap((data) =>
          this.medicoService.listar().pipe(
            map((data) => {
              this.listar(data.listaMedicos);
              this.spinnerService.ocultarSpinner();
            })
          )
        ),
        catchError((error) => {
          this.spinnerService.ocultarSpinner();
          return error;
        })
      )
      .subscribe(
        (data) => console.log("data", data),
        (error) => console.error("Error al llamar a la API:", error)
      );
  }

  //
  filter(event) {
    let dataFilter: Medico[] = [];
    let valorString: string = this.buscador.nativeElement.value;
    this.data.forEach((obj) => {
      if (
        obj.nombreCompleto.toUpperCase().includes(valorString.toUpperCase())
      ) {
        dataFilter.push(obj);
      } else if (obj.texto.toUpperCase().includes(valorString.toUpperCase())) {
        dataFilter.push(obj);
      } else if (
        obj.numeroDocumento.toUpperCase().includes(valorString.toUpperCase())
      ) {
        dataFilter.push(obj);
      } else if (
        obj.matricula.toUpperCase().includes(valorString.toUpperCase())
      ) {
        dataFilter.push(obj);
      }
    });
    this.dataSource = new MatTableDataSource<Medico>(dataFilter);
    this.dataSource.paginator = this.paginator;
  }

  //
  listaActualizada(mensaje: boolean) {
    this.medicoService.listar().subscribe(
      (data) => {
        console.log("data", data);
        this.listar(data.listaMedicos);
        this.spinnerService.ocultarSpinner();
      },
      (error) => {
        console.log("error", error);
        this.spinnerService.ocultarSpinner();
      }
    );
  }

  //
  cambiarPassword(id) {
    const dialogRef = this.dialog.open(PasswordDialogComponent, {
      width: "50%",
      data: { nombre: "medico", id: id },
    });

    dialogRef.afterClosed().subscribe((res) => {});
  }

  //
  openDialog(element: Medico) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "50%",
      data: "médico",
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.borrar(element);
      }
    });
  }

  //
  openDialogFoto() {
    const dialogRef = this.fotoDialog.open(FotoDialogComponent, {
      width: "50%",
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (
        res.data.id == "ico_paciente.png" ||
        res.data.id == "ico_pacienteM.png"
      ) {
        this.myfilename = res.data.id;
        this.imagen = "assets/img/" + res.data.id + "";
        this.divImagen.nativeElement.style.display = "block";
      } else {
        this.myfilename = res.data.name;
        this.imagen = res.img;
        this.divImagen.nativeElement.style.display = "block";
      }
    });
  }
}
