import { Directive, ElementRef, Input } from '@angular/core';

declare const cornerstone;

@Directive({
    selector: '[myCornerstoneElement]',
})
export class CornerstoneElementDirective {
    @Input('image') imageId: string;
    elementRef: ElementRef<any>;
    constructor(elementRef: ElementRef) {
        this.elementRef = elementRef;
    }
    ngOnInit(el: ElementRef) {
        // If no imageId is given, do nothing
        if (!this.imageId) {
            return;
        }

        // Retrieve the DOM element itself
        var element = this.elementRef.nativeElement;

        // Enable the element with Cornerstone
        cornerstone.enable(element);

        // Load the image and enable tools
        cornerstone.loadImage(this.imageId).then(function (image) {
            cornerstone.displayImage(element, image);
        });
    }
}