import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformesRoutingModule } from './informes-routing.module';
import { InformesComponent } from './informes/informes.component';
import { SharedModule } from 'primeng/api';
import { MaterialModule } from 'app/material/material.module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { SharedPanelModule } from '../shared-panel/shared-panel.module'; 
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { InformeCreacionesPorAnioComponent } from './informe-creaciones-por-anio/informe-creaciones-por-anio.component';
import { InformeTopMedicosComponent } from './informe-top-medicos/informe-top-medicos.component';
import { InformeGraficoTortaComponent } from './informe-grafico-torta/informe-grafico-torta.component';

@NgModule({
  declarations: [
    InformesComponent,
    InformeCreacionesPorAnioComponent,
    InformeTopMedicosComponent,
    InformeGraficoTortaComponent, 
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedPanelModule,
    InformesRoutingModule,
    NgxChartsModule,
  ]
})
export class InformesModule { }
