import { PacienteService } from 'app/services/paciente.service';
import { MedicoService } from 'app/services/medico.service';
import { AfterViewInit,Component,Input,ViewChild,HostListener } from "@angular/core";
import { animate,state,style,transition,trigger,} from "@angular/animations";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { EstudioMedicoPacienteService } from "app/services/estudio-medico-paciente.service";
import { DatePipe } from '@angular/common';
import { Paciente } from "app/models/paciente.interface";
import { GlobalVariable } from "app/global/global";
import { environment } from 'environments/environment.prod';
import { CornerstoneService } from 'app/services/cornerstone.service';
import { ArchivoService } from 'app/services/archivo.service';
import { ActivatedRoute } from '@angular/router';
import { ShowImagenComponent } from 'app/shared/show-imagen/show-imagen.component'; 
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { Medico } from 'app/models/medico.interface';
import { Router } from '@angular/router';


@Component({
  selector: 'app-estudios-medicos',
  templateUrl: './estudios-medicos.component.html',
  styleUrls: ['./estudios-medicos.component.scss'],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class EstudiosMedicosComponent implements AfterViewInit {


  //
  @Input("cols") cols: any[] = [];
  isOpen = false;
  url:string = GlobalVariable.BASE_API_URL
  urlXls:string =  GlobalVariable.BASE_API_URL + 'storage/images/paciente/';
  dataSource:any;
  sortedData = [];
  @ViewChild(MatSort) sort: MatSort;
  idPaciente:string;
  medico:Medico;
  imageData: any;
  paciente:any;
  estudiosMedicosPaciente:any;
  tipoestudios = environment.BASE_TYPEOFESTUDIOS;
  columnsFiles = ["fecha", "estudio", "medico", "centromedico", "acciones"];
  columnsFile = ["fecha", "archivo", "acciones"];
  historialMedico = false;
  @Input() set response(value:any) {
    if(value) {
      this.listarEstudiosMedicos(value);
    }
  }

  //
  constructor(
    public dialog: MatDialog,
    private apiMS:MedicoService,
    private datePipe: DatePipe,
    private apiEMPS:EstudioMedicoPacienteService,
    private apiPS:PacienteService,
    //public csS: CornerstoneService,
    private apiArchivo:ArchivoService,
    private actRoute:ActivatedRoute,
    private router: Router,
  ) {
    this.idPaciente = this.actRoute.snapshot.paramMap.get("id");
    if(window.innerWidth > 900){
      this.historialMedico = false;
    }
    else{
      this.historialMedico = true;
    }
  }


  //
  ngAfterViewInit() {}


  //
  @HostListener('window:resize', ['$event']) onResize(event) {
    if(event.target.innerWidth > 900){
      this.historialMedico = false
    }
    else if(event.target.innerWidth <= 900){
      this.historialMedico = true
    }
  }
  

  //
  listarEstudiosMedicos(data:any){
    this.paciente = data.paciente;
    this.estudiosMedicosPaciente = data.estudiosMedicosPaciente;
    this.estudiosMedicosPaciente.sort((a, b) => {
      return new Date(b.fecha).getTime() - new Date(a.fecha).getTime()
    })
    this.estudiosMedicosPaciente.forEach(element => {
      let date = new Date(element.fecha)
      element.fecha = this.datePipe.transform(date,'dd/MM/yyyy');
    });
    this.dataSource = new MatTableDataSource(this.estudiosMedicosPaciente);
    this.dataSource.sort = this.sort;
  }


  //
  async mostrarImagen(imagen:any){
      const dialogRef = this.dialog.open(ShowImagenComponent,{
        width: '95%',
        height: '95%',
        data: imagen
      })
      dialogRef.afterClosed().subscribe(res=>{})
  }


  //
  descargarImage(imagen:any){
    let id = imagen.imagen.idPaciente;
    fetch(this.urlXls+id+'/'+imagen.id+'/'+imagen.imagen.nombre).then(function(response) {
      response.blob().then(function(blob){
        let file = new File([blob],imagen.imagen.nombre,{type:'application/jpeg'});
        saveAs(file);
      });
    });
  }


  //
  descargarPdf(imagen:any){
    let id = imagen.imagen.idPaciente;
    fetch(this.urlXls+id+'/'+imagen.id+'/'+imagen.imagen.nombre).then(function(response) {
      response.blob().then(function(blob){
        let file = new File([blob],imagen.imagen.nombre,{type:'application/pdf'});
        saveAs(file);
      });
    });
  }


  //
  descargarExcel(imagen:any){
    let id = imagen.imagen.idPaciente;
    fetch(this.urlXls+id+'/'+imagen.id+'/'+imagen.imagen.nombre).then(function(response) {
      response.blob().then(function(blob){
        let file = new File([blob],imagen.imagen.nombre,{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        saveAs(file);
      });
    });
  }


  //
  cargarImagen(event) {
    (event.target as HTMLImageElement).style.display = 'flex';
  }


  //
  select(expandedElement,element){
    //this.router.navigate(['/seccion-medico/estudios-medicos/detalle',element.id]);
  }

}
