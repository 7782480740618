import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { EstudioMedicoPaciente } from "app/models/estudioMedicoPaciente.interface";
import { GlobalVariable } from "app/global/global";
import moment from "moment";
import { map } from "rxjs/operators";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { SpinnerService } from "./spinner.service";

@Injectable({
  providedIn: "root",
})
export class EstudioMedicoPacienteService {
  url: string = GlobalVariable.BASE_API_URL + "api/estudio-medico-paciente/";

  constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService
  ) {}

  listar(): Observable<any> {
    return this.http.get(this.url + "listar");
  }

  ///estudio-medico-paciente/inicio/{id}
  estudioMedicoPacienteInicio(id): Observable<any> {
    return this.http.get(this.url + "inicio/" + id);
  }

  listarPorPaciente(id): Observable<any> {
    return this.http.get(this.url + "listar-por-paciente/" + id);
  }

  // listar-por-paciente
  crear(listFile: File[], estudioMedicoPaciente: any, descripcion: string[]) {
    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    formData.append("idPaciente", estudioMedicoPaciente.idPaciente);
    formData.append("medico", estudioMedicoPaciente.medico);
    formData.append("estudioMedico", estudioMedicoPaciente.estudioMedico);
    formData.append("fecha", estudioMedicoPaciente.fecha);
    for (let i = 0; i < listFile.length; i++) {
      formData.append("imagenes[" + i + "]", listFile[i]);
      formData.append("descripcion" + i, descripcion[i]);
    }
    formData.append("centroMedico", estudioMedicoPaciente.centroMedico);
    formData.append(
      "observaciones",
      estudioMedicoPaciente.observaciones.trim()
    );
    return this.http.post(this.url + "crear", formData, { headers: headers });
  }

  borrar(estudioMedicoPaciente: EstudioMedicoPaciente): Observable<any> {
    return this.http.delete(this.url + "borrar/" + estudioMedicoPaciente.id);
  }

  //
  mostrar(id): Observable<any> {
    return this.http.get(this.url + "mostrar/" + id);
  }

  //
  estudioMedicoPacienteEditar(id, estudioId): Observable<any> {
    return this.http.get(this.url + "mostrar/" + id + "/" + estudioId);
  }

  editar(
    listFile: any,
    estudioMedicoPaciente: any,
    descripcion: string[]
  ): Observable<any> {
    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    formData.append("id", estudioMedicoPaciente.id);
    formData.append("medico", estudioMedicoPaciente.medico);
    formData.append("estudioMedico", estudioMedicoPaciente.estudioMedico);
    if (listFile) {
      for (let i = 0; i < listFile.length; i++) {
        if (!listFile[i]?.id) {
          formData.append("imagenes[" + i + "]", listFile[i]);
          formData.append("descripcion" + i, descripcion[i]);
        } else {
          formData.append("imagenesId[]", listFile[i]?.id);
        }
      }
    }
    formData.append("fecha", estudioMedicoPaciente.fecha);
    formData.append("centroMedico", estudioMedicoPaciente.centroMedico);
    formData.append(
      "observaciones",
      estudioMedicoPaciente.observaciones.trim()
    );
    return this.http.post(this.url + "editar", formData, { headers: headers });
  }

  listarInformes(id): Observable<any> {
    return this.http.get(this.url + "listar-informes/" + id);
  }

  mostrarDetalle(id, idMedico = null): Observable<any> {
    let urlDetalle = idMedico
      ? "detalle/" + id + "/" + idMedico
      : "detalle/" + id;
    console.log("urlDetalle", urlDetalle);
    return this.http.get(this.url + urlDetalle);
  }

  cantidad() {
    return this.http.get(this.url + "cantidad");
  }

  listarInformesMedico(idMedico, idPaciente): Observable<any> {
    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    formData.append("idMedico", idMedico);
    formData.append("idPaciente", idPaciente);
    return this.http.post(this.url + "listar-informes-medico", formData, {
      headers: headers,
    });
  }

  listarEstudiosMedicosPaciente(): Observable<any> {
    return this.http.get(this.url + "listar-estudios-medicos");
  }

  descargarPDFEstudioMedico(estudioMedicoPaciente) {
    const tempDiv = document.createElement("div");

    tempDiv.innerHTML = `
    <div style="display: flex; flex-direction: column; min-height: 297mm; font-family: Arial, sans-serif; padding: 10mm;">
      <header style="text-align: center; margin-bottom: 10mm; border-bottom: 1px solid #ccc;">
        <h1>Detalle estudio médico</h1>
        <p>${estudioMedicoPaciente.fecha}</p>
        <img src="${estudioMedicoPaciente.imagenCM}" height=50></img>
        <p>${estudioMedicoPaciente.nombreCM}</p>
      </header>
      <main style="flex-grow: 1;">
          <p><span><b>Medico:</b></span>${estudioMedicoPaciente.nombreM}</p>
          <p><span><b>Paciente: </b></span>${estudioMedicoPaciente.nombreP}</p>
          <p><span><b>Estudio: </b></span>${estudioMedicoPaciente.nombreEM}</p>
          <p><b>Observaciones: </b></p>
          <div>${estudioMedicoPaciente.observaciones}</div>
      </main>
      <footer style="text-align: center; margin-top: 10mm; border-top: 1px solid #ccc;">
        <img src="assets/img/logo.png" height=60>
      </footer>
    </div>
  `;

    tempDiv.style.position = "absolute";
    tempDiv.style.top = "-9999px";
    tempDiv.style.left = "-9999px";
    tempDiv.style.width = "210mm";
    tempDiv.style.minHeight = "297mm";
    tempDiv.style.padding = "5mm";

    document.body.appendChild(tempDiv);
    this.spinnerService.mostrarSpinner();
    html2canvas(tempDiv, {
      useCORS: true,
      scale: 2,
      logging: false,
      allowTaint: true,
    })
      .then((canvas) => {
        document.body.removeChild(tempDiv);

        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4", true);
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save(
          `${estudioMedicoPaciente.fecha}-${estudioMedicoPaciente.nombreEM}-${estudioMedicoPaciente.nombreP}-.pdf`
        );
        this.spinnerService.ocultarSpinner();
      })
      .catch((error) => {
        this.spinnerService.ocultarSpinner();
        console.error("Error generando PDF:", error);
        document.body.removeChild(tempDiv);
      });
  }
}
