import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TipoDocumentoEditarComponent } from './tipo-documento-editar/tipo-documento-editar.component';
import { SharedModule } from 'primeng/api';
import { MaterialModule } from 'app/material/material.module';
import { TipoDocumentoRoutingModule } from './tipo-documento-routing.module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { SharedPanelModule } from '../shared-panel/shared-panel.module';
import { HttpClientModule } from '@angular/common/http';
import { TipoDocumentoFormComponent } from './tipo-documento-form/tipo-documento-form.component';
import { TipoDocumentoIndexComponent } from './tipo-documento-index/tipo-documento-index.component';

@NgModule({
  declarations: [
    TipoDocumentoFormComponent,
    TipoDocumentoIndexComponent,
    TipoDocumentoEditarComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    TipoDocumentoRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedPanelModule,
    HttpClientModule
  ]
})
export class TipoDocumentoModule { }
