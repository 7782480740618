import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements CanActivate {
  constructor(
    private authService: UsuarioPanelService,
    private router: Router
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) : Promise<boolean | UrlTree> {
    if (this.authService.getPerfil() === "Administrador") {
      return true;  
    }
    else{
      this.router.navigate(['/seccion-panel/home-panel']);
      return false;
    }
  }
}
