import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from './pages/profile/profile.component';
import { StudiesComponent } from './pages/studies/studies.component';

const routes: Routes = [
  {
    path: 'user',
    children: [

      {
        path: 'estudios',
        pathMatch: 'full',
        component: StudiesComponent
      },
      {
        path: ':user',
        pathMatch: 'full',
        component: ProfileComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
