<div class="div">
  <!-- <a class="buttonClose" (click)="onClickNo()">
    <mat-icon>close</mat-icon>
  </a> -->
  <img class="img" *ngIf="extd == 'img'" [src]="imageData" />

  <div
    *ngIf="extd == 'dcm'"
    oncontextmenu="return false;"
    id="dicomWrapper"
    class="disable-selection noIbar"
    [class.cursor-medir]="selectedTool === 'length'"
    [class.cursor-mover]="selectedTool === 'pan'"
    [class.cursor-magnify]="selectedTool === 'magnify'"
    onselectstart="return false;"
    onmousedown="return false;"
  >
    <div cornerstone class="dcm" [image]="imageData" id="dicomImage"></div>

    <mat-button-toggle-group #group="matButtonToggleGroup">
      <mat-button-toggle
        value="zoom"
        (click)="activateTool('zoom')"
        aria-label="zoom"
        matTooltip="Zoom"
      >
        <mat-icon>zoom_in</mat-icon>
      </mat-button-toggle>

      <mat-button-toggle
        value="length"
        matTooltip="Mover"
        (click)="activateTool('pan')"
        aria-label="mover"
      >
        <mat-icon>open_with</mat-icon>
      </mat-button-toggle>

      <mat-button-toggle
        value="length"
        matTooltip="Medir"
        (click)="activateTool('length')"
        aria-label="medir"
      >
        <mat-icon>linear_scale</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle
        value="magnify"
        matTooltip="Lupa"
        (click)="activateTool('magnify', 1)"
        aria-label="lupa"
      >
        <mat-icon>image_search</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle
        value="reset"
        matTooltip="Deshacer"
        (click)="resetView()"
        aria-label="reset"
      >
        <mat-icon> undo</mat-icon>
      </mat-button-toggle>

      <mat-button-toggle
        value="help"
        matTooltip="Ayuda"
        (click)="openHelp()"
        aria-label="Ayuda"
      >
        <mat-icon>help</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle
        value="close"
        matTooltip="Cerrar"
        (click)="onClickNo()"
        aria-label="Cerrar"
      >
        <mat-icon>cancel</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>

    <div *ngIf="selectedTool === 'zoom'" class="zoom">
      <mat-button-toggle (click)="zoomOut()" matTooltip="Zoom Out">
        <mat-icon>remove</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle (click)="zoomIn()" matTooltip="Zoom In">
        <mat-icon>add</mat-icon>
      </mat-button-toggle>
    </div>
  </div>

  <pdf-viewer
    class="pdf"
    *ngIf="extd == 'pdf'"
    [src]="imageData"
    [render-text]="true"
    [original-size]="false"
  >
  </pdf-viewer>

  <iframe
    *ngIf="extd == 'xlsx' && imageData.includes('http')"
    class="pdf"
    [src]="imageData | safe"
  >
  </iframe>

  <p *ngIf="extd == 'xlsx' && !imageData.includes('http')">{{ imageData }}</p>
</div>
