<div class="contentModal">
    <div class="tituloModal">
        <span>
            Cambiar clave
        </span>
        <mat-icon class="buttonClose" [mat-dialog-close]=false>close</mat-icon>
    </div>
    <div *ngIf="!response" class="textModal">
        ¿Estás seguro de que quieres cambiar esta clave? 
    </div>
    <div *ngIf="response" class="textModal">
        Nueva clave: [ {{password}} ]
    </div>
    <div class="accionModal">
        <div class="buttons">
            <button mat-button class="button cancelar" [mat-dialog-close]=false>Cancelar</button>
            <button mat-button class="button cambiar" *ngIf="!response" (click)="cambiar()">Cambiar</button>
            <button mat-button class="button cambiar" *ngIf="response" [mat-dialog-close]=false>Confirmar</button>
        </div>
    </div>
</div>
