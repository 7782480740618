import {
  ElementRef,
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { FotoDialogComponent } from "app/shared/foto-dialog/foto-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MedicoService } from "app/services/medico.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NotificationComponent } from "app/shared/notification/notification.component";
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  FormBuilder,
} from "@angular/forms";
import { TelefonoPais } from "app/models/telefonoPais.interface";
import { GlobalVariable } from "app/global/global";
import { TipoDocumento } from "app/models/tipoDocumento.interface";
import { SpinnerService } from "app/services/spinner.service";
import { CentroMedico } from "../../../models/centroMedico.interface";
import { Especialidad } from "../../../models/Especialidades.interface";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import {
  agendaOverlapValidator,
  passwordMatchValidator,
  validarTodasLasFechas,
  validateDaySchedule,
} from "./utils/validators";
import { ModalAdvertenciaAgendasComponent } from "../modal-advertencia-agendas/modal-advertencia-agendas.component";
import { Agenda } from "../../../models/agenda.interface";

@Component({
  selector: "app-crear-form-medico",
  templateUrl: "./crear-form-medico.component.html",
  styleUrls: ["./crear-form-medico.component.scss"],
})
export class CrearFormMedicoComponent implements OnInit {
  //
  funcionCorrecta: any;
  url: string = GlobalVariable.BASE_API_URL + "storage/images/medico/";
  editarValor: boolean;
  panelOpenState = false;
  @Input() set editar(value: boolean) {
    this.editarValor = value;
    this.panelOpenState = value ? true : false;
  }
  @Output() listar: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild("UploadFileInput") uploadFileInput: ElementRef;
  @ViewChild("divImagen") divImagen: ElementRef;
  telPais: FormControl = new FormControl();
  celPais: FormControl = new FormControl();
  imagen = "";
  nameImage: string = "";
  medico: any;
  file: File = null;
  myfilename = "Seleccionar archivo";
  telefonoPais: TelefonoPais[];
  filtroTelefonoPais: TelefonoPais[] = [];
  filtroCelularPais: TelefonoPais[] = [];
  tiposDocumentos: TipoDocumento[];
  checked = false;
  especialidades: Especialidad[] = [];
  sucursales: CentroMedico[] = [];
  errorDocumento: any;
  errorNumeroDocumento: any;
  errorTipoDocumento: any;
  errorMatricula: any;
  errorEmail: any;
  errorAgenda: any;
  selectedColor: string = "#c32af3";
  agenda: boolean;

  public telPaisBuscador: FormControl = new FormControl();
  public celPaisBuscador: FormControl = new FormControl();
  medicoForm = new FormGroup(
    {
      id: new FormControl(""),
      nombreCompleto: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      ]),
      tipoDocumento: new FormControl(""),
      numeroDocumento: new FormControl(""),
      especialidad: new FormControl(""),
      matricula: new FormControl("", [
        Validators.required,
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      ]),
      email: new FormControl("", Validators.email),
      telPais: new FormControl(""),
      telPaisBuscador: new FormControl(""),
      telCaract: new FormControl(""),
      telNumero: new FormControl(""),
      celPais: new FormControl(""),
      celPaisBuscador: new FormControl(""),
      celCaract: new FormControl(""),
      celNumero: new FormControl(""),
      agenda: new FormControl(false),
      codigoInterno: new FormControl(""),
      memberContactNo: new FormControl("Seleccionar archivo"),
      notificaciones: new FormControl(true),
      crearEstudiosMedicos: new FormControl(true),
      password: new FormControl(""),
      repeatPassword: new FormControl(""),
      sucursalSeleccionada: new FormControl(""),
      sucursales: new FormArray([], agendaOverlapValidator()),
      color_agenda: new FormControl("#000ec4"),
    },
    [passwordMatchValidator]
  );
  @Input() set response(value: any) {
    if (value) {
      this.formularioMedico(value);
      if (value.medico) {
        this.iniciarFormularioEditado(value);
      }
    }
  }
  public agendaFormGroup$: Observable<FormGroup>;
  private overlapError = 0;

  //
  constructor(
    public dialogs: MatDialog,
    private medicoService: MedicoService,
    private router: Router,
    private snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private fb: FormBuilder
  ) {}

  //
  ngOnInit(): void {
    this.overlapErrorHandler();
    this.initAgendaFormGroup();
  }

  //
  clearNumeroDocumento() {
    this.errorNumeroDocumento = false;
    this.errorDocumento = false;
  }

  //
  clearTipoDocumento() {
    this.errorTipoDocumento = false;
    this.errorDocumento = false;
  }

  //
  clearMatricula = () => {
    this.errorMatricula = false;
  };

  //
  clearEmail = () => {
    this.errorEmail = false;
  };

  //
  formularioMedico = (data: any) => {
    this.telefonoPais = data.listaTelefonoPais;
    this.filtroTelefonoPais = data.listaTelefonoPais;
    this.filtroCelularPais = data.listaTelefonoPais;
    this.tiposDocumentos = data.listatipoDocumento;
    this.especialidades = data.listaEspecialidad;
    this.sucursales = data.sucursales;

    this.sucursales.forEach((s) => {
      this.agregarAgenda(s);
    });
  };

  //
  iniciarFormularioEditado = (data: any) => {
    this.medico = data.medico;
    if (!this.imagen || !this.imagen) {
      this.imagen = `${this.url}${this.medico.imagen}`;
      this.divImagen.nativeElement.style.display = "block";
      this.nameImage = this.medico.imagen;
    }

    this.agenda = this.medico.agenda;
    this.medicoForm.patchValue({
      id: this.medico.id,
      nombreCompleto: this.medico.nombreCompleto
        ? this.medico.nombreCompleto
        : "",
      tipoDocumento: this.medico.tipoDocumento
        ? parseInt(this.medico.tipoDocumento)
        : "",
      numeroDocumento: this.medico.numeroDocumento
        ? this.medico.numeroDocumento
        : "",
      especialidad: this.medico.especialidad ? this.medico.especialidad : "",
      matricula: this.medico.matricula ? this.medico.matricula : "",
      email: this.medico.email ? this.medico.email : "",
      telPais: this.medico.telPais ? this.medico.telPais : "",
      telPaisBuscador: "",
      telCaract: this.medico.telCaract ? this.medico.telCaract : "",
      telNumero: this.medico.telNumero ? this.medico.telNumero : "",
      celPais: this.medico.celPais ? this.medico.celPais : "",
      celPaisBuscador: "",
      celCaract: this.medico.celCaract ? this.medico.celCaract : "",
      celNumero: this.medico.celNumero ? this.medico.celNumero : "",
      agenda: this.medico.agenda.toString() == "0" ? false : true,
      codigoInterno: this.medico.codigoInterno ? this.medico.codigoInterno : "",
      memberContactNo: this.medico.imagen
        ? this.medico.imagen
        : "Seleccionar archivo",
      notificaciones:
        this.medico.notificaciones.toString() == "0" ? false : true,
      color_agenda: this.medico.color_agenda
        ? this.medico.color_agenda
        : "#000ec4",
      crearEstudiosMedicos: this.medico.crearEstudiosMedicos === 1,
    });

    if (data?.agendaMedico?.length > 0) {
      data.agendaMedico.forEach((agenda) => {
        this.obtenerFormGroupAgenda(agenda.id_sucursal).patchValue({
          id: agenda.id,
          lunesDesde: agenda.lunes_desde ? agenda.lunes_desde : "",
          lunesHasta: agenda.lunes_hasta ? agenda.lunes_hasta : "",
          martesDesde: agenda.martes_desde ? agenda.martes_desde : "",
          martesHasta: agenda.martes_hasta ? agenda.martes_hasta : "",
          miercolesDesde: agenda.miercoles_desde ? agenda.miercoles_desde : "",
          miercolesHasta: agenda.miercoles_hasta ? agenda.miercoles_hasta : "",
          juevesDesde: agenda.jueves_desde ? agenda.jueves_desde : "",
          juevesHasta: agenda.jueves_hasta ? agenda.jueves_hasta : "",
          viernesDesde: agenda.viernes_desde ? agenda.viernes_desde : "",
          viernesHasta: agenda.viernes_hasta ? agenda.viernes_hasta : "",
          sabadoDesde: agenda.sabado_desde ? agenda.sabado_desde : "",
          sabadoHasta: agenda.sabado_hasta ? agenda.sabado_hasta : "",
          domingoDesde: agenda.domingo_desde ? agenda.domingo_desde : "",
          domingoHasta: agenda.domingo_hasta ? agenda.domingo_hasta : "",
        });
      });
      setTimeout(() => {
        const centroMedicoPrincipal = this.sucursales.find(
          (s) => Number(s.sucursal) === 0
        );

        this.medicoForm.patchValue({
          sucursalSeleccionada: centroMedicoPrincipal?.id,
        });
      });
    }
  };

  //
  filtrarTelPais = (event: any) => {
    let textBuscador: string = this.telPaisBuscador.value;
    this.filtroTelefonoPais = this.telefonoPais.filter(function (element) {
      if (element.nombre.toUpperCase().includes(textBuscador.toUpperCase())) {
        return element;
      }
    });
  };

  //
  filtrarCelPais = (event: any) => {
    let textBuscador: string = this.celPaisBuscador.value;
    this.filtroCelularPais = this.telefonoPais.filter(function (element) {
      if (element.nombre.toUpperCase().includes(textBuscador.toUpperCase())) {
        return element;
      }
    });
  };

  //
  fileChangeEvent = (event: any) => {
    var target = event.target || event.srcElement;
  };

  //
  cambiarAgenda = (event) => {
    this.agenda = event.checked;
    this.errorAgenda = false;
    setTimeout(() => {
      const centroMedicoPrincipal = this.sucursales.find(
        (s) => Number(s.sucursal) === 0
      );

      this.medicoForm.patchValue({
        sucursalSeleccionada: centroMedicoPrincipal?.id,
      });
    });
  };

  //
  guardarMedico(form: any) {
    this.funcionCorrecta = this.editarValor
      ? this.editarMedico(form)
      : this.crearMedico(form);
  }

  //
  crearMedico(form: any) {
    console.log('form => ', form);
    if (this.medicoForm.valid) {
      this.spinnerService.mostrarSpinner();
      this.medicoService.crear(this.nameImage, form, this.file).subscribe(
        (data) => {
          console.log("data", data);
          let response: any = data;
          if (response.statusError) {
            this.errorNumeroDocumento = response.errorNumeroDocumento;
            this.errorTipoDocumento = response.errorTipoDocumento;
            this.errorDocumento = response.errorDocumento;
            this.errorMatricula = response.errorMatricula;
            this.errorEmail = response.errorEmail;
            this.errorAgenda = response.errorAgenda;
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Guardado fallido", "error");
          } else {
            this.openSnackBar("Guardado exitoso", "exito");
            this.listar.emit();
            this.resetearFormulario();
          }
        },
        (error) => {
          console.log("error", error);
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Guardado fallido", "error");
        }
      );
    } else {
      this.openSnackBar("Guardado fallido", "error");
    }
  }

  //
  editarMedico(form: any) {
    if (this.medicoForm.valid) {
      this.spinnerService.mostrarSpinner();
      this.medicoService.editar(this.nameImage, form, this.file).subscribe(
        (data) => {
          let response: any = data;
          if (response.statusError) {
            this.errorNumeroDocumento = response.errorNumeroDocumento;
            this.errorTipoDocumento = response.errorTipoDocumento;
            this.errorDocumento = response.errorDocumento;
            this.errorMatricula = response.errorMatricula;
            this.errorEmail = response.errorEmail;
            this.errorAgenda = response.errorAgenda;
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Editado fallido", "error");
          } else {
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Editado exitoso", "exito");
            this.router.navigate(["/seccion-panel/medico/"]);
          }
        },
        (error) => {
          console.log(error);
          this.spinnerService.ocultarSpinner();
          this.openSnackBar("Editado fallido", "error");
        }
      );
    }
  }

  //
  ocultarMostrar = () => {
    this.panelOpenState = this.panelOpenState == true ? false : true;
  };

  //
  resetearFormulario = () => {
    this.file = null;
    this.medicoForm.reset();
    this.imagen = "";
    this.myfilename = "Seleccionar archivo";
    this.medicoForm.patchValue({
      nombreCompleto: "",
      tipoDocumento: "",
      numeroDocumento: "",
      especialidad: "",
      matricula: "",
      email: "",
      telPais: "",
      telPaisBuscador: "",
      telCaract: "",
      telNumero: "",
      celPais: "",
      celPaisBuscador: "",
      celCaract: "",
      celNumero: "",
      agenda: false,
      codigoInterno: "",
      memberContactNo: "Seleccionar archivo",
      notificaciones: true,
      crearEstudiosMedicos: true,
      password: "",
      repeatPassword: "",
      sucursalSeleccionada: "",
      sucursales: [],
      color_agenda: "#000ec4",
    });
    this.agenda = false;
    this.errorAgenda = false;
    this.divImagen.nativeElement.style.display = "none";
    this.panelOpenState = false;
    this.sucursales.forEach((s) => {
      this.agregarAgenda(s);
    });
  };

  //
  openDialogFoto = () => {
    const dialogRef = this.dialogs.open(FotoDialogComponent, {
      width: "50%",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res.data == "" && res.img == "") {
      } else {
        if (
          res.data.id == "ico_paciente.png" ||
          res.data.id == "ico_pacienteM.png"
        ) {
          this.myfilename = res.data.id;
          this.imagen = "assets/img/" + res.data.id + "";
          this.nameImage = res.data.id;
          this.divImagen.nativeElement.style.display = "block";
        } else {
          this.myfilename = res.data.name;

          this.imagen = res.img;
          this.divImagen.nativeElement.style.display = "block";
          this.file = res.data;
          this.nameImage = "";
        }
      }
    });
  };

  //
  openSnackBar = (message: string, action: string) => {
    this.snackBar.openFromComponent(NotificationComponent, {
      data: {
        message: message,
        action: action,
      },
      duration: 2500,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: [action],
    });
  };

  agregarAgenda(sucursal: CentroMedico) {
    const agendaForm = this.fb.group(
      {
        id: [""],
        id_sucursal: sucursal.id,
        esSucursal: Number(sucursal.sucursal) !== 0,
        lunesDesde: [""],
        lunesHasta: [""],
        martesDesde: [""],
        martesHasta: [""],
        miercolesDesde: [""],
        miercolesHasta: [""],
        juevesDesde: [""],
        juevesHasta: [""],
        viernesDesde: [""],
        viernesHasta: [""],
        sabadoDesde: [""],
        sabadoHasta: [""],
        domingoDesde: [""],
        domingoHasta: [""],
      },
      {
        validators: [
          validateDaySchedule("lunes"),
          validateDaySchedule("martes"),
          validateDaySchedule("miercoles"),
          validateDaySchedule("jueves"),
          validateDaySchedule("viernes"),
          validateDaySchedule("sabado"),
          validarTodasLasFechas,
        ],
      }
    );
    (this.medicoForm.get("sucursales") as FormArray).push(agendaForm);
  }
  private initAgendaFormGroup() {
    this.agendaFormGroup$ =
      this.medicoForm.controls.sucursalSeleccionada.valueChanges.pipe(
        map((sucursalId: number) => {
          return this.obtenerFormGroupAgenda(sucursalId);
        })
      );
  }

  private obtenerFormGroupAgenda(sucursalId?: number): FormGroup {
    const agendaIndex = this.medicoForm.value.sucursales.findIndex(
      (s) => s.id_sucursal === sucursalId
    );

    const sucursales = this.medicoForm.get("sucursales") as FormArray;
    return sucursales.at(agendaIndex) as FormGroup;
  }

  private abrirModalAdvertenciaAgendas(
    errorData: {
      overlappingDays: string[];
      agenda1: Agenda;
      agenda2: Agenda;
    }[]
  ) {
    errorData.forEach((element) => {
      element.agenda1 = {
        ...element.agenda1,
        nombre_sucursal: this.sucursales.find(
          (s) => s.id === element.agenda1.id_sucursal
        ).nombre,
      };

      element.agenda2 = {
        ...element.agenda2,
        nombre_sucursal: this.sucursales.find(
          (s) => s.id === element.agenda2.id_sucursal
        ).nombre,
      };
    });
    const dialogRef = this.dialogs.open(ModalAdvertenciaAgendasComponent, {
      height: "fit-content",
      data: { errorData },
    });
  }

  private overlapErrorHandler() {
    this.medicoForm.get("sucursales").statusChanges.subscribe((status) => {
      if (
        status == "INVALID" &&
        this.medicoForm.get("sucursales")?.errors?.agendaOverlap.length > 0 &&
        this.medicoForm.get("sucursales")?.errors?.agendaOverlap.length >
          this.overlapError
      ) {
        this.abrirModalAdvertenciaAgendas(
          this.medicoForm.get("sucursales")?.errors?.agendaOverlap
        );
        this.overlapError += 1;
      }

      if (
        status === "VALID" ||
        this.medicoForm.get("sucursales")?.errors?.agendaOverlap.length === 0
      ) {
        this.overlapError = 0;
      }
    });
  }

  public overlapErrorDays(idSucursal: number): string[] {
    const agendaOverlap =
      this.medicoForm.get("sucursales")?.errors?.agendaOverlap;

    if (!agendaOverlap || !Array.isArray(agendaOverlap)) {
      return [];
    }

    for (const overlap of agendaOverlap) {
      if (
        overlap.agenda1?.id_sucursal === idSucursal ||
        overlap.agenda2?.id_sucursal === idSucursal
      ) {
        return overlap.overlappingDays || [];
      }
    }

    return [];
  }
}
