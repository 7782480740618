<div class="divContent">
    <mat-accordion class="acordeonContent">
        <mat-expansion-panel hideToggle [expanded]="panelOpenState" class="mat-elevation-z4">
            <mat-expansion-panel-header class="acordeonHeader" (click)="ocultarMostrar()">
                <div class="topTitle">
                    <mat-card class="titleSeccion">
                        <span *ngIf="!editarValor">Crear tipo documento</span>
                        <span *ngIf="editarValor">Editar tipo documento</span>
                        <mat-icon *ngIf="!panelOpenState" class="iconArrow">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="panelOpenState" class="iconArrow">keyboard_arrow_up</mat-icon>
                    </mat-card>
                </div>
            </mat-expansion-panel-header>

            <form [formGroup]="tipoDocumentoForm" (submit)="guardarTipoDocumento(tipoDocumentoForm.value)" class="formCard">
                <div class="card">
                    <div class="formGroup">
                        <input hidden matInput formControlName="id">
                    </div>
                    <div class="formGroup">
                        <mat-form-field class="example-full-width input" appearance="fill">
                            <mat-label>Texto</mat-label>
                            <input formControlName="texto" type="text" matInput (ngModelChange)="clearTexto()">
                        </mat-form-field>
                        <span style="color:rgb(236,75,72);" *ngIf="tipoDocumentoForm.get('texto').invalid && (tipoDocumentoForm.get('texto').dirty || tipoDocumentoForm.get('texto').touched)">
                            El texto es requerido
                        </span>
                        <span style="color:rgb(236,75,72);" *ngIf="errorTexto && tipoDocumentoForm.get('texto').touched">
                            El texto fue registrado por otro tipo de documento
                        </span>
                    </div>
                    <div class="formButton">
                        <button *ngIf="!editarValor" mat-raised-button class="buttonForm">Crear</button>
                        <button *ngIf="editarValor" mat-raised-button class="buttonForm Cancelar"routerLink="../../">Cancelar</button>
                        <button *ngIf="editarValor" mat-raised-button class="buttonForm">Editar</button>
                    </div>
                </div>
            </form>

        </mat-expansion-panel>
    </mat-accordion>
</div>