import {
  ElementRef,
  Component,
  OnInit,
  ViewChild,
  Input,
  HostListener,
} from "@angular/core";
import { GlobalVariable } from "app/global/global";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { ResponsiveService } from "../../../services/responsive.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  //
  VERSION: string = GlobalVariable.VERSION_FRONT;
  turnoHabilitado: any;
  usuarioPanelValue: any;
  isAdmin = false;
  showFiller = false;
  public opened = false;
  public mobile = false;

  @ViewChild("dashboardContent") dashboardContent: ElementRef;
  @ViewChild("shadow") shadow: ElementRef;
  @Input() set usuarioPanel(value: any) {
    if (value) {
      this.usuarioPanelValue = value;
      if (this.usuarioPanelValue.perfil.toUpperCase() === "ADMINISTRADOR") {
        this.isAdmin = true;
      }
    }
  }

  @Input() set menu(value: string) {
    this.mostrarShadow(value);
  }

  //
  constructor(
    private router: Router,
    private rsponsiveService: ResponsiveService
  ) {}

  //
  ngOnInit(): void {
    this.turnoHabilitado = Number(localStorage.getItem("turno_habilitado"));
    this.rsponsiveService.isMobile$.subscribe({
      next: (isMobile) => {
        this.mobile = isMobile;
      },
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.mobile) {
          this.quitarShadow();
        }
      });
  }

  mostrarShadow(value: string) {
    if (value != undefined || value != null) {
      this.shadow.nativeElement.style.display = "block";
      this.dashboardContent.nativeElement.style.left = "0px";
    }
  }

  //
  quitarShadow() {
    this.shadow.nativeElement.style.display = "none";
    this.dashboardContent.nativeElement.style.left = "-100%";
  }

  @HostListener("window:resize", ["$event"]) onResize(event) {
    if (event.target.innerWidth > 900) {
      this.dashboardContent.nativeElement.style.left = "0px";
      this.shadow.nativeElement.style.display = "none";
    } else if (event.target.innerWidth <= 900) {
      this.dashboardContent.nativeElement.style.left = "-100%";
      this.shadow.nativeElement.style.display = "none";
    }
  }

  public toggleSidebar() {
    this.opened = !this.opened;
  }
}
