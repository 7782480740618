<div class="content-password">
    <div class="content">
        <a mat-flat-button routerLink="/auth/login-paciente">
            <img src="assets/img/ico_volver2.png" class="icon"/> 
            <span class="nav-caption">Volver</span>
        </a>
        <mat-card class="mail-card mat-elevation-z4">
            <div class="title">Recuperar Clave</div>
            <form [formGroup]="mailForm" (submit)="enviarMail(mailForm.value)" class="example-form">
                <div class="divInput">
                    <mat-form-field class="example-full-width input" appearance="fill">
                        <mat-label>Mail</mat-label>
                        <input type="email" formControlName="email" matInput placeholder="">
                    </mat-form-field>
                </div>
                <!-- recuperar-contraseña -->
                <div class="divButton">
                    <button type="submit" mat-raised-button color="primary" class="enviar">Enviar Email</button>
                </div>
            </form>
        </mat-card>
    </div>
</div>
