<div class="panel">
    <div class="dashboard">
        <app-dashboard [usuarioPanel]="usuarioPanel"></app-dashboard>
    </div>
    <div class="panelContent">  
        <app-header-panel [usuarioPanelBinding]="usuarioPanel" [notificacionesPushBinding]="notificacionesPush"></app-header-panel>
        <app-obra-social-form [editar]="editar" (listar)="listaActualizada($event)"></app-obra-social-form>
        <div class="seccionTable">
            <mat-card class="cardTable mat-elevation-z4">  
                <div class="topTitle">
                    <mat-card class="titleSeccion">Obras sociales</mat-card>
                </div>
                
                <mat-form-field class="example-full-width buscador" appearance="fill">
                    <mat-label>Buscador</mat-label>
                    <input placeholder="" #buscador (keyup)="filter($event)" [(ngModel)]="searchKey" type="text" matInput autocomplete="off">
                </mat-form-field>
        
                <table class="" mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef><span class="span">ID</span> </th>
                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                    </ng-container>
                
                    <ng-container matColumnDef="nombre">
                        <th mat-header-cell *matHeaderCellDef> Nombre </th>
                        <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                    </ng-container>
                                
                    <ng-container matColumnDef="logo">
                        <th mat-header-cell *matHeaderCellDef> Logo </th>
                        <td mat-cell *matCellDef="let element"> 
                            <div class="divFoto">
                                <img style='display:none;' (load)="cargarImagen($event)" src="{{url}}{{element.logo}}" class="logo"/>
                            </div>
                        </td>
                    </ng-container>
                                
                    <ng-container matColumnDef="acciones">
                        <th mat-header-cell *matHeaderCellDef class="th"> Acciones </th>
                        <td mat-cell *matCellDef="let element" class="td">
                            <div class="divIcons">
                                <div [routerLink]="['/seccion-panel/obra-social/editar',element.id]" class="editar divIcon">
                                    <mat-icon class="icon">edit</mat-icon>
                                </div>
                                <div (click)="openDialog(element)" class="borrar divIcon">
                                    <mat-icon class="icon">delete_forever</mat-icon>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                    
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons 
                    aria-label="Select page of periodic elements">
                </mat-paginator>      
            </mat-card>        
        </div>        
    </div>  
</div>