<div class="panel">
    <div class="dashboard">
        <app-dashboard-medico [response]="responseLogin" [menu]="menu"></app-dashboard-medico>
    </div>
    <div class="panelContent">
        <app-header-medico [medicoBinding]="responseLogin" [notificacionesPushBinding]="notificacionesPush" (messageEvent)="receiveMessage($event)"></app-header-medico>
        <div class="divContent">
            <app-filtro-turnos-medico 
                (filtroEvent)="busquedaFiltrada($event)" 
                [response]="responseDatos" 
                [turnos]="turnos"
            ></app-filtro-turnos-medico>
            <app-calendario-medico 
                (listarEvent)="refrescarListaTurnos($event)" 
                [pacienteInput]="paciente" 
                [response]="responseDatos"
                [filtroTurnos]="filtroTurnos"
                [usuario]="medico"
                [centroMedicoSeleccionado]="centroMedico"
            ></app-calendario-medico>
        </div>
    </div>
</div>
