<div class="panel">
    <div class="dashboard">
        <app-dashboard [usuarioPanel]="usuarioPanel"></app-dashboard>
    </div>
    <div class="panelContent">
        <app-header-panel [usuarioPanelBinding]="usuarioPanel" [notificacionesPushBinding]="notificacionesPush"></app-header-panel>
        <app-form-sucursal [response]="response" (listar)="listaActualizada($event)" [editar]="editar"></app-form-sucursal>
        <div class="seccionTable">
            <mat-card class="cardTable mat-elevation-z4">
                <div class="topTitle"><mat-card class="titleSeccion">Sucursales</mat-card></div>
                <mat-form-field class="example-full-width buscador" appearance="fill">
                    <mat-label>Buscador</mat-label>
                    <input placeholder="" #buscador (keyup)="filter($event)" [(ngModel)]="searchKey" type="text" matInput autocomplete="off">
                </mat-form-field>
                <table  class="" mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="nombre">
                        <th mat-header-cell *matHeaderCellDef class="th"><span class="span">Nombre</span> </th>
                        <td mat-cell *matCellDef="let element" class="td">{{element.nombre}}</td>
                    </ng-container>
                    <ng-container matColumnDef="direccion">
                    <th mat-header-cell *matHeaderCellDef class="th">Dirección</th>
                    <td mat-cell *matCellDef="let element" class="td">{{element.direccion}}</td>
                    </ng-container>
                    <ng-container matColumnDef="telefono">
                        <th mat-header-cell *matHeaderCellDef class="th">Teléfono</th>
                        <td mat-cell *matCellDef="let element" class="td">{{element.telefono}}</td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef class="th">Email</th>
                        <td mat-cell *matCellDef="let element" class="td">{{element.email}}</td>
                    </ng-container>
                    <ng-container matColumnDef="acciones">
                        <th mat-header-cell *matHeaderCellDef class="th">Acciones</th>
                        <td mat-cell *matCellDef="let element" class="td">
                            <div class="divIcons">
                                <div [routerLink]="['/seccion-panel/sucursal/editar',element.id]" class="editar divIcon">
                                    <mat-icon class="icon">edit</mat-icon>
                                </div>
                                <div (click)="openDialog(element)" class="borrar divIcon">
                                    <mat-icon class="icon">delete_forever</mat-icon>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>    
                <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons 
                 aria-label="Select page of periodic elements">
                </mat-paginator>
            </mat-card>        
        </div>
    </div>
</div>