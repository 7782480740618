import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl, Validator, Validators } from "@angular/forms";

@Component({
  selector: "app-filtro-turnos-medico",
  templateUrl: "./filtro-turnos-medico.component.html",
  styleUrls: ["./filtro-turnos-medico.component.scss"],
})
export class FiltroTurnosMedicoComponent implements OnInit {
  //
  filtroPacientes: any;
  listPacientes: any;
  listTurnos: any;
  filtrosTurnos: any;
  listaCentrosMedicos: any;
  filtroCentroMedicos: any;
  idMedico: any;
  tipoMedico: any;
  panelOpenState = true;
  public paciente: FormControl = new FormControl();
  public pacienteBuscador: FormControl = new FormControl();
  filtroCalendario = new FormGroup({
    pacienteBuscador: new FormControl(""),
    paciente: new FormControl(""),
    centrosMedicos: new FormControl(""),
    centrosMedicosBuscador: new FormControl(""),
  });
  @Output() filtroEvent = new EventEmitter<string>();
  @Input() set response(value: any) {
    if (value) {
      this.mostrarDatos(value);
    }
  }
  @Input() set turnos(value: any) {
    if (value) {
      this.createListTurno(value);
    }
  }

  //
  constructor() {}

  //
  ngOnInit(): void {}

  //
  mostrarDatos(data: any) {
    this.listPacientes = data.pacientes;
    this.filtroPacientes = data.pacientes;
    this.listaCentrosMedicos = data.centrosMedicos;
    this.filtroCentroMedicos = data.centrosMedicos;
  }

  //
  createListTurno(data: any) {
    this.listTurnos = data;
    this.filtrosTurnos = data;
    this.busquedaFiltrada();
  }

  //
  filtrarPaciente(event: any) {
    let valorString: string = this.pacienteBuscador.value;
    this.filtroPacientes = [];

    for (let ops of this.listPacientes) {
      if (
        ops.nombreApellido.toUpperCase().includes(valorString.toUpperCase())
      ) {
        let nuevoP: any = ops;
        this.filtroPacientes.push(nuevoP);
      }
    }
  }

  filtrarCentroMedico(event: any) {
    let valorString: string = this.filtroCalendario.get(
      "centrosMedicosBuscador"
    ).value;
    let array = [];
    for (let ops of this.listaCentrosMedicos) {
      if (ops.nombre.toUpperCase().includes(valorString.toUpperCase())) {
        let nuevoCM: any = ops;
        array.push(nuevoCM);
      }
    }
    this.filtroCentroMedicos = array;
  }

  //
  busquedaFiltrada() {
    let pacienteValue = this.filtroCalendario.get("paciente").value;
    let centroMedicoValue = this.filtroCalendario.get("centrosMedicos").value;

    this.filtrosTurnos = this.listTurnos.filter((elemento: any) => {
      const matchesPaciente =
        !pacienteValue || pacienteValue == elemento.id_paciente;

      const matchesCentroMedico =
        !centroMedicoValue || centroMedicoValue == elemento.id_centromedico;

      return matchesPaciente && matchesCentroMedico;
    });

    let paciente = this.listPacientes.find(
      (element: any) => element.id == pacienteValue
    );
    let centroMedico = this.listaCentrosMedicos.find(
      (element: any) => element.id == centroMedicoValue
    );
    let object = {
      paciente,
      centroMedico,
      filtroTurnos: this.filtrosTurnos,
    };
    this.filtroEvent.emit(JSON.stringify(object));
  }

  //
  ocultarMostrar() {
    this.panelOpenState = this.panelOpenState == true ? false : true;
  }
}
