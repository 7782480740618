<div class="divButton">
    <button *ngIf="visible" [disabled]="disabled" mat-raised-button class="button" (click)="imprimirPdf()">Imprimir</button>
    <div class="spinner" *ngIf="spinner">
        <mat-spinner [diameter]="30"></mat-spinner>
    </div>
</div>
<full-calendar #calendar [options]="calendarOptions"></full-calendar>

<style>
    .fc-button {
      background-color: red;
      color: white;
      /* otros estilos personalizados */
    }
  </style>