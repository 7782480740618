import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TurnosMedicoComponent } from './turnos-medico/turnos-medico.component'; 
import { TurnosMedicoGuard } from 'app/guards/turnos-medico.guard';

const routes: Routes = [
  {
    path: "seccion-medico",
      children: [
        {
          path: "turnos",
          pathMatch: "full",
          component: TurnosMedicoComponent,
          canActivate: [TurnosMedicoGuard]
        },
      ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TurnosMedicoRoutingModule { }
