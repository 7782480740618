import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'primeng/api';
import { MaterialModule } from 'app/material/material.module';
import { EstudioMedicoRoutingModule } from './estudio-medico-routing.module';
import { SharedPanelModule } from '../shared-panel/shared-panel.module'; 
import { EstudioMedicoEditarComponent } from './estudio-medico-editar/estudio-medico-editar.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { EstudioMedicoIndexComponent } from './estudio-medico-index/estudio-medico-index.component';
import { EstudioMedicoFormComponent } from './estudio-medico-form/estudio-medico-form.component';


@NgModule({
  declarations: [
    EstudioMedicoEditarComponent,
    EstudioMedicoIndexComponent,
    EstudioMedicoFormComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    EstudioMedicoRoutingModule,
    FormsModule,
    SharedPanelModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
})
export class EstudioMedicoModule { }
