import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule,ReactiveFormsModule } from "@angular/forms";
import { AuthInterceptor } from './services/Interceptor/auth.interceptor';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CornerstoneDirective } from "./directives/cornerstone.directive";
import { PrimeNgModule } from "./prime-ng/prime-ng.module";
import { SharedModule } from "./shared/shared.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material/material.module";
import { PagesComponent } from "./pages/pages.component";
import { AuthModule } from "./auth/auth.module";
import { SucursalModule } from "./panel-seccion/sucursal/sucursal.module";
import { ObraSocialModule } from "./panel-seccion/obra-social/obra-social.module"; 
import { EstudioMedicoModule } from "./panel-seccion/estudio-medico/estudio-medico.module"; 
import { ConfirmDialogComponent } from "./shared/confirm-dialog/confirm-dialog.component";
import { TipoDocumentoModule } from "./panel-seccion/tipo-documento/tipo-documento.module"; 
import { UsuarioPanelModule } from "./panel-seccion/usuario-panel/usuario-panel.module"; 
import { FotoDialogComponent } from "./shared/foto-dialog/foto-dialog.component";
import { MedicoModule } from "./panel-seccion/medico/medico.module";
import { PacienteModule } from "./panel-seccion/paciente/paciente.module"; 
import { FormDialogComponent } from "./shared/form-dialog/form-dialog.component";
import { MedicoFormDialogComponent } from "./shared/medico-form-dialog/medico-form-dialog.component";
import { PasswordDialogComponent } from "./shared/password-dialog/password-dialog.component";
import { CentroMedicoModule } from "./panel-seccion/centro-medico/centro-medico.module";
import { HomePanelModule } from "./panel-seccion/home-panel/home-panel.module"; 
import { ShowImagenComponent } from "./shared/show-imagen/show-imagen.component";
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SharedPanelModule } from "./panel-seccion/shared-panel/shared-panel.module"; 
import { ContactoDialogComponent } from "./shared/contacto-dialog/contacto-dialog.component";
import { UserModule } from "./user/user.module";
import { DicomViewerModule } from 'ng-dicomviewer';
import { CornerstoneService } from "./services/cornerstone.service";
import { SharedMedicoModule } from './medico-seccion/shared-medico/shared-medico.module';
import { TurnosMedicoModule } from './medico-seccion/turnos-medico/turnos-medico.module';
import { InformesModule } from './panel-seccion/informes/informes.module';
import { TurnoAddDialogComponent } from './shared/turno-add-dialog/turno-add-dialog.component';
import { TurnoDeleteDialogComponent } from './shared/turno-delete-dialog/turno-delete-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    PrimeNgModule,
    MaterialModule,
    AuthModule,
    SucursalModule,
    EstudioMedicoModule,
    ObraSocialModule,
    TipoDocumentoModule,
    UsuarioPanelModule,
    MedicoModule,
    PacienteModule,
    CentroMedicoModule,
    HomePanelModule,
    SharedPanelModule,
    UserModule,
    DicomViewerModule,
    MatTableModule,
    NgxSpinnerModule,
    SharedMedicoModule,
    TurnosMedicoModule,
    InformesModule,
  ],
  entryComponents : [
    ConfirmDialogComponent,
    FotoDialogComponent,
    FormDialogComponent,
    MedicoFormDialogComponent,
    PasswordDialogComponent,
    ShowImagenComponent,
    ContactoDialogComponent,
    TurnoAddDialogComponent,
    TurnoDeleteDialogComponent,
  ],
  providers: [
    CornerstoneService,
    {
      provide: MAT_DATE_LOCALE, 
      useValue: 'en-GB'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}
