import { Component, OnInit } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Paciente } from 'app/models/paciente.interface';
import { RecuperarContraseñaService } from 'app/services/recuperar-contraseña.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { FormGroup,FormControl,Validator, Validators } from '@angular/forms';
import { SpinnerService } from 'app/services/spinner.service';

@Component({
  selector: 'app-enviar-email-paciente',
  templateUrl: './enviar-email-paciente.component.html',
  styleUrls: ['./enviar-email-paciente.component.scss']
})
export class EnviarEmailPacienteComponent implements OnInit {

  mailForm:FormGroup;

  constructor(
    private router: Router,
    private apiRCS:RecuperarContraseñaService,
    private snackBar:MatSnackBar,
    private spinService:SpinnerService,
  ) { }


  ngOnInit(): void {
    this.iniciarFormularion();
  }


  iniciarFormularion(){
    this.mailForm = new FormGroup({
      email : new FormControl('',[Validators.required,Validators.email])
    })
  }


  enviarMail(form:any){
    this.spinService.mostrarSpinner();
    this.apiRCS.recuperarPaciente(form).subscribe(
      data=>{
        this.spinService.ocultarSpinner();
        this.openSnackBar("Mail enviado exitosamente","exito");
      },
      error=>{
        this.spinService.ocultarSpinner();
        this.openSnackBar("El email ingresado no esta registrado","error");
      }
    )
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.openFromComponent(NotificationComponent,{
      data: {
        message : message,
        action : action
      },
      duration:2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass : [action]
    });
  }

}
