<div class="div">
    <a class="buttonClose" (click)="onClickNo()">
        <mat-icon>close</mat-icon>
    </a>
    <img class="img" *ngIf="extd=='img'" [src]="imageData"/>
    
    <div
        *ngIf="extd=='dcm'"
        oncontextmenu="return false;"
        id="dicomWrapper"
        class="disable-selection noIbar"
        unselectable="on"
        onselectstart="return false;"
        onmousedown="return false;"
    >
        <div cornerstone class="dcm" [image]="imageData" id="dicomImage"></div>
    </div>

    <pdf-viewer 
        class="pdf"
        *ngIf="extd=='pdf'"
        [src]="imageData"
        [render-text]="true"
        [original-size]="false"
    >
    </pdf-viewer>

    <iframe
        *ngIf="extd=='xlsx' && imageData.includes('http')"
        class="pdf"
        [src]="imageData | safe"
    >
    </iframe>

    <p *ngIf="extd=='xlsx' && !imageData.includes('http')">{{imageData}}</p>

</div>