<div class="chatContent">
    <div [ngClass]="getClassPaciente()">
        <div *ngFor="let item of listComentarioEstudioMedico; index as i;last as isLast">
            <div [ngClass]="getClasses(i)">
                <div *ngIf="item.spinner" class="spinnerContentDiv">
                    <div class="spinnerContent">
                        <div class="ball-beat">
                            <div class="ball"></div>
                            <div class="ball"></div>
                            <div class="ball"></div>
                        </div>
                    </div>
                </div>
                <div class="seccionComentario">
                    <div class="contenidoComentario">
                        <div *ngIf="item.boton_editar == 0" class="textoComentario">{{item.texto}}</div>
                        <textarea *ngIf="item.boton_editar == 1" rows="1" class="textAreaComentario" [attr.data-id]="item.id"
                            rows="5" placeholder="" (input)="textAreaValid($event, item)">{{item.texto}}</textarea>
                        <button mat-fab [disabled]="isDisabledEdit" (click)="editarComentario(item)"
                            *ngIf="item.boton_editar == 0 && (idUsuario == item.id_usuario_panel || idUsuario == item.id_medico)"
                            class="iconEdit">
                            <mat-icon class="icon">edit</mat-icon>
                        </button>
                        <button mat-fab [matMenuTriggerFor]="menu"
                            (click)="openMenu($event)"
                            *ngIf="item.boton_editar == 1 && (idUsuario == item.id_usuario_panel || idUsuario == item.id_medico)"
                            class="iconEdit">
                            <mat-icon class="icon">more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu" class="matMenu">
                            <button [disabled]="isDisabledEdit || item.disabled" mat-menu-item
                                (click)="confirmarEdicion(item, index)"><mat-icon>edit</mat-icon>Editar</button>
                            <button mat-menu-item
                                (click)="cancelarEdicion(item, index)"><mat-icon>clear</mat-icon>Cancelar</button>
                        </mat-menu>
                    </div>
                </div>
                <div class="seccionDatos">
                    <div class="nombreUsuario">{{ (item.nombre_completo_usuario) ? item.nombre_completo_usuario :
                        item.nombre_completo_medico }}</div>
                    <div class="fecha">{{item.fecha_formateada}}</div>
                </div>
            </div>
        </div>
        <div *ngIf="createSpinner" class="spinnerContentCreate">
            <div class="spinnerContent">
                <div class="ball-beat">
                    <div class="ball"></div>
                    <div class="ball"></div>
                    <div class="ball"></div>
                </div>
            </div>
        </div>
    </div>
    <form 
        *ngIf="tipoUsuario == 'medico' || tipoUsuario == 'usuario_panel'"
        [formGroup]="comentarioEstudioMedicoForm"
        (submit)="guardarComentarioEstudioMedico(comentarioEstudioMedicoForm.value)" 
        class="formCard"
    >
        <div class="formGroupComentario">
            <div class="comentarioText">
                <mat-form-field class="example-full-width input" appearance="fill">
                    <mat-label>Comentario</mat-label>
                    <input formControlName="texto" type="text" matInput (input)="valid($event)">
                </mat-form-field>
            </div>
            <div class="agregar">
                <button [disabled]="isDisabledCreate" mat-fab class="botonAgregar">
                    <mat-icon>send</mat-icon>
                </button>
            </div>
        </div>
    </form>
</div>