import { Component, OnInit, Input, EventEmitter,Output } from '@angular/core';
import { FormGroup,FormControl,Validator, Validators } from '@angular/forms';
import { EstudioMedicoService } from 'app/services/estudio-medico.service';
import { EstudioMedico } from 'app/models/estudioMedico.interface'; 
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { SpinnerService } from 'app/services/spinner.service';


@Component({
  selector: 'app-estudio-medico-form',
  templateUrl: './estudio-medico-form.component.html',
  styleUrls: ['./estudio-medico-form.component.scss']
})
export class EstudioMedicoFormComponent implements OnInit {


  //
  panelOpenState:boolean = false;
  funcionCorrecta:any;
  editarValor:boolean;
  @Input() set editar(value:boolean) {
    console.log(value);
    this.editarValor = value;
    this.panelOpenState = (value) ? true : false;
  }
  @Output() listar:EventEmitter<boolean> = new EventEmitter<boolean>();
  estudioMedicoValue:EstudioMedico;
  errorTexto:any;
  estudioMedicoForm = new FormGroup({
    id: new FormControl(''),
    texto: new FormControl('',Validators.required)
  })
  @Input() set estudioMedico(value:any) {
    if(value){
      this.mostrarEstudioMedico(value);
    }
  }
  

  //
  constructor(
    private estudioMedicoService: EstudioMedicoService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private spinnerService:SpinnerService,
  ) { }


  //
  ngOnInit(): void {}


  //
  clearTexto(){
    this.errorTexto = false;
  }


  //
  mostrarEstudioMedico = (data:any) => {
    this.estudioMedicoValue = data;
    this.estudioMedicoForm.setValue({
      id: this.estudioMedicoValue.id,
      texto: this.estudioMedicoValue.texto
    }); 
  }


  guardarEstudioMedico = (form:EstudioMedico) => {
    this.funcionCorrecta = (this.editarValor) ? this.editarEstudioMedico(form) : this.crearEstudioMedico(form); 
  }


  //
  crearEstudioMedico = (form:EstudioMedico) => {
    if(this.estudioMedicoForm.valid){
      this.spinnerService.mostrarSpinner();
      this.estudioMedicoService.crear(form).subscribe(
        data => {
          let response:any = data;
          if(response.errorTexto){
            this.errorTexto = response.errorTexto;
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Guardado fallido","error");
          }
          else{
            this.resetearFormulario();
            this.openSnackBar("Guardado exitoso","exito");
            this.listar.emit();
          }
        },
        error => {
          console.log('error',error);
          this.openSnackBar("Guardado fallido","error");
          this.spinnerService.ocultarSpinner();
        }
      )
    } 
    else{
      this.openSnackBar("Guardado fallido","error");
    }
  }


  //
  editarEstudioMedico(form:EstudioMedico){
    if(this.estudioMedicoForm.valid){
      this.spinnerService.mostrarSpinner();
      this.estudioMedicoService.editar(form).subscribe(
        data => {
          let response:any = data;
          if(response.errorTexto){
            this.errorTexto = response.errorTexto;
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Editado fallido","error");
          }
          else{
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Editado exitoso","exito");
            this.router.navigate(['/seccion-panel/estudio-medico/']);
          }
        },
        error => {
          this.openSnackBar("Editado fallido","error");
          this.spinnerService.ocultarSpinner();
        }
      )
    }
    else{
      this.openSnackBar("Editado fallido","error");
    }
  }


  //
  ocultarMostrar = () => {
    this.panelOpenState = (this.panelOpenState) ? false : true;
  }


  //
  resetearFormulario = () => {
    this.estudioMedicoForm.reset();
    this.panelOpenState = false;
  }


  //
  openSnackBar = (message: string, action: string) => {
    this.snackBar.openFromComponent(NotificationComponent,{
      data: {
        message: message,
        action: action
      },
      duration: 2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [action]
    });
  }


}
