import { Component, OnInit, Input } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Informes } from 'app/models/informes.interface';

@Component({
  selector: 'app-informe-creaciones-por-anio',
  templateUrl: './informe-creaciones-por-anio.component.html',
  styleUrls: ['./informe-creaciones-por-anio.component.scss']
})
export class InformeCreacionesPorAnioComponent implements OnInit {


  //
  @Input() titulo:any;
  @Input() descripcion:any;
  informes:any;
  informesEstudioMedico:Array<any> = []
  single:any = [];
  habilitar:boolean = false;
  meses = [
    { 'id': 1 ,'mes' : 'Enero' },
    { 'id': 2 ,'mes' : 'Febrero' },
    { 'id': 3 ,'mes' : 'Marzo' },
    { 'id': 4 ,'mes' : 'Abril' },
    { 'id': 5 ,'mes' : 'Mayo' }, 
    { 'id': 6 ,'mes' : 'Junio' },
    { 'id': 7 ,'mes' : 'Julio' },
    { 'id': 8 ,'mes' : 'Agosto' }, 
    { 'id': 9 ,'mes' : 'Septiembre' }, 
    { 'id': 10 ,'mes' : 'Octubre' },
    { 'id': 11 ,'mes' : 'Noviembre' },
    { 'id': 12 ,'mes' : 'Diciembre' }, 
  ]
  @Input() set informesPorAnio(value: any) {
    this.crearArray(value);
  }
  view: any[] = [700, 400];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = new Date().getFullYear();
  showYAxisLabel = true;
  yAxisLabel = '';
  colorScheme = {
    domain: ['#5AA454']
  };


  //
  constructor() { }


  //
  ngOnInit(): void {}


  //
  crearArray(value){
    console.log(value);
    if(value != null){  
      let currentTime = new Date();
      let mes = currentTime.getMonth()+1;
      this.meses.forEach((element,i) => {
        mes++;
        if(mes > 12){
            mes = 1;
        }

        let estado = false;
        value.forEach((element,j) => {
          if(value[j].mes == this.meses[mes-1].id){
            let info:Informes = {
              name: this.meses[mes-1].mes,
              value: value[j].cantidad
            };
            this.informesEstudioMedico.push(info);
            estado = true;
          } 
        });

        if(estado == false){
          let info:Informes = {
            name: this.meses[mes-1].mes,
            value: 0
          };
          this.informesEstudioMedico.push(info);
        }
      });

      this.habilitar = true;
      Object.assign(this, this.informesEstudioMedico);
    }
  }


  //
  onSelect(event) {
    console.log(event);
  }
  

}
