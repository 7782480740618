<div class="divContent">
  <mat-accordion class="acordeonContent">
    <mat-expansion-panel
      hideToggle
      [expanded]="panelOpenState"
      class="mat-elevation-z4"
    >
      <mat-expansion-panel-header
        class="acordeonHeader"
        (click)="ocultarMostrar()"
      >
        <div class="topTitle">
          <mat-card class="titleSeccion">
            <span *ngIf="!editar"
              >Crear estudio médico para
              <span *ngIf="paciente != null">{{
                paciente.nombreApellido
              }}</span></span
            >
            <span *ngIf="editar"
              >Editar estudio médico para
              <span *ngIf="paciente != null">{{
                paciente.nombreApellido
              }}</span></span
            >
            <mat-icon *ngIf="!panelOpenState" class="iconArrow"
              >keyboard_arrow_down</mat-icon
            >
            <mat-icon *ngIf="panelOpenState" class="iconArrow"
              >keyboard_arrow_up</mat-icon
            >
          </mat-card>
        </div>
      </mat-expansion-panel-header>

      <form
        [formGroup]="estudioMedicoPacienteForm"
        (submit)="guardarEstudioMedicoPaciente(estudioMedicoPacienteForm.value)"
        class="formCard"
      >
        <div class="card">
          <div class="formGroup">
            <input hidden matInput formControlName="id" />
          </div>
          <div class="formGroup">
            <input hidden matInput formControlName="idPaciente" />
          </div>
          <div class="validPaciente">
            <span
              class="advertenciaMensaje"
              *ngIf="paciente && !paciente?.email"
            >
              <mat-icon class="advertenciaIcono">report_problem</mat-icon>El
              paciente actual no tiene email, no le llegaran notificaciones que
              pueden ser importantes
            </span>
          </div>
          <div #selecEstMed class="formGroup selecEstMed">
            <mat-form-field
              appearance="fill"
              class="example-full-width input estMed"
            >
              <mat-label>Estudio médico</mat-label>
              <mat-select *ngIf="!primEstudio"></mat-select>
              <mat-select
                *ngIf="primEstudio"
                formControlName="estudioMedico"
                [placeholder]="'Estudio médico'"
              >
                <mat-option>
                  <ngx-mat-select-search
                    id="estudioMedico"
                    [noEntriesFoundLabel]="'Not found'"
                    [placeholderLabel]="'Buscador'"
                    [formControl]="estudioMedico"
                    name="filterPost"
                    (ngModelChange)="filtrarEstudioMedico($event)"
                  >
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let element of filtroEstudioMedico"
                  [value]="element.id"
                >
                  <span>{{ element.texto }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="divAgregar">
              <button
                type="button"
                mat-mini-fab
                (click)="agregarEstudioMedico()"
                class="agregar"
              >
                <mat-icon>add</mat-icon>
              </button>
            </div>
            <div class="validSelectAdd">
              <span
                style="color: rgb(236, 75, 72)"
                *ngIf="
                  estudioMedicoPacienteForm.get('estudioMedico').errors
                    ?.required &&
                  estudioMedicoPacienteForm.get('estudioMedico').touched
                "
              >
                El estudio médico es requerido
              </span>
            </div>
          </div>
          <div class="formGroup selecMed" *ngIf="!medicoSection">
            <mat-form-field
              appearance="fill"
              class="example-full-width input medico"
            >
              <mat-label>Médico</mat-label>
              <mat-select *ngIf="!primMedico"></mat-select>
              <mat-select
                *ngIf="primMedico"
                formControlName="medico"
                [placeholder]="'Médico'"
                (ngModelChange)="validarMedicoEmail($event)"
              >
                <mat-option>
                  <ngx-mat-select-search
                    id="medico"
                    [noEntriesFoundLabel]="'Not found'"
                    [placeholderLabel]="'Buscador'"
                    [formControl]="medico"
                    name="filterPost"
                    (ngModelChange)="filtrarMedico($event)"
                  >
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option
                  class="opcion"
                  *ngFor="let element of filtroMedicos"
                  [value]="element.id"
                >
                  <div class="optionCM">
                    <span>{{ element.nombreCompleto }}</span>
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="divAgregar">
              <button
                type="button"
                mat-mini-fab
                (click)="agregarMedico()"
                class="agregar"
              >
                <mat-icon>add</mat-icon>
              </button>
            </div>
            <div class="validSelectAdd">
              <span
                class="advertenciaMensaje"
                *ngIf="medicoSelecionado && !medicoSelecionado?.email"
              >
                <mat-icon class="advertenciaIcono">report_problem</mat-icon> El
                médico selecionado no tiene email, no le llegaran notificaciones
                que pueden ser importantes
              </span>
              <span
                style="color: rgb(236, 75, 72)"
                *ngIf="
                  estudioMedicoPacienteForm.get('medico').errors?.required &&
                  estudioMedicoPacienteForm.get('medico').touched
                "
              >
                El médico es requerido
              </span>
            </div>
          </div>
          <div class="formGroup">
            <mat-form-field class="formFile">
              <div class="myfilebrowser">
                <mat-toolbar>
                  <input
                    matInput
                    [(ngModel)]="myfilename"
                    readonly
                    formControlName="memberContactNo"
                  />
                  <button mat-raised-button class="fileButton" color="primary">
                    Agregar
                  </button>
                </mat-toolbar>
                <input
                  type="file"
                  #fileUpload
                  id="fileUpload"
                  (change)="fileChangeEvent($event)"
                  name="fileUpload"
                  multiple
                  accept="*"
                />
              </div>
            </mat-form-field>
          </div>
          <div class="imagenesArray">
            <mat-card
              class="cartaImagenes mat-elevation-z3"
              *ngFor="let imagen of listFile; let i = index"
            >
              <div class="imagenesAgregadas" *ngIf="!imagen.name">
                {{ imagen.descripcion ? imagen.descripcion : imagen.nombre }}
                <div class="divIconsMul">
                  <div class="divIcon ver" (click)="mostrarImagen(imagen)">
                    <mat-icon class="icon">visibility</mat-icon>
                  </div>
                  <div
                    class="divIcon download"
                    *ngIf="imagen.nombre.slice(-4) == 'xlsx'"
                    (click)="descargarExcel(imagen)"
                  >
                    <mat-icon class="icon">download</mat-icon>
                  </div>
                  <div
                    class="divIcon download"
                    *ngIf="imagen.nombre.slice(-3) == 'pdf'"
                    (click)="descargarPdf(imagen)"
                  >
                    <mat-icon class="icon">download</mat-icon>
                  </div>
                  <div
                    class="divIcon download"
                    *ngIf="
                      imagen.nombre.slice(-4) != 'xlsx' &&
                      imagen.nombre.slice(-3) != 'pdf'
                    "
                    (click)="descargarImage(imagen)"
                  >
                    <mat-icon class="icon">download</mat-icon>
                  </div>
                  <div class="divIcon borrar" (click)="borrarImagen(i)">
                    <mat-icon class="icon">delete_forever</mat-icon>
                  </div>
                  <!--<mat-icon class="borrarImagen" (click)="borrarArchivo(imagen.id)">delete_forever</mat-icon>-->
                </div>
              </div>
              <div class="imagenesAgregadas" *ngIf="imagen.name">
                {{ imagen.name }}
                <div>
                  <input
                    (input)="descriptionChangeEvent($event, i)"
                    matInput
                    placeholder="Descripción"
                    name="descripcion"
                    id="descripcion"
                  />
                </div>
                <div class="divIcons">
                  <div class="divIcon ver" (click)="mostrarImagen(imagen)">
                    <mat-icon class="icon">visibility</mat-icon>
                  </div>
                  <div class="divIcon borrar" (click)="borrarImagen(i)">
                    <mat-icon class="icon">delete_forever</mat-icon>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
          <div class="formGroup">
            <mat-form-field appearance="fill" class="input">
              <mat-label>Fecha</mat-label>
              <input
                formControlName="fecha"
                matInput
                [matDatepicker]="picker"
              />
              <mat-datepicker-toggle matSuffix [for]="picker">
              </mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <div class="valid">
              <span
                style="color: rgb(236, 75, 72)"
                *ngIf="
                  estudioMedicoPacienteForm.get('fecha').errors?.required &&
                  estudioMedicoPacienteForm.get('fecha').touched
                "
              >
                La fecha es requerida
              </span>
            </div>
          </div>
          <div class="formGroup selecCenMed">
            <mat-form-field appearance="fill" class="example-full-width input">
              <mat-label>Centro médico</mat-label>
              <mat-select *ngIf="!primCentro"></mat-select>
              <mat-select
                *ngIf="primCentro"
                formControlName="centroMedico"
                [placeholder]="'Centro médico'"
              >
                <mat-option>
                  <ngx-mat-select-search
                    id="centroMedico"
                    [noEntriesFoundLabel]="'Not found'"
                    [placeholderLabel]="'Buscador'"
                    [formControl]="centroMedico"
                    name="filterPost"
                    (ngModelChange)="filtrarCentroMedico($event)"
                  >
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option
                  *ngFor="let element of filtroCentrosMedicos"
                  [value]="element.id"
                >
                  <div class="optionCM">
                    <div class="nombreSelect">{{ element.nombre }}</div>
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="valid">
              <span
                style="color: rgb(236, 75, 72)"
                *ngIf="
                  estudioMedicoPacienteForm.get('centroMedico').errors
                    ?.required &&
                  estudioMedicoPacienteForm.get('centroMedico').touched
                "
              >
                El centro médico es requerido
              </span>
            </div>
          </div>
          <div class="formGroup">
            <!-- <mat-form-field class="example-full-width input" appearance="fill">
              <mat-label>Observaciones</mat-label>
              <textarea
                formControlName="observaciones"
                class="textarea"
                type="text"
                matInput
              ></textarea>
            </mat-form-field> -->

            <editor
              [init]="init"
              formControlName="observaciones"
              apiKey="k37phrycqy3x1ulstdet98giirkkgiecg6npgtta255b7nm7"
            ></editor>
          </div>

          <!-- <div *ngIf="editar">
            <div class="subTitleComentarios">Comentarios</div>
            <div class="divComentarioEstudioMedico">
              <app-comentario-estudio-medico
                class="comentarioEstudioMedico"
                [responseComentarios]="comentarios"
              ></app-comentario-estudio-medico>
            </div>
          </div> -->

          <div class="formButton">
            <button
              *ngIf="editar"
              mat-raised-button
              class="buttonForm Cancelar"
              (click)="backClicked()"
            >
              Cancelar
            </button>
            <button
              *ngIf="editar"
              (click)="fileUpload.value = null"
              mat-raised-button
              class="buttonForm"
            >
              Editar
            </button>
            <button
              *ngIf="!editar"
              (click)="fileUpload.value = null"
              mat-raised-button
              class="buttonForm"
            >
              Crear
            </button>
          </div>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>
