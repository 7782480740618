import {
  ElementRef,
  Component,
  OnInit,
  ViewChild,
  Output,
  Input,
  HostListener,
} from "@angular/core";
import { GlobalVariable } from "app/global/global";
import { CentroMedicoService } from "app/services/centro-medico.service";
import { PacienteService } from "app/services/paciente.service";
import { MatDialog } from "@angular/material/dialog";
import { ContactoDialogComponent } from "app/shared/contacto-dialog/contacto-dialog.component";
import { Paciente } from "app/models/paciente.interface";
import { environment } from "environments/environment.prod";
import { MedicoService } from "app/services/medico.service";
import { Medico } from "app/models/medico.interface";

@Component({
  selector: "app-dashboard-medico",
  templateUrl: "./dashboard-medico.component.html",
  styleUrls: ["./dashboard-medico.component.scss"],
})
export class DashboardMedicoComponent implements OnInit {
  //
  VERSION: string = GlobalVariable.VERSION_FRONT;
  titulo_contacto: string = "Contacto";
  paciente: Paciente;
  centroMedico: any;
  turnoHabilitado: any;
  medico: any;
  imagen: string = "";
  turnos_medico: any;
  @ViewChild("shadow") shadow: ElementRef;
  @ViewChild("dashboardContent") dashboardContent: ElementRef;
  @Input() set menu(value: any) {
    this.mostrarShadow(value);
  }
  @Input() set response(value: any) {
    if (value) {
      this.mostrarLogin(value);
    }
  }

  //
  constructor(
    public dialog: MatDialog,
    private pacienteService: PacienteService,
    private medicoService: MedicoService,
    private centroService: CentroMedicoService
  ) {
    this.titulo_contacto = environment.BOTON_CONTACTO;
  }

  //
  ngOnInit(): void {
    this.turnoHabilitado = Number(localStorage.getItem("turno_habilitado"));
  }

  //
  mostrarLogin(data: any) {
    this.medico = data.medico;
    this.centroMedico = data.centroMedico;
    const imageUrl = `${GlobalVariable.BASE_API_URL}storage/images/centro-medico/${this.centroMedico.foto}`;

    this.imagen = this.checkImageExists(imageUrl)
      ? imageUrl
      : `assets/img/ico_hospital.svg`;
  }

  checkImageExists(url: string): boolean {
    const img = new Image();
    img.src = url;
    return img.complete && img.naturalHeight !== 0;
  }

  //
  contactoDialog() {
    let data = {
      usuario: this.medico,
      tipoUsuario: "medico",
    };
    const dialogRef = this.dialog.open(ContactoDialogComponent, {
      panelClass: "contacto-dialog",
      data: data,
    });
    dialogRef.afterClosed().subscribe((res) => {});
  }

  //
  mostrarShadow(value) {
    if (value != undefined || value != null) {
      this.shadow.nativeElement.style.display = "block";
      this.dashboardContent.nativeElement.style.left = "0px";
    }
  }

  //
  quitarShadow() {
    this.shadow.nativeElement.style.display = "none";
    this.dashboardContent.nativeElement.style.left = "-100%";
  }

  //
  @HostListener("window:resize", ["$event"]) onResize(event) {
    if (event.target.innerWidth > 900) {
      this.dashboardContent.nativeElement.style.left = "0px";
      this.shadow.nativeElement.style.display = "none";
    } else if (event.target.innerWidth <= 900) {
      this.dashboardContent.nativeElement.style.left = "-100%";
      this.shadow.nativeElement.style.display = "none";
    }
  }
}
