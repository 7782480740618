import { Component, OnInit } from "@angular/core";
import { NotificacionesPushService } from "app/services/notificaciones-push.service";
import { UsuarioPanelService } from "app/services/usuario-panel.service";
import { SpinnerService } from "app/services/spinner.service";
import { MedicoService } from "app/services/medico.service";
import { ActivatedRoute, Router } from "@angular/router";
import { of } from "rxjs";
import { switchMap, map, catchError } from "rxjs/operators";

@Component({
  selector: "app-editar-medico",
  templateUrl: "./editar-medico.component.html",
  styleUrls: ["./editar-medico.component.scss"],
})
export class EditarMedicoComponent implements OnInit {
  //
  usuarioPanel: any;
  medicoFormulario: any;
  response: any;
  editar: boolean = true;
  notificacionesPush: any[];
  tipoUsuario: any = "medico";

  //
  constructor(
    private actRoute: ActivatedRoute,
    private medicoService: MedicoService,
    private usuarioPanelService: UsuarioPanelService,
    private spinnerService: SpinnerService
  ) {
    this.spinnerService.mostrarSpinner();
    let id = this.actRoute.snapshot.paramMap.get("id");
    this.usuarioPanelService
      .profileUser()
      .pipe(
        map((data) => {
          this.usuarioPanel = data;
        }),
        catchError((error) => {
          this.spinnerService.ocultarSpinner();
          this.usuarioPanelService.logout();
          return of({ results: null });
        }),
        switchMap((data) =>
          this.medicoService.mostrar(id).pipe(
            map((data) => {
              this.response = data;
              this.notificacionesPush = data.notificacionesPush;
              this.spinnerService.ocultarSpinner();
            })
          )
        ),
        catchError((error) => {
          this.spinnerService.ocultarSpinner();
          return of({ results: null });
        })
      )
      .subscribe(
        (data) => console.log("data", data),
        (error) => console.error("Error al llamar a la API:", error)
      );
  }

  //
  ngOnInit(): void {}
}
