import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomePanelComponent } from "./home-panel/home-panel.component";


const routes: Routes = [
  {
    path: "seccion-panel",
      children: [
        {
          path: "home-panel",
          pathMatch: "full",
          component: HomePanelComponent
        },
      ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomePanelRoutingModule {}