import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalVariable } from 'app/global/global';

@Injectable({
  providedIn: 'root'
})
export class InformesService {

  url:string = GlobalVariable.BASE_API_URL+"api/informes/";

  constructor(private http:HttpClient) { }

  mostrar():Observable<any>{
    return this.http.get(this.url+"mostrar");
  }
}
