<app-tipo-documento-form
  [editar]="editar"
  (listar)="listaActualizada($event)"
></app-tipo-documento-form>
<div class="seccionTable">
  <mat-card class="cardTable mat-elevation-z4">
    <div class="topTitle">
      <mat-card class="titleSeccion">Tipos de documentos</mat-card>
    </div>

    <!-------buscador------->
    <mat-form-field class="example-full-width buscador" appearance="fill">
      <mat-label>Buscador</mat-label>
      <input
        placeholder=""
        #buscador
        (keyup)="filter($event)"
        [(ngModel)]="searchKey"
        type="text"
        matInput
        autocomplete="off"
      />
    </mat-form-field>

    <table class="" mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>
          <span class="span">ID</span>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>
      <ng-container matColumnDef="texto">
        <th mat-header-cell *matHeaderCellDef>Texto</th>
        <td mat-cell *matCellDef="let element">{{ element.texto }}</td>
      </ng-container>
      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef class="th">Acciones</th>
        <td mat-cell *matCellDef="let element" class="td">
          <div class="divIcons">
            <div
              [routerLink]="[
                '/seccion-panel/tipo-documento/editar/',
                element.id
              ]"
              class="editar divIcon"
            >
              <mat-icon class="icon">edit</mat-icon>
            </div>
            <div (click)="openDialog(element)" class="borrar divIcon">
              <mat-icon class="icon">delete_forever</mat-icon>
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </mat-card>
</div>
