import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalVariable } from 'app/global/global';

@Injectable({
  providedIn: 'root'
})
export class TelefonoPaisService {
  
  constructor(private http:HttpClient) { }

  url:string = GlobalVariable.BASE_API_URL+"api/telofono-pais/";

  listar():Observable<any>{
    return this.http.get(this.url+"listar");
  }

}
