<div class="divContent">
  <mat-accordion class="acordeonContent">
    <mat-expansion-panel
      hideToggle
      [expanded]="panelOpenState"
      class="mat-elevation-z4"
    >
      <mat-expansion-panel-header
        class="acordeonHeader"
        (click)="ocultarMostrar()"
      >
        <div class="topTitle">
          <mat-card class="titleSeccion">
            <span *ngIf="!editarValor">Crear usuario panel</span>
            <span *ngIf="editarValor">Editar usuario panel</span>
            <mat-icon *ngIf="!panelOpenState" class="iconArrow"
              >keyboard_arrow_down</mat-icon
            >
            <mat-icon *ngIf="panelOpenState" class="iconArrow"
              >keyboard_arrow_up</mat-icon
            >
          </mat-card>
        </div>
      </mat-expansion-panel-header>

      <form
        [formGroup]="usuarioPanelForm"
        (submit)="guardarUsuarioPanel(usuarioPanelForm.value)"
        class="formCard"
      >
        <div class="card">
          <div class="formGroup">
            <input hidden matInput formControlName="id" />
          </div>
          <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
              <mat-label>Nombre usuario</mat-label>
              <input
                formControlName="nombreUsuario"
                (ngModelChange)="clearNombreUsuario()"
                type="text"
                matInput
              />
            </mat-form-field>
            <div class="valid">
              <span
                style="color: rgb(236, 75, 72)"
                *ngIf="
                  usuarioPanelForm.get('nombreUsuario').errors?.required &&
                  usuarioPanelForm.get('nombreUsuario').touched
                "
              >
                El nombre usuario es requerido
              </span>
              <span
                style="color: rgb(236, 75, 72)"
                *ngIf="
                  errorNombreUsuario &&
                  usuarioPanelForm.get('nombreUsuario').touched
                "
              >
                El nombre usuario fue registrado por otro usuario
              </span>
            </div>
          </div>
          <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
              <mat-label>Nombre apellido</mat-label>
              <input formControlName="nombreCompleto" type="text" matInput />
            </mat-form-field>
            <div class="valid">
              <span
                style="color: rgb(236, 75, 72)"
                *ngIf="
                  usuarioPanelForm.get('nombreCompleto').errors?.required &&
                  usuarioPanelForm.get('nombreCompleto').touched
                "
              >
                El nombre completo es requerido
              </span>
            </div>
          </div>
          <div class="formGroup">
            <mat-form-field class="example-full-width input" appearance="fill">
              <mat-label>Email</mat-label>
              <input
                formControlName="email"
                (ngModelChange)="clearEmail()"
                type="email"
                matInput
              />
            </mat-form-field>
            <div class="valid">
              <span
                style="color: rgb(236, 75, 72)"
                *ngIf="
                  usuarioPanelForm.get('email').errors?.required &&
                  usuarioPanelForm.get('email').touched
                "
              >
                El email es requerido
              </span>
              <span
                style="color: rgb(236, 75, 72)"
                *ngIf="
                  usuarioPanelForm.get('email').errors?.email &&
                  usuarioPanelForm.get('email').touched
                "
              >
                El email no tiene el formato correcto
              </span>
              <span
                style="color: rgb(236, 75, 72)"
                *ngIf="errorEmail && usuarioPanelForm.get('email').touched"
              >
                El email fue registrado por otro usuario
              </span>
            </div>
          </div>
          <div class="formGroup telefonoForm">
            <div class="tefonoPais">
              <mat-form-field appearance="fill" class="telefonoInput1">
                <mat-label>Teléfono país</mat-label>
                <mat-select formControlName="telPais">
                  <mat-option>
                    <ngx-mat-select-search
                      id="telPais"
                      [noEntriesFoundLabel]="'Not found'"
                      [placeholderLabel]="'Buscador'"
                      [formControl]="telPais"
                      name="filterPost"
                      (ngModelChange)="filtrarTelPais($event)"
                    >
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    *ngFor="let tel of filtroTelefonoPais"
                    [value]="tel.codigo"
                  >
                    {{ tel.nombre }} ({{ tel.codigo }})
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="valid">
                <span
                  style="color: rgb(236, 75, 72)"
                  *ngIf="
                    usuarioPanelForm.get('telPais').errors?.required &&
                    usuarioPanelForm.get('telPais').touched
                  "
                >
                  El teléfono país es requerido
                </span>
              </div>
            </div>
            <div class="tefonoCaract">
              <mat-form-field
                class="example-full-width"
                class="input telefonoInput2"
                appearance="fill"
              >
                <mat-label>Teléfono caract.</mat-label>
                <input formControlName="telCaract" type="text" matInput />
              </mat-form-field>
              <div class="valid">
                <span
                  style="color: rgb(236, 75, 72)"
                  *ngIf="
                    usuarioPanelForm.get('telCaract').errors?.required &&
                    usuarioPanelForm.get('telCaract').touched
                  "
                >
                  La característica es requerida
                </span>
              </div>
            </div>
            <div class="tefonoNumero">
              <mat-form-field
                class="example-full-width"
                class="input telefonoInput3"
                appearance="fill"
              >
                <mat-label>Teléfono número</mat-label>
                <input formControlName="telNumero" type="text" matInput />
              </mat-form-field>
              <div class="valid">
                <span
                  style="color: rgb(236, 75, 72)"
                  *ngIf="
                    usuarioPanelForm.get('telNumero').errors?.required &&
                    usuarioPanelForm.get('telNumero').touched
                  "
                >
                  El número de telefono es requerido
                </span>
              </div>
            </div>
          </div>
          <div class="formGroup">
            <mat-form-field class="formFile">
              <div class="myfilebrowser">
                <mat-toolbar>
                  <input
                    matInput
                    [(ngModel)]="myfilename"
                    readonly
                    formControlName="memberContactNo"
                  />
                  <button mat-raised-button class="fileButton" color="primary">
                    Buscar
                  </button>
                </mat-toolbar>
                <input
                  type="button"
                  #UploadFileInput
                  id="fileUpload"
                  (click)="openDialogFoto()"
                  name="fileUpload"
                  accept="image/*"
                />
              </div>
            </mat-form-field>
            <div class="divText">
              <p class="textAclaration"
                >Imagen debe contar con el mismo alto y ancho, max
                1200*1200</p
              >
            </div>
          </div>
          <div #divImagen class="divImagen">
            <img #mostrarImagen class="imagen" [src]="imagen" />
          </div>
          <div class="valid">
            <span style="color: rgb(236, 75, 72)" *ngIf="errorImage">
              La imagen es requerida
            </span>
          </div>
          <div class="formGroup">
            <mat-form-field appearance="fill" class="input">
              <mat-label>Perfil</mat-label>
              <mat-select formControlName="perfil">
                <mat-option value="Administrador">Administrador</mat-option>
                <mat-option value="Asesor">Asesor</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="valid">
              <span
                style="color: rgb(236, 75, 72)"
                *ngIf="
                  usuarioPanelForm.get('perfil').errors?.required &&
                  usuarioPanelForm.get('perfil').touched
                "
              >
                El perfil es requerido
              </span>
            </div>
          </div>
          <div *ngIf="!editarValor">
            <div class="formGroup">
              <mat-form-field
                class="example-full-width input"
                appearance="fill"
              >
                <mat-label>Password</mat-label>
                <input formControlName="password" type="password" matInput />
              </mat-form-field>
              <div class="valid">
                <span
                  style="color: rgb(236, 75, 72)"
                  *ngIf="
                    usuarioPanelForm.get('password').errors?.required &&
                    usuarioPanelForm.get('password').touched
                  "
                >
                  El password es requerido
                </span>
              </div>
            </div>
            <div class="formGroup">
              <mat-form-field
                class="example-full-width input"
                appearance="fill"
              >
                <mat-label>Repetir password</mat-label>
                <input
                  formControlName="repeatPassword"
                  type="password"
                  matInput
                />
              </mat-form-field>
              <div class="valid">
                <span
                  style="color: rgb(236, 75, 72)"
                  *ngIf="
                    usuarioPanelForm.hasError('mismatch') &&
                    usuarioPanelForm.get('repeatPassword').touched
                  "
                >
                  Los passwords no son iguales
                </span>
              </div>
            </div>
          </div>
          <div class="formButton">
            <button *ngIf="!editarValor" mat-raised-button class="buttonForm">
              Crear
            </button>
            <button
              *ngIf="editarValor"
              mat-raised-button
              class="buttonForm Cancelar"
              routerLink="../../"
            >
              Cancelar
            </button>
            <button *ngIf="editarValor" mat-raised-button class="buttonForm">
              Editar
            </button>
          </div>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>
