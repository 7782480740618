import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ayuda-botones',
  templateUrl: './ayuda-botones.component.html',
  styleUrls: ['./ayuda-botones.component.scss']
})
export class AyudaBotonesComponent implements OnInit {

  constructor( private dialogRef: MatDialogRef<AyudaBotonesComponent>) { }
  ngOnInit(): void {
  }

  closeHelp() {
    this.dialogRef.close();
  }

}
