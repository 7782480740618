import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CentroMedicoComponent } from "./centro-medico/centro-medico.component";
import { FormCentroMedicoComponent } from "./form-centro-medico/form-centro-medico.component";
import { EditarCentroMedicoComponent } from "./editar-centro-medico/editar-centro-medico.component";

const routes: Routes = [
  {
    path: "seccion-panel",
      children: [
        {
          path: "centro-medico",
          pathMatch: "full",
          component: CentroMedicoComponent,
        },
        {
          path: "centro-medico/editar/:id",
          pathMatch: "full",
          component: EditarCentroMedicoComponent,
        },
      ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CentroMedicoRoutingModule {}