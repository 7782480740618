import { Injectable } from '@angular/core';
import { GlobalVariable } from 'app/global/global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import Echo from 'laravel-echo';
import * as Pusher from 'pusher-js';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesPushService {

  //
  private echo: Echo;
  url: string = GlobalVariable.BASE_API_URL + "api/notifiaciones-push/";

  //
  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }


  //
  listar(): Observable<any> {
    return this.http.get(this.url + "listar");
  }


  //
  listarSinLeer(): Observable<any> {
    return this.http.get(this.url + "listar-sin-leer");
  }


  //
  listarPorMedicoSinLeer(id: number): Observable<any> {
    return this.http.get(this.url + "listar-por-medico-sin-leer/" + id);
  }


  //
  listarPorMedico(id: number): Observable<any> {
    return this.http.get(this.url + "listar-por-medico/" + id);
  }


  //
  leerNotificacion(id: any): Observable<any> {
    const formData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    formData.append("id", id);
    return this.http.post(this.url + "leer", formData, { headers: headers });
  }

}
