<div class="content-password">
    <mat-card class="mail-card mat-elevation-z4">
        <div class="title">Cambiar Clave</div>
        <form [formGroup]="resetPasswordForm" (submit)="validarPassword(resetPasswordForm.value)" class="example-form">
            <div class="formGroup">
                <mat-form-field class="example-full-width input" appearance="fill">
                    <mat-label>Email</mat-label>
                    <input formControlName="email" type="email" [readonly]="true" matInput>
                </mat-form-field>
            </div>
            <div class="formGroup">
                <mat-form-field class="example-full-width input" appearance="fill">
                    <mat-label>Password</mat-label>
                    <input formControlName="password" type="password" matInput>
                </mat-form-field>
                <div class="validError">
                    <span style="color:rgb(236,75,72);" *ngIf="password.errors?.required && resetPasswordForm.get('password').touched">La contraseña es requerida</span>
                </div>
            </div>
            <div class="formGroup">
                <mat-form-field class="example-full-width input" appearance="fill">
                    <mat-label>Repetir Password</mat-label>
                    <input formControlName="repeatPassword" type="password" matInput>
                </mat-form-field>
                <div class="validError">
                    <span style="color:rgb(236,75,72);" *ngIf="resetPasswordForm.hasError('passwordError') && resetPasswordForm.get('repeatPassword').touched">Las contraseñas tienen que ser iguales</span>
                </div>
            </div>
            <div class="divButton">
                <button type="submit" mat-raised-button color="primary" class="enviar">Cambiar Contraseña</button>
            </div>
        </form>
    </mat-card>
</div>

