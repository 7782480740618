import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListarMedicoComponent } from './listar-medico/listar-medico.component';
import { EditarMedicoComponent } from './editar-medico/editar-medico.component';
import { SharedModule } from 'primeng/api';
import { MaterialModule } from 'app/material/material.module';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { SharedPanelModule } from '../shared-panel/shared-panel.module'; 
import { MedicoRoutingModule } from './medico-routing.module';
import { CrearFormMedicoComponent } from './crear-form-medico/crear-form-medico.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AgendaFormComponent } from './agenda-form/agenda-form.component';
import { ModalAdvertenciaAgendasComponent } from './modal-advertencia-agendas/modal-advertencia-agendas.component';


@NgModule({
  declarations: [
    ListarMedicoComponent,
    EditarMedicoComponent,
    CrearFormMedicoComponent,
    AgendaFormComponent,
    ModalAdvertenciaAgendasComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    MedicoRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedPanelModule,
    NgxMaterialTimepickerModule
  ]
})
export class MedicoModule { }
