import { UsuarioPanel } from 'app/models/usuarioPanel.interface';
import { UsuarioPanelService } from 'app/services/usuario-panel.service';
import { ElementRef , Component , OnInit , ViewChild, Input  } from '@angular/core';
import { GlobalVariable } from 'app/global/global';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  

  //
  VERSION:string = GlobalVariable.VERSION_FRONT;
  turnoHabilitado:any;
  usuarioPanelValue:any;
  isAdmin = false;
  showFiller = false;
  @Input() set usuarioPanel(value: any) {
    if(value) {
      this.usuarioPanelValue = value;
      if(this.usuarioPanelValue.perfil.toUpperCase() === "ADMINISTRADOR"){
        this.isAdmin = true;
      }
    }
  }


  //
  constructor(    
    private api:UsuarioPanelService
  ) {}


  //
  ngOnInit(): void {  
    this.turnoHabilitado = Number(localStorage.getItem('turno_habilitado'));
  }


}
