<div class="modal-header">
    <h2>Ayuda</h2>
    <button mat-button (click)="closeHelp()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="modal-body">
    <h3>Funcionalidades</h3>
    <ul>
      <li>
        <strong>Zoom:</strong> Permite acercar o alejar la vista actual. Haz clic
        en este botón y luego utiliza el scroll del mouse para ajustar el zoom.
        También puedes usar los botones +/-
      </li>
      <li>
        <strong>Mover:</strong> Permite mover la vista actual. Haz clic en este
        botón y luego arrastra la vista con el mouse.
      </li>
      <li>
        <strong>Medir:</strong> Permite medir distancias en la vista actual. Haz
        clic en este botón y luego selecciona dos puntos para medir la distancia
        entre ellos.
      </li>
      <li>
        <strong>Lupa:</strong> Permite hacer zoom en una área específica de la
        vista. Haz clic en este botón y luego cliquea sobre el área que deseas
        ampliar.
      </li>
      <li>
        <strong>Recargar:</strong> Restablece la vista a su estado inicial,
        eliminando cualquier zoom o desplazamiento aplicado.
      </li>
      <li>
        <strong>Ayuda:</strong> Abre este modal de ayuda para obtener más
        información sobre las funcionalidades disponibles.
      </li>
    </ul>
  </div>
  