import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { LoginPanelComponent } from "./login-panel/login-panel.component";
import { LoginPacienteComponent } from "./login-paciente/login-paciente.component";
import { EnviarMailComponent } from "./enviar-mail/enviar-mail.component";
import { CambiarContraseniaComponent } from "./cambiar-contrasenia/cambiar-contrasenia.component";
import { CambiarContraseniaPacienteComponent } from "./cambiar-contrasenia-paciente/cambiar-contrasenia-paciente.component";
import { EnviarEmailPacienteComponent } from "./enviar-email-paciente/enviar-email-paciente.component";
import { LoginMedicoComponent } from './login-medico/login-medico.component';
import { EnviarMailMedicoComponent } from './enviar-mail-medico/enviar-mail-medico.component';
import { CambiarContraseniaMedicoComponent } from './cambiar-contrasenia-medico/cambiar-contrasenia-medico.component';

const routes: Routes = [
  {
    path: "auth",
      children: [
        {
          path: "",
          pathMatch: "full",
          component: LoginComponent,
        },
        {
          path: "login",
          pathMatch: "full",
          component: LoginComponent,
        },
        {
          path: "login-panel",
          pathMatch: "full",
          component: LoginPanelComponent,
        },
        {
          path: "login-paciente",
          pathMatch: "full",
          component: LoginPacienteComponent,
        },
        {
          path: "login-medico",
          pathMatch: "full",
          component: LoginMedicoComponent,
        },
        {
          path: "recuperar-contraseña-panel",
          pathMatch: "full",
          component: EnviarMailComponent
        },
        {
          path: "cambiar-contraseña-panel/:token",
          pathMatch: "full",
          component: CambiarContraseniaComponent
        
        },
        {
          path: "recuperar-contraseña-paciente",
          pathMatch: "full",
          component: EnviarEmailPacienteComponent
        },
        {
          path: "cambiar-contraseña-paciente/:token",
          pathMatch: "full",
          component: CambiarContraseniaPacienteComponent
        },
        {
          path: "recuperar-contraseña-medico",
          pathMatch: "full",
          component: EnviarMailMedicoComponent
        },
        {
          path: "cambiar-contraseña-medico/:token",
          pathMatch: "full",
          component: CambiarContraseniaMedicoComponent
        },
      ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
