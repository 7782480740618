import { ElementRef,AfterViewInit,Component, OnInit,ViewChild,NgModule  } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {

  /*displayedColumns: string[] = ['nombre', 'direccion', 'telefono', 'mail', 'acciones'];
  dataSource = new MatTableDataSource<PeriodicElement>(data);

  @ViewChild('dropdown2') element: ElementRef;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }*/

  ngOnInit(): void {
  }
/*
  title = 'angular-material-file-upload-app';

  imagen = '';

  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  myfilename = 'Seleccionar Archivo';

  @ViewChild('divImagen') divImagen: ElementRef;


  fileChangeEvent(fileInput: any) {

    if (fileInput.target.files && fileInput.target.files[0]) {


      this.myfilename = '';
      Array.from(fileInput.target.files).forEach((file: File) => {
        
        this.myfilename += file.name + ',';
      });

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (event:any) => {

          this.divImagen.nativeElement.style.display = "block";
          this.imagen = e.target.result;
          const imgBase64Path = e.target.result;
          

        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);

      // Reset File Input to Selct Same file again
      this.uploadFileInput.nativeElement.value = "";
    } else {
      this.myfilename = 'Seleccionar Archivo';
    }
  }*/
}
/*
export interface PeriodicElement {
  nombre: string;
  direccion: string;
  telefono: string;
  mail: string;
}

const data: PeriodicElement[] = [
  {nombre: 'sucursal1', direccion: 'direccion1', telefono: '100079', mail: 'email1@emial.com'},
  {nombre: 'sucursal2', direccion: 'direccion2', telefono: '400026', mail: 'email2@emial.com'},
  {nombre: 'sucursal3', direccion: 'direccion3', telefono: '609412', mail: 'email3@emial.com'},
  {nombre: 'sucursal4', direccion: 'direccion4', telefono: '900122', mail: 'email4@emial.com'},
  {nombre: 'sucursal5', direccion: 'direccion5', telefono: '108112', mail: 'email5@emial.com'},
  {nombre: 'sucursal6', direccion: 'direccion6', telefono: '120107', mail: 'email6@emial.com'},
  {nombre: 'sucursal7', direccion: 'direccion7', telefono: '140067', mail: 'email7@emial.com'},
  {nombre: 'sucursal8', direccion: 'direccion8', telefono: '159994', mail: 'email8@emial.com'},
  {nombre: 'sucursal9', direccion: 'direccion9', telefono: '189984', mail: 'email9@emial.com'},
  {nombre: 'sucursal10', direccion: 'direccion10', telefono: '201797', mail: 'email10@emial.com'},
];*/
