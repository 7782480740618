<!-- 

    <p-table [columns]="cols" [value]="examenes" dataKey="code">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th pSortableColumn="code" *ngFor="let col of columns">
                    <p-sortIcon field="code"></p-sortIcon>
                    {{ col.header }}
                </th>
            </tr>
        </ng-template>
        <ng-template
        pTemplate="body"
        let-rowData
        let-examen
        let-columns="columns"
        let-expanded="expanded"
        >
        <tr>
            <ng-container *ngFor="let col of columns">
                <td [pRowToggler]="col.field != 'medic' ? examen : false">
                    <div
                    class="row-doctor"
                    *ngIf="col.field == 'medic'"
                    (click)="op.toggle($event)"
                    >
                    <img src="assets/img//avatar.png" />
                    <span class="image-text">John Doe</span>
                </div>
            </td>
        </ng-container>
    </tr>
</ng-template>
<ng-template pTemplate="rowexpansion" let-examen>
    <div class="grid">
        <div class="col-6">
            <h2>Documentos</h2>
            <div *ngFor="let document of examen.documents">
                <i
                [ngClass]="[
                document.type == 'pdf' ? 'fa fa-file-pdf-o' : '',
                document.type == 'image' ? 'fa fa-picture-o' : ''
                ]"
                aria-hidden="true"
                >
            </i>
            
            {{ document.title }}
        </div>
        <a href="#">Ver más...</a>
    </div>
    <div class="col-6">
        <h2>Observaciones</h2>
        <div *ngFor="let document of examen.documents">
            <i
            [ngClass]="[
            document.type == 'pdf' ? 'fa fa-file-pdf-o' : '',
            document.type == 'image' ? 'fa fa-picture-o' : ''
            ]"
            aria-hidden="true"
            >
        </i>
        
        {{ document.title }}
    </div>
    <a href="#">Ver más...</a>
</div>
</div>
</ng-template>
</p-table>

<p-overlayPanel #op [showCloseIcon]="true">
    <ng-template pTemplate>
        <img src="assets/img/avatar.png" />
        Doctor John Doe
        <p>Dato</p>
        <button pButton label="Ver perfil" icon="pi pi-power-off"></button>
    </ng-template>
</p-overlayPanel>

-->
<!-- 

    <app-exams-table [cols]="cols"></app-exams-table>
-->

<app-examns-dashboard [cols]="cols"></app-examns-dashboard>
