<div class="panel">
    <div class="dashboard">
        <app-dashboard [usuarioPanel]="usuarioPanel"></app-dashboard>
    </div>
    <div class="panelContent">
        <app-header-panel [usuarioPanelBinding]="usuarioPanel"
            [notificacionesPushBinding]="notificacionesPush"></app-header-panel>
        <app-indicadores-panel [setResponse]="response"></app-indicadores-panel>
        <app-plan-panel [setPlan]="plan"></app-plan-panel>
    </div>
</div>