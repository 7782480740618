import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { InformesService } from "app/services/informes.service";
import { SpinnerService } from "app/services/spinner.service";
import { UsuarioPanelService } from "app/services/usuario-panel.service";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { of } from "rxjs";
import { switchMap, map, catchError, startWith } from "rxjs/operators";
import { InformeCreacionesPorAnioComponent } from "../informe-creaciones-por-anio/informe-creaciones-por-anio.component";
import { InformeGraficoTortaComponent } from "../informe-grafico-torta/informe-grafico-torta.component";
import { InformeTopMedicosComponent } from "../informe-top-medicos/informe-top-medicos.component";

@Component({
  selector: "app-informes",
  templateUrl: "./informes.component.html",
  styleUrls: ["./informes.component.scss"],
})
export class InformesComponent implements OnInit {
  informes: any;
  estudiosMedicosPorcentaje: any;
  turnosPorcentaje: any;
  pacientesPorAnio: any;
  estudiosMedicosPacientesPorAnio: any;
  turnosPorAnio: any;
  topMedicos: any;
  cantidad: any;
  usuarioPanel: any;
  tituloPacientesPorAnio = "Pacientes";
  descPacientesPorAnio =
    "Informe cantidad de pacientes creados en el último año por mes.";
  tituloEstudiosMedicosPacientesPorAnio = "Estudios médico de pacientes";
  descEstudiosMedicosPacientesPorAnio =
    "Informe cantidad de estudios médico de pacientes creados en el último año por mes.";
  tituloTurnosPorAnio = "Turnos";
  descPacientePorAnio =
    "Informe cantidad de turnos creados en el último año por mes.";
  tituloEstudiosMedicosTorta = "Estudios médicos";
  descEstudiosMedicosTorta =
    "Informe porcentaje de estudios médicos por cada tipo.";
  tituloTurnosEspecialidadTorta = "Turnos";
  descTurnosEspecialidadTorta =
    "Informe porcentaje de turnos relacionado a especialidades de médicos.";
  tituloTopMedicos = "Médicos";
  descTopMedicos = "Informe de top 5 médicos con más pacientes.";
  tituloPacientePorObraSocial = "Pacientes por obra social";
  descPacientePorObraSocial = "Informe porcentaje de paciente por obra social";
  pacientesPorObraSocial: any;
  sucursales: any;
  loading = false;
  currentYear = new Date().getFullYear();
  lastTenYears = Array.from({ length: 10 }, (_, i) => this.currentYear - i);
  downloadingPdf = false;

  filters = new FormGroup({
    sucursal: new FormControl(""),
    selectedYear: new FormControl(this.currentYear),
  });

  @ViewChildren(InformeGraficoTortaComponent)
  graficosTorta!: QueryList<InformeGraficoTortaComponent>;

  @ViewChildren(InformeCreacionesPorAnioComponent)
  graficosAnio!: QueryList<InformeCreacionesPorAnioComponent>;

  @ViewChildren(InformeTopMedicosComponent)
  graficoTopMedicos!: QueryList<InformeTopMedicosComponent>;

  constructor(
    private usuarioPanelService: UsuarioPanelService,
    private informesService: InformesService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.loadUserProfile();
    this.setupFilterListener();
  }

  private loadUserProfile(): void {
    this.spinnerService.mostrarSpinner();
    this.loading = true;

    this.usuarioPanelService
      .profileUser()
      .pipe(
        map((data) => {
          this.usuarioPanel = data;
        }),
        catchError(() => {
          this.handleError();
          return of(null);
        }),
        switchMap(() => this.loadInformes())
      )
      .subscribe(
        () => {
          this.spinnerService.ocultarSpinner();
          this.loading = false;
        },
        (error) => {
          this.handleError();
        }
      );
  }

  private loadInformes() {
    return this.informesService.mostrar().pipe(
      map((data) => {
        this.informes = data;
        this.topMedicos = this.informes.topMedicos;
        this.pacientesPorAnio = this.informes.pacientesPorAnio;
        this.estudiosMedicosPacientesPorAnio =
          this.informes.estudiosMedicosPacientesPorAnio;
        this.turnosPorAnio = this.informes.turnosPorAnio;
        this.estudiosMedicosPorcentaje =
          this.informes.estudiosMedicosPorcentaje;
        this.turnosPorcentaje = this.informes.turnosPorcentaje;
        this.pacientesPorObraSocial = this.informes.pacientesPorObraSocial;
        this.sucursales = this.informes.sucursales;
      }),
      catchError((error) => {
        this.handleError();
        return of(null);
      })
    );
  }

  private setupFilterListener(): void {
    this.filters.valueChanges
      .pipe(
        startWith(this.filters.value), // Emitir el valor inicial
        switchMap((filters) => {
          this.spinnerService.mostrarSpinner();
          this.loading = true;
          return this.informesService
            .mostrar(filters.sucursal, filters.selectedYear)
            .pipe(
              map((data) => {
                this.informes = data;
                this.updateInformesData();
                this.spinnerService.ocultarSpinner();
                this.loading = false;
              }),
              catchError((error) => {
                this.handleError();
                return of(null);
              })
            );
        })
      )
      .subscribe();
  }

  private updateInformesData(): void {
    this.topMedicos = this.informes.topMedicos;
    this.pacientesPorAnio = this.informes.pacientesPorAnio;
    this.estudiosMedicosPacientesPorAnio =
      this.informes.estudiosMedicosPacientesPorAnio;
    this.turnosPorAnio = this.informes.turnosPorAnio;
    this.estudiosMedicosPorcentaje = this.informes.estudiosMedicosPorcentaje;
    this.turnosPorcentaje = this.informes.turnosPorcentaje;
    this.pacientesPorObraSocial = this.informes.pacientesPorObraSocial;
    this.sucursales = this.informes.sucursales;
  }

  private handleError(): void {
    this.spinnerService.ocultarSpinner();
    this.loading = false;
    this.usuarioPanelService.logout();
  }

  async exportarTodosLosPDF() {
    this.downloadingPdf = true;

    const pdf = new jsPDF("p", "mm", "a4");
    let posicionY = 10;
    const espacioEntreGraficos = 10; // Espacio reducido entre gráficos
    const margenPagina = 15; // Margen de seguridad
    const alturaMaximaPagina = pdf.internal.pageSize.getHeight() - margenPagina;
    let graficosEnPaginaActual = 0; // Contador de gráficos en la página actual

    try {
      // Agregar los filtros como datos en el PDF
      const año = this.filters.get("selectedYear").value;
      const sucursalId = this.filters.get("sucursal").value;
      const sucursalNombre = sucursalId
        ? this.sucursales.find((s) => s.id === sucursalId)?.nombre || "Todas"
        : "Todas";

      pdf.setFontSize(12);
      pdf.text(`Año: ${año}`, 10, posicionY);
      posicionY += 8;
      pdf.text(`Sucursal: ${sucursalNombre}`, 10, posicionY);
      posicionY += 12; // Espacio después de los filtros

      // Procesar los gráficos por tipo para poder aplicar tamaños diferentes
      const procesarGraficos = async (
        graficos,
        esTorta = false,
        esTabla = false
      ) => {
        for (let i = 0; i < graficos.length; i++) {
          const grafico = graficos[i];
          const elementoGrafico =
            grafico.elementRef.nativeElement.querySelector(
              ".grafico-container"
            );

          if (!elementoGrafico) continue;

          // Obtener título y descripción
          const titulo = grafico.titulo || "Gráfico";
          const descripcion = grafico.descripcion || "";

          // Si ya hay dos gráficos en la página actual, crear nueva página
          if (graficosEnPaginaActual >= 2) {
            pdf.addPage();
            posicionY = margenPagina;
            graficosEnPaginaActual = 0;
          }

          // Agregar título (centrado)
          pdf.setFontSize(12); // Título más grande
          const tituloWidth =
            (pdf.getStringUnitWidth(titulo) * 12) / pdf.internal.scaleFactor;
          const tituloX = (pdf.internal.pageSize.getWidth() - tituloWidth) / 2;
          pdf.text(titulo, tituloX, posicionY);
          posicionY += 4; // Espacio reducido entre título y descripción

          // Agregar descripción (centrada) con tamaño aumentado
          pdf.setFontSize(9.5); // Tamaño de descripción aumentado significativamente
          const descripcionLines = pdf.splitTextToSize(
            descripcion,
            pdf.internal.pageSize.getWidth() - 20
          );

          descripcionLines.forEach((line) => {
            const lineWidth =
              (pdf.getStringUnitWidth(line) * 9.5) / pdf.internal.scaleFactor;
            const lineX = (pdf.internal.pageSize.getWidth() - lineWidth) / 2;
            pdf.text(line, lineX, posicionY);
            posicionY += 5; // Mayor espacio entre líneas para mejor legibilidad
          });
          posicionY += 3;

          // Generar la imagen del gráfico
          const canvas = await html2canvas(elementoGrafico);
          const imgData = canvas.toDataURL("image/png");

          // Calcular dimensiones según el tipo de gráfico
          const anchoDisponible = pdf.internal.pageSize.getWidth() - 20;

          // Factor de tamaño según tipo de gráfico y posición
          const esUltimoYSolo =
            i === graficos.length - 1 && graficosEnPaginaActual === 0;
          let factorTamaño;

          if (esTabla) {
            // La tabla del top médicos más grande
            factorTamaño = esUltimoYSolo ? 0.98 : 0.95;
          } else if (esTorta) {
            // Gráficos de torta
            factorTamaño = esUltimoYSolo ? 0.95 : 0.9;
          } else {
            // Otros gráficos
            factorTamaño = esUltimoYSolo ? 0.9 : 0.85;
          }

          const imgWidth = anchoDisponible * factorTamaño;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          // Verificar si hay espacio suficiente en la página actual
          if (posicionY + imgHeight > alturaMaximaPagina) {
            pdf.addPage();
            posicionY = margenPagina;
            graficosEnPaginaActual = 0;
          }

          // Agregar imagen al PDF
          pdf.addImage(
            imgData,
            "PNG",
            (pdf.internal.pageSize.getWidth() - imgWidth) / 2,
            posicionY,
            imgWidth,
            imgHeight
          );

          // Calcular espacio para el siguiente gráfico
          const espacioAdicional =
            graficosEnPaginaActual === 0 ? 8 : espacioEntreGraficos;
          posicionY += imgHeight + espacioAdicional;
          graficosEnPaginaActual++;
        }
      };

      // Procesar cada tipo de gráfico
      await procesarGraficos(this.graficosAnio.toArray(), false);
      await procesarGraficos(this.graficosTorta.toArray(), true); // Con flag para gráficos de torta
      await procesarGraficos(this.graficoTopMedicos.toArray(), false, true); // Con flag para tabla

      pdf.save("informe-completo.pdf");
    } catch (error) {
      console.error("Error al generar PDF:", error);
    } finally {
      this.downloadingPdf = false;
    }
  }
}
