import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi,EventInput } from '@fullcalendar/angular';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { TurnoAddDialogComponent } from 'app/shared/turno-add-dialog/turno-add-dialog.component';
import { TurnoDeleteDialogComponent } from 'app/shared/turno-delete-dialog/turno-delete-dialog.component';
import { TurnosService } from 'app/services/turnos.service'; 
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import esLocale from '@fullcalendar/core/locales/es';
import * as moment from 'moment';
import { GlobalVariable } from 'app/global/global';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { Observable } from 'rxjs/Observable';
import { SpinnerService } from 'app/services/spinner.service';
import { CentroMedico } from '../../../models/centroMedico.interface';


@Component({
  selector: 'app-calendario-medico',
  templateUrl: './calendario-medico.component.html',
  styleUrls: ['./calendario-medico.component.scss']
})
export class CalendarioMedicoComponent implements OnInit {


  //
  pacienteFiltro:any;
  especialidades:any;
  visible: any = false;
  disabled: any = false;
  spinner:boolean = false;
  fechaError:any; 
  currentEvents: EventApi[] = [];
  INITIAL_EVENTS:EventInput[] = [];
  calendarVisible = true;
  listTurnos:any;
  listPacientes:any;
  listMedicos:any;
  listCentrosMedicos:any;
  Events: any[] = [];
  dataSend:any;
  turnoSelect:any;
  medico:any;
  tipoMedico:any;
  fechaEnd:any;
  fechaStart:any;
  centroMedico:any;
  general:any;
  plan:any;
  calendarOptions:CalendarOptions = {
    //plugins:[dayGridPlugin,timeGridPlugin,InteractionPlugin],
    headerToolbar: {
      left: 'prev,next,today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    initialView: 'dayGridMonth',
    initialEvents: this.INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    eventDrop: this.eventDrop.bind(this),
    datesSet: this.handleMonthChange.bind(this),
    eventResize: this.eventDrop.bind(this),
    eventContent: this.eventContent.bind(this), 
    locale: esLocale,
  };
  @Output() listarEvent = new EventEmitter<string>();
  @Input() set pacienteInput(value:any){
    this.pacienteFiltro = value;
  }
  @Input() set response(value:any){
    if(value){
      this.mostrarDatos(value);
    }
  }
  @Input() set usuario(value:any){
    if(value){
      this.medico = value;
    }
  }
  @Input() set filtroTurnos(value:any){
    if(value){
      this.mostrarTurnos(value);
    }
  }

  @Input() centroMedicoSeleccionado : CentroMedico


  //
  constructor(
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private turnosService:TurnosService,
    private snackBar:MatSnackBar,
    private spinnerService:SpinnerService,
  ) { }


  //
  ngOnInit(): void { 
    //estilo general
    document.querySelector('style').textContent += ".fc .fc-toolbar .fc-button-primary:not(:disabled).fc-button-active:focus{ "+
        "outline: 0 none; outline-offset: 0; box-shadow: 0 0 0 1px #7676ED; "+
      "}"+
      ".fc .fc-toolbar .fc-button:enabled:active:focus {"+
        "outline: 0 none; outline-offset: 0; box-shadow: 0 0 0 1px #7676ED; background: #7676ED"+
      "}"+
      "primary:not(:disabled):active:focus { "+
        "box-shadow: 0 0 0 0.2rem rgba(76,91,106,.5)"+
      "}"+
      ".fc .fc-toolbar .fc-button:focus {"+
        "outline: 0 none; outline-offset: 0; box-shadow: 0 0 0 1px #7676ED; background: #7676ED"+
      "}";

    //estilo boton previo
    document.querySelector('style').textContent += ""+      
      ".fc .fc-toolbar .fc-button.fc-prev-button.fc-button.fc-button-primary {"+
        "background: #3434EC; color: #ffffff; border: 1px solid #7676ED; "+
      "}"+
      ".fc .fc-toolbar .fc-button.fc-prev-button.fc-button:focus {"+
        "outline: 0 none; outline-offset: 0; box-shadow: 0 0 0 1px #7676ED; background: #7676ED"+
      "}"+
      ".fc .fc-toolbar .fc-button.fc-prev-button.fc-button:enabled:active:focus {"+
        "outline: 0 none; outline-offset: 0; box-shadow: 0 0 0 1px #7676ED; "+
      "}";

    //estilo boton proximo
    document.querySelector('style').textContent += ""+      
      ".fc .fc-toolbar .fc-button.fc-next-button.fc-button.fc-button-primary {"+
        "background: #3434EC; color: #ffffff; border: 1px solid #7676ED; "+
      "}"+
      ".fc .fc-toolbar .fc-button.fc-next-button.fc-button:focus {"+
       "outline: 0 none; outline-offset: 0; box-shadow: 0 0 0 1px #7676ED; background: #7676ED"+
      "}"+
      ".fc .fc-toolbar .fc-button.fc-next-button.fc-button:enabled:active:focus {"+
        "outline: 0 none; outline-offset: 0; box-shadow: 0 0 0 1px #7676ED; "+
      "}";

    //estilo boton hoy
    document.querySelector('style').textContent += ""+      
      ".fc .fc-toolbar .fc-button.fc-today-button.fc-button.fc-button-primary {"+
        "background: #3434EC; color: #ffffff; border: 1px solid #7676ED; "+
      "}"+
      ".fc .fc-toolbar .fc-button.fc-today-button.fc-button:focus {"+
       "outline: 0 none; outline-offset: 0; box-shadow: 0 0 0 1px #7676ED; "+
      "}"+
      ".fc .fc-toolbar .fc-button.fc-today-button.fc-button:enabled:active:focus {"+
        "outline: 0 none; outline-offset: 0; box-shadow: 0 0 0 1px #7676ED; "+
      "}";

    //estilo boton mes
    document.querySelector('style').textContent += ""+
      ".fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button.fc-button-primary {"+
        "background: #3434EC; color: #ffffff; border: 1px solid #7676ED; "+
      "}"+
      ".fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button.fc-button-primary:hover {"+
        "background: #7676ED; color: #ffffff; border: 1px solid #7676ED;"+ 
      "}"+
      ".fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button.fc-button-primary:enabled:active:focus {"+
        "background: #7676ED; color: #ffffff; border: 1px solid #7676ED;"+ 
      "}"+
      ".fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active {"+ 
        "background: #7676ED; color: #ffffff; border: 1px solid #7676ED;"+
      "}"+
      ".fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active:hover {"+
        "background: #7676ED; color: #ffffff; border: 1px solid #7676ED;"+
      "}"+
      ".fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active:enabled:active:focus {"+
        "background: #7676ED; color: #ffffff; border: 1px solid #7676ED;"+
      "}";

    //estilo boton semana
    document.querySelector('style').textContent += ""+
      ".fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button.fc-button-primary {"+
        "background: #3434EC; color: #ffffff; border: 1px solid #7676ED; "+
      "}"+
      ".fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button.fc-button-primary:hover {"+
        "background: #7676ED; color: #ffffff; border: 1px solid #7676ED;"+ 
      "}"+
      ".fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button.fc-button-primary.fc-button-active {"+ 
        "background: #7676ED; color: #ffffff; border: 1px solid #7676ED;"+
      "}"+
      ".fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button.fc-button-primary.fc-button-active:hover {"+
        "background: #7676ED; color: #ffffff; border: 1px solid #7676ED;"+
      "}";

    //estilo boton dia
    document.querySelector('style').textContent += ""+
      ".fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button.fc-button-primary {"+
        "background: #3434EC; color: #ffffff; border: 1px solid #7676ED; "+
      "}"+
      ".fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button.fc-button-primary:hover {"+
        "background: #7676ED; color: #ffffff; border: 1px solid #7676ED;"+ 
      "}"+
      ".fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button.fc-button-primary.fc-button-active {"+ 
        "background: #7676ED; color: #ffffff; border: 1px solid #7676ED;"+
      "}"+
      ".fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button.fc-button-primary.fc-button-active:hover {"+
        "background: #7676ED; color: #ffffff; border: 1px solid #7676ED;"+
      "}";
      
    //estilo boton agenda
    document.querySelector('style').textContent += ""+
      ".fc .fc-toolbar .fc-button.fc-listWeek-button.fc-button.fc-button-primary {"+
        "background: #3434EC; color: #ffffff; border: 1px solid #7676ED; "+
      "}"+
      ".fc .fc-toolbar .fc-button.fc-listWeek-button.fc-button.fc-button-primary:hover {"+
        "background: #7676ED; color: #ffffff; border: 1px solid #7676ED;"+ 
      "}"+
      ".fc .fc-toolbar .fc-button.fc-listWeek-button.fc-button.fc-button-primary.fc-button-active {"+ 
        "background: #7676ED; color: #ffffff; border: 1px solid #7676ED;"+
      "}"+
      ".fc .fc-toolbar .fc-button.fc-listWeek-button.fc-button.fc-button-primary.fc-button-active:hover {"+
        "background: #7676ED; color: #ffffff; border: 1px solid #7676ED;"+
      "}";

    //estilo responsive general
    document.querySelector('style').textContent += "@media screen and (max-width:900px) {"+ 
      ".fc-toolbar.fc-header-toolbar {"+
        "flex-direction:column;"+
      "}"+
      ".fc-toolbar-chunk {"+
        "display: table-row; text-align:center; padding:5px 0;"+
      "}"+
      ".fc-toolbar-title { "+
        "font-size: 15px;"+
      "}";
  }
  

  //
  mostrarTurnos(data:any){
    this.listTurnos = data;
    this.createListTurno(data);
  }


  //
  mostrarDatos(data:any){

    this.especialidades = data.especialidades;
    this.general = data.general;
    this.centroMedico = data.centroMedico;
    this.listMedicos = data.medicos;
    this.listCentrosMedicos = data.centrosMedicos;
    this.listPacientes = data.pacientes;
    this.plan = data.plan;
    this.visible = (this.plan.imprimir) ? true : false;
  }


  //
  filtroMedicos(){
    document.querySelector('style').textContent += "@media screen and (max-width:900px) {"+ 
        ".fc-toolbar.fc-header-toolbar {flex-direction:column;} "+
        ".fc-toolbar-chunk { display: table-row; text-align:center; padding:5px 0; }"+
        ".fc-toolbar-title { font-size: 15px; }"+
        ".fc .fc-toolbar .fc-button { background: #000000 }"+
      "}";
  }


  //
  createListTurno(value:any){
    let arryEvent = [];
    if(value){
      value.forEach((elemento:any,index:any)=> {
        const elementoTurno = {
          id: elemento.id.toString(),
          title: elemento.paciente_nombre_apellido,
          start: elemento.fecha_desde,
          end: elemento.fecha_hasta,
          color: elemento.color_agenda,
          colorMedico: elemento.color_agenda,
          confirmado: elemento.confirmado, 
          fechaDesde: elemento.fecha_desde,
          fechaHasta: elemento.fecha_hasta,
        }
        arryEvent.push(elementoTurno);
      });

      this.INITIAL_EVENTS = arryEvent;
      this.calendarOptions.events = this.INITIAL_EVENTS;
    }
  }


  //
  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }


  //
  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }


  //
  handleDateSelect(selectInfo:DateSelectArg) {
    this.crearTurno(selectInfo);
  }


  //
  handleEventClick(clickInfo:EventClickArg) {
    this.editTurno(clickInfo.event._def.publicId);
  }


  //
  handleEvents(events:EventApi[]) {
    this.currentEvents = events;
  }


  //
  handleMonthChange(payload){
    this.fechaStart = payload.start;
    this.fechaEnd = payload.end;
  }


  //
  eventContent(events:any){
    let title = events.event._def?.title;
    let confirmado = events.event._def?.extendedProps.confirmado;
    let colorMedico = events.event._def?.extendedProps.colorMedico;
    let startIcon = (confirmado) ? '<i style="color:#FFD800;" class="fa-solid fa-star"></i>' : '';
    let circleIcon = `<i style="color: ${colorMedico}; "class="fa-solid fa-circle"></i>`;
    let fechaDesde =  moment(events.event._def?.extendedProps.fechaDesde).format('HH:mm');

    const iconHtml = `<div style="
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          ">
            ${startIcon}
            ${circleIcon}
            ${fechaDesde}
            <span  style="font-weight: bold;">${title}</span>
          </div>`;

    return { html: iconHtml };
  }
  

  //
  eventDrop(info) {
    let turno = this.listTurnos.find(turno => turno.id == info.event.id);
    turno = {
      ...turno,
      centroMedico : turno.id_centromedico
    }

    let fechaDesde = moment(info.event.start).format('YYYY-MM-DD HH:mm');
    let end = (info.event.end) ? info.event.end : info.event.start; 
    let fechaHasta = moment(end).format('YYYY-MM-DD HH:mm');
    this.validarFecha(turno,fechaDesde,fechaHasta);
  }


  //
  validarFecha(turno,fechaDesde,fechaHasta){
    this.spinnerService.mostrarSpinner();
    this.turnosService.validarTurnoEditar(turno,fechaDesde,fechaHasta).subscribe(
      data => {
        let response:any = data;
        this.fechaError = response.fechaError;
        if(this.fechaError == ""){
          this.editarFechaDrop(turno,fechaDesde,fechaHasta);
        }
        else{
          this.openSnackBar(this.fechaError,"error");
          this.createListTurno(this.listTurnos);
          this.spinnerService.ocultarSpinner();
        }
      },
      error => {
        this.openSnackBar(this.fechaError,"error");
        this.createListTurno(this.listTurnos);
        this.spinnerService.ocultarSpinner();
      }
    );  
  }


  //
  editarFechaDrop(turno,fechaDesde,fechaHasta){
    let usuario = 'medico';
    this.turnosService.editarFechaDrop(usuario,turno,fechaDesde,fechaHasta).subscribe(
      data => {
        let response:any = data;
        if(response.turnoError){
          this.openSnackBar(response.turnoError,"error");
          this.createListTurno(this.listTurnos);
          this.spinnerService.ocultarSpinner();
        }
        else{
          this.listarEvent.emit();
        }
      },
      error => {
        console.log(error);
        this.createListTurno(this.listTurnos);
        this.spinnerService.ocultarSpinner();
      }  
    );
  }


  //
  crearTurno(selectInfo){
    this.dataSend = {
      pacientes: this.listPacientes,
      medicos: this.listMedicos,
      centrosMedicos: this.listCentrosMedicos,
      selectInfo: selectInfo,
      tipoUsuario: 'medico',
      usuario: this.medico, 
      general: this.general,
      especialidades: this.especialidades,
      editar: false,
      centroMedicoSeleccionado: this.centroMedicoSeleccionado
    }

    const dialogRef = this.dialog.open(TurnoAddDialogComponent,{
      width: '60%',
      data: this.dataSend
    });
    dialogRef.afterClosed().subscribe(res=>{
      if(res){
        if(res.estado == 1){
          this.listarEvent.emit(res);
        }
      }
    });
  }


  //
  editTurno(id){
    let turno = this.listTurnos.find(elemento => elemento.id == id);
    this.dataSend = {
      pacientes: this.listPacientes,
      medicos: this.listMedicos,
      centrosMedicos: this.listCentrosMedicos,
      turno: turno,
      tipoUsuario: 'medico',
      usuario: this.medico, 
      general: this.general,
      especialidades: this.especialidades,
      editar: true
    }

    const dialogRef = this.dialog.open(TurnoAddDialogComponent,{
      width: '60%',
      data: this.dataSend
    });
    dialogRef.afterClosed().subscribe(res => {

      if(res){
        if(res.estado == 1) {
          this.listarEvent.emit(res);
        }
        else if(res.estado == 2) {
          this.borrarTurno(res.id);
        }
      }
    });
  }


  //
  borrarTurno(res){
    const dialogRef = this.dialog.open(TurnoDeleteDialogComponent,{
      width: '50%',
      data: res
    });
    dialogRef.afterClosed().subscribe(res=>{
      if(res){
        if(res.estado == 1){
          this.listarEvent.emit();
        }
        else if(res.estado == 2){
          this.editTurno(res.id);
        }
      }
    });
  }


  //
  imprimirPdf(){
    this.disabled = true;
    this.spinner = true;
    let promise = () => {
      return new Observable(observer => {
        setTimeout(() => {
          this.generarPdf(this.fechaStart, this.fechaEnd, this.listTurnos, this.centroMedico, observer);
        }, 1000);
      });
    }

    promise().subscribe(data=>{
      this.disabled = false;
      this.spinner = false;
    });    
  }


  //
  async generarPdf(fechaStart:any, fechaEnd:any, listTurnos:any, centroMedico:any, observer:any){
    let lineaY = 180;
    const doc = new jsPDF('p', 'pt', 'a4');
    let fechaStartAgenda = moment(fechaStart).format('YYYY-MM-DD HH:mm:ss');
    let fechaEndAgenda = moment(fechaEnd).format('YYYY-MM-DD HH:mm:ss');

    let listFilter = listTurnos.filter((element) => {
      if(element.fecha_desde >= fechaStartAgenda && element.fecha_desde < fechaEndAgenda){
        return element;
      } 
    });

    listFilter = listFilter.sort(function (a, b) {
      if (a.fecha_desde < b.fecha_desde)
        return -1;
    });

    let dataTotal = [];
    for(let i=0 ; i<listFilter.length ;i++){
      let arr = [];
      arr.push(moment(listFilter[i].fecha_desde).format('DD/MM/YYYY')+" "+moment(listFilter[i].fecha_desde).format('HH:mm')+" - "+moment(listFilter[i].fecha_hasta).format('HH:mm'));
      arr.push((listFilter[i].color_agenda == null) ? '#3788D8' : listFilter[i].color_agenda);
      arr.push(listFilter[i].paciente_nombre_apellido +" ("+ listFilter[i].medico_nombre_completo+")");
      dataTotal.push(arr);
    }

    await this.loadImage('assets/img/logo.png')
    .then((image:any) => {
      console.log('Image loaded successfully:', image);
      doc.addImage(image, 'JPEG', 30, 30, 70, 70);
    })
    .catch((error:any) => {
      console.error('Error loading image:', error);
    });

    await this.loadImage(`${GlobalVariable.BASE_API_URL}storage/images/centro-medico/${centroMedico.foto}`)
    .then((image:any) => {
      console.log('Image loaded successfully:', image);
      doc.addImage(image, 'JPEG', 495.28, 30 , 70, 70);
    })
    .catch((error:any) => {
      console.error('Error loading image:', error);
    });

    doc.setFontSize(30);
    doc.text('Agenda', 30, lineaY);

    lineaY += 20;
    doc.setFontSize(12);
    doc.text(`Fecha de Impresion ${moment(new Date()).format('DD/MM/YYYY HH:mm')}`, 30, lineaY);
    if(this.pacienteFiltro){
      lineaY += 20;
      doc.text(`Paciente: ${this.pacienteFiltro.nombreApellido}`, 30, lineaY);
    }
    
    lineaY += 20;
    autoTable(doc, {
      body: dataTotal,
      startY: lineaY,
      margin: { horizontal: 30 },
      tableLineColor: [189, 195, 199],
      tableLineWidth: 0.75,
      didDrawCell: (data) => {
        if (data.row.section === 'body' && data.column.index === 1) {
          let hex = data.cell.raw;
          var red = parseInt(hex[1]+hex[2],16);
          var green = parseInt(hex[3]+hex[4],16);
          var blue = parseInt(hex[5]+hex[6],16);
          let color = red+","+green+","+blue;
          let colorArray = color.split(",");
          doc.setFillColor(parseInt(colorArray[0]),parseInt(colorArray[1]),parseInt(colorArray[2]));    
          doc.roundedRect(data.cell.x, data.cell.y+5.75, 10, 10, 5, 5,'F');
        }
      },
      didParseCell: (data) => {
        if (data.row.section === 'body' && data.column.index === 1) {
          data.cell.text[0] = "";
        }
      },   
      didDrawPage: (dataArg) => {}
    }); 

    doc.save('agenda.pdf');
    observer.next(true);
  }


  //
  loadImage(url:any) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        console.log('img',img);
        img.onload = function() {
            console.log('img',img);
            resolve(img);
        };
        img.onerror = function() {
            reject(new Error('Image load failed'));
        };
        img.src = url;
    });
  }


  //
  openSnackBar(message: string, action: string) {
    this.snackBar.openFromComponent(NotificationComponent,{
      data: {
        message: message,
        action: action
      },
      duration: 2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [action]
    });
  }


}
