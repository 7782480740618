import { Component, OnInit} from "@angular/core";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.sass"],
})
export class AppComponent implements OnInit {
  

  //
  imageData: any;
  sideBar = true;
  login:boolean = false;


  //
  verLogin(bool:boolean){
    this.login = bool;
  }


  //
  constructor() {}


  //
  ngOnInit() {}
  

  //
  openSideBar(event) {
    this.sideBar = !this.sideBar;
  }


}
