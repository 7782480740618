<div class="divContent">
    <div *ngIf="habilitar">
        <mat-card class="cardTable mat-elevation-z4">
            <div class="topTitle"><mat-card class="titleSeccion">{{titulo}}</mat-card></div>
            <div class="topDesc">{{descripcion}}</div>
            <div class="divGrafico">
                <div class="grafico">
                    <ngx-charts-pie-chart
                            [view]="view"
                            [scheme]="colorScheme"
                            [results]="informesPorcentajeArray"
                            [gradient]="gradient"
                            [legend]="showLegend"
                            [legendPosition]="legendPosition"
                            [labels]="showLabels"
                            [doughnut]="isDoughnut"
                            (select)="onSelect($event)"
                            (activate)="onActivate($event)"
                            (deactivate)="onDeactivate($event)"
                    >
                    </ngx-charts-pie-chart>
                </div>
            </div>
        </mat-card>
    </div>
</div>