import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Agenda } from "../../../models/agenda.interface";

@Component({
  selector: "app-modal-advertencia-agendas",
  templateUrl: "./modal-advertencia-agendas.component.html",
  styleUrls: ["./modal-advertencia-agendas.component.scss"],
})
export class ModalAdvertenciaAgendasComponent implements OnInit {
  public arrayDeErrores: {
    overlappingDays: string[];
    agenda1: Agenda;
    agenda2: Agenda;
  }[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      errorData: {
        overlappingDays: string[];
        agenda1: Agenda;
        agenda2: Agenda;
      }[];
    },
    private dialogRef: MatDialogRef<ModalAdvertenciaAgendasComponent>
  ) {}

  ngOnInit(): void {
    this.arrayDeErrores = this.data.errorData;
  }

  public close() {
    this.dialogRef.close();
  }
}
