import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelComponent } from './panel/panel.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from "app/shared/shared.module";
import { MaterialModule } from 'app/material/material.module';
import { SharedPanelRoutingModule } from './shared-panel-routing.module'; 
import { FormsModule } from '@angular/forms';
import { HeaderPanelComponent } from './header-panel/header-panel.component';

@NgModule({
  declarations: [
    PanelComponent,
    DashboardComponent,
    HeaderPanelComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    SharedPanelRoutingModule,
    FormsModule
  ],
  exports: [
    PanelComponent,
    DashboardComponent,
    HeaderPanelComponent
  ],

})
export class SharedPanelModule { }
