import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RightArrowComponent } from "./right-arrow/right-arrow.component";

@NgModule({
  declarations: [RightArrowComponent],
  imports: [CommonModule],
  exports: [RightArrowComponent],
})
export class ComponentsModule {}
