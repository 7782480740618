export function generarTurnos(
  horaInicio: string,
  horaFin: string,
  duracionTurno: string
): string[] {
  const turnos: string[] = [];

  if (!horaInicio || !horaFin || !duracionTurno) {
    return turnos;
  }
  const [inicioHoras, inicioMinutos] = horaInicio?.split(":")?.map(Number);
  const [finHoras, finMinutos] = horaFin?.split(":")?.map(Number);
  const [duracionHoras, duracionMinutos] = duracionTurno
    ?.split(":")
    ?.map(Number);

  let currentHoras = inicioHoras;
  let currentMinutos = inicioMinutos;

  while (
    currentHoras < finHoras ||
    (currentHoras === finHoras && currentMinutos < finMinutos)
  ) {
    const siguienteHoras =
      currentHoras +
      duracionHoras +
      Math.floor((currentMinutos + duracionMinutos) / 60);
    const siguienteMinutos = (currentMinutos + duracionMinutos) % 60;

    if (
      siguienteHoras > finHoras ||
      (siguienteHoras === finHoras && siguienteMinutos > finMinutos)
    ) {
      break;
    }

    const turnoInicio = `${String(currentHoras).padStart(2, "0")}:${String(
      currentMinutos
    ).padStart(2, "0")}`;
    const turnoFin = `${String(siguienteHoras).padStart(2, "0")}:${String(
      siguienteMinutos
    ).padStart(2, "0")}`;
    turnos.push(`${turnoInicio} - ${turnoFin}`);

    currentHoras = siguienteHoras;
    currentMinutos = siguienteMinutos;
  }

  return turnos;
}

export const diasSemanaOrdenAnglo = [
  "domingo",
  "lunes",
  "martes",
  "miercoles",
  "jueves",
  "viernes",
  "sabado",
];
