import { Component, OnInit } from "@angular/core";
import {trigger,state,style,transition,animate,} from "@angular/animations";

@Component({
  selector: "app-studies",
  templateUrl: "./studies.component.html",
  styleUrls: ["./studies.component.scss"],
})
export class StudiesComponent implements OnInit {
  examenes: any[];

  cols: any[] = [
    { field: "code", header: "Fecha de creación" },
    { field: "study", header: "Estudio" },
    { field: "medic", header: "Médico", type: "entity" },
    { field: "laboratory", header: "Laboratorio", type: "entity" },
    { field: "estudios", header: "estudios", type: "entity" },
  ]; //Columnas de la tabla
  constructor() {}

  ngOnInit(): void {
    this.examenes = [
      {
        code: 1,
        medic: "John Doe",
        laboratory: "Dexter",
        documents: [
          {
            title: "Último examen",
            type: "pdf",
          },
          {
            title: "Doc. imagen",
            type: "image",
          },
        ],
      },
      { code: 2 },
    ];
  }
}
