import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationComponent } from '../notification/notification.component'; 
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup,FormControl,Validator, Validators } from '@angular/forms';
import { EstudioMedico } from 'app/models/estudioMedico.interface';
import { EstudioMedicoService } from 'app/services/estudio-medico.service';
import { SpinnerService } from 'app/services/spinner.service';


@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss']
})
export class FormDialogComponent implements OnInit {


  //
  estudioMedicoForm:FormGroup;
  editar:boolean;
  errorTexto:any;

  //
  constructor(
    public dialogRef:MatDialogRef<FormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public mensaje:string,
    private snackBar:MatSnackBar,
    private estudioMedicoService:EstudioMedicoService,
    private spinnerService:SpinnerService,
  ) { }


  //
  ngOnInit(): void {
    this.editar= false;
    this.estudioMedicoForm = new FormGroup({
      texto : new FormControl('',Validators.required)
    })
  }

  //
  clearTexto(){
    this.errorTexto = false;
  }



  //
  crearEstudioMedico(estudioMedico:EstudioMedico){
    if(this.estudioMedicoForm.valid){
      this.spinnerService.mostrarSpinner();
      this.estudioMedicoService.crear(estudioMedico).subscribe(
        data => {
          let response:any = data;
          if(response.errorTexto){
            this.errorTexto = response.errorTexto;
            this.spinnerService.ocultarSpinner();
            this.openSnackBar("Guardado fallido","error");
          }
          else{
            this.resetearFormulario();
            this.openSnackBar("Guardado exitoso","exito");
            this.onClickNo();
          }
        },
        error => {
          console.log('error',error);
          this.openSnackBar("Guardado fallido","error");
          this.spinnerService.ocultarSpinner();
        }
      )
    } 
    else{
      this.openSnackBar("Guardado fallido","error");
    }
  }


  //
  resetearFormulario(){
    this.estudioMedicoForm.reset();
  }
  

  //
  onClickNo(){
    this.dialogRef.close(true);
  }


  //
  openSnackBar(message: string, action: string) {
    this.snackBar.openFromComponent(NotificationComponent,{
      data: {
        message : message,
        action : action
      },
      duration:2500,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass : [action]
    });
  }


}


