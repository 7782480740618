<style>
  .mat-column-acciones {
    text-align: right;
  }

  .mat-column-archivo {
    text-align: center;
  }
</style>

<!-- **** -->
<div class="divContent">
  <div class="tituloNombre">Paciente: {{paciente?.nombreApellido}}</div>
  <ng-container *ngIf="tipoestudios == 1;else elseblock" (window:resize)="onResize($event)">
    <table class="mat-elevation-z4 mat-table" mat-table [dataSource]="dataSource" multiTemplateDataRows>
      <ng-container matColumnDef="fecha">
        <mat-header-cell *matHeaderCellDef>
          <span *ngIf="historialMedico==false" class="tabCell">FECHA ESTUDIO</span>
          <span *ngIf="historialMedico==true" class="tabCell">HISTORIAL MÉDICO</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="FECHA ESTUDIO">
          <span class="valCell">{{ element.fecha }}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="estudio">
        <mat-header-cell *matHeaderCellDef>
          <span *ngIf="historialMedico==false" class="tabCell">ESTUDIO</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="ESTUDIO">
          <span class="valCell">
            {{ element.nombreEM }}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="medico">
        <mat-header-cell *matHeaderCellDef>
          <span *ngIf="historialMedico==false" class="tabCell">MÉDICO</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="MEDICO">
          <div class="divFoto">
            <img class="tablaImg" style="display:none;" (load)="cargarImagen($event)"
              src="{{url}}storage/images/medico/{{element.imagenM}}" />
            <span class="valCell">{{ element.nombreM }}</span>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="centromedico">
        <mat-header-cell *matHeaderCellDef>
          <span *ngIf="historialMedico==false" class="tabCell">
            CENTRO MÉDICO
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="CENTRO MEDICO">
          <div class="divFoto">
            <img class="tablaImg" style="display:none;" (load)="cargarImagen($event)"
              src="{{url}}storage/images/centro-medico/{{element.imagenCM}}" />
            <span class="valCell">{{element.nombreCM}}</span>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="acciones">
        <mat-header-cell *matHeaderCellDef>
          <span *ngIf="historialMedico==false" class="tabCell">ACCIONES</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="ACCIONES">
          <div class="divAccion">
            <div class="divIcons">
              <div class="divIcon ver">
                <mat-icon [routerLink]="['/seccion-paciente/estudios-medicos/detalle',element.id]"
                  class="icon">visibility</mat-icon>
              </div>
            </div>
          </div>
        </mat-cell>
      </ng-container>
      <!--
    <ng-container matColumnDef="informes">
      <mat-header-cell *matHeaderCellDef>
        <span class="tabCell">INFORMES</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="INFORMES">
        <img [routerLink]="['/seccion-paciente/estudios-medicos/detalle',element.id]" *ngIf="element.cant!=0" src="assets/img/ico_informe.svg" class="informe"/>
      </mat-cell>
    </ng-container>
    -->
      <ng-container matColumnDef="expandedDetail">
        <td class="row-expanded color" mat-cell *matCellDef="let element" [attr.colspan]="cols.length">
          <div *ngIf="cellResponsive == false" class="example-element-detail"
            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div>
              <div class="grid grid-expansion">
                <div class="col-7">
                  <div class="divTitle">
                    <span class="colapTitle">Observaciones</span>
                  </div>
                  <div>{{ element.observaciones }}</div>
                  <img [routerLink]="['/seccion-paciente/estudios-medicos/detalle',element.id]"
                    matTooltip="Informe Completo"
                    aria-label="Button that displays a tooltip when focused or hovered over"
                    src="assets/img/ico_verMas.png" class="verMas" />
                </div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsFiles"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsFiles" class="example-element-row"
        [class.example-expanded-row]="expandedElement === element" (click)="select(expandedElement,element)"></tr>
      <!--expandedElement = expandedElement === element ? null : element-->
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
  </ng-container>

  <!-- **** -->
  <ng-template #elseblock>
    <table class="mat-elevation-z4 tablaEstudios" mat-table [dataSource]="dataSource" multiTemplateDataRows>
      <ng-container matColumnDef="fecha">
        <th mat-header-cell *matHeaderCellDef>
          Fecha estudio
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.fecha }}
        </td>
      </ng-container>

      <ng-container matColumnDef="archivo">
        <th mat-header-cell *matHeaderCellDef class="th"><span class="span">Nombre del documento</span> </th>
        <td mat-cell *matCellDef="let element" class="td"> {{ element.imagen? element.imagen.descripcion?
          element.imagen.descripcion : element.imagen.nombre : 'No tiene'}} </td>
      </ng-container>

      <ng-container matColumnDef="acciones">
        <th mat-header-cell *matHeaderCellDef class="th"> Acciones </th>
        <td mat-cell *matCellDef="let element" class="td">
          <mat-icon class="verImagen" (click)="mostrarImagen(element.imagen)">visibility</mat-icon>
          <mat-icon *ngIf="(element.imagen) ? element.imagen.nombre.slice(-4)=='xlsx' : ''" class="dExcel"
            (click)="descargarExcel(element)">download1</mat-icon>
          <mat-icon *ngIf="(element.imagen) ? element.imagen.nombre.slice(-3)=='pdf' : ''" class="dExcel"
            (click)="descargarPdf(element)">download2</mat-icon>
          <mat-icon
            *ngIf="(element.imagen) ? element.imagen.nombre.slice(-4)!='xlsx' && element.imagen.nombre.slice(-3)!='pdf' : ''"
            class="dExcel" (click)="descargarImage(element)">download3</mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsFile"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsFile;"></tr>
    </table>
  </ng-template>


  <p-overlayPanel #op [showCloseIcon]="true">
    <ng-template pTemplate>
      <img src="assets/img/avatar.png" />
      John Doe
      <p>Nombre:</p>
      <p>Matrícula:</p>
      <p>Teléfono:</p>
    </ng-template>
  </p-overlayPanel>
</div>